import request from "@/utils/studio7ApiService";

export function getNodeTypes(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/node/node-types?" + params.toString(),
    method: "get"
  });
}

export function createNodeType(nodeType) {
  return request({
    url: "/node/node-types",
    method: "post",
    data: nodeType
  });
}

export function updateNodeType(nodeType) {
  return request({
    url: `/node/node-types/${nodeType.node_type}`,
    method: "put",
    data: nodeType
  });
}

export function deleteNodeType(nodeType) {
  return request({
    url: `/node/node-types/${nodeType.node_type}`,
    method: "delete",
    data: nodeType
  });
}
