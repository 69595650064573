import request, { ac_headers, sp_headers } from "@/utils/studio7ApiService";
const base_url = "/portal/activity-logs?";

export function getActivityLogs(options = { fetch_all: 1 }, scope) {
  const params = new URLSearchParams(options);
  return request({
    url: base_url + params.toString(),
    method: "get",
    headers: scope === "ac" ? ac_headers() : sp_headers()
  });
}

export function getActivityLogEventNames(scope) {
  return request({
    url: "/portal/activity-log-names",
    method: "get",
    headers: scope === "ac" ? ac_headers() : sp_headers()
  });
}
