import request from "@/utils/studio7ApiService";

export function getTtsPackages(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/prompt/tts-packages?" + params.toString(),
    method: "get"
  });
}

// export function createTtsPackage(data) {
//   const ttsPackage = { ...data, guard_name: "admin" };
//   return request({
//     url: "/prompt/tts-packages",
//     method: "post",
//     data: ttsPackage
//   });
// }

export function createTtsPackage(data) {
  const ttsPackage = { ...data, guard_name: "admin" };
  return request({
    url: "/prompt/create-tts-packages",
    method: "post",
    data: ttsPackage
  });
}
//
// export function updateTtsPackage(ttsPackage) {
//   return request({
//     url: `/prompt/tts-packages/${ttsPackage.tts_package_id}`,
//     method: "put",
//     data: ttsPackage
//   });
// }

export function updateTtsPackage(ttsPackage) {
  return request({
    url: `/prompt/update-tts-packages/${ttsPackage.tts_package_id}`,
    method: "put",
    data: ttsPackage
  });
}

export function deleteTtsPackage(ttsPackage) {
  return request({
    url: `/prompt/tts-packages/${ttsPackage.tts_package_id}`,
    method: "delete"
  });
}

export function checkUniqueName(tts_package_name) {
  return request({
    url: `/prompt/validate-tts-pack-name/${tts_package_name}`,
    method: "get"
  });
}
