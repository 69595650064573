import request, { ac_headers } from "@/utils/studio7ApiService";

export function getASRLanguages(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/util/asr-languages?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getASRLanguageGroup(options = { tree: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/util/asr-language-group?" + params.toString(),
    method: "get"
  });
}
