import store from "@/store";
import __ from "@/translation";

export const pasteNode = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/triggerPasteNode", {
      id
    });
  }
};

export default {
  text: `${__("Paste Node")}<span>Ctrl+V</span>`,
  icon: "",
  onClick: pasteNode
};
