import request, { sp_headers } from "@/utils/studio7ApiService";

export function getPasswordPolicies(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/auth/password-policies?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function createPasswordPolicy(passwordPolicy) {
  return request({
    url: "/auth/password-policies",
    method: "post",
    headers: sp_headers(),
    data: passwordPolicy
  });
}

export function updatePasswordPolicy(passwordPolicy) {
  return request({
    url: `/auth/password-policies/${passwordPolicy.password_policy_id}`,
    method: "put",
    headers: sp_headers(),
    data: passwordPolicy
  });
}

export function deletePasswordPolicy(passwordPolicy) {
  return request({
    url: `/auth/password-policies/${passwordPolicy.password_policy_id}`,
    method: "delete",
    headers: sp_headers(),
    data: passwordPolicy
  });
}
