import { render, staticRenderFns } from "./HelperIcons.vue?vue&type=template&id=5bba8972&scoped=true"
import script from "./HelperIcons.vue?vue&type=script&lang=js"
export * from "./HelperIcons.vue?vue&type=script&lang=js"
import style0 from "./HelperIcons.vue?vue&type=style&index=0&id=5bba8972&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bba8972",
  null
  
)

export default component.exports