import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

export function getPhoneNumbers(options = { fetch_all: 1 }, sys_telephony = 0) {
  options["sys_telephony"] = sys_telephony;
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/phone-numbers?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function getCampaignNumbers(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/campaign-numbers?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createPhoneNumber(data) {
  const phoneNumber = { ...data, guard_name: "admin" };
  return request({
    url: "/portal/phone-numbers",
    method: "post",
    data: phoneNumber,
    headers: sp_headers()
  });
}

export function updatePhoneNumber(phoneNumber) {
  return request({
    url: `/portal/phone-numbers/${phoneNumber.phone_number_id}`,
    method: "put",
    data: phoneNumber,
    headers: sp_headers()
  });
}

export function deletePhoneNumber(phoneNumber) {
  return request({
    url: `/portal/phone-numbers/${phoneNumber.phone_number_id}`,
    method: "delete",
    data: phoneNumber,
    headers: sp_headers()
  });
}

export function movePhoneNumbers(data) {
  const phoneNumbers = { ...data };
  return request({
    url: "/portal/move-phone-numbers",
    method: "post",
    data: phoneNumbers,
    headers: sp_headers()
  });
}

export function manageVirtualPhoneNumbers(data) {
  const phoneNumbers = { ...data };
  return request({
    url: "/portal/manage-virtual-phone-numbers",
    method: "post",
    data: phoneNumbers,
    headers: ac_headers()
  });
}

export function manageMultiplePhoneNumbersAction(data) {
  const phoneNumbers = { ...data };
  return request({
    url: "/portal/multiple-phone-numbers",
    method: "post",
    data: phoneNumbers,
    headers: sp_headers()
  });
}

export function switchStudioVersion(phoneNumber, useStudio6) {
  return request({
    url: `/portal/switch-phone-number-studio-version/${phoneNumber.phone_number_id}`,
    method: "post",
    data: {
      use_studio_6: useStudio6
    },
    headers: sp_headers()
  });
}
