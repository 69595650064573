import request, { ac_headers } from "@/utils/studio7ApiService";

export function getKnowledgeGroups() {
  return request({
    url: "/knowledge-distiller/knowledge-groups",
    method: "get",
    headers: ac_headers()
  });
}

export function getKnowledgePrompts() {
  return request({
    url: "/knowledge-distiller/prompts",
    method: "get",
    headers: ac_headers()
  });
}
