import router, { resetRouter } from "@/router";
import store from "@/store";

const resetRoutes = async () => {
  await resetRouter();
  const accessRoutes = await store.dispatch("permission/generateRoutes");
  accessRoutes.forEach(accessRoute => router.addRoute(accessRoute));
  // router.addRoutes(accessRoutes);
};

/**
 * Use meta.scope to determine if the scope currently the user in allows access to a route
 * @param scopes
 * @param route
 */
function hasScope(scopes, route) {
  if (route.meta && route.meta.scopes) {
    return scopes.some(scope => route.meta.scopes.includes(scope));
  } else {
    return true;
  }
}

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

function isAccountLevelPermissionCheckForRouteRequiredForUser() {
  return store.getters.selectedAccountId !== "all";
}

function isServiceProviderLevelPermissionCheckForRouteRequiredForUser(route) {
  return (
    store.getters.userType === "sp" &&
    (route.path.includes("manage") ||
      route.path.includes("discover") ||
      route.path.includes("analyse"))
  );
}

function isSystemLevelPermissionCheckForRouteRequiredForUser(route) {
  return route.path.includes("system") && store.getters.userRole !== "system";
}

function hasPrivilege(route) {
  if (route.meta.permissions && route.meta.permissions.length > 0) {
    if (
      isAccountLevelPermissionCheckForRouteRequiredForUser() ||
      isServiceProviderLevelPermissionCheckForRouteRequiredForUser(route) ||
      isSystemLevelPermissionCheckForRouteRequiredForUser(route)
    ) {
      return store.state.app.enabled_features.includes(
        route.meta.permissions[0]
      );
    } else {
      return true;
    }
  } else {
    return true;
  }
}

/**
 * Return current route
 */
function getCurrentRoute() {
  return router.history.current.path;
}

/**
 * Filter special routes to mismatch the backend routes
 */
function filterSpecialRoutes(current_route) {
  // Add any routes that differ from the permission routes that are defined in backend
  switch (current_route) {
    case "manage.ac_plans":
      return "manage.account_plan";
    case "system.sp_plans":
      return "system.service_provider_plans";
    default:
      return current_route;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param scopes
 * @param roles
 */
export function filterAsyncRoutes(routes, scopes, roles) {
  const res = [];

  routes.forEach(route => {
    const tmp = { ...route };
    if (hasScope(scopes, tmp) && hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, scopes, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

export default {
  resetRoutes,
  hasScope,
  hasPermission,
  filterAsyncRoutes,
  filterSpecialRoutes,
  getCurrentRoute,
  hasPrivilege
};
