import { getActivityLogs, getActivityLogEventNames } from "@/api/activitylogs";
import _ from "lodash";

const state = {
  activityLogs: [],
  eventNames: [],
  isLoading: false
};

const mutations = {
  SET_ACTIVITY_LOGS(state, activityLogs) {
    state.activityLogs = activityLogs;
  },
  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ACTIVITY_LOG_EVENT_NAMES(state, eventNames) {
    state.eventNames = eventNames;
  }
};

const getters = {
  groupedEventNamesBySubject: state => {
    return _.groupBy(state.eventNames, event => event.subject_type);
  }
};

const actions = {
  getActivityLogs({ commit, rootState }, options) {
    options.display_date_format = rootState.app.displayDateFormat;
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getActivityLogs(options, rootState.app.userApplicationViewScope)
        .then(({ data }) => {
          if (data && Array.isArray(data.data)) {
            data.data.forEach(item => {
              if (!item.event_details) {
                item.event_details = [];
              }
              item.event_details.push({
                title: "Timestamp",
                detail: item.created_at
              });
            });
          }

          commit("SET_ACTIVITY_LOGS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  getActivityLogEventNames({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getActivityLogEventNames(rootState.app.userApplicationViewScope)
        .then(({ data }) => {
          commit("SET_ACTIVITY_LOG_EVENT_NAMES", data.data);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
