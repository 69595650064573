import Vue from "vue";
import _ from "lodash";
import store from "@/store";
import routerUtils from "@/utils/router";

const can = key => {
  return _.some(store.getters.enabledFeatures, feature => {
    let transformedFeature = feature
      .replaceAll("_", "-")
      .replaceAll(" ", "_")
      .toString()
      .toLowerCase();

    let transformedKey = key
      .replaceAll("_", "-")
      .replaceAll(" ", "_")
      .toString()
      .toLowerCase();

    return transformedFeature.includes(transformedKey);
  });
};

Vue.mixin({
  computed: {
    canRead() {
      let self = this;
      return key => {
        let basePath = self.getCurrentRoute(key);
        if (basePath !== "/") return self.can(basePath + ".read");
        else return false;
      };
    },
    canWrite() {
      let self = this;
      return key => {
        let basePath = self.getCurrentRoute(key);
        if (basePath !== "/") return self.can(basePath + ".write");
        else return false;
      };
    },
    can() {
      return key => {
        return can(key);
      };
    }
  },
  methods: {
    getCurrentRoute: additional_path => {
      let current_route = routerUtils.getCurrentRoute();
      if (current_route !== "/") {
        current_route = _.replace(current_route, /\//g, ".")
          .replace(/-/g, "_")
          .toString()
          .toLowerCase();
        //  Remove the '.' as well if present at the end of string which depends
        //  whether page is refreshed or routed from other page otherwise just remove '.' at start of string
        if (_.endsWith(current_route, "."))
          current_route = current_route.slice(1, -1);
        else current_route = current_route.slice(1);

        // Filter and get the special route to match the backend permission route
        current_route = routerUtils.filterSpecialRoutes(current_route);

        if (additional_path) {
          current_route += `.${additional_path}`;
        }
      }
      return current_route;
    },

    mandatory_permission: (checkedNodes, change) => {
      return _.filter(
        _.map(checkedNodes, function(node) {
          let pathLabel = node.pathLabels.join(".");
          if (pathLabel.includes(".write")) {
            let searchString = pathLabel.replaceAll("write", "read").toString();
            let values = _.flatMap(change);
            let valuesString = values.join(".");
            if (!valuesString.includes(searchString)) {
              return searchString.split(".");
            }
          }
        }),
        function(value) {
          if (value !== undefined) {
            return value;
          }
        }
      );
    }
  }
});

export default can;
