import {
  getDynamicFormTypes,
  createDynamicFormType,
  updateDynamicFormType,
  deleteDynamicFormType,
  publishDynamicFormTypeAsSystem,
  deleteSystemDynamicFormType
} from "@/api/dynamicformtypes";
import { cloneDeep, find, findLastIndex } from "lodash";

const state = {
  dynamicformtypes: [],
  loading: false
};

const getters = {
  /**
   * get a dynamic form type by its id
   * @param state
   * @returns {function(*): T}
   */
  getDynamicFormTypeOfId(state) {
    return dft_id => {
      return cloneDeep(
        find(state.dynamicformtypes, {
          dynamic_form_type_id: dft_id
        })
      );
    };
  }
};

const mutations = {
  SET_DYNAMICFORMTYPES(state, dynamicformtypes) {
    state.dynamicformtypes = dynamicformtypes;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  EDIT_DYNAMIC_FORM_TYPE(state, dynamicFormType) {
    let dynamicFormTypes = cloneDeep(state.dynamicformtypes);
    const index = findLastIndex(dynamicFormTypes, {
      dynamic_form_type_id: dynamicFormType.dynamic_form_type_id
    });

    if (index !== -1) {
      dynamicFormTypes.splice(index, 1, dynamicFormType);
      state.dynamicformtypes = dynamicFormTypes;
    }
  }
};

const actions = {
  /**
   * get dynamic form types
   * @param commit
   * @param options
   * @returns {Promise<unknown>}
   */
  getDynamicFormTypes({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getDynamicFormTypes(options)
        .then(({ data }) => {
          commit("SET_DYNAMICFORMTYPES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * get system dynamic form types
   * @param commit
   * @param options
   * @returns {Promise<unknown>}
   */
  getSystemDynamicFormTypes({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options.only_is_system = true;
      getDynamicFormTypes(options)
        .then(({ data }) => {
          commit("SET_DYNAMICFORMTYPES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * create dynamic form type
   * @param commit
   * @param dynamicformtype
   * @returns {Promise<unknown>}
   */
  createDynamicFormType({ commit }, dynamicformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createDynamicFormType(dynamicformtype)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * update dynamic form type
   * @param commit
   * @param dynamicformtype
   * @returns {Promise<unknown>}
   */
  updateDynamicFormType({ commit }, dynamicformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const DynamicFormTypeObj = { ...dynamicformtype };
      updateDynamicFormType(DynamicFormTypeObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * set is refreshing for a particular dynamic form type
   * @param commit
   * @param getters
   * @param content_id
   * @param refreshing
   */
  refreshDynamicFormType({ commit, getters }, { content_id, refreshing }) {
    let dynamicFormType = getters.getDynamicFormTypeOfId(content_id);

    if (dynamicFormType) {
      dynamicFormType.is_refreshing = refreshing;
      commit("EDIT_DYNAMIC_FORM_TYPE", dynamicFormType);
    }
  },

  /**
   * delete dynamic form
   * @param commit
   * @param dynamicformtype
   * @returns {Promise<unknown>}
   */
  deleteDynamicFormType({ commit }, dynamicformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteDynamicFormType(dynamicformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * delete system dynamic form
   * @param commit
   * @param dynamicformtype
   * @returns {Promise<unknown>}
   */
  deleteSystemDynamicFormType({ commit }, dynamicformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteSystemDynamicFormType(dynamicformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * undo delete dynamic form
   * @param commit
   * @param dynamicformtype
   * @returns {Promise<unknown>}
   */
  undoDeleteDynamicFormType({ commit }, dynamicformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      dynamicformtype.is_enabled = 1;
      updateDynamicFormType(dynamicformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * safe delete dynamic form
   * @param commit
   * @param dynamicformtype
   * @returns {Promise<unknown>}
   */
  safeDeleteDynamicFormType({ commit }, dynamicformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      dynamicformtype.is_archived = 1;
      updateDynamicFormType(dynamicformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  /**
   * publish system dynamic form
   * @param commit
   * @param openFormType
   * @returns {Promise<unknown>}
   */
  publishDynamicFormTypeAsSystem({ commit }, openFormType) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      publishDynamicFormTypeAsSystem(openFormType)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
