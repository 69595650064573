import request, { ac_headers } from "@/utils/studio7ApiService";

export function getDataStores(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/data/data-store?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createDataStore(data) {
  const dataStore = { ...data, guard_name: "admin" };
  return request({
    url: "/data/data-store",
    method: "post",
    data: dataStore,
    headers: ac_headers()
  });
}

export function updateDataStore(dataStore) {
  return request({
    url: `/data/data-store/${dataStore.data_store_id}`,
    method: "put",
    data: dataStore,
    headers: ac_headers()
  });
}

export function deleteDataStore(dataStore) {
  return request({
    url: `/data/data-store/${dataStore.data_store_id}`,
    method: "delete",
    data: dataStore,
    headers: ac_headers()
  });
}

export function checkUniqueName(displayName) {
  return request({
    url: `/data/validate-ds-name/${displayName}`,
    method: "get",
    headers: ac_headers()
  });
}

// export function getDataStoreData(dataStore, formData) {
//   return request({
//     url: `data/data-store-collection/`,
//     method: "get",
//     headers: ac_headers(),
//     data: formData
//   });
// }

export function uploadDataStoreData(dataStore, formData) {
  return request({
    url: `data/${dataStore.data_store_id}/upload`,
    method: "post",
    headers: ac_headers(),
    data: formData
  });
}

export function getDatastoreDataTemplate(dataStore) {
  return request({
    url: `data/${dataStore.data_store_id}/template`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}
