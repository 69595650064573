import request, { sp_headers } from "@/utils/studio7ApiService";

export function upload(data) {
  return request({
    url: `/services/upload`,
    method: "post",
    data: data,
    headers: sp_headers()
  });
}

export function deleteUpload(path) {
  return request({
    url: `/services/delete-upload`,
    method: "post",
    data: path,
    headers: sp_headers()
  });
}

export function removeUnusedFiles(data) {
  return request({
    url: `/services/remove-unused-files-replacement`,
    method: "post",
    data: data,
    headers: sp_headers()
  });
}
