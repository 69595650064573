import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

export function getActiveCalls(scope) {
  return request({
    url: "/opensip/active-calls",
    method: "post",
    // data: data,
    headers: scope === "ac" ? ac_headers() : scope === "sp" ? sp_headers() : {}
  });
}

export function clearStaleCalls(data) {
  return request({
    url: "/opensip/clear-stale-calls",
    method: "post",
    data
  });
}
