import {
  getOpenFormTypes,
  createOpenFormType,
  updateOpenFormType,
  deleteOpenFormType,
  previewDialogflowResponse,
  previewLexResponse,
  publishOpenFormTypeAsSystem,
  deleteSystemOpenFormType
} from "@/api/openformtypes";
import _ from "lodash";
/* eslint-disable no-unused-vars */
import { nlpEngineTypes } from "@/constants/openFormType";

const state = {
  openformtypes: [],
  loading: false,
  nlpEngineTypes: nlpEngineTypes
};

const getters = {
  getNlpEngineTypeLabel: state => nlpEngineValue => {
    let nlpEngine = _.find(state.nlpEngineTypes, {
      value: nlpEngineValue
    });
    return nlpEngine ? nlpEngine.label : nlpEngineValue;
  },

  openformtypes(state) {
    return _.cloneDeep(_.sortBy(state.openformtypes, ["oft_name"]));
  }
};

const mutations = {
  SET_OPENFORMTYPES(state, openformtypes) {
    state.openformtypes = openformtypes;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getOpenFormTypes({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getOpenFormTypes(options)
        .then(({ data }) => {
          commit("SET_OPENFORMTYPES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  resetOpenFormTypes({ commit }) {
    commit("SET_OPENFORMTYPES", []);
  },

  getSystemOpenFormTypes({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options.only_is_system = true;
      getOpenFormTypes(options)
        .then(({ data }) => {
          commit("SET_OPENFORMTYPES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  createOpenFormType({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createOpenFormType(openformtype)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateOpenFormType({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const OpenFormTypeObj = { ...openformtype };
      updateOpenFormType(OpenFormTypeObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteOpenFormType({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteOpenFormType(openformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteSystemOpenFormType({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteSystemOpenFormType(openformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  undoDeleteOpenFormType({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      openformtype.is_enabled = 1;
      updateOpenFormType(openformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  safeDeleteOpenFormType({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      openformtype.is_archived = 1;
      updateOpenFormType(openformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  previewDialogflowResponse({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      previewDialogflowResponse(openformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  previewLexResponse({ commit }, openformtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      previewLexResponse(openformtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  publishOpenFormTypeAsSystem({ commit }, openFormType) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      publishOpenFormTypeAsSystem(openFormType)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  refreshOpenFormType({ commit, getters }, { content_id, refreshing }) {
    let openformtypes = _.map(getters.openformtypes, openformtype => {
      if (openformtype.open_form_type_id === content_id) {
        openformtype.is_refreshing = refreshing;
      }
      return openformtype;
    });
    commit("SET_OPENFORMTYPES", openformtypes);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
