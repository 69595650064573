import request, { ac_headers } from "@/utils/studio7ApiService";

export function getXsiSubscriptions(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/broadworks/xsi-subscriptions?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getXsiPackages(xsiProfile) {
  return request({
    url: "/broadworks/xsi-packages",
    method: "post",
    headers: ac_headers(),
    data: xsiProfile
  });
}

export function createXsiSubscription(xsiSubscription) {
  return request({
    url: "/broadworks/xsi-subscriptions",
    method: "post",
    headers: ac_headers(),
    data: xsiSubscription
  });
}

export function deleteXsiSubscription(xsiSubscription) {
  return request({
    url: `/broadworks/xsi-subscriptions/${xsiSubscription.xsi_subscription_id}`,
    method: "delete",
    headers: ac_headers(),
    data: xsiSubscription
  });
}

export function getXsiSubscriptionRules(taskId) {
  return request({
    url: `/broadworks/get-xsi-rules?task_id=${taskId}`,
    method: "get",
    headers: ac_headers()
  });
}
