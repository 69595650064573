import { get } from "lodash";

/**
 * Generate the account name label for a given row
 * @param { Object } accountRow
 */
export function accountNameLabel(accountRow) {
  if (accountRow.label) {
    return accountRow.label;
  }

  const domainInfo = get(accountRow, "ac_domain_map.data");

  if (!get(domainInfo, "domain_id")) {
    return accountRow.ac_name;
  }

  return domainInfo.domain_name;
}
