import { getASRLanguages, getASRLanguageGroup } from "@/api/asrLanguages";
import _ from "lodash";

const state = {
  asrLanguages: [],
  asrLanguageMap: {},
  loading: false,
  test: [
    {
      language_id: 1,
      language_name: "Test1"
    },
    {
      language_id: 2,
      language_name: "Test2"
    }
  ]
};

const mutations = {
  SET_ASRLANGUAGES(state, asrLanguages) {
    state.asrLanguages = asrLanguages;
    let id_map = {};
    /*state.asrLanguageMap = _.flatMap(
      _.map(asrLanguages, function(language) {
        console.log(language);
        return _.map(language["children"], function(item) {
          return [language.label, item.value];
        });
      })
    );*/
    _.map(asrLanguages, function(language) {
      _.map(language["children"], function(item) {
        if (id_map[item.value] === undefined) {
          id_map[item.value] = [];
        }
        id_map[item.value].push(language.label);
      });
    });
    state.asrLanguageMap = id_map;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getASRLanguages({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getASRLanguages(options)
        .then(({ data }) => {
          commit("SET_ASRLANGUAGES", data.data);
          commit("CHANGE_LOADING", false);
          resolve();
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getASRLanguageGroup({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getASRLanguageGroup(options)
        .then(({ data }) => {
          commit("SET_ASRLANGUAGES", data.data);
          commit("CHANGE_LOADING", false);
          resolve();
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
