import request, { ac_headers } from "@/utils/studio7ApiService";
import store from "@/store";

export function fetchVariables(
  options = { fetch_all: 1, scope: store.state.tasks.taskFilter },
  cancelToken
) {
  const params = new URLSearchParams(options);
  return request({
    url: "/content/variables?" + params.toString(),
    method: "get",
    cancelToken: cancelToken,
    headers: ac_headers()
  });
}

export function createVariable(variable) {
  return request({
    url: "/content/variables",
    method: "post",
    data: variable,
    headers: ac_headers()
  });
}

export function updateVariable(variable) {
  return request({
    url: `/content/variables/${variable.variable_id}`,
    method: "put",
    data: variable,
    headers: ac_headers()
  });
}

export function deleteVariable(variable) {
  return request({
    url: `/content/variables/${variable.variable_id}`,
    method: "delete",
    data: variable,
    headers: ac_headers()
  });
}

export function validateVariable(name) {
  return request({
    url: `/content/variables/validate`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}

export function getComplexVariables(taskId) {
  return request({
    url: `/content/get_complex_variables?task_id=${taskId}`,
    method: "get",
    headers: ac_headers()
  });
}
