import store from "@/store";
import __ from "@/translation";

const addNewNode = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/startAddNewNodeProcess", {
      id,
      addNewNodeBelow: true
    });
  }
};

export default {
  text: __("Insert Node Below"),
  icon: "",
  onClick: addNewNode
};
