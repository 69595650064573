import {
  getJavascriptFunctions,
  createJavascriptFunction,
  updateJavascriptFunction,
  deleteJavascriptFunction
} from "@/api/javascript";
import _ from "lodash";

const state = {
  javascripts: [],
  systemLogJavascripts: [],
  loading: false
};

export const getters = {
  javascriptNames(state) {
    state;
    return [];
  },

  javascriptCollectionStructure: state => (
    javascript_function_id,
    is_date = false
  ) => {
    state;
    javascript_function_id;
    is_date;
    return [];
  },

  /**
   * get a js function by its id
   * @param state
   * @returns {function(*): T}
   */
  getJavascriptFunctionOfId(state) {
    return jsf_id => {
      return _.cloneDeep(
        _.find(state.javascripts, {
          javascript_function_id: jsf_id
        })
      );
    };
  }
};
const mutations = {
  SET_JAVASCRIPT(state, javascripts) {
    state.javascripts = javascripts;
  },
  SET_SYSTEM_LOG_JAVASCRIPT(state, javascripts) {
    state.systemLogJavascripts = javascripts;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  EDIT_JAVASCRIPT_FUNCTION(state, javascriptFunction) {
    let javascriptFunctions = _.cloneDeep(state.javascripts);
    const index = _.findLastIndex(javascriptFunctions, {
      javascript_function_id: javascriptFunction.javascript_function_id
    });

    if (index !== 1) {
      javascriptFunctions.splice(index, 1, javascriptFunction);
      state.javascripts = javascriptFunctions;
    }
  }
};

export const actions = {
  setLoading({ commit }, value) {
    commit("CHANGE_LOADING", value);
  },

  resetJavascript({ commit }) {
    commit("SET_JAVASCRIPT", []);
  },

  getJavascript({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getJavascriptFunctions(options)
        .then(({ data }) => {
          commit("SET_JAVASCRIPT", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getJavascriptFunctionsForList({ dispatch }, options) {
    options = { ...options, is_log: 0 };
    return new Promise((resolve, reject) => {
      dispatch("getJavascript", options)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  createJavascriptFunction({ commit }, javascript) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createJavascriptFunction(javascript)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateJavascriptFunction({ commit }, javascript) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const jsObj = { ...javascript };
      updateJavascriptFunction(jsObj)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  safeDeleteJavascriptFunction({ commit }, javascript) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      javascript.is_archived = 1;
      const jsObj = { ...javascript };
      updateJavascriptFunction(jsObj)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  deleteJavascriptFunction({ commit }, javascript) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteJavascriptFunction(javascript)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },
  undoDeleteJavascriptFunction({ commit }, javascript) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      javascript.is_enabled = 1;
      updateJavascriptFunction(javascript)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  /**
   * set is refreshing for a particular js function
   * @param commit
   * @param getters
   * @param content_id
   * @param refreshing
   */
  refreshJavascriptFunction({ commit, getters }, { content_id, refreshing }) {
    let javascriptFunction = getters.getJavascriptFunctionOfId(content_id);
    if (javascriptFunction) {
      javascriptFunction.is_refreshing = refreshing;
      commit("EDIT_JAVASCRIPT_FUNCTION", javascriptFunction);
    }
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
