import {
  getPermissions,
  createPermission,
  updatePermission,
  deletePermission,
  getAcScopePermissions,
  getUserPermissions,
  getRolePermissions
} from "@/api/permissions";
//import _ from "lodash";

const state = {
  permissions: [],
  isLoading: false
};

const mutations = {
  SET_PERMISSION(state, permissions) {
    state.permissions = permissions;
  },

  CREATE_PERMISSION(state, permission) {
    state.permissions.push(permission);
  },

  UPDATE_PERMISSION(state, { index, permission }) {
    state.permissions.splice(index, 1, permission);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getPermissions({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getPermissions(options)
        .then(({ data }) => {
          commit("SET_PERMISSION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  setEmptyPermission({ commit }) {
    commit("SET_PERMISSION", []);
  },

  createPermission({ commit }, permission) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createPermission(permission)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updatePermission({ commit }, permission) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      updatePermission(permission)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deletePermission({ commit }, permission) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deletePermission(permission)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  undoDeleteContent({ commit }, permission) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      permission.is_enabled = 1;
      updatePermission(permission)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getAcScopePermissions({ commit }, scope) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAcScopePermissions(scope)
        .then(({ data }) => {
          commit("SET_PERMISSION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getUserPermissions({ commit }, { scope, user_id, overlap_from }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getUserPermissions(scope, user_id, overlap_from)
        .then(({ data }) => {
          commit("SET_PERMISSION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getRolePermissions({ commit }, { scope, role_id }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getRolePermissions(scope, role_id)
        .then(({ data }) => {
          commit("SET_PERMISSION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
