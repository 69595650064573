import {
  createRegion,
  deleteRegion,
  getRegions,
  searchRegions,
  updateRegion
} from "@/api/regions";

import _ from "lodash";

const state = {
  regions: [],
  isLoading: false
};

const mutations = {
  SET_REGION(state, regions) {
    state.regions = regions;
  },

  CREATE_REGION(state, region) {
    state.regions.push(region);
  },

  UPDATE_REGION(state, { index, region }) {
    state.regions.splice(index, 1, region);
  },

  DELETE_REGION(state, region) {
    state.regions = state.regions.filter(
      oldRegion => oldRegion.region_id !== region.region_id
    );
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const getters = {};

const actions = {
  getRegionsAction({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getRegions(options)
        .then(({ data }) => {
          commit("SET_REGION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  createRegionAction({ commit }, region) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createRegion(region)
        .then(({ data }) => {
          commit("CREATE_REGION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updateRegionAction({ commit, state }, region) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      updateRegion(region)
        .then(res => {
          var index = _.findIndex(state.regions, function(oldRegion) {
            return oldRegion.region_id === region.region_id;
          });
          commit("UPDATE_REGION", { index, region: res.data.data });

          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deleteRegionAction({ commit }, region) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      deleteRegion(region)
        .then(data => {
          commit("DELETE_REGION", region);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  undoDeleteContent({ commit }, region) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      region.is_enabled = 1;
      updateRegion(region)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  searchRegions({ commit }, queryString) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      searchRegions(queryString)
        .then(({ data }) => {
          commit("SET_REGION", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve();
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
