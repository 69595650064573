import {
  getAsrLanguagePackages,
  createAsrLanguagePackage,
  updateAsrLanguagePackage,
  deleteAsrLanguagePackage
} from "@/api/asrlanguagepack";
import _ from "lodash";

/*const getDistinctVoices = (ttsPackages, languages) => {
  const languagesFromDB = _.uniq(_.map(ttsPackages, "lang"));
  let newLanguages = [];
  _.map(languagesFromDB, languageFromDB => {
    const language = _.find(languages, { value: languageFromDB });
    if (!language) {
      newLanguages.push({ label: languageFromDB, value: languageFromDB });
    }
  });
  return newLanguages;
};*/

export const state = {
  asrLanguagePackages: [],
  originalPackages: [],
  loading: false
};

export const getters = {
  ttsPackageNames(state) {
    if (state.ttsPackages) {
      return _.map(
        _.orderBy(state.ttsPackages, ["updated_at"], ["desc"]),
        "tts_package_name"
      );
    } else {
      return [];
    }
  },

  getProviderLabel(state) {
    return provider => {
      return _.find(state.providers, { value: provider }).label;
    };
  },

  getLanguageLabel(state) {
    return language => {
      return _.find(state.languages, { value: language }).label;
    };
  }
};

export const mutations = {
  SET_ASR_LANGUAGE_PACKAGE(state, asrLanguagePackages) {
    state.asrLanguagePackages = asrLanguagePackages;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_ORIGINAL_PLAN_ASR_PACKAGES(state, asrLanguagePackages) {
    state.originalPackages = asrLanguagePackages;
  }

  /*ADD_TO_LANGUAGES(state, languages) {
    state.languages = _.concat(state.languages, languages);
  }*/
};

export const actions = {
  getAsrLanguagePackages({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getAsrLanguagePackages(options)
        .then(async ({ data }) => {
          commit("SET_ASR_LANGUAGE_PACKAGE", data.data);
          commit("SET_ORIGINAL_PLAN_ASR_PACKAGES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  setASRPackagesExcludingBasePlanPackages({ commit }, asrLanguagePackages) {
    commit("SET_ASR_LANGUAGE_PACKAGE", asrLanguagePackages);
  },

  resetASRPacksAsPerPlanType({ commit, state }) {
    commit("SET_ASR_LANGUAGE_PACKAGE", state.originalPackages);
  },
  createAsrLanguagePackage({ commit }, asrLanguagePackage) {
    return new Promise((resolve, reject) => {
      // mock data which needs to be replaced
      commit("CHANGE_LOADING", true);
      createAsrLanguagePackage(asrLanguagePackage)
        .then(async ({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateAsrLanguagePackage({ commit }, asrLanguagePackage) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const asrLanguagePackageObj = { ...asrLanguagePackage };
      updateAsrLanguagePackage(asrLanguagePackageObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteAsrLanguagePackage({ commit }, asrLanguagePackage) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteAsrLanguagePackage(asrLanguagePackage)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteAsrLanguagePackage({ commit }, asrLanguagePackage) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      asrLanguagePackage.is_enabled = 1;
      updateAsrLanguagePackage(asrLanguagePackage)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
