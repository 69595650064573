import request, { sp_headers } from "@/utils/studio7ApiService";

export function getAcPlans(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/ac-plans?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function createAcPlan(acPlan) {
  return request({
    url: "/billing/ac-plans",
    method: "post",
    data: acPlan,
    headers: sp_headers()
  });
}

export function updateAcPlan(acPlan) {
  return request({
    url: `/billing/ac-plans/${acPlan.ac_plan_id}`,
    method: "put",
    data: acPlan,
    headers: sp_headers()
  });
}

export function deleteAcPlan(acPlan) {
  return request({
    url: `/billing/ac-plans/${acPlan.ac_plan_id}`,
    method: "delete",
    data: acPlan,
    headers: sp_headers()
  });
}

export function getAccountsForSP(options) {
  const params = new URLSearchParams(options);
  return request({
    url: `/billing/ac-plans-sp-accounts?` + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function getAcPlansForSelectedAccount(ac_id, plan_type) {
  return request({
    url: `/billing/ac-plans-for-accounts/${ac_id}/${plan_type}`,
    method: "get",
    headers: sp_headers()
  });
}

export function getAllocatedPlanAndUpdatedUnits(ac_plan_id) {
  return request({
    url: `/billing/ac-allocated-plan/${ac_plan_id}`,
    method: "get",
    headers: sp_headers()
  });
}

export function getAccountFeatureLimits(options) {
  const params = new URLSearchParams(options);
  return request({
    url: `/billing/ac-feature-limit?` + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}
