import request, { ac_headers } from "@/utils/studio7ApiService";

export function getPaymentGateways(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/payment/payment-gateway?" + params.toString(),
    method: "get",
    ...(!options.system_access && { headers: ac_headers() })
  });
}

export function createPaymentGateway(data) {
  const paymentGateway = { ...data, guard_name: "admin" };
  return request({
    url: "/payment/payment-gateway",
    method: "post",
    data: paymentGateway
  });
}

export function updatePaymentGateway(paymentGateway) {
  return request({
    url: `/payment/payment-gateway/${paymentGateway.payment_gateway_id}`,
    method: "put",
    data: paymentGateway
  });
}

export function deletePaymentGateway(paymentGateway) {
  return request({
    url: `/payment/payment-gateway/${paymentGateway.payment_gateway_id}`,
    method: "delete",
    data: paymentGateway
  });
}

export function checkUniqueName(paymentGatewayName) {
  return request({
    url: `/payment/payment-gateway/validate-name/${paymentGatewayName}`,
    method: "get"
  });
}
