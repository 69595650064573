import request from "@/utils/studio7ApiService";

export function getAnnouncements(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/system/announcements?" + params.toString(),
    method: "get"
  });
}

export function createAnnouncement(announcement) {
  return request({
    url: `/system/announcements`,
    method: "post",
    data: announcement
  });
}

export function updateAnnouncement(announcement) {
  return request({
    url: `/system/announcements/${announcement.announcement_id}`,
    method: "put",
    data: announcement
  });
}

export function deleteAnnouncement(announcement) {
  return request({
    url: `/system/announcements/${announcement.announcement_id}`,
    method: "delete"
  });
}
