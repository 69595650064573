import request from "@/utils/studio7ApiService";

export function getPermissions(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/auth/permissions?" + params.toString(),
    method: "get"
  });
}

export function createPermission(data) {
  const permission = { ...data, guard_name: "admin" };
  return request({
    url: "/auth/permissions",
    method: "post",
    data: permission
  });
}

export function updatePermission(permission) {
  return request({
    url: `/auth/permissions/${permission.id}`,
    method: "put",
    data: permission
  });
}

export function deletePermission(permission) {
  return request({
    url: `/auth/permissions/${permission.id}`,
    method: "delete"
  });
}

export function getAcScopePermissions(scope) {
  return request({
    url: "/auth/ac-permissions",
    method: "get",
    headers: { scope: scope }
  });
}

export function getUserPermissions(scope, user_id, overlap_from = "") {
  let options = { user_id, overlap_from };
  const params = new URLSearchParams(options);
  return request({
    url: "/auth/user-permissions?" + params.toString(),
    method: "get",
    headers: { scope }
  });
}

export function getRolePermissions(scope, role_id) {
  const params = new URLSearchParams({ role_id: role_id });
  return request({
    url: "/auth/role-permissions?" + params.toString(),
    method: "get",
    headers: { scope }
  });
}
