import store from "@/store";
import __ from "@/translation";

export const cutBranch = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/copyNode", {
      id,
      copyType: "cut_branch"
    });
  }
};

export default {
  text: `${__("Cut Branch")}<span>Ctrl+A, Ctrl+X</span>`,
  icon: "",
  onClick: cutBranch
};
