import {
  getNotifications,
  createNotification,
  updateNotification,
  deleteNotification,
  readNotification,
  getUserNotifications,
  updateUserNotification,
  getSpNotifications
} from "@/api/notifications";

import _ from "lodash";

const state = {
  notifications: [],
  timezones: [],
  isLoading: false,
  userNotifications: [],
  lastNotification: {}
};

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },

  CREATE_NOTIFICATION(state, notification) {
    state.notifications.push(notification);
  },

  UPDATE_NOTIFICATION(state, { index, notification }) {
    state.notifications.splice(index, 1, notification);
  },

  UPDATE_USER_NOTIFICATION(state, { index, notification }) {
    state.userNotifications.splice(index, 1, notification);
  },

  DELETE_NOTIFICATION(state, notification) {
    state.notifications = state.notifications.filter(
      oldNotification =>
        oldNotification.notification_message_id !==
        notification.notification_message_id
    );
  },

  SET_USER_NOTIFICATIONS(state, userNotifications) {
    state.userNotifications = userNotifications;
  },

  SET_LAST_NOTIFICATION(state, notification) {
    state.lastNotification = notification;
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getNotifications({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getNotifications(options)
        .then(({ data }) => {
          commit("SET_NOTIFICATIONS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createNotification({ commit }, notification) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createNotification(notification)
        .then(({ data }) => {
          commit("CREATE_NOTIFICATION", data.data);
          commit("SET_LAST_NOTIFICATION", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateNotification({ commit, state }, notification) {
    return new Promise((resolve, reject) => {
      const notificationObj = { ...notification };
      commit("UPDATE_LOADING_STATUS", true);
      updateNotification(notificationObj)
        .then(({ data }) => {
          var index = _.findIndex(state.notifications, oldNotification => {
            return (
              oldNotification.notification_message_id ===
              notification.notification_message_id
            );
          });
          commit("UPDATE_NOTIFICATION", { index, notification: data.data });
          commit("SET_LAST_NOTIFICATION", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteNotification({ commit }, notification) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteNotification(notification)
        .then(data => {
          commit("DELETE_NOTIFICATION", notification);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteNotification({ commit }, notification) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      notification.is_enabled = 1;
      updateNotification(notification)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  readNotification({ commit }, { notificationMessageId, userId }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      readNotification({
        notification_message_id: notificationMessageId,
        user_id: userId,
        message_status: "READ"
      })
        .then(({ data }) => {
          const index = _.findIndex(
            state.userNotifications,
            oldNotification => {
              return (
                oldNotification.notification_message_id ===
                notificationMessageId
              );
            }
          );
          const notification = { ...state.userNotifications[index] };
          notification.message_status = "READ";

          commit("UPDATE_USER_NOTIFICATION", { index, notification });
          commit("UPDATE_LOADING_STATUS", false);

          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getUserNotifications({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getUserNotifications(options)
        .then(({ data }) => {
          commit("SET_USER_NOTIFICATIONS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateUserNotification({ commit, state }, notification) {
    return new Promise((resolve, reject) => {
      const notificationObj = { ...notification };
      commit("UPDATE_LOADING_STATUS", true);
      updateUserNotification(notificationObj)
        .then(({ data }) => {
          var index = _.findIndex(state.notifications, oldNotification => {
            return (
              oldNotification.notification_message_id ===
              notification.notification_message_id
            );
          });
          commit("UPDATE_NOTIFICATION", { index, notification: data.data });
          commit("SET_LAST_NOTIFICATION", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  getSpNotifications({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getSpNotifications(options)
        .then(({ data }) => {
          commit("SET_NOTIFICATIONS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
