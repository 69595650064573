import { getActiveCalls, clearStaleCalls } from "@/api/activecalls";

const state = {
  activeCalls: []
};

const mutations = {
  SET_ACTIVE_CALLS(state, activeCalls) {
    state.activeCalls = activeCalls;
  }
};

const actions = {
  getActiveCalls({ commit }, scope) {
    return new Promise((resolve, reject) => {
      getActiveCalls(scope)
        .then(({ data }) => {
          commit("SET_ACTIVE_CALLS", data.data);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  clearStaleCalls(_, num) {
    return new Promise((resolve, reject) => {
      clearStaleCalls({ number_of_minutes: num })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  setActiveCalls({ commit }, data) {
    commit("SET_ACTIVE_CALLS", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
