import store from "@/store";
import _ from "lodash";
import { findConnectorConditionTag } from "@/utils/canvas";
import __ from "@/translation";

const addNewNodeAbove = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    // check new node is getting placed above a same node
    let attemptConvertSameNodeToGoto =
      "same" === _.get(id.toString().split("_"), 1);

    // if the new node to be inserted above is going to placed just below a connector condition,
    // figure out which condition
    let connectorCondition = "";
    let node = store.state.canvas.chart.getBGNode(id);
    let idPartsOfParent = node.pid ? node.pid.toString().split("_") : [];

    if ("condition" === _.get(idPartsOfParent, 1)) {
      let parent = node.parent;
      connectorCondition = findConnectorConditionTag(parent);
    }

    store.dispatch("canvas/startAddNewNodeProcess", {
      id,
      addNewNodeBelow: false,
      attemptConvertSameNodeToGoto,
      connectorCondition
    });
  }
};

export default {
  text: __("Insert Node Above"),
  icon: "",
  onClick: addNewNodeAbove
};
