import request, { ac_headers } from "@/utils/studio7ApiService";

export function getFiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/content/attachment-files?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createFile(data) {
  const file = { ...data, guard_name: "admin" };
  return request({
    url: "/content/attachment-files",
    method: "post",
    data: file,
    headers: ac_headers()
  });
}

export function updateFile(file) {
  return request({
    url: `/content/attachment-files/${file.file_id}`,
    method: "put",
    data: file,
    headers: ac_headers()
  });
}

export function deleteFile(file) {
  return request({
    url: `/content/attachment-files/${file.file_id}`,
    method: "delete",
    data: file,
    headers: ac_headers()
  });
}

export function downloadFile(file) {
  return request({
    url: `/content/attachment-files/download/${file.file_id}`,
    method: "get",
    headers: ac_headers()
  });
}
