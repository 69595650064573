import request, { ac_headers, sp_headers } from "@/utils/studio7ApiService";

export function validateApiKey(apiKey, scope) {
  return request({
    url: `/auth/user-api-keys/validate-api-key?api_key=${apiKey}`,
    method: "get",
    headers: scope === "ac" ? ac_headers() : sp_headers()
  });
}

export function validateApiKeyNameForUser(apiKeyName, scope) {
  return request({
    url: `/auth/user-api-keys/validate-api-key-name?api_key_name=${apiKeyName}`,
    method: "get",
    headers: scope === "ac" ? ac_headers() : sp_headers()
  });
}

export function getUserApiKeys(scope, options = {}, cancelToken = undefined) {
  const params = new URLSearchParams(options);
  return request({
    url: "/auth/user-api-keys?" + params.toString(),
    method: "get",
    cancelToken,
    headers: scope === "ac" ? ac_headers() : sp_headers()
  });
}

export function createUserApiKey(apiKey, scope) {
  return request({
    url: "/auth/user-api-keys",
    method: "post",
    headers: scope === "ac" ? ac_headers() : sp_headers(),
    data: apiKey
  });
}

export function updateUserApiKey(apiKey, scope) {
  return request({
    url: `/auth/user-api-keys/${apiKey.user_api_key_id}`,
    method: "put",
    headers: scope === "ac" ? ac_headers() : sp_headers(),
    data: apiKey
  });
}

export function deleteUserApiKey(apiKey, scope) {
  return request({
    url: `/auth/user-api-keys/${apiKey.user_api_key_id}`,
    method: "delete",
    headers: scope === "ac" ? ac_headers() : sp_headers(),
    data: apiKey
  });
}
