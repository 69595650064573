<template>
  <div>
    <div class="search-wrapper">
      <el-input
        size="small"
        :placeholder="__('Search Folders')"
        v-model="search"
        @input="handleSearch"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i
      ></el-input>
    </div>

    <div v-loading="foldersLoading">
      <el-menu
        @select="selectFolder"
        :default-active="selectedFolderId"
        class="folder-names"
      >
        <el-menu-item index="0" style="padding: 0 10px;">
          <drop class="folder-name-container" @drop="onDragToMove($event, 0)">
            <i
              :class="[
                selectedFolderId == 0
                  ? 'el-icon-folder-opened'
                  : 'el-icon-folder'
              ]"
            ></i>
            <span class="folder-name">Main</span>
          </drop>
        </el-menu-item>
        <el-menu-item
          v-for="folder in folders"
          :index="folder.folder_id.toString()"
          :key="folder.folder_id"
          style="padding:0 10px 0 25px;height: 40px;line-height: 40px;"
          class="folder-name display-on-hover-parent"
        >
          <drop
            class="folder-name-container copy"
            @drop="onDragToMove($event, folder.folder_id)"
          >
            <i
              :class="[
                selectedFolderId == folder.folder_id
                  ? 'el-icon-folder-opened'
                  : 'el-icon-folder'
              ]"
            ></i>
            <span class="folder-name">{{ folder.folder_name }}</span>
            <div class="display-on-hover">
              <span
                style="padding-right: 5px;"
                @click="editFolderName(folder.folder_id, folder.folder_name)"
                ><img :src="require('@/assets/icons/icon-edit.svg')" alt=""
              /></span>
              <span @click="deleteSelectedFolder(folder.folder_id)"
                ><img :src="require('@/assets/icons/icon-delete.svg')" alt=""
              /></span>
            </div>
          </drop>
        </el-menu-item>
        <el-menu-item index="-1" style="padding: 0 10px;">
          <drop @drop="onDragToMove($event, -1)">
            <i
              :class="[
                selectedFolderId == '-1'
                  ? 'el-icon-folder-opened'
                  : 'el-icon-folder'
              ]"
            ></i>
            <span>{{ __("Archived") }}</span>
          </drop>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import { Drop } from "vue-easy-dnd";

export default {
  components: {
    Drop
  },
  computed: {
    ...mapState("folders", {
      folders: state => state.folders,
      foldersLoading: state => state.loading,
      selectedFolderId: state => state.selectedFolderId.toString()
    }),

    ...mapGetters("folders", {
      folderName: "folderName"
    })
  },

  data() {
    return {
      search: ""
    };
  },

  methods: {
    ...mapActions("folders", {
      getFolders: "getFolders",
      updateFolder: "updateFolder",
      deleteFolder: "deleteFolder",
      updateSelectedFolderId: "updateSelectedFolderId",
      moveContentToFolder: "moveContentToFolder"
    }),

    async selectFolder(key) {
      EventBus.$emit("uncheck-all-items");
      EventBus.$emit("de-select-all");
      EventBus.$emit("empty-multi-selected-items");
      await this.updateSelectedFolderId(key);
      EventBus.$emit("fetch-content-items-in-folder", key);

      let name = "Main";
      if (key === -1) {
        name = "Archived";
      } else if (key !== 0) {
        name = this.folderName(key);
      }
      EventBus.$emit("update-content-title", name, "folder");
    },

    handleSearch() {
      this.updateSelectedFolderId(-1);
      EventBus.$emit("search-in-root", this.search);
    },

    editFolderName(folderId, oriName) {
      this.$prompt(__("Folder name"), __("Edit folder name"), {
        inputValue: oriName,
        confirmButtonText: __("Update"),
        cancelButtonText: __("Cancel")
      })
        .then(({ value }) => {
          this.updateFolder({
            folder_id: folderId,
            folder_name: value
          })
            .then(data => {
              EventBus.$emit(
                "update-content-title",
                data.data.folder_name,
                "folder"
              );
              this.getFolders()
                .then(() => {
                  this.$message({
                    type: "success",
                    message: __("Folder updated")
                  });
                })
                .catch(() => {});
            })
            .catch(error => {
              this.$message({
                type: "error",
                message:
                  error && error.message
                    ? error.message
                    : __("Unable to update folder")
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("Update canceled")
          });
        });
    },

    deleteSelectedFolder(folderId) {
      console.log(folderId);
      this.$confirm(
        __("Are you sure you want to delete this folder?"),
        __("Warning"),
        {
          confirmButtonText: __("Delete"),
          cancelButtonText: __("Cancel"),
          type: "warning"
        }
      )
        .then(() => {
          this.deleteFolder({
            folder_id: folderId
          }).then(data => {
            console.log(data);
            this.selectFolder(0);
            this.getFolders()
              .then(() => {
                this.$message({
                  type: "success",
                  message: data.message
                });
              })
              .catch(() => {});
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("canceled")
          });
        });
    },

    onDragToMove(e, folderId) {
      if (folderId === -1) {
        this.$message({
          type: "error",
          message: __("Contents cannot be moved into Archived folder directly")
        });
      } else {
        const contentIds = e.data.content_id;
        const contentType = e.data.content_type;
        this.moveContentToFolder({
          folder_id: folderId,
          content_ids: contentIds,
          content_model: contentType
        })
          .then(() => {
            this.selectFolder(this.selectedFolderId);
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err.message
            });
          });
      }
    }
  },

  created() {
    this.search = "";
    EventBus.$on("select-folder", value => {
      this.selectFolder(value);
    });
  },

  beforeDestroy() {
    this.search = "";
    EventBus.$off("select-folder");
  }
};
</script>

<style lang="scss">
.display-on-hover-parent:hover {
  .display-on-hover {
    display: inline-flex;
    flex: 1;
    justify-content: flex-end;
  }
}
.folder-name-container {
  display: flex;
  align-items: center;

  .folder-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 140px;
    overflow: hidden;
  }
}

.display-on-hover {
  display: none;
  float: right;

  > span > img {
    height: 20px;
  }
}

.drop-forbidden {
  background-color: rgba(255, 0, 0, 0.2);
}
</style>
