import store from "@/store";
import __ from "@/translation";

export const copyNode = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/copyNode", {
      id,
      copyType: "copy_node"
    });
  }
};

export default {
  text: `${__("Copy Node")}<span>Ctrl+C</span>`,
  icon: "",
  onClick: copyNode
};
