// socket io setup
import Echo from "laravel-echo";
import { socketOptions } from "@/utils/socketOptions";
import { getSectorIdFromWindow } from "@/utils/url";

getSectorIdFromWindow();

let echo = new Echo(socketOptions());

export default echo;
