import {
  getTtsPackages,
  createTtsPackage,
  updateTtsPackage,
  deleteTtsPackage
} from "@/api/ttspackages";
import _ from "lodash";
import __ from "@/translation";

const getDistinctVoices = (ttsPackages, languages) => {
  const languagesFromDB = _.uniq(_.map(ttsPackages, "lang"));
  let newLanguages = [];
  _.map(languagesFromDB, languageFromDB => {
    const language = _.find(languages, { value: languageFromDB });
    if (!language) {
      newLanguages.push({ label: languageFromDB, value: languageFromDB });
    }
  });
  return newLanguages;
};

export const flattenVoices = ttsVoices => {
  return _.mapValues(
    _.groupBy(ttsVoices, voice => voice.shift()),
    _.flattenDeep
  );
};

export const state = {
  ttsPackages: [],
  originalPackages: [],
  loading: false,
  languages: [
    {
      label: __("US English"),
      value: "en-US"
    },
    {
      label: __("UK English"),
      value: "en-UK"
    }
  ],
  providers: [
    {
      label: __("Lumenvox"),
      value: "lumenvox"
    },
    {
      label: __("Google"),
      value: "google"
    }
  ]
};

export const getters = {
  ttsPackageNames(state) {
    if (state.ttsPackages) {
      return _.map(
        _.orderBy(state.ttsPackages, ["updated_at"], ["desc"]),
        "tts_package_name"
      );
    } else {
      return [];
    }
  },

  getProviderLabel(state) {
    return provider => {
      return _.find(state.providers, { value: provider }).label;
    };
  },

  getLanguageLabel(state) {
    return language => {
      return _.find(state.languages, { value: language }).label;
    };
  }
};

export const mutations = {
  SET_TTS_PACKAGES(state, ttsPackages) {
    state.ttsPackages = ttsPackages;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  ADD_TO_LANGUAGES(state, languages) {
    state.languages = _.concat(state.languages, languages);
  },

  SET_ORIGINAL_PLAN_TTS_PACKAGES(state, ttsPackages) {
    state.originalPackages = ttsPackages;
  }
};

export const actions = {
  getTtsPackages({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getTtsPackages(options)
        .then(async ({ data }) => {
          const newLanguages = await getDistinctVoices(
            data.data,
            state.languages
          );
          commit("ADD_TO_LANGUAGES", newLanguages);
          commit("SET_TTS_PACKAGES", data.data);
          commit("SET_ORIGINAL_PLAN_TTS_PACKAGES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  setTTSPackagesExcludingBasePlanPackages({ commit }, ttsPackages) {
    commit("SET_TTS_PACKAGES", ttsPackages);
  },

  resetTTSPacksAsPerPlanType({ commit, state }) {
    commit("SET_TTS_PACKAGES", state.originalPackages);
  },
  createTtsPackage({ commit }, ttsPackage) {
    ttsPackage.tts_package_voices = flattenVoices(
      ttsPackage.tts_package_voices
    );
    return new Promise((resolve, reject) => {
      // mock data which needs to be replaced
      commit("CHANGE_LOADING", true);
      createTtsPackage(ttsPackage)
        .then(async ({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateTtsPackage({ commit }, ttsPackage) {
    ttsPackage.tts_package_voices = flattenVoices(
      ttsPackage.tts_package_voices
    );
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const ttsPackageObj = { ...ttsPackage };
      updateTtsPackage(ttsPackageObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteTtsPackage({ commit }, ttsPackage) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteTtsPackage(ttsPackage)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteTtsPackage({ commit }, ttsPackage) {
    delete ttsPackage.tts_package_voices;
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      ttsPackage.is_enabled = 1;
      updateTtsPackage(ttsPackage)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
