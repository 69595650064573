import {
  getExportDataForUser,
  deleteDataExport,
  requestDataExport
} from "@/api/exports";

const state = {
  exports: [],
  loading: false
};

const mutations = {
  SET_EXPORTS(state, exports) {
    state.exports = exports;
  },

  SET_LOADING_STATUS(state, loading) {
    state.loading = loading;
  },

  DELETE_DATA_EXPORT(state, dataStoreExport) {
    state.exports = state.exports.filter(
      old => old.data_store_export_id !== dataStoreExport.data_store_export_id
    );
  }
};

const actions = {
  getExportDataForUser({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATUS", true);
      getExportDataForUser(options)
        .then(({ data }) => {
          commit("SET_EXPORTS", data.data);
          commit("SET_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("SET_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteDataExport({ commit }, dataStoreExport) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATUS", true);
      deleteDataExport(dataStoreExport)
        .then(data => {
          commit("SET_LOADING_STATUS", false);
          commit("DELETE_DATA_EXPORT", dataStoreExport);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("SET_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  requestDataExport({ commit }, exportData) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATUS", true);
      requestDataExport(exportData)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
