import Vue from "vue";
import Router from "vue-router";
import __ from "@/translation";

/* Layout */
import Layout from "@/layout";
import buildModuleRouter from "./modules/build";
import analyseModuleRouter from "./modules/analyse";
import manageModuleRouter from "./modules/manage";
import systemModuleRouter from "./modules/system";
import discoverModuleRouter from "./modules/discover";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index"),
    hidden: true,
    meta: { auth: false },
    props: route => ({ redirectUrl: route.query.redirectUrl })
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("@/views/login/forgot"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/reset",
    component: () => import("@/views/login/reset"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/verify/otp",
    name: "VerifyOtp",
    component: () => import("@/views/login/verify-otp"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/setup/otp",
    name: "SetupOtp",
    component: () => import("@/views/login/setup-otp"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/login/google/callback",
    component: () => import("@/views/login/google"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/login/okta/callback",
    component: () => import("@/views/login/okta-launch"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/profile",
    component: () => import("@/views/profile/users/pages/createProfile"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/login/five9-sso",
    component: () => import("@/views/login/five9"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/build/callflow/node/:node_id(\\d+)?",
    component: () => import("@/views/build/callflow/pages/CallflowNodeGate"),
    name: "callflow_node_gate",
    meta: {
      title: __("Callflow node gate")
    },
    props: true,
    hidden: true
  },
  {
    path:
      "/analyse/reports/job/:job_id/:from_link/:notification_message_id/:user_id?",
    component: () => import("@/views/analyse/reports/reportJobGate.vue"),
    name: "report job notification",
    meta: {
      title: "Report Job"
    },
    props: true,
    hidden: true
  },
  {
    path: "/analyse/reports/job/:job_id?",
    component: () => import("@/views/analyse/reports/reportJobGate.vue"),
    name: "report job",
    meta: {
      title: "Report Job"
    },
    props: true,
    hidden: true
  },
  {
    path: "/invite",
    component: () => import("@/views/manage/invite/pages/acceptInvite"),
    hidden: true,
    meta: { auth: false }
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/NotFound"),
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/NotAuthorized"),
    hidden: true
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    meta: { auth: true, singleChild: true },
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "Dashboard",
        meta: {
          get title() {
            return __("Dashboard");
          },
          icon: "icon-dashboard.svg",
          index: "1"
        }
      },
      {
        path: "manage/profile",
        component: () => import("@/views/manage/profile/index"),
        name: "profile"
      }
    ]
  },
  {
    path: "/visualform/:uuid?",
    component: () => import("@/views/default/VisualFormPage"),
    meta: { auth: false },
    props: true,
    hidden: true
  }
  // {
  //   path: "/profile",
  //   component: Layout,
  //   redirect: "/profile/index",
  //   hidden: true,
  //   children: [
  //     {
  //       path: "index",
  //       component: () => import("@/views/profile/index"),
  //       name: "Profile"
  //       // meta: { title: "Profile", icon: "user"}
  //     }
  //   ]
  // }
];

export const asyncRoutes = [
  // collection of route objects
  buildModuleRouter,
  analyseModuleRouter,
  manageModuleRouter,
  systemModuleRouter,
  discoverModuleRouter,
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true }
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
