import request from "@/utils/studio7ApiService";

export function getAccountsForServiceProvider(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/accounts?" + params.toString(),
    method: "get"
  });
}

export function validateAccountContent(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/validate-account?" + params.toString(),
    method: "get"
  });
}

export function batchValidateAccountContent(data) {
  return request({
    url: "/studio6/account/batch-validate-accounts",
    method: "post",
    data: data
  });
}

export function migrateAccountContent(accountContent) {
  return request({
    url: "/studio6/account/migrate-account-content",
    method: "post",
    data: accountContent
  });
}

export function batchMigrateAccountContent(s6GroupsToMigrate) {
  return request({
    url: "/studio6/account/batch-migrate-account-content",
    method: "post",
    data: s6GroupsToMigrate
  });
}

export function rollbackAccountContent(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/rollback-account-content?" + params.toString(),
    method: "get"
  });
}

export function reportMigrationError(migrationErrorData) {
  return request({
    url: "/studio6/account/report-migration-error",
    method: "post",
    data: migrationErrorData
  });
}

export function getPortalTablesToMigrate() {
  return request({
    url: "/studio6/portal/tables-to-map",
    method: "get"
  });
}

export function migratePortalTable(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/migrate-portal?" + params.toString(), // portal/tables-to-map
    method: "get"
  });
}

export function rollbackPortalTable(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/rollback-migration?" + params.toString(), // portal/tables-to-map
    method: "get"
  });
}

export function checkNodeMigration(data) {
  return request({
    url: `/studio6/account/check-node-migration`,
    method: "post",
    data: data
  });
}

export function checkCanvasesMigration(data) {
  return request({
    url: `/studio6/account/check-canvases-migration`,
    method: "post",
    data: data
  });
}

export function rollbackAccountTable(options = {}) {
  return request({
    url: "/studio6/account/selective-rollback-migration",
    method: "post",
    data: options
  });
}

export function getMigrationMap(data) {
  return request({
    url: "/studio6/account/migration-map",
    method: "post",
    data: data
  });
}

export function consolidateNodeMigrationProgress(data) {
  return request({
    url: "/studio6/account/consolidate-node-migration-progress",
    method: "post",
    data: data
  });
}

export function publishMigratedTemplates() {
  return request({
    url: "/studio6/account/publish-migrated-templates",
    method: "get"
  });
}

export function checkContentMigration(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/check-content-migration?" + params.toString(),
    method: "get"
  });
}

export function checkMigrationErrors(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/check-migration-errors?" + params.toString(),
    method: "get"
  });
}

export function getMigrationErrorList() {
  return request({
    url: "/studio6/account/get-error-list",
    method: "get"
  });
}

export function getMigrationJobList() {
  return request({
    url: "/studio6/account/get-job-list",
    method: "get"
  });
}

export function getMigrationJobInfo(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/get-job-info?" + params.toString(),
    method: "get"
  });
}

export function getGroupIdsWithErrors() {
  return request({
    url: "/studio6/account/get-error-group-ids",
    method: "get"
  });
}

export function getGroupJobIdsWithErrors(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/get-group-job-ids-with-errors?" + params.toString(),
    method: "get"
  });
}

export function groupIdsWithJobs() {
  return request({
    url: "/studio6/account/get-job-group-ids",
    method: "get"
  });
}

export function getMappedGroupIds() {
  return request({
    url: "/studio6/account/get-mapped-group-ids",
    method: "get"
  });
}

export function getJobIdsForGroup(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/get-job-ids-for-group?" + params.toString(),
    method: "get"
  });
}

export function migrateDataStoreDataForAccount(data) {
  return request({
    url: `/studio6/account/migrate-data-store-data`,
    method: "post",
    data: data
  });
}

export function getDataStoreListForAccount(options) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/studio6/account/get-data-store-list-for-account?" + params.toString(),
    method: "get"
  });
}

export function getUnusedAccounts() {
  return request({
    url: "/studio6/account/get-unused-accounts",
    method: "get"
  });
}

export function deleteUnusedAccounts(data) {
  return request({
    url: "/studio6/account/delete-unused-accounts",
    method: "post",
    data: data
  });
}

export function getFlaggedNodesForAccount(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/account/get-flagged-nodes?" + params.toString(),
    method: "get"
  });
}
