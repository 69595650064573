import {
  getPhoneNumbers,
  getCampaignNumbers,
  createPhoneNumber,
  updatePhoneNumber,
  deletePhoneNumber,
  movePhoneNumbers,
  manageVirtualPhoneNumbers,
  manageMultiplePhoneNumbersAction,
  switchStudioVersion
} from "@/api/phonenumbers";

const state = {
  phoneNumbers: [],
  loading: false
};

export const getters = {};
const mutations = {
  SET_PHONE_NUMBERS(state, phoneNumbers) {
    state.phoneNumbers = phoneNumbers;
  },

  CHANGE_LOADING(state, loading) {
    state.loading = loading;
  }
};

const actions = {
  getPhoneNumbers({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getPhoneNumbers(options)
        .then(({ data }) => {
          commit("SET_PHONE_NUMBERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getCampaignNumbers({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getCampaignNumbers(options)
        .then(({ data }) => {
          commit("SET_PHONE_NUMBERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getSysPhoneNumbers({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getPhoneNumbers(options, 1)
        .then(({ data }) => {
          commit("SET_PHONE_NUMBERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  createPhoneNumber({ commit }, phoneNumber) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createPhoneNumber(phoneNumber)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updatePhoneNumber({ commit }, phoneNumber) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const phoneNumberObj = { ...phoneNumber };
      updatePhoneNumber(phoneNumberObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deletePhoneNumber({ commit }, phoneNumber) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deletePhoneNumber(phoneNumber)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  undoDeletePhoneNumber({ commit }, phoneNumber) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      phoneNumber.is_enabled = 1;
      updatePhoneNumber(phoneNumber)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  movePhoneNumbers({ commit }, phoneNumbers) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      movePhoneNumbers(phoneNumbers)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data.data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  manageVirtualPhoneNumbers({ commit }, phoneNumbers) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      manageVirtualPhoneNumbers(phoneNumbers)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data.data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  manageMultiplePhoneNumbersAction({ commit }, phoneNumbers) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      manageMultiplePhoneNumbersAction(phoneNumbers)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data.data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  switchStudioVersion({ commit }, { phoneNumber, useStudio6 }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      switchStudioVersion(phoneNumber, useStudio6)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
