import request, { ac_headers } from "@/utils/studio7ApiService";

/**
 * get list dynamic forms
 * @param options
 * @returns {*}
 */
export function getDynamicFormTypes(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/form/dynamic-form-types?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

/**
 * create dynamic form type
 * @param data
 * @returns {*}
 */
export function createDynamicFormType(data) {
  const dynamicFormType = { ...data, guard_name: "admin" };
  return request({
    url: "/form/dynamic-form-types",
    method: "post",
    data: dynamicFormType,
    headers: ac_headers()
  });
}

/**
 * update dynamic form type
 * @param dynamicFormType
 * @returns {*}
 */
export function updateDynamicFormType(dynamicFormType) {
  return request({
    url: `/form/dynamic-form-types/${dynamicFormType.dynamic_form_type_id}`,
    method: "put",
    data: dynamicFormType,
    headers: ac_headers()
  });
}

/**
 * delete dynamic form
 * @param dynamicFormType
 * @returns {*}
 */
export function deleteDynamicFormType(dynamicFormType) {
  return request({
    url: `/form/dynamic-form-types/${dynamicFormType.dynamic_form_type_id}`,
    method: "delete",
    data: dynamicFormType,
    headers: ac_headers()
  });
}

/**
 * delete system dynamic form
 * @param dynamicFormType
 * @returns {*}
 */
export function deleteSystemDynamicFormType(dynamicFormType) {
  return request({
    url: `/form/dynamic-form-types/${dynamicFormType.dynamic_form_type_id}`,
    method: "delete",
    data: dynamicFormType,
    headers: {
      scope: "ac",
      "scope-id": "all"
    }
  });
}

/**
 * publish system dynamic form
 * @param data
 * @returns {*}
 */
export function publishDynamicFormTypeAsSystem(data) {
  const dynamicFormType = { ...data, guard_name: "admin" };
  return request({
    url: `/form/publish-system-dynamic-form/${dynamicFormType.dynamic_form_type_id}`,
    method: "post",
    data: dynamicFormType,
    headers: ac_headers()
  });
}

/**
 * check unique name
 * @param displayName
 * @returns {*}
 */
export function checkUniqueName(displayName) {
  return request({
    url: `/form/validate-dft-name/${displayName}`,
    method: "get",
    headers: ac_headers()
  });
}
