import {
  createPasswordPolicy,
  deletePasswordPolicy,
  getPasswordPolicies,
  updatePasswordPolicy
} from "@/api/passwordpolicies";
//import _ from "lodash";

const state = {
  policies: [],
  isLoading: false
};

const mutations = {
  SET_PASSWORD_POLICY(state, policies) {
    state.policies = policies;
  },

  CREATE_PASSWORD_POLICY(state, password_policy) {
    state.policies.push(password_policy);
  },

  UPDATE_PASSWORD_POLICY(state, { index, password_policy }) {
    state.policies.splice(index, 1, password_policy);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getPasswordPolicies({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getPasswordPolicies(options)
        .then(({ data }) => {
          commit("SET_PASSWORD_POLICY", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  createPasswordPolicy({ commit }, password_policy) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createPasswordPolicy(password_policy)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updatePasswordPolicy({ commit }, password_policy) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      updatePasswordPolicy(password_policy)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deletePasswordPolicy({ commit }, password_policy) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deletePasswordPolicy(password_policy)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  undoDeleteContent({ commit }, password_policy) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      password_policy.is_enabled = 1;
      updatePasswordPolicy(password_policy)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
