import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

export function getAcPeakSessions(data) {
  return request({
    url: "/reporting/ac-hourly-peak-sessions",
    method: "post",
    data: data,
    headers: ac_headers()
  });
}

export function getSpPeakSessions(data) {
  return request({
    url: "/reporting/sp-hourly-peak-sessions",
    method: "post",
    data: data,
    headers: sp_headers()
  });
}

export function getPopPeakSessions(data) {
  return request({
    url: "/reporting/pop-hourly-peak-sessions",
    method: "post",
    data: data
  });
}
