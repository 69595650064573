import {
  getEmailProfiles,
  createEmailProfile,
  updateEmailProfile,
  deleteEmailProfile,
  sendTestEmail
} from "@/api/emailprofiles";
import _ from "lodash";

const state = {
  emailProfiles: [],
  emailProfile_filter_key: "all",
  loading: false
};

const getters = {
  getEmailProfile: state => profile_id => {
    return _.find(state.emailProfiles, { profile_id: +profile_id });
  }
};

const mutations = {
  SET_EMAIL_PROFILES(state, emailProfiles) {
    state.emailProfiles = emailProfiles;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getEmailProfiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getEmailProfiles(options)
        .then(({ data }) => {
          let emailProfiles = data.data;
          emailProfiles.forEach(function(emailProfile) {
            emailProfile.edit_name = false;
          });
          commit("SET_EMAIL_PROFILES", emailProfiles);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createEmailProfile({ commit }, emailProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createEmailProfile(emailProfile)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateEmailProfile({ commit }, emailProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const emailProfileObj = { ...emailProfile };
      updateEmailProfile(emailProfileObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteEmailProfile({ commit }, emailProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteEmailProfile(emailProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteEmailProfile({ commit }, emailProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      emailProfile.is_enabled = 1;
      updateEmailProfile(emailProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  sendTestEmail({ commit }, { profile_id, email }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      sendTestEmail(profile_id, email)
        .then(() => {
          commit("CHANGE_LOADING", false);
          resolve();
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
