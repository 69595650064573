import request, { ac_headers } from "@/utils/studio7ApiService";

export function getQueues(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/qforme/queues?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createQueue(data) {
  const queue = { ...data };
  return request({
    url: "/qforme/queues",
    method: "post",
    data: queue,
    headers: ac_headers()
  });
}

export function updateQueue(queue) {
  return request({
    url: `/qforme/queues/${queue.queue_id}`,
    method: "put",
    data: queue,
    headers: ac_headers()
  });
}

export function deleteQueue(queue) {
  return request({
    url: `/qforme/queues/${queue.queue_id}`,
    method: "delete",
    data: queue,
    headers: ac_headers()
  });
}

export function getWaiters(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/qforme/waiters?` + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function deleteWaiter(waiter) {
  return request({
    url: `/qforme/waiters/${waiter.waiter_id}`,
    method: "delete",
    data: waiter,
    headers: ac_headers()
  });
}

export function getQformeCallLog(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/qforme/queue-call-logs?` + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getPlanTypesDetail() {
  return request({
    url: `/qforme/plan-types-waiters`,
    method: "get",
    headers: ac_headers()
  });
}

export function getPostmanCollection(queue) {
  return request({
    url: `/qforme/${queue.queue_id}/collection`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function validateQueue(name) {
  return request({
    url: `/qforme/queues/validate-name`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}
