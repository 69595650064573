import {
  getTemplates,
  updateTemplate,
  decodeTemplate,
  deleteTemplate,
  fetchTemplatesForSp
} from "@/api/tasklibrary";

const state = {
  templates: [],
  loading: false,
  taskFilter: "voice"
};

const mutations = {
  SET_TEMPLATES(state, templates) {
    // state.templates = templates;
    state.templates = templates;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_TASK_FILTER_KEY(state, filter) {
    state.taskFilter = filter;
  }
};

const actions = {
  setTaskFilter({ commit }, filter) {
    commit("SET_TASK_FILTER_KEY", filter);
  },
  getTemplates({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      commit("SET_TEMPLATES", []);
      commit("CHANGE_LOADING", true);
      options = { ...options, task_type: state.taskFilter };
      getTemplates(options)
        .then(({ data }) => {
          commit("SET_TEMPLATES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateTemplate({ commit }, template) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateTemplate(template)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  decodeTemplate({ commit }, template_id) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      decodeTemplate(template_id)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  deleteTemplate({ commit }, template) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteTemplate(template)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  undoDeleteTemplate({ commit }, template) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      template.is_enabled = 1;
      updateTemplate(template)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  fetchTemplatesForSp({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("SET_TEMPLATES", []);
      commit("CHANGE_LOADING", true);
      fetchTemplatesForSp(options)
        .then(({ data }) => {
          commit("SET_TEMPLATES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
