import Layout from "@/layout";
import __ from "@/translation";

const analyseModuleRouter = {
  path: "/analyse",
  component: Layout,
  redirect: "/",
  meta: {
    auth: true,
    singleChild: false,
    icon: "icon-analyse.svg",
    get title() {
      return __("Analyse");
    },
    index: "analyse"
  },
  children: [
    {
      path: "virtual-agent-usage",
      component: () => import("@/views/analyse/virtual-agent-usage/index.vue"),
      name: "virtual-agent-usage",
      meta: {
        get title() {
          return __("Virtual Agent Usage");
        },
        icon: "icon-virtual-agent-usage.svg",
        index: "analyse-2",
        permissions: ["analyse.virtual-agent-usage"]
      }
    },
    {
      path: "virtual-agent-tracker/:sp_id?/:ac_id?/:from_ac_id?",
      component: () =>
        import("@/views/analyse/virtual-agent-tracker/index.vue"),
      name: "live-virtual-agent-tracker",
      meta: {
        get title() {
          return __("Live Virtual Agent Tracker");
        },
        icon: "icon-virtual-agent-tracker.svg",
        index: "analyse-1",
        permissions: ["analyse.live-virtual-agent-tracking"]
      }
    },
    {
      path: "user-activities",
      component: () => import("@/views/analyse/activitylogs/index.vue"),
      name: "user-activities",
      meta: {
        get title() {
          return __("User Activity");
        },
        icon: "icon-user-activity.svg",
        index: "analyse-3",
        permissions: ["analyse.user-activity"]
      }
    },
    {
      path: "reports",
      component: () => import("@/views/analyse/reports/index.vue"),
      name: "reports",
      meta: {
        get title() {
          return __("Reports");
        },
        icon: "icon-user-activity.svg",
        index: "analyse-4",
        permissions: ["analyse.reports"]
      }
    }
    // {
    //   path: "pricing",
    //   component: () => import("@/views/analyse/pricing/index.vue"),
    //   name: "monthly-usage-summary",
    //   meta: {
    //     title: "Virtual Agent Usage",
    //     icon: "icon-virtual-agent-usage.svg",
    //     index: "analyse-1"
    //   }
    // }
  ]
};

export default analyseModuleRouter;
