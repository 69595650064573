import request from "@/utils/studio7ApiService";

export function getAsrLanguagePackages(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/util/asr-language-packages?" + params.toString(),
    method: "get"
  });
}

export function createAsrLanguagePackage(data) {
  const asrLangPack = { ...data, guard_name: "admin" };
  return request({
    url: "/util/asr-language-packages",
    method: "post",
    data: asrLangPack
  });
}

export function updateAsrLanguagePackage(asrLangPack) {
  return request({
    url: `/util/asr-language-packages/${asrLangPack.asr_language_package_id}`,
    method: "put",
    data: asrLangPack
  });
}

export function deleteAsrLanguagePackage(asrLangPack) {
  return request({
    url: `/util/asr-language-packages/${asrLangPack.asr_language_package_id}`,
    method: "delete",
    data: asrLangPack
  });
}

export function checkUniqueName(asr_language_package_name) {
  return request({
    url: `/util/validate-asr-pack-name/${asr_language_package_name}`,
    method: "get"
  });
}
