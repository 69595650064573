import { ANNOUNCEMENT_TYPE } from "@/constants/announcementTypes";
import {
  createAnnouncement,
  deleteAnnouncement,
  getAnnouncements,
  updateAnnouncement
} from "@/api/announcements";

import _ from "lodash";

export const state = {
  announcement_type: null,
  tutorials: [],
  whats_new: [],
  countLiveTutorials: 0,
  countLiveWhatsNew: 0,
  loading: false
};

export const mutations = {
  SET_ANNOUNCEMENT_TYPE(state, filter) {
    state.announcement_type = filter;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_ANNOUNCEMENTS(state, { announcements, type }) {
    switch (type) {
      case ANNOUNCEMENT_TYPE.TUTORIAL:
        state.tutorials = announcements;
        break;
      case ANNOUNCEMENT_TYPE.WHATS_NEW:
        state.whats_new = announcements;
        break;
    }
  },
  SET_LIVE_ANNOUNCEMENT_STATS(state, { countTutorials, countWhatsNew }) {
    state.countLiveTutorials = countTutorials;
    state.countLiveWhatsNew = countWhatsNew;
  }
};

export const getters = {
  allAnnouncements: state =>
    _(state.tutorials)
      .concat(state.whats_new)
      .value(),

  hasAnnouncements: (state, getter) => getter.allAnnouncements.length
};

export const actions = {
  setAnnouncementType({ commit }, filter) {
    commit("SET_ANNOUNCEMENT_TYPE", filter);
  },

  setLiveAnnouncementsStats({ commit }, { countTutorials, countWhatsNew }) {
    commit("SET_LIVE_ANNOUNCEMENT_STATS", { countTutorials, countWhatsNew });
  },

  getAnnouncements({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      if (!options.announcement_type && !state.announcement_type) {
        commit("SET_ANNOUNCEMENT_TYPE", ANNOUNCEMENT_TYPE.TUTORIAL);
      }

      options = {
        ...options,
        announcement_type: options.announcement_type
          ? options.announcement_type
          : state.announcement_type
      };

      if (!options["notShowLoader"]) {
        commit("SET_ANNOUNCEMENTS", {
          announcements: [],
          type: options.announcement_type
        });
        commit("CHANGE_LOADING", true);
      }

      getAnnouncements(options)
        .then(({ data }) => {
          commit("SET_ANNOUNCEMENTS", {
            announcements: data.data,
            type: options.announcement_type
          });
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  createAnnouncement({ commit }, announcement) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createAnnouncement(announcement)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateAnnouncement({ commit }, announcement) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateAnnouncement(announcement)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  deleteAnnouncement({ commit }, announcement) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteAnnouncement(announcement)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  undoDeleteAnnouncement({ commit }, announcement) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      announcement.is_enabled = 1;
      updateAnnouncement(announcement)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
