import request, { ac_headers } from "@/utils/studio7ApiService";

export function getSmsProfiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/content/sms-profiles?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createSmsProfile(data) {
  const smsProfile = { ...data, guard_name: "admin" };
  return request({
    url: "/content/sms-profiles",
    method: "post",
    data: smsProfile,
    headers: ac_headers()
  });
}

export function updateSmsProfile(smsProfile) {
  return request({
    url: `/content/sms-profiles/${smsProfile.sms_profile_id}`,
    method: "put",
    data: smsProfile,
    headers: ac_headers()
  });
}

export function deleteSmsProfile(smsProfile) {
  return request({
    url: `/content/sms-profiles/${smsProfile.sms_profile_id}`,
    method: "delete",
    data: smsProfile,
    headers: ac_headers()
  });
}

export function sendTestSms(sms_profile_id, recipient, smsContent) {
  return request({
    url: `/content/sms-profiles/test/${sms_profile_id}`,
    method: "post",
    data: {
      sms_content: smsContent,
      receiver: recipient
    },
    headers: ac_headers()
  });
}
//
// export function sendTestSms() {
//   return request({
//     url: `node/send-sms-nodes/send-sms`,
//     method: "post",
//     data: {
//       node_id: 149,
//       recipient: 'tes@test.com',
//       subject: 'hello',
//       content: 'hello content'
//     },
//     headers: ac_headers()
//   });
// }
