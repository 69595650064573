import request, { ac_headers } from "@/utils/studio7ApiService";

export function getTemplates(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio/task-libraries?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function updateTemplate(data) {
  const template = { ...data, guard_name: "admin" };
  return request({
    url: "/studio/task-libraries/" + data.template_id,
    method: "put",
    data: template,
    headers: ac_headers()
  });
}

export function decodeTemplate(template_id) {
  return request({
    url: `/studio/decode-template/${template_id}`,
    method: "post",
    headers: ac_headers()
  });
}

export function deleteTemplate(template) {
  return request({
    url: `/studio/task-libraries/${template.template_id}`,
    method: "delete",
    data: template,
    headers: ac_headers()
  });
}

export function fetchTemplatesForSp(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio/task-library-sp-mappings?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}
