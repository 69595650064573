import request from "@/utils/interactionService";

export function fetchCAVVariables(options, domainId, cancelToken) {
  return request({
    url: `/interactions/v1/domains/${domainId}/variables`,
    method: "get",
    cancelToken: cancelToken
  });
}

export function fetchCAVVariableGroups(options, domainId, cancelToken) {
  return request({
    url: `/interactions/v1/domains/${domainId}/variable-groups`,
    method: "get",
    cancelToken: cancelToken
  });
}
