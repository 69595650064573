import request, { ac_headers } from "@/utils/studio7ApiService";

export function getDnisPools(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/dnis-pool/dnis-pools?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createDnisPool(data) {
  const dnis_pool = { ...data, guard_name: "admin" };
  return request({
    url: "/dnis-pool/dnis-pools",
    method: "post",
    data: dnis_pool,
    headers: ac_headers()
  });
}

export function updateDnisPool(dnis_pool) {
  return request({
    url: `/dnis-pool/dnis-pools/${dnis_pool.pool_id}`,
    method: "put",
    data: dnis_pool,
    headers: ac_headers()
  });
}

export function deleteDnisPool(dnis_pool) {
  return request({
    url: `/dnis-pool/dnis-pools/${dnis_pool.pool_id}`,
    method: "delete",
    data: dnis_pool,
    headers: ac_headers()
  });
}

export function getPoolNumbersData(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/dnis-pool/pool-numbers?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function deleteDnisPoolNumber(pool_number) {
  return request({
    url: `/dnis-pool/pool-numbers/${pool_number.pool_number_id}`,
    method: "delete",
    headers: ac_headers()
  });
}

export function validatePool(name) {
  return request({
    url: `/dnis-pool/validate-name`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}

export function getDnisPoolUploadTemplate() {
  return request({
    url: `/dnis-pool/template`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}
