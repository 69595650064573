import request, { sp_headers } from "@/utils/studio7ApiService";

export function getBurstUsages(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/burst-usages?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function getBurstUsageItems(billing_burst_usage_id) {
  return request({
    url: `/billing/burst-usage-items/${billing_burst_usage_id}`,
    method: "get",
    headers: sp_headers()
  });
}

export function deleteBurstUsages(burstUsage) {
  return request({
    url: `/billing/burst-usages/${burstUsage.billing_burst_usage_id}`,
    method: "delete",
    data: burstUsage,
    headers: sp_headers()
  });
}

export function generateBurstUsages(data) {
  const yearMonth = { ...data, guard_name: "admin" };
  return request({
    url: "/billing/generate-burst-usages/" + data.sp_id,
    method: "post",
    data: yearMonth,
    headers: sp_headers()
  });
}

export function generateBurstUsageCsv(data) {
  return request({
    url: "/billing/generate-burst-usage-csv/" + data.billing_burst_usage_id,
    method: "post",
    data: {
      plan_type: data.plan_type
    },
    headers: sp_headers()
  });
}
