import { getEventProfiles } from "@/api/eventprofiles";

const state = {
  eventprofiles: [],
  loading: false
};

export const getters = {
  eventProfiles(state) {
    if (state.eventProfiles) {
      return state.eventprofiles;
    } else {
      return [];
    }
  }
};
const mutations = {
  SET_EVENT_PROFILES(state, eventprofiles) {
    state.eventprofiles = eventprofiles;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getEventProfiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getEventProfiles(options)
        .then(({ data }) => {
          commit("SET_EVENT_PROFILES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
