import store from "@/store";
import __ from "@/translation";

const cloneBranch = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/copyNode", {
      id,
      copyType: "copy_branch_to_new_canvas"
    });
  }
};

export default {
  text: __("Clone Branch To New Canvas"),
  icon: "",
  onClick: cloneBranch
};
