import {
  createWslProfile,
  deleteWslProfile,
  getWslProfiles,
  getWslProfilesVoices,
  updateWslProfile
} from "@/api/wslprofiles";
import _ from "lodash";

const state = {
  wslProfiles: [],
  wslProfilesVoices: [],
  wslProfiles_filter_key: "all",
  loading: false
};

const getters = {
  getWslProfiles(state) {
    return wsl_profile_id => _.find(state.wslProfiles, { wsl_profile_id });
  }
};

const mutations = {
  SET_WSL_PROFILES(state, wslProfiles) {
    state.wslProfiles = wslProfiles;
  },
  SET_WSL_PROFILES_VOICES(state, wslProfilesVoices) {
    state.wslProfilesVoices = wslProfilesVoices;
  },
  CREATE_WSL_PROFILE(state, wslProfile) {
    state.wslProfiles.push(wslProfile);
  },
  UPDATE_WSL_PROFILE(state, { index, wslProfile }) {
    state.wslProfiles.splice(index, 1, wslProfile);
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getWslProfiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
        commit("SET_WSL_PROFILES", []);
      }
      getWslProfiles(options)
        .then(({ data }) => {
          let wslProfiles = data.data;
          commit("SET_WSL_PROFILES", wslProfiles);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getWslProfilesVoices({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getWslProfilesVoices(options)
        .then(({ data }) => {
          let wslProfilesVoices = data.data;
          commit("SET_WSL_PROFILES_VOICES", wslProfilesVoices);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  // setWslProfileType({ commit }, wsl_profile_type) {
  //   commit("SET_AUTH_PROFILE_TYPE", wsl_profile_type);
  // },

  // setOAuthFlow({ commit }, wsl_profile_flow) {
  //   commit("SET_AUTH_PROFILE_FLOW", wsl_profile_flow);
  // },

  createWslProfile({ commit }, wslProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createWslProfile(wslProfile)
        .then(({ data }) => {
          commit("CREATE_WSL_PROFILE", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateWslProfile({ commit }, wslProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const wslProfilesObj = { ...wslProfile };
      updateWslProfile(wslProfilesObj)
        .then(({ data }) => {
          let index = _.findIndex(state.wslProfiles, oldProfile => {
            return oldProfile.wsl_profile_id === wslProfilesObj.wsl_profile_id;
          });
          commit("UPDATE_WSL_PROFILE", { index, wslProfile: data.data });
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  deleteWslProfile({ commit }, wslProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteWslProfile(wslProfile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  undoDeleteWslProfile({ commit }, wslProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      wslProfile.is_enabled = 1;
      updateWslProfile(wslProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
