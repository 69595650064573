import {
  getSmsProviders,
  createSmsProvider,
  updateSmsProvider,
  deleteSmsProvider
  // sendTestSms
} from "@/api/smsproviders";
import _ from "lodash";

const state = {
  smsProviders: [],
  smsProvider_filter_key: "all",
  loading: false
};

const getters = {
  getSmsProvider: state => sms_provider_id => {
    return _.find(state.smsProviders, { sms_provider_id: +sms_provider_id });
  }
};

const mutations = {
  SET_EMAIL_PROVIDERS(state, smsProviders) {
    state.smsProviders = smsProviders;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getSmsProviders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getSmsProviders(options)
        .then(({ data }) => {
          let smsProviders = data.data;
          smsProviders.forEach(function(smsProvider) {
            smsProvider.edit_name = false;
          });
          commit("SET_EMAIL_PROVIDERS", smsProviders);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createSmsProvider({ commit }, smsProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createSmsProvider(smsProvider)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateSmsProvider({ commit }, smsProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const smsProviderObj = { ...smsProvider };
      updateSmsProvider(smsProviderObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteSmsProvider({ commit }, smsProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteSmsProvider(smsProvider)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteSmsProvider({ commit }, smsProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      smsProvider.is_enabled = 1;
      updateSmsProvider(smsProvider)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  // sendTestSms({ commit }, { sms_provider_id, sms }) {
  //   return new Promise((resolve, reject) => {
  //     commit("CHANGE_LOADING", true);
  //     sendTestSms(sms_provider_id, sms)
  //       .then(() => {
  //         commit("CHANGE_LOADING", false);
  //         resolve();
  //       })
  //       .catch(err => {
  //         commit("CHANGE_LOADING", false);
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
