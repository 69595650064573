import request, { ac_headers } from "@/utils/studio7ApiService";

export function getNotifications(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/system/notification-messages?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createNotification(data) {
  const notification = { ...data, guard_name: "admin" };
  return request({
    url: "/system/notification-messages",
    method: "post",
    data: notification,
    headers: ac_headers()
  });
}

export function updateNotification(notification) {
  return request({
    url: `/system/notification-messages/${notification.notification_message_id}`,
    method: "put",
    data: notification,
    headers: ac_headers()
  });
}

export function deleteNotification(notification) {
  return request({
    url: `/system/notification-messages/${notification.notification_message_id}`,
    method: "delete",
    data: notification,
    headers: ac_headers()
  });
}

export function readNotification(data) {
  const notification = { ...data, guard_name: "admin" };
  return request({
    url: "/system/notification-message-user-actions",
    method: "post",
    data: notification,
    headers: ac_headers()
  });
}

export function getUserNotifications() {
  return request({
    url: "/system/get-user-notifications",
    method: "get",
    headers: ac_headers()
  });
}

export function updateUserNotification(notification) {
  return request({
    url: "/system/update-user-notification",
    data: notification,
    method: "post",
    headers: ac_headers()
  });
}

export function getSpNotifications(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/system/get-sp-notifications?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}
