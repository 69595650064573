import {
  getSpPlans,
  createSpPlan,
  updateSpPlan,
  deleteSpPlan,
  getBusinessPlansOfSameType,
  getAvailablePlansForSP,
  getServiceProvidersForBusinessPlans,
  getAvailableFeaturesForSpPlan
} from "@/api/spplans";
import { getServiceProviders } from "@/api/serviceproviders";
import _ from "lodash";

export const state = {
  spPlans: [],
  bpIds: [],
  availablePlans: [],
  serviceProviders: [],
  isLoading: false,
  planFilter: "IVA",
  availableFeatures: []
};

export const mutations = {
  SET_SP_PLANS(state, spPlans) {
    state.spPlans = spPlans;
  },

  SET_AVAILABLE_SP_PLANS(state, availablePlans) {
    state.availablePlans = availablePlans;
  },

  SET_SPS_FOR_BP(state, serviceProvidersBP) {
    state.serviceProviders = serviceProvidersBP;
  },

  CREATE_SP_PLAN(state, spPlan) {
    state.spPlans.push(spPlan);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  UPDATE_SP_PLAN(state, { index, spPlan }) {
    state.spPlans.splice(index, 1, spPlan);
  },

  DELETE_SP_PLAN(state, spPlan) {
    state.spPlans = state.spPlans.filter(
      oldPlan => oldPlan.sp_plan_id !== spPlan.sp_plan_id
    );
  },

  SET_BP_PLAN_ID(state, bpIds) {
    state.bpIds = bpIds;
  },

  SET_PLAN_FILTER_KEY(state, filter) {
    state.planFilter = filter;
  },

  SET_AVAILABLE_FEATURES_FOR_SP(state, availableFeatures) {
    state.availableFeatures = availableFeatures;
  }
};

export const actions = {
  getSpPlans({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      if (options && !_.get(options, "plan_type"))
        options = { ...options, plan_type: state.planFilter };
      getSpPlans(options)
        .then(async ({ data }) => {
          commit("SET_SP_PLANS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  setBusinessPlanFilter({ commit }, filter) {
    commit("SET_PLAN_FILTER_KEY", filter);
  },

  createSpPlan({ commit }, spPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createSpPlan(spPlan)
        .then(async ({ data }) => {
          commit("CREATE_SP_PLAN", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  updateSpPlan({ commit, state }, spPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      updateSpPlan(spPlan)
        .then(({ data }) => {
          var index = _.findIndex(state.spPlans, oldPlan => {
            return oldPlan.sp_plan_id === spPlan.sp_plan_id;
          });
          commit("UPDATE_SP_PLAN", { index, spPlan: data.data });
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteSpPlan({ commit }, spPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      deleteSpPlan(spPlan)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          commit("DELETE_SP_PLAN", spPlan);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteSpPlan({ commit }, spPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      spPlan.is_enabled = 1;
      updateSpPlan(spPlan)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getBusinessPlansOfSameType({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getBusinessPlansOfSameType(options)
        .then(async ({ data }) => {
          commit("SET_AVAILABLE_SP_PLANS", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getAvailablePlansForSP({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAvailablePlansForSP(options)
        .then(async ({ data }) => {
          commit("SET_AVAILABLE_SP_PLANS", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getServiceProvidersForBusinessPlans({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getServiceProvidersForBusinessPlans(options)
        .then(async ({ data }) => {
          commit("SET_SPS_FOR_BP", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getSpForEditMode({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getServiceProviders({ search: options.sp_id, searchFields: "sp_id:=" })
        .then(async ({ data }) => {
          commit("SET_SPS_FOR_BP", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  getAvailableFeaturesForSpPlan({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAvailableFeaturesForSpPlan(options)
        .then(async ({ data }) => {
          commit("SET_AVAILABLE_FEATURES_FOR_SP", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export const getters = {
  spPlanName: state => sp_plan_id => {
    let spPlan = _.find(state.spPlans, {
      sp_plan_id: parseInt(sp_plan_id)
    });
    return spPlan ? spPlan.sp_plan_name : "Error";
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
