import axios from "axios";
import _ from "lodash";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_INTERACTION_BASE_API_URL
});

// request interceptor
service.interceptors.request.use(
  async config => {
    const token = localStorage.getItem("studio7-user-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  response => {
    return response;
  },
  async error => {
    if (!isErrorDueToCancelledToken(error)) {
      return Promise.reject({
        ...error,
        message:
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0] &&
            error.response.data.errors[0][0]) ||
          (error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.message) ||
          (error.response.data instanceof Blob &&
            error.response.data.type === "application/json" &&
            JSON.parse(await error.response.data.text()).message) ||
          __("Operation Failed")
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export const cancelPendingRequests = cancelTokens => {
  _.map(cancelTokens, cancelToken =>
    cancelToken.cancel(__("Operation canceled due to new request."))
  );
};

export const generateCancelToken = () => {
  return axios.CancelToken.source();
};

export const isErrorDueToCancelledToken = err => {
  return axios.isCancel(err);
};

export default service;
