import request, { ac_headers } from "@/utils/studio7ApiService";

export function getFormTypes(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/form/form-types?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createFormType(data) {
  const formtype = { ...data, guard_name: "admin" };
  return request({
    url: "/form/form-types",
    method: "post",
    data: formtype,
    headers: ac_headers()
  });
}

export function updateFormType(formtype) {
  return request({
    url: `/form/form-types/${formtype.form_type_id}`,
    method: "put",
    data: formtype,
    headers: ac_headers()
  });
}

export function deleteFormType(formtype) {
  return request({
    url: `/form/form-types/${formtype.form_type_id}`,
    method: "delete",
    data: formtype,
    headers: ac_headers()
  });
}

export function getFormTypesWithDatastoreColumns(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/form/get-form-types-with-datastore-columns?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function checkUniqueName(displayName) {
  return request({
    url: `/form/validate-ft-name/${displayName}`,
    method: "get",
    headers: ac_headers()
  });
}
