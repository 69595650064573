<template>
  <div class="not-scoped">
    <div v-if="!switchScopeText">
      <p class="select-scope-text" style="font-size: smaller;font-weight: bold">
        <!-- eslint-disable-next-line -->
        {{ __("You are viewing All :pluralScope.", { pluralScope }) }}
      </p>
      <p class="select-scope-text" style="font-size: small">
        <!-- eslint-disable-next-line -->
        {{ __("To view :resource, select an individual :scope.", { resource, scope }) }}
      </p>
    </div>
    <div v-else>
      <p class="select-scope-text" style="font-size: smaller;font-weight: bold">
        <!-- eslint-disable-next-line -->
        {{  __("You are viewing individual :scope.", { scope }) }}
      </p>
      <p class="select-scope-text" style="font-size: small">
        <!-- eslint-disable-next-line -->
        {{  __("To view :resource, select all :scope.", { resource, scope }) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotScoped",
  props: {
    scope: {
      required: true,
      type: String
    },
    resource: {
      required: true,
      type: String
    },
    switchScopeText: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    /**
     * pluralize scope
     * @returns {string}
     */
    pluralScope() {
      return `${this.scope}s`;
    }
  }
};
</script>

<style scoped>
.not-scoped {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-scope-text {
  text-align: center;
}

.select-scope-text:first-child {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 150px;
}
</style>
