import store from "@/store";
import __ from "@/translation";

export const deleteBranch = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/askPermissionForDelete", {
      id,
      deleteType: "delete_branch"
    });
  }
};

export default {
  text: `${__("Delete Branch")}<span>Ctrl+A, Ctrl+D</span>`,
  icon: "",
  onClick: deleteBranch
};
