import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

export function getMsgCreditUsage(scope) {
  return request({
    url: "/reporting/msg-credit-usages",
    method: "get",
    headers: scope === "ac" ? ac_headers() : scope === "sp" ? sp_headers() : {}
  });
}

export function getCallUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/reporting/usages/call-usage?" + params.toString(),
    method: "get"
  });
}

export function getDetailedUsageLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/get-detailed-logs-for-task?" + params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getWorkflowUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/workflow-usages?" + params.toString(),
    method: "get"
  });
}

export function getMessagingUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/virtual-msg-logs?" + params.toString(),
    method: "get"
  });
}

export function getDetailedMsgUsageLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/virtualagentusage/msg-log/get-detailed-logs-for-task?" +
      params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getChatbotUsages(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/chatbot-usage-logs?" + params.toString(),
    method: "get"
  });
}

export function getChatbotDetailedLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/virtualagentusage/chatbot-usage-log/get-detailed-logs-for-task?" +
      params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getVoiceDetailedLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url: "/reporting/usages/get-detailed-logs-for-task?" + params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getBiometricUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/biometric-usage?" + params.toString(),
    method: "get"
  });
}

export function getBiometricDetailedLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/virtualagentusage/biometric-usage/get-detailed-logs-for-task?" +
      params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getTranscriptionUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/transcription-usages?" + params.toString(),
    method: "get"
  });
}

export function getTranscriptionDetailedLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/virtualagentusage/transcription-usage/get-detailed-logs-for-task?" +
      params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getToneAnalyzerUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/tone-analyzer-usages?" + params.toString(),
    method: "get"
  });
}

export function getToneAnalyzerDetailedLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/virtualagentusage/tone-analyzer-usage/get-detailed-logs-for-task?" +
      params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}

export function getNumberInsightUsage(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/virtualagentusage/number-insight-usages?" + params.toString(),
    method: "get"
  });
}

export function getNumberInsightDetailedLog(
  task,
  dateRange,
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/virtualagentusage/number-insight-usage/get-detailed-logs-for-task?" +
      params.toString(),
    method: "post",
    data: {
      task_id: task.task_id,
      ac_id: task.ac_id,
      start_date: dateRange[0],
      end_date: dateRange[1]
    }
  });
}
