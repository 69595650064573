import { getLoadedConfigurations } from "@/api/loadedconfigurations";

export const state = {
  configuration_type: null,
  configurations: {},
  loading: false
};

export const mutations = {
  SET_LOADED_CONFIGURATION_TYPE(state, filter) {
    state.configuration_type = filter;
  },
  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_CONFIGURATIONS(state, configurations) {
    state.configurations = configurations;
  },
  SET_LOADED_CONFIGURATION(state, { announcements, type }) {
    switch (type) {
      case "front-end":
        state.front_end = announcements;
        break;
      case "back-end":
        state.back_end = announcements;
        break;
    }
  }
};

export const getters = {};

export const actions = {
  setLoadedConfigurationType({ commit }, filter) {
    commit("SET_LOADED_CONFIGURATION", filter);
  },
  getLoadedConfigurations({ commit }) {
    return new Promise((resolve, reject) => {
      {
        commit("UPDATE_LOADING_STATUS", true);
        getLoadedConfigurations()
          .then(({ data }) => {
            // Mask frontend env variables
            const frontendEnvMasked = Object.entries(process.env).reduce(
              (accumulator, [key, value]) => {
                if (key.includes("KEY")) {
                  accumulator[key] = "XXXXXXX";
                } else {
                  accumulator[key] = value;
                }
                return accumulator;
              },
              {}
            );
            data.data.front_end = frontendEnvMasked;
            commit("SET_CONFIGURATIONS", data.data);
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
