const getters = {
  userType: state => state.app.userType,
  userRole: state => state.app.userRole,
  selectedServiceProviderId: state => state.app.selectedServiceProviderId,
  selectedAccountId: state => state.app.selectedAccountId,
  selectedAccountDomain: state => state.app.selectedAccountDomain,
  enabledFeatures: state => state.app.enabled_features,
  colors: state => state.theme.colors,
  appSubdomain: state => state.app.subdomain,
  redirected: state => state.app.redirected,
  accountAccessibleTtsVoices: state => state.accountTtsVoices.accountTtsVoices
};

export default getters;
