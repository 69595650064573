import { getSystemSettings, updateSystemSettings } from "@/api/systemsettings";

const state = {
  settings: {},
  isLoading: false
};

const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getSettings({ commit }, options) {
    return new Promise((resolve, reject) => {
      {
        commit("UPDATE_LOADING_STATUS", true);
        getSystemSettings(options)
          .then(({ data }) => {
            commit("SET_SETTINGS", data.data);
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      }
    });
  },
  updateSystemSettings({ commit }, settings) {
    return new Promise((resolve, reject) => {
      {
        commit("UPDATE_LOADING_STATUS", true);
        updateSystemSettings(settings)
          .then(({ data }) => {
            commit("SET_SETTINGS", data.data);
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
