import {
  getAcPeakSessions,
  getSpPeakSessions,
  getPopPeakSessions
} from "@/api/peaksessions";

const state = {
  accounts: []
};

const mutations = {
  SET_AC_HOURLY_PEAK_SESSIONS(state, peakSessions) {
    state.peakSessions = peakSessions;
  }
};

const actions = {
  getAcPeakSessions({ commit }, params) {
    return new Promise((resolve, reject) => {
      getAcPeakSessions(params)
        .then(({ data }) => {
          commit("SET_AC_HOURLY_PEAK_SESSIONS", data.data);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  getSpPeakSessions({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSpPeakSessions(params)
        .then(({ data }) => {
          commit("SET_AC_HOURLY_PEAK_SESSIONS", data.data);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  getPopPeakSessions({ commit }, params) {
    return new Promise((resolve, reject) => {
      getPopPeakSessions(params)
        .then(({ data }) => {
          commit("SET_AC_HOURLY_PEAK_SESSIONS", data.data);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
