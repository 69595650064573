import request, { ac_headers, sp_headers } from "@/utils/studio7ApiService";

export function getWebServiceProfiles() {
  return request({
    url: `/util/web-service-profiles/list`,
    method: "get",
    headers: sp_headers()
  });
}

export function getAcWebServiceProfiles() {
  return request({
    url: `/util/ac-web-service-profiles/list`,
    method: "get",
    headers: ac_headers()
  });
}
