import moment from "moment-timezone";
import _ from "lodash";
import __ from "@/translation";

const calendarFormatWithTz = {
  sameDay: `[${__("Today at")}] LT z`,
  nextDay: `[${__("Tomorrow at")}] LT z`,
  nextWeek: "dddd LT z",
  lastDay: `[${__("Yesterday at")}] LT z`,
  lastWeek: `[${__("Last")}] dddd LT z`,
  sameElse: "lll z"
};

const localCalendarFormat = {
  sameDay: `[${__("Today at")}] LT`,
  nextDay: `[${__("Tomorrow at")}] LT`,
  nextWeek: "dddd LT",
  lastDay: `[${__("Yesterday at")}] LT`,
  lastWeek: `[${__("Last")}] dddd LT`,
  sameElse: "lll"
};

export const convertToTimeStamp = dateTimeString =>
  moment(dateTimeString).format("X");

export const currentTime = () => moment();

export const formatTimeStampAsCalendar = (timestamp, tz = "") => {
  let now = currentTime();
  let timeStampedMoment = moment.unix(timestamp);
  if (tz) {
    now = currentTimeInTz(tz);
    timeStampedMoment = timeStampedMoment.tz(tz);
  }
  let duration = moment.duration(now.diff(timeStampedMoment));
  return _.upperFirst(
    duration.asSeconds() < 10
      ? __("just now")
      : duration.asSeconds() <= 30
      ? __("a few seconds ago")
      : duration.asSeconds() < 60
      ? __("less than a minute ago")
      : duration.asHours() > 1
      ? timeStampedMoment.calendar(localCalendarFormat)
      : timeStampedMoment.fromNow()
  );
};

export const formatTimeStampAsCalendarWithTz = (timestamp, tz = "UTC") => {
  return moment.tz(timestamp * 1000, tz).calendar(calendarFormatWithTz);
};

export const formatAsCalendarWithTz = (
  datetime,
  tz = "UTC",
  format = "YYYY-MM-D HH:mm:ss"
) => {
  return moment.tz(datetime, format, tz).calendar(calendarFormatWithTz);
};

export const formatAsCalendar = (datetime, format = "YYYY-MM-D HH:mm:ss") => {
  return moment(datetime, format).calendar(localCalendarFormat);
};

export const formatTimeStampWithTz = (
  timestamp = "",
  tz = "UTC",
  format = "YYYY-MM-D HH:mm:ss"
) => {
  // if user tz is not set use the local tz
  tz = tz !== "" ? tz : currentTzName();

  // timestamp not set then use current
  let momentTime =
    timestamp === "" ? moment().tz(tz) : moment(timestamp).tz(tz);

  if (!momentTime.isValid()) {
    return timestamp;
  }
  return momentTime.format(format);
};

export const convertTzTimestampMsToLocalTimestampMs = (timestampMs, timezone) =>
  moment(
    moment(+timestampMs)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss")
  ).format("X") * 1000;

export const convertLocalTimestampMsToTzTimestampMs = (
  localTimestampMs,
  targetTimezone
) =>
  moment
    .tz(
      moment(+localTimestampMs).format("YYYY-MM-DD HH:mm:ss"),
      "YYYY-MM-DD HH:mm:ss",
      targetTimezone
    )
    .format("X") * 1000;

export const currentTimeInTz = (tz = "UTC") => moment().tz(tz);

export const stringTime = (time, format = "YYYY-MM-DD HH:mm:ss") =>
  time.format(format);

export const stringDate = (time, format = "YYYY-MM-DD") => time.format(format);

export const currentTzName = () => moment.tz.guess();

export const createTimeInTz = (
  datetime,
  tz = "UTC",
  format = "YYYY-MM-D HH:mm:ss"
) => moment.tz(datetime, format, tz);

export const convertDurationInMicroSecondsToHumanReadableForm = microSeconds => {
  let seconds = Math.round(microSeconds / 1000000);
  return moment
    .duration(seconds, "seconds")
    .format("d [day] h [hr] m [min] s [sec]", { trim: "both mid" });
};

const pickerDateFormat = "YYYY-MM-DD";
export const presetDates = timezone => {
  return {
    today: currentTimeInTz(timezone).format(pickerDateFormat),
    yesterday: currentTimeInTz(timezone)
      .subtract(1, "days")
      .format(pickerDateFormat),
    sevenDaysAgo: currentTimeInTz(timezone)
      .subtract(7, "days")
      .format(pickerDateFormat),
    startOfThisMonth: currentTimeInTz(timezone)
      .startOf("month")
      .format(pickerDateFormat),
    endOfThisMonth: currentTimeInTz(timezone)
      .endOf("month")
      .format(pickerDateFormat),
    startOfLastMonth: currentTimeInTz(timezone)
      .subtract(1, "months")
      .startOf("month")
      .format(pickerDateFormat),
    endOfLastMonth: currentTimeInTz(timezone)
      .subtract(1, "months")
      .endOf("month")
      .format(pickerDateFormat),
    startOfLastThreeMonth: currentTimeInTz(timezone)
      .subtract(3, "months")
      .startOf("month")
      .format(pickerDateFormat),
    startOfThisWeek: currentTimeInTz(timezone)
      .startOf("week")
      .format(pickerDateFormat),
    endOfThisWeek: currentTimeInTz(timezone)
      .endOf("week")
      .format(pickerDateFormat),
    startOfLastWeek: currentTimeInTz(timezone)
      .subtract(1, "weeks")
      .startOf("week")
      .format(pickerDateFormat),
    endOfLastWeek: currentTimeInTz(timezone)
      .subtract(1, "weeks")
      .endOf("week")
      .format(pickerDateFormat)
  };
};
