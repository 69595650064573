import store from "@/store";
import __ from "@/translation";

const unlinkNode = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/unlinkNode", {
      id,
      unlinkType: "unlink_node"
    });
  }
};

export default {
  text: __("Unlink Node"),
  icon: "",
  onClick: unlinkNode
};
