// src/store/modules/conversationMonitoring.js

import { enableMonitoring } from "@/api/conversationMonitoring"; // Adjust the import path as necessary

const state = {
  isMonitoring: false,
  isLoading: false,
  error: null
};

const mutations = {
  SET_MONITORING(state, status) {
    state.isMonitoring = status;
  },
  SET_LOADING(state, status) {
    state.isLoading = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  /**
   * Enables ANI Monitoring by dispatching an API call.
   *
   * @param {Object} context - Vuex context.
   * @param {Object} payload - Contains taskId and ani.
   * @returns {Promise<boolean>} - Indicates success or failure.
   */
  async enableMonitoringAction({ commit }, { taskId, ani, scope }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const response = await enableMonitoring({ taskId, ani, scope });
      if (response.status === 200) {
        commit("SET_MONITORING", true);
        return true;
      } else {
        commit(
          "SET_ERROR",
          response.data.message || "Failed to enable monitoring."
        );
        return false;
      }
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occurred while enabling ANI monitoring.";
      commit("SET_ERROR", errorMessage);
      return false;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  /**
   * Stops monitoring by resetting the state.
   *
   * @param {Object} context - Vuex context.
   */
  stopMonitoringAction({ commit }) {
    commit("SET_MONITORING", false);
    commit("SET_ERROR", null);
  }
};

const getters = {
  isMonitoring: state => state.isMonitoring,
  isLoading: state => state.isLoading,
  error: state => state.error
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
