import {
  getCampaigns,
  createCampaign,
  updateCampaign,
  getCampaign,
  deleteCampaign,
  getCampaignData,
  makeACall,
  getAcMaxThreads
} from "@/api/campaigns";
import _ from "lodash";

const state = {
  campaigns: [],
  campaign_data: [],
  campaignFilter: "On-Demand",
  loading: false,
  selected_campaign_id: -1
};

const getters = {
  getCampaign: state => campaign_id => {
    return _.find(state.campaigns, { campaign_id: +campaign_id });
  }
};
const mutations = {
  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns;
  },

  SET_CAMPAIGN_DATA(state, campaign_data) {
    state.campaign_data = campaign_data;
  },

  SET_CAMPAIGN_FILTER_KEY(state, filter) {
    state.campaignFilter = filter;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  CHANGE_SELECTED_CAMPAIGN_ID(state, campaign_id) {
    state.selected_campaign_id = campaign_id;
  }
};

const actions = {
  getCampaigns({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options, campaign_type: state.campaignFilter };
      getCampaigns(options)
        .then(({ data }) => {
          commit("SET_CAMPAIGNS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getOnDemandCampaigns() {
    return new Promise((resolve, reject) => {
      const options = { fetch_all: 1, campaign_type: "on-demand" };
      getCampaigns(options)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  getCampaign({ commit, getters }, campaign_id) {
    return new Promise((resolve, reject) => {
      let campaign = getters.getCampaign(campaign_id);
      if (!campaign) {
        commit("CHANGE_LOADING", true);
        getCampaign(campaign_id)
          .then(({ data }) => {
            commit("ADD_CAMPAIGN", data.data);
            resolve(campaign);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      } else {
        resolve(campaign);
      }
    });
  },

  createCampaign({ commit }, { campaign }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createCampaign(campaign)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateCampaign({ commit }, { campaign }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateCampaign(campaign)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  setCampaignFilter({ commit }, filter) {
    commit("SET_CAMPAIGN_FILTER_KEY", filter);
  },

  setSelectedCampaignId({ commit }, campaign_id) {
    commit("CHANGE_SELECTED_CAMPAIGN_ID", campaign_id);
  },

  deleteCampaign({ commit }, campaign) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteCampaign(campaign)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteCampaign({ commit }, campaign) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      campaign.is_enabled = 1;
      console.log("Undo Delete ", campaign);
      updateCampaign(campaign)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },
  getCampaignData({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options, campaign_id: state.selected_campaign_id };
      getCampaignData(options)
        .then(({ data }) => {
          commit("SET_CAMPAIGN_DATA", data.data);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  makeACall({ commit }, { campaign }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      makeACall(campaign)
        .then(res => {
          commit("CHANGE_LOADING", false);
          resolve(res);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getAcMaxThreads({ commit }) {
    return new Promise((resolve, reject) => {
      getAcMaxThreads()
        .then(res => {
          commit("CHANGE_LOADING", false);
          resolve(res.data.max_threads);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
