<template>
  <div class="items">
    <div
      @click="handleClick(item)"
      v-for="(item, index) in itemsToShow"
      class="item"
      :key="index"
    >
      <img :src="getIconSVG(item.svg)" alt="" />
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      items: [
        // {
        //   name: "dark_mode",
        //   svg: "dark_mode.svg"
        // },
        {
          name: "contents",
          svg: "content_items.svg"
        }
        // {
        //   name: "help",
        //   alwaysShow: true,
        //   svg: "question_mark.svg"
        // }
      ]
    };
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId,
      darkTheme: state => state.darkTheme
    }),
    itemsToShow() {
      return _.filter(
        this.items,
        item => item.alwaysShow || this.selectedAccountId !== "all"
      );
    }
  },
  methods: {
    ...mapActions("app", {
      toggleDarkTheme: "toggleDarkTheme"
    }),
    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },
    handleClick(item) {
      if (item.name === "contents") {
        EventBus.$emit("open-contents-modal");
      } else if (item.name === "help") {
        console.log("redirecting to help page");
      } else if (item.name == "dark_mode") {
        this.toggleDarkTheme();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  display: flex;
  background-color: #222933;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  justify-content: space-around;
  align-items: center;
  border-radius: 1000px;
  z-index: 999;
  position: fixed;
  bottom: 20px;
  right: 30px;

  .item {
    display: flex;
    align-self: center;
    padding: 10px 15px;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
