export const NLP_DIALOGFLOW = "dialogflow";
export const NLP_DIALOGFLOW_CX = "dialogflow_cx";
export const NLP_LEX = "lex";
export const NLP_WATSON = "watson";
export const NLP_AZURE_OPENAI = "azure_openai";
export const NLP_OPENAI = "openai";

export const nlpEngineTypes = [
  { label: "Google Dialogflow ES", value: NLP_DIALOGFLOW, ldHide: false },
  { label: "Google Dialogflow CX", value: NLP_DIALOGFLOW_CX, ldHide: false },
  { label: "Amazon Lex", value: NLP_LEX, ldHide: false },
  { label: "IBM Watson", value: NLP_WATSON, ldHide: false },
  { label: "Azure OpenAI", value: NLP_AZURE_OPENAI, ldHide: true }
];

export const gptModelList = [
  { value: "gpt-3.5-turbo" },
  { value: "gpt-4" },
  { value: "gpt-4-0314" },
  { value: "gpt-3.5-turbo-0301" }
];

export const aiSummaryLanguageList = [
  { value: "English US" },
  { value: "English Australia" },
  { value: "English Canada" },
  { value: "English Great Britain" },
  { value: "English India" },
  { value: "Chinese (Simplified)" },
  { value: "Chinese Hong Kong (Hong Kong)" },
  { value: "Chinese Traditional (Traditional)" },
  { value: "Danish" },
  { value: "Dutch" },
  { value: "French" },
  { value: "French Canada" },
  { value: "French France" },
  { value: "German" },
  { value: "Hindi" },
  { value: "Indonesian" },
  { value: "Italian" },
  { value: "Japanese" },
  { value: "Korean (South Korea)" },
  { value: "Norwegian" },
  { value: "Polish" },
  { value: "Portuguese Brazil (Brazilian)" },
  { value: "Portuguese Portugal (European)" },
  { value: "Russian" },
  { value: "Spanish" },
  { value: "Spanish Latin America" },
  { value: "Spanish Spain" },
  { value: "Swedish" },
  { value: "Thai" },
  { value: "Turkish" },
  { value: "Ukrainian" }
];
