<script>
import * as Five9ManagedUser from "@/utils/Five9AdminConsoleManagedUser";

export default {
  computed: {
    /**
     * Check if the currently authenticated user is managed by Five9 admin console
     * @returns { bool }
     */
    five9ManagedCurrentUser() {
      return Five9ManagedUser.currentUserIsManaged();
    }
  }
};
</script>
