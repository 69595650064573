import request, { ac_headers } from "@/utils/studio7ApiService";

export function getHolidaySchedules(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/holidayschedule/holiday-schedules?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createHolidaySchedule(data) {
  const holidaySchedule = { ...data, guard_name: "admin" };
  return request({
    url: "/holidayschedule/holiday-schedules",
    method: "post",
    data: holidaySchedule,
    headers: ac_headers()
  });
}

export function updateHolidaySchedule(holidaySchedule) {
  return request({
    url: `/holidayschedule/holiday-schedules/${holidaySchedule.schedule_id}`,
    method: "put",
    data: holidaySchedule,
    headers: ac_headers()
  });
}

export function deleteHolidaySchedule(holidaySchedule) {
  return request({
    url: `/holidayschedule/holiday-schedules/${holidaySchedule.schedule_id}`,
    method: "delete",
    data: holidaySchedule,
    headers: ac_headers()
  });
}

export function validateHolidayScheduleName(name) {
  return request({
    url: `/holidayschedule/validate`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}
