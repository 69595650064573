<template>
  <div class="folder-sidebar-wrapper">
    <div class="folder-sidebar">
      <el-button
        :disabled="!this.canCreateNewFolder()"
        v-if="activeName === 'folders' && navItems.length"
        @click="createNewFolder"
        type="text"
        class="new-folder-btn"
        ><img
          src="@/assets/icons/content-icon-add-folder.svg"
          onload="SVGInject(this)"
          alt="icon-content"
      /></el-button>
      <el-tabs v-model="activeName" @tab-click="handleSwitchTab">
        <el-tab-pane :label="__('Folders')" name="folders">
          <folders-tab></folders-tab>
        </el-tab-pane>
        <el-tab-pane :label="__('Tasks')" name="tasks">
          <tasks-tab></tasks-tab>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FoldersTab from "./components/FoldersTab";
import TasksTab from "./components/TasksTab";
import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  components: {
    FoldersTab,
    TasksTab
  },

  props: {
    navItems: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      activeName: "folders"
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  methods: {
    ...mapActions("folders", {
      getFolders: "getFolders",
      createFolder: "createFolder",
      updateSelectedFolderId: "updateSelectedFolderId",
      updateSelectedTaskId: "updateSelectedTaskId",
      updateCurrentTab: "updateCurrentTab"
    }),

    ...mapActions("tasks", {
      getTasks: "getTasks"
    }),

    canCreateNewFolder() {
      let canCreateNewFolder = false;
      let vm = this;
      _.forEach(this.navItems, item => {
        canCreateNewFolder =
          canCreateNewFolder ||
          vm.can("build.content." + item.label + ".write");
      });
      return canCreateNewFolder;
    },

    createNewFolder() {
      this.$prompt(__("Folder name"), __("Create new folder"), {
        confirmButtonText: __("Create"),
        cancelButtonText: __("Cancel"),
        confirmButtonClass: "folderDialogSubmitBtn",
        cancelButtonClass: "folderDialogCancelBtn",
        customClass: "folderDialog"
      })
        .then(({ value }) => {
          this.createFolder({
            folder_name: value
          })
            .then(data => {
              console.log(data);
              this.getFolders()
                .then(() => {
                  this.$message({
                    type: "success",
                    message: __("Folder created")
                  });
                })
                .catch(() => {});
            })
            .catch(error => {
              this.$message({
                type: "error",
                message:
                  error && error.message
                    ? error.message
                    : __("Unable to create folder")
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("Input canceled")
          });
        });
    },

    handleSwitchTab(val) {
      EventBus.$emit("uncheck-all-items");
      EventBus.$emit("de-select-all");
      EventBus.$emit("empty-multi-selected-items");
      this.updateCurrentTab(val.name);
      if (val.name === "folders") {
        EventBus.$emit("select-folder", 0);
        this.updateSelectedTaskId(-1);
      } else if (val.name === "tasks") {
        this.updateSelectedFolderId(0);
      }
    },

    fetchContents() {
      this.getFolders({ fetch_all: 1 });
      this.getTasks({ ignore_task_type_filter: 1, fetch_all: 1 });
    }
  },

  mounted() {
    this.fetchContents();
    this.updateSelectedTaskId(-1);
  },

  beforeDestroy() {
    this.updateSelectedTaskId(-1);
    this.updateSelectedFolderId(0);
  },

  watch: {
    selectedAccountId: {
      handler() {
        this.$nextTick(() => {
          this.fetchContents();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/button.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.form-container {
  padding: 20px 0;
}
</style>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$site-secondary-background-color: var(
  --site-secondary-background-color
) !default;

.folderDialogSubmitBtn {
  height: 44px;
  background: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
  border: none;
  color: white;
  font-weight: 200;
  font-size: 1rem;

  &:hover {
    background: var(--theme-hover-color) !important;
    color: white;
  }
}

.folderDialogCancelBtn {
  border: none;
  height: 44px;
  background: #f5f5f5;
  color: #a0a8b5;
  font-weight: 200;
  font-size: 1rem;

  &:hover {
    background: lightgrey !important;
    color: white !important;
    border-color: lightgray !important;
  }
}

.folderDialog {
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: var(--theme-color) !important;
    outline: 0;
    height: 44px;
  }
  .el-message-box__headerbtn:focus .el-message-box__close,
  .el-message-box__headerbtn:hover .el-message-box__close {
    color: var(--theme-color) !important;
  }
  .el-input--medium .el-input__inner {
    height: 44px;
    line-height: 44px;
  }
}

.folder-sidebar-wrapper {
  padding: 0 8px;
  border-right: $site-secondary-background-color 1px solid;
  height: 100%;
  display: flex;

  .folder-sidebar {
    position: relative;
    width: 100%;
    padding: 0;

    .new-folder-btn {
      color: var(--theme-color);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding-right: 10px;
    }

    .el-tabs__header {
      padding: 0 10px;

      .el-tabs__nav-wrap::after {
        background-color: transparent;
      }
    }

    .search-wrapper {
      padding: 0 10px 10px;

      .el-input__inner {
        background-color: transparent;
        padding-left: 8px;
      }

      .el-input__prefix {
        left: auto;
        right: 5px;
      }
    }

    .el-menu {
      &.folder-names,
      &.task-names {
        border-right: none;
        background-color: $site-secondary-background-color;

        .el-menu-item {
          height: 40px;
          line-height: 40px;

          &:hover {
            background-color: white;
          }
        }
      }
    }
  }
}
</style>
