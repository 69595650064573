import {
  createAuthProfile,
  deleteAuthProfile,
  getAuthProfiles,
  updateAuthProfile,
  getAuthProfileToken
} from "@/api/authprofiles";
import _ from "lodash";

import {
  cancelPendingRequests,
  generateCancelToken,
  isErrorDueToCancelledToken
} from "@/utils/studio7ApiService";

const state = {
  authProfiles: [],
  authProfile_filter_key: "all",
  auth_profile_type: "Basic",
  prev_selected_auth_profile_type: "",
  oauth2_flow: "",
  loading: false,
  cancelTokens: []
};

const getters = {
  getAuthProfiles(state) {
    return auth_profile_id => _.find(state.authProfiles, { auth_profile_id });
  },
  getAuthProfilesOfTypeAndFlow(state) {
    return (type, flow) => {
      return _.filter(state.authProfiles, profile => {
        if (type === "Basic") {
          return profile.auth_profile_type === type;
        }
        return (
          profile.auth_profile_type === type &&
          profile.auth_profile_oauth2_cc.data.oauth2_flow === flow
        );
      });
    };
  },
  clientCredentialsAuthProfiles(state, getters) {
    return getters.getAuthProfilesOfTypeAndFlow("OAuth2_CC", "Client");
  }
};

const mutations = {
  SET_AUTH_PROFILES(state, authProfiles) {
    state.authProfiles = authProfiles;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_AUTH_PROFILE_TYPE(state, auth_profile_type) {
    state.prev_selected_auth_profile_type = state.auth_profile_type;
    state.auth_profile_type = auth_profile_type;
  },
  SET_AUTH_PROFILE_FLOW(state, oauth2_flow) {
    state.oauth2_flow = oauth2_flow;
  },
  ADD_TO_CANCEL_TOKENS(state, token) {
    state.cancelTokens.push(token);
  },

  SET_CANCEL_TOKENS(state, tokens) {
    state.cancelTokens = tokens;
  }
};

const actions = {
  getAuthProfiles({ commit, state }, options) {
    cancelPendingRequests(state.cancelTokens);
    let cancelToken = generateCancelToken();
    commit("ADD_TO_CANCEL_TOKENS", cancelToken);

    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
        commit("SET_AUTH_PROFILES", []);
      }
      options["auth_profile_type"] = state.auth_profile_type;
      getAuthProfiles(options, cancelToken.token)
        .then(({ data }) => {
          let authProfiles = data.data;
          commit("SET_AUTH_PROFILES", authProfiles);
          cancelPendingRequests(state.cancelTokens);
          commit("SET_CANCEL_TOKENS", []);
          resolve(data);
        })
        .catch(err => {
          if (!isErrorDueToCancelledToken(err)) {
            commit("SET_LOADING_STATE", false);
          }
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  setAuthProfileType({ commit }, auth_profile_type) {
    commit("SET_AUTH_PROFILE_TYPE", auth_profile_type);
  },

  setOAuthFlow({ commit }, auth_profile_flow) {
    commit("SET_AUTH_PROFILE_FLOW", auth_profile_flow);
  },

  createAuthProfile({ commit }, authProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createAuthProfile(authProfile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateAuthProfile({ commit }, authProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const authProfileObj = { ...authProfile };
      updateAuthProfile(authProfileObj)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  deleteAuthProfile({ commit }, authProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteAuthProfile(authProfile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  undoDeleteAuthProfile({ commit }, authProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      authProfile.is_enabled = 1;
      updateAuthProfile(authProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getAuthProfileToken(context, id) {
    return new Promise((resolve, reject) => {
      getAuthProfileToken(id)
        .then(data => {
          resolve(data.data.token);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
