import _ from "lodash";

const __ = (key, replace) => {
  let translation = key;
  if (window.StudioLang && window.StudioLang[key]) {
    translation = window.StudioLang[key];
  }

  _.forEach(replace, (value, key) => {
    translation = translation.replaceAll(":" + key, value);
  });

  return _.unescape(translation);
};

export default __;
