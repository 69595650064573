<template>
  <div class="page-header">
    <header v-if="title.length" class="title">
      <!--      todo: change this back when folder icon design is available-->
      <img
        :src="require('@/assets/icons/' + titleIcon + '.svg')"
        v-if="titleIcon && titleIcon.length && titleIconExists"
        alt="icon"
        style="width: 24px; height: 24px;"
        onload="SVGInject(this)"
      />
      <i
        v-if="titleIcon && titleIcon.length && !titleIconExists"
        :class="titleIcon"
      ></i>
      {{ dynamicHeading }}
    </header>

    <div v-if="navItems.length && !loadingState" class="navItems">
      <nav
        class="navItem"
        dusk="nav-item"
        :class="getClasses(navItem)"
        v-for="navItem in navItems"
        :key="navItem.label"
        :style="activeTabStyles(navItem)"
        @click="
          navItem.readPermission
            ? !can(navItem.readPermission)
              ? null
              : navigateTo(navItem)
            : !navItem.active && navigateTo(navItem)
        "
        v-show="toggleVisibility(navItem)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          placement="bottom"
          :open-delay="500"
          :disabled="
            navItem.readPermission ? can(navItem.readPermission) : true
          "
        >
          <div slot="content" style="white-space: pre-wrap;">
            {{ __("You don't have permission") }}
          </div>
          <div style="display: flex; align-items: center">
            <img
              :src="getTabIcon(navItem)"
              v-if="navItem.icon"
              alt="icon"
              style="width: 24px; height: 24px;"
              onload="SVGInject(this)"
            />
            <div style="padding: 0 4px 0 5px; white-space: nowrap">
              {{ getDisplayTextForTabItem(navItem) }}
            </div>
          </div>
        </el-tooltip>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
      default: ""
    },
    navItems: {
      required: false,
      type: Array,
      default: () => []
    },
    titleIcon: {
      required: false,
      type: String,
      default: ""
    },
    contentId: {
      required: false,
      type: Number,
      default: -1000
    },
    loadingState: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    dynamicHeading: function() {
      if (this.contentId === -1000) {
        return this.title;
      } else {
        return this.contentId === -1
          ? __("Create :title", { title: this.title })
          : __("Update :title", { title: this.title });
      }
    },
    isActive() {
      return navItem => {
        // console.log(navItem);
        return navItem.active;
      };
    },
    getClasses() {
      return navItem => {
        let classes = this.isActive(navItem)
          ? "text-gray-dark "
          : "text-gray-light ";

        classes += navItem.preserve_icon ? "preserve-icon" : "";

        if (navItem.readPermission) {
          classes += this.can(navItem.readPermission) ? "" : "no-permission";
        }

        return classes;
      };
    },
    activeTabStyles() {
      return navItem => {
        if (navItem.active) {
          return {
            "--active-tab-color": navItem.color
              ? navItem.color
              : "var(--theme-color)"
          };
        }
        return navItem.active;
      };
    },
    checkPermission() {
      return permission => {
        if (!permission) {
          return true;
        } else {
          return this.can(permission);
        }
      };
    },

    /**
     * Toggle the navigation tab visibility based on:
     * { permission } -> whether the user has the permission
     * { ld_key } -> whether the user is allowed to view feature based on LaunchDarkly response
     */
    toggleVisibility() {
      return ({ permission, show }) => {
        let showFeature = true;
        if (typeof show === "boolean") {
          showFeature = show;
        }

        return showFeature && this.checkPermission(permission);
      };
    },

    titleIconExists() {
      try {
        require("@/assets/icons/" + this.titleIcon + ".svg");
        return true;
      } catch (e) {
        return false;
      }
    }
  },

  methods: {
    navigateTo(navItem) {
      if (!navItem.active) {
        this.$emit("navItemChanged", navItem);
      }
    },
    getTabIcon(navItem) {
      if (navItem.icon) {
        return require("@/assets/icons/" + navItem.icon);
      }
    },

    /**
     * Get the display text for a tab item. By default, it will use the displayText key. If it doesn't exist, it will use the label
     * @param { Object } navItem The navigation item to get the display text from
     */
    getDisplayTextForTabItem(navItem) {
      return navItem.displayText !== undefined
        ? navItem.displayText
        : navItem.label;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  /*width: 100%;*/
  /*align-items: baseline;*/
  /*display: flex;*/
  padding-right: 2.5rem;

  .navItem {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background-color: #f5f5f8;
      z-index: 2;
    }
  }

  .no-permission {
    opacity: 0.25;
    cursor: not-allowed !important;
  }

  .navItem.text-gray-dark {
    &:not(.preserve-icon) ::v-deep svg {
      fill: var(--active-tab-color);

      path {
        fill: var(--active-tab-color);
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background-color: var(--active-tab-color);
      z-index: 2;
    }
  }

  .navItems {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 15px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      height: 2px;
      background-color: #f5f5f8;
      z-index: 1;
      padding-bottom: 0;
    }
  }
}

.page-header .title {
  font-size: 2.3rem;
  font-weight: 300;
  color: #454545;
  margin-bottom: 36px;
}

.page-header .navItems {
  display: flex;
  margin-bottom: 10px;
}

.page-header .navItems:not(:first-child) .navItem {
  margin-right: 2rem;
  cursor: pointer;
  font-weight: 300;
}

.page-header .navItems > .navItem:hover {
  color: #454545;
}

.text-gray-dark {
  color: var(--active-tab-color);

  :hover {
    color: var(--active-tab-color);
  }
}

.text-gray-light {
  color: #696969;

  &:not(.preserve-icon) ::v-deep svg {
    fill: #696969;

    path {
      fill: #696969;
    }
  }
}

::v-deep .navItems::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::v-deep .navItems::-webkit-scrollbar-thumb {
  background-color: #a1a3a950;
  border-radius: 3px;
}
</style>
