import {
  createPaymentProfile,
  deletePaymentProfile,
  generateRequestBody,
  getPaymentProfiles,
  testPaymentProfile,
  updatePaymentProfile
} from "@/api/paymentprofiles";

const state = {
  paymentProfiles: [],
  paymentProfile_filter_key: "all",
  loading: false
};

const getters = {
  getPaymentProfile: state => payment_profile_id => {
    return state.paymentProfiles.find(
      paymentProfile => paymentProfile.payment_profile_id === payment_profile_id
    );
  }
};

const mutations = {
  SET_PAYMENT_PROFILES(state, paymentProfiles) {
    state.paymentProfiles = paymentProfiles;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getPaymentProfiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getPaymentProfiles(options)
        .then(({ data }) => {
          let paymentProfiles = data.data;
          commit("SET_PAYMENT_PROFILES", paymentProfiles);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createPaymentProfile({ commit }, paymentProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createPaymentProfile(paymentProfile)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updatePaymentProfile({ commit }, paymentProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const paymentProfileObj = { ...paymentProfile };
      updatePaymentProfile(paymentProfileObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deletePaymentProfile({ commit }, paymentProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deletePaymentProfile(paymentProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeletePaymentProfile({ commit }, paymentProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      paymentProfile.is_enabled = 1;
      updatePaymentProfile(paymentProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  testPaymentProfile({ commit }, paymentGatewayData) {
    commit("CHANGE_LOADING", false);
    console.log(paymentGatewayData);
    return new Promise((resolve, reject) => {
      testPaymentProfile(paymentGatewayData)
        .then(({ data }) => {
          console.log(data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  generateRequestBody(
    { commit },
    {
      paymentProfileId,
      paymentProcessType,
      requestParamVariables,
      requestParamInputs,
      customPayload
    }
  ) {
    commit("CHANGE_LOADING", false);
    return new Promise((resolve, reject) => {
      generateRequestBody(
        paymentProfileId,
        paymentProcessType,
        requestParamVariables,
        requestParamInputs,
        customPayload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
