import { login, logout, verifyOtp } from "@/api/auth";
import session from "@/utils/sessionManager";
import store from "@/store";

const actions = {
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      login(credentials).catch(() => reject());
    });
  },

  async logout() {
    session.clearAll();
    store.dispatch("app/resetAppState");
    await logout();
  },

  verifyOtp(context, credentials) {
    return new Promise((resolve, reject) => {
      verifyOtp(credentials)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }
};

export default {
  namespaced: true,
  actions
};
