import Vue from "vue";

export const vFocus = {
  // When the bound element is inserted into the DOM...
  inserted: function(el) {
    // Focus the element
    el.focus();
  }
};

// Register a global custom directive called `v-focus`
Vue.directive("focus", vFocus);

// Register a global custom directive called `v-permit`
export const vPermit = {
  bind(el, binding, vnode) {
    const regEx = binding.value.toString();
    if (!/^\/\[.*?]\/$/.test(regEx)) {
      console.warn(
        "regex provided to v-permit should be a character class like /[a-z]/ or /[^a-z]/"
      );
      return;
    }
    const isExcludingRegex = /^\/\[\^/.test(regEx);
    let newRegex = isExcludingRegex
      ? regEx.replace("/[^", "/[")
      : regEx.replace("/[", "/[^");
    newRegex = newRegex.replace(/\//g, "");
    let replaceRegex = new RegExp(newRegex, "gu");

    el.addEventListener("input", function() {
      const currentValue = vnode.componentInstance.value;
      const newVal = currentValue.replace(replaceRegex, "");

      if (newVal !== currentValue) {
        vnode.componentInstance.$emit("input", newVal);
      }
    });
  }
};

Vue.directive("permit", vPermit);
