import _ from "lodash";
import {
  CONNECTOR_CONDITION_TAG_PREFIX,
  DEFAULT_ON_NOT_DROP_RULE
} from "@/constants/nodes";

export const simpleHash = str => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const findConnectorConditionTag = BGNode => {
  let connectorCondition = "";
  let connectorConditionTag = _.find(_.get(BGNode, "tags", []), tag =>
    tag.startsWith(CONNECTOR_CONDITION_TAG_PREFIX)
  );

  if (connectorConditionTag) {
    // slice on 2, because CONNECTOR_CONDITION_TAG_PREFIX is 'connector_condition_',
    // with first 2 parts being connector and condition after splitting on '_'
    connectorCondition = _.join(connectorConditionTag.split("_").slice(2), "_");
  }

  return connectorCondition;
};

export const dropCriteria = rules => {
  const dropRules = JSON.parse(rules);
  let baseRule = ".node";
  if (_.isEmpty(rules)) {
    return baseRule + " .drop-box";
  } else {
    let ruleKeys = Object.keys(dropRules);

    //order matters
    if (ruleKeys.includes("onNot") && !_.isEmpty(dropRules.onNot)) {
      _.map(_.concat(dropRules.onNot, DEFAULT_ON_NOT_DROP_RULE), rule => {
        baseRule += ":not(." + rule + ")";
      });
    } else {
      _.map(DEFAULT_ON_NOT_DROP_RULE, rule => {
        baseRule += ":not(." + rule + ")";
      });
    }

    if (ruleKeys.includes("onlyOn")) {
      _.map(dropRules.onlyOn, rule => {
        baseRule += "." + rule;
      });
    }

    if (ruleKeys.includes("on") && !_.isEmpty(dropRules.on)) {
      let newRules = [];
      _.map(dropRules.on, rule => {
        newRules.push(baseRule + "." + rule);
      });
      baseRule = newRules.join(", ");
    }
  }
  let cssSelectors = _.map(baseRule.split(","), rule => rule + " .drop-box");
  baseRule = "";
  _.map(cssSelectors, selector => (baseRule += " " + selector + ","));
  baseRule = baseRule.trim();
  return baseRule.substring(0, baseRule.length - 1); // to strip the last ","
};

export const jsonPathValue = (path, variables) => {
  let regExForVariableId = new RegExp("{{(-?\\d+)}}", "g");
  let val = path && path.toString();
  if (!val) {
    return path;
  }

  let regExForVariableSuffix = new RegExp("\\|variable", "g");
  val = val.replace(regExForVariableSuffix, "");

  let matches = val.matchAll(regExForVariableId);
  let variableIds = _.map(Array.from(matches), match => match[1]);

  _.map(variableIds, variableId => {
    let variable =
      _.find(variables, { variable_id: +variableId }) ||
      _.find(variables, { variable_name: variableId });

    let re = new RegExp(`{{(${variableId})}}`, "g");
    val = val.replace(re, `{{${variable.variable_name}}}`);
  });

  return val;
};
