import request, { ac_headers } from "@/utils/studio7ApiService";

export function getSmsProviders(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/content/sms-providers?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createSmsProvider(data) {
  const smsProvider = { ...data, guard_name: "admin" };
  return request({
    url: "/content/sms-providers",
    method: "post",
    data: smsProvider,
    headers: ac_headers()
  });
}

export function updateSmsProvider(smsProvider) {
  return request({
    url: `/content/sms-providers/${smsProvider.sms_provider_id}`,
    method: "put",
    data: smsProvider,
    headers: ac_headers()
  });
}

export function deleteSmsProvider(smsProvider) {
  return request({
    url: `/content/sms-providers/${smsProvider.sms_provider_id}`,
    method: "delete",
    data: smsProvider,
    headers: ac_headers()
  });
}

// export function sendTestSms(sms_provider_id, sms) {
//   return request({
//     url: `/content/sms-providers/test/${sms_provider_id}`,
//     method: "post",
//     data: {
//       send_to: sms
//     },
//     headers: ac_headers()
//   });
// }
//
// export function sendTestSms() {
//   return request({
//     url: `node/send-sms-nodes/send-sms`,
//     method: "post",
//     data: {
//       node_id: 149,
//       recipient: 'tes@test.com',
//       subject: 'hello',
//       content: 'hello content'
//     },
//     headers: ac_headers()
//   });
// }
