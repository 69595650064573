import {
  createFormType,
  deleteFormType,
  getFormTypes,
  getFormTypesWithDatastoreColumns,
  updateFormType
} from "@/api/formtypes";
import _ from "lodash";

const state = {
  formtypes: [],
  loading: false
};

export const getters = {
  formtypes(state) {
    return _.cloneDeep(state.formtypes);
  }
};

const mutations = {
  SET_FORMTYPES(state, formtypes) {
    state.formtypes = formtypes;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getFormTypes({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getFormTypes(options)
        .then(({ data }) => {
          commit("SET_FORMTYPES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  resetFormTypes({ commit }) {
    commit("SET_FORMTYPES", []);
  },

  createFormType({ commit }, formtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createFormType(formtype)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateFormType({ commit }, formtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const formTypeObj = { ...formtype };
      updateFormType(formTypeObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteFormType({ commit }, formtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteFormType(formtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  undoDeleteFormType({ commit }, formtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      formtype.is_enabled = 1;
      updateFormType(formtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  safeDeleteFormType({ commit }, formtype) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      formtype.is_archived = 1;
      updateFormType(formtype)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getFormTypesWithDatastoreColumns({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getFormTypesWithDatastoreColumns(options)
        .then(({ data }) => {
          commit("SET_FORMTYPES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  refreshFormType({ commit, getters }, { content_id, refreshing }) {
    let formtypes = _.map(getters.formtypes, formtype => {
      if (formtype.form_type_id === content_id) {
        formtype.is_refreshing = refreshing;
      }
      return formtype;
    });
    commit("SET_FORMTYPES", formtypes);
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
