import _ from "lodash";

export const filterRowsIfEveryKeyValueIsAbsent = (
  collection,
  columns = "*"
) => {
  let collectionToFilterThrough = _.cloneDeep(collection);

  if (!_.isEmpty(collectionToFilterThrough)) {
    if (!Array.isArray(collectionToFilterThrough)) {
      try {
        collectionToFilterThrough = JSON.parse(collectionToFilterThrough);
      } catch (error) {
        console.log(error);
        return null;
      }
    }

    return _.filter(collectionToFilterThrough, item => {
      let keys = [];
      if (columns === "*") {
        keys = Object.keys(item);
        return !_.every(keys, key => _.isEmpty(item[key]));
      } else {
        keys = columns.split(",");
      }

      return !_.every(keys, key => {
        key = key.trim();
        if (typeof item[key] === "string" || item[key] instanceof String) {
          return _.isEmpty(item[key].trim());
        }
        return _.isEmpty(item[key]);
      });
    });
  }
  return collectionToFilterThrough;
};

export const filterRowsIfSomeKeyValueIsAbsent = (collection, columns = "*") => {
  let collectionToFilterThrough = _.cloneDeep(collection);

  if (!_.isEmpty(collectionToFilterThrough)) {
    if (!Array.isArray(collectionToFilterThrough)) {
      try {
        collectionToFilterThrough = JSON.parse(collectionToFilterThrough);
      } catch (error) {
        console.log(error);
        return null;
      }
    }

    return _.filter(collectionToFilterThrough, item => {
      let keys = [];
      if (columns === "*") {
        keys = Object.keys(item);
        return !_.some(keys, key => _.isEmpty(item[key]));
      } else {
        keys = columns.split(",");
      }

      return !_.some(keys, key => {
        key = key.trim();
        if (typeof item[key] === "string" || item[key] instanceof String) {
          return _.isEmpty(item[key].trim());
        }
        return _.isEmpty(item[key]);
      });
    });
  }
  return collectionToFilterThrough;
};

export const getSubKeyCollection = (collection, keys) => {
  return _.map(_.cloneDeep(collection), function(object) {
    return getSubKeyObject(object, keys);
  });
};

export const getSubKeyObject = (object, keys) => {
  return _.pick(_.cloneDeep(object), keys);
};

// rule parameter has to be an object like {key: "", value: ""},
// where key is the property of an object in the collection that should be
// returned as the value of 'key' property in the returned collection
// and value is the property of an object in the collection that should be
// returned as the value of 'value' property in the returned collection
export const generateKeyValueFrom = (collection, rule) => {
  return _.map(_.cloneDeep(collection), item => {
    return {
      key: item[rule.key],
      value: item[rule.value],
      type: _.get(item, _.get(rule, "type", "type"), "text")
    };
  });
};

export const includesAny = (collection1, collection2) => {
  return _.some(collection1, itemFromCollection1 =>
    _.some(
      collection2,
      itemFromCollection2 => itemFromCollection1 === itemFromCollection2
    )
  );
};

export const enclosePartInBrackets = part => {
  part = part.trim();
  part = part.replace(/'/g, "");
  if (part === "*" || part.includes(":") || part.includes(",")) {
    return "[" + part + "]";
  } else {
    return "['" + part + "']";
  }
};

export const extractPathParts = path => {
  path = path.replace(/\[(['"])?/g, ".");
  path = path.replace(/(['"])?]/g, "");
  path = path.replace(/^\./g, "");
  path = path.replace(/\.$/g, "");
  path = path.replace(/^\(/g, "");
  path = path.replace(/\)$/g, "");
  return path.split(".");
};

export const convertDotNotationToBracketNotation = path => {
  if (path) {
    path = path.replace(/@\.length/g, "xpath-length-expr");
    const pathParts = extractPathParts(path);
    let bracketNotations = _(_.tail(pathParts))
      .map(enclosePartInBrackets)
      .value();

    path = pathParts[0] + bracketNotations.join("");
    path = path.replace(/xpath-length-expr/g, "@.length");
    // return path.trim();
  }
  return path;
};

export const sortObjectEntries = (obj, n) => {
  return Object.entries(obj)
    .sort((a, b) => b[1] - a[1])
    .map(el => el[0])
    .slice(0, n);
};

export const stringifyJsonArray = array => {
  return Array.isArray(array) ? JSON.stringify(array) : !array ? "[]" : array;
};

/**
 * find  keyword condition
 * @param conditions
 * @param keyword
 * @returns {T}
 */
export function findKeywordCondition(conditions, keyword) {
  const condition = _.cloneDeep(_.find(conditions, { keyword }));
  return _.isEmpty(condition) ? {} : condition;
}
