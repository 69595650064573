import {
  getDataStores,
  createDataStore,
  updateDataStore,
  deleteDataStore
} from "@/api/dataStores";
import _ from "lodash";
import { SYSTEM_LOG_DS, CALL_MONITORING_DS } from "@/constants/datastore";

const state = {
  dataStores: [],
  systemLogDataStore: [],
  loading: false
};

export const getters = {
  dataStoreNames(state) {
    if (state.dataStores) {
      return _.map(_.orderBy(state.dataStores, ["updated_at"]), "display_name");
    } else {
      return [];
    }
  },

  getDataStores(state) {
    if (state.dataStores) {
      return state.dataStores;
    } else {
      return [];
    }
  },

  getDataStoresLoading(state) {
    return state.loading;
  },

  dataStores(state) {
    return _.cloneDeep(state.dataStores);
  },

  dataStoreCollectionStructure: state => (data_store_id, is_date = false) => {
    if (state.dataStores) {
      const datastore = _.find(state.dataStores, { data_store_id });
      if (datastore && datastore.collection_structure) {
        return is_date
          ? datastore.collection_structure.filter(col => col.type == "date")
          : datastore.collection_structure;
      }

      return [];
    } else {
      return [];
    }
  },

  // filter out the datastore for live call monitoring
  datastoresExceptCallMonitorDS(state) {
    if (state.dataStores) {
      return state.dataStores.filter(
        ds => !ds.display_name.includes(CALL_MONITORING_DS)
      );
    } else {
      return [];
    }
  },

  //temporary function to hide sys logs datastore attributes such as no match, no input, node id and node name
  dataStoreCollectionStructureRemoveSystemAttributes: state => data_store_id => {
    let filteredColumns = [];
    if (state.dataStores) {
      const datastore = _.find(state.dataStores, { data_store_id });
      if (datastore && datastore.collection_structure) {
        filteredColumns = _.cloneDeep(datastore.collection_structure);
        if (
          datastore.is_log === 1 &&
          !datastore.display_name.includes(CALL_MONITORING_DS)
        ) {
          const notAllowedAttributes = [
            "details.no_match_attempts",
            "details.no_input_attempts",
            "details.node_id",
            "details.node_name"
          ];
          filteredColumns = filteredColumns.filter(structure => {
            return !notAllowedAttributes.includes(structure.col_name);
          });
        }
      }
    }
    return _.filter(
      filteredColumns,
      column => !column.secure && column.type !== "audio"
    );
  }
};

const mutations = {
  SET_DATASTORES(state, dataStores) {
    state.dataStores = dataStores;
  },
  SET_SYSTEM_LOG_DATASTORE(state, dataStores) {
    state.systemLogDataStore = dataStores;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  resetDataStores({ commit }) {
    commit("SET_DATASTORES", []);
  },

  getDataStores({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getDataStores(options)
        .then(({ data }) => {
          commit("SET_DATASTORES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getDataStoresForList({ dispatch }, options) {
    options = { ...options, is_log: 0 };
    return new Promise((resolve, reject) => {
      dispatch("getDataStores", options)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getSysLogDataStore({ commit }, options) {
    options = { ...options, is_log: 1 };
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getDataStores(options)
        .then(({ data }) => {
          commit(
            "SET_SYSTEM_LOG_DATASTORE",
            data.data.filter(ds => ds.display_name.includes(SYSTEM_LOG_DS))
          );
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  createDataStore({ commit }, dataStore) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createDataStore(dataStore)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateDataStore({ commit }, dataStore) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const dataStoreObj = { ...dataStore };
      updateDataStore(dataStoreObj)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  safeDeleteDataStore({ commit }, dataStore) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      dataStore.is_archived = 1;
      const dataStoreObj = { ...dataStore };
      updateDataStore(dataStoreObj)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  deleteDataStore({ commit }, dataStore) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteDataStore(dataStore)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },
  undoDeleteDataStore({ commit }, dataStore) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      dataStore.is_enabled = 1;
      updateDataStore(dataStore)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  refreshDataStore({ commit, getters }, { content_id, refreshing }) {
    let dataStores = _.map(getters.dataStores, dataStore => {
      if (dataStore.data_store_id === content_id) {
        dataStore.is_refreshing = refreshing;
      }
      return dataStore;
    });
    commit("SET_DATASTORES", dataStores);
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
