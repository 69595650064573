import {
  getIntent,
  createIntent,
  updateIntent,
  deleteIntent
} from "@/api/intent";
import _ from "lodash";

const state = {
  intents: [],
  loading: false
};

const getters = {
  intents(state) {
    return _.cloneDeep(state.intents);
  },

  getIntentOfId(state) {
    return intent_id => {
      return _.cloneDeep(
        _.find(state.intents, {
          intent_id: intent_id
        })
      );
    };
  }
};

const mutations = {
  SET_INTENTS(state, intents) {
    state.intents = intents;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getIntents({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getIntent(options)
        .then(({ data }) => {
          commit("SET_INTENTS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  resetIntents({ commit }) {
    commit("SET_INTENTS", []);
  },

  createIntent({ commit }, intent) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createIntent(intent)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateIntent({ commit }, intent) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const IntentObj = { ...intent };
      updateIntent(IntentObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteIntent({ commit }, intent) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteIntent(intent)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  undoDeleteIntent({ commit }, intent) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      intent.is_enabled = 1;
      updateIntent(intent)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  safeDeleteIntent({ commit }, intent) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      intent.is_archived = 1;
      updateIntent(intent)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  refreshIntent({ commit, getters }, { content_id, refreshing }) {
    let intents = _.map(getters.intents, intent => {
      if (intent.intent_id === content_id) {
        intent.is_refreshing = refreshing;
      }
      return intent;
    });
    commit("SET_INTENTS", intents);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
