import Vue from "vue";
import { asyncRoutes, constantRoutes } from "@/router";
import routerUtils from "@/utils/router";
import store from "@/store";
import _ from "lodash";

const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};

export const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      let accessedRoutes;
      if (
        Vue.auth.user().roles.includes("system") ||
        !_.isEmpty(
          Vue.auth
            .user()
            .roles.find(role => role.toLowerCase().includes("system"))
        )
      ) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = routerUtils.filterAsyncRoutes(
          asyncRoutes,
          [store.getters.userType],
          Vue.auth.user().roles
        );
      }

      commit("SET_ROUTES", accessedRoutes);

      resolve(accessedRoutes);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
