<script>
import { EventBus } from "@/EventBus";
import { i18n } from "@/i18n";
import _ from "lodash";
import TranslationLanguages from "@/translation/lang";

const TRANSLATE_EVENT_NAME = "userLanguageChanged";
const DEFAULT_SUPPORTED_LOCALES = "en,zh";
const EN_LOCALE = "en";

export default {
  name: "TranslationMixin",
  TRANSLATE_EVENT_NAME,
  methods: {
    /**
     *  Getting the default locale from the environment variables.
     */
    getAppDefaultLocale() {
      return process.env.VUE_APP_I18N_LOCALE || EN_LOCALE;
    },

    /**
     *  Getting the fallback locale from the environment variables.
     */
    getAppFallbackLocale() {
      return process.env.VUE_APP_I18N_FALLBACK_LOCALE || EN_LOCALE;
    },

    /**
     *  Getting the app supported locales from the environment variables.
     */
    getAppSupportedLocales() {
      const supportedLocales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALES || DEFAULT_SUPPORTED_LOCALES;
      return supportedLocales.split(",");
    },

    /**
     *  Getting the current locale from the i18n object.
     */
    getAppCurrentI18nLocale() {
      return i18n.locale;
    },

    /**
     *  Setting the locale in the i18n object.
     * @param locale
     */
    setAppCurrentI18nLocale(locale) {
      i18n.locale = locale;
    },

    /**
     *   Checking if the locale is supported by the application.
     * @param locale
     */
    isLocaleSupported(locale) {
      return _.includes(this.getAppSupportedLocales(), locale);
    },

    /**
     *  Setting the locale in the services and in the HTML.
     * @param locale
     */
    setI18nLocaleInServices(locale) {
      this.setAppCurrentI18nLocale(locale);
      document.querySelector("html").setAttribute("lang", locale);
      return locale;
    },

    /**
     * Loading the locale files for the element-ui and other third party libs.
     * @param locale
     */
    changeAppLocale(locale) {
      if (!this.isLocaleSupported(locale))
        throw new Error(`Locale: ${locale} not supported`);

      if (this.getAppCurrentI18nLocale() === locale)
        return Promise.resolve(locale);

      return Promise.all([
        this.loadElementLocaleFile(locale),
        this.loadVuexLocaleFile(locale)
      ]).then(results => {
        let allMessages = {};
        _.forEach(results, function(result) {
          if (result) {
            allMessages = { ...allMessages, ...(result.default || result) };
          }
        });
        i18n.setLocaleMessage(locale, allMessages);
        return this.setI18nLocaleInServices(locale);
      });
    },

    /**
     *  Getting the locale file suffix for the supplied locale.
     * @param locale
     * @param localeSuffixPath
     * @param defaultSuffixCode
     */
    getLocaleFileSuffix(locale, localeSuffixPath, defaultSuffixCode = "en") {
      const langObj = _.get(TranslationLanguages, locale);
      return _.get(langObj, localeSuffixPath, defaultSuffixCode);
    },

    /**
     * Loading the element-ui locale file for the current locale.
     * @param locale
     */
    loadElementLocaleFile(locale) {
      const localePathSuffix = this.getLocaleFileSuffix(
        locale,
        "elementUILocaleCode"
      );
      return import(`element-ui/lib/locale/lang/${localePathSuffix}`);
    },

    /**
     *  Loading the vuex locale file for the current locale.
     * @param locale
     */
    loadVuexLocaleFile(locale) {
      const localePathSuffix = this.getLocaleFileSuffix(
        locale,
        "vxeTableLocaleCode",
        "en-US"
      );
      return import(`vxe-table/lib/locale/lang/${localePathSuffix}.js`);
    },

    /**
     * Load the user's translation language preference
     * @param {Object} user The user object
     */
    translateForUser(user) {
      const locale =
        user && user.data && user.data.language ? user.data.language : "en";

      EventBus.$emit(TRANSLATE_EVENT_NAME, locale);
    },

    /**
     * Get the current application locale
     * @returns {String} The current application locale code
     */
    getAppLang() {
      const appLang = window.localStorage.getItem("appLang");
      return appLang === null ? "en" : appLang;
    }
  }
};
</script>
