// Uncomment language to add language to language selector dropdown
// Replace key with the translation file name from backend (if needed)
/**
 * For newly supported languages
 *  add elementUILocaleCode param, for element ui supported locales value refer https://element.eleme.io/#/en-US/component/i18n#import-via-cdn
 *  add vxeTableLocaleCode param, for vxe table supported locales value  refer https://vxetable.cn/#/table/start/i18n
 */
const TranslationLanguages = {
  // af: { name: "Afrikaans", transliteration: "Afrikaans" },
  // sq: { name: "Albanian", transliteration: "Shqip" },
  // am: { name: "Amharic", transliteration: "" },
  // ar: { name: "Arabic", transliteration: "اللغة العربية" },
  // hy: { name: "Armenian", transliteration: "Հայերեն" },
  // az: { name: "Azerbaijani", transliteration: "Azərbaycanca" },
  // eu: { name: "Basque", transliteration: "Euskara" },
  // be: { name: "Belarusian", transliteration: "Беларуская" },
  // bn: { name: "Bengali", transliteration: "" },
  // bs: { name: "Bosnian", transliteration: "Bosanski" },
  // bg: { name: "Bulgarian", transliteration: "Български" },
  // ca: { name: "Catalan", transliteration: "Català" },
  // ceb: { name: "Cebuano", transliteration: "" },
  // ny: { name: "Chichewa", transliteration: "" },
  // zh: {
  //   name: "Chinese (Simplified)",
  //   transliteration: "简体中文（中国大陆）",
  //   elementUILocaleCode: "zh-CN",
  //   vxeTableLocaleCode: "zh-CN"
  // },
  // "zh-TW": {
  //   name: "Chinese (Traditional)",
  //   transliteration: "繁體中文（台灣）"
  // },
  // co: { name: "Corsican", transliteration: "" },
  // hr: { name: "Croatian", transliteration: "Hrvatski" },
  // cs: { name: "Czech", transliteration: "Čeština" },
  // da: { name: "Danish", transliteration: "Dansk" },
  // nl: { name: "Dutch", transliteration: "Nederlands" },
  en: {
    name: "English",
    transliteration: "English",
    elementUILocaleCode: "en",
    vxeTableLocaleCode: "en-US"
  }
  // eo: { name: "Esperanto", transliteration: "Esperanto" },
  // et: { name: "Estonian", transliteration: "Eesti" },
  // tl: { name: "Filipino", transliteration: "Filipino" },
  // fi: { name: "Finnish", transliteration: "Suomi" },
  // fr: { name: "French", transliteration: "Français" },
  // fy: { name: "Frisian", transliteration: "Frysk" },
  // gl: { name: "Galician", transliteration: "Galego" },
  // ka: { name: "Georgian", transliteration: "ქართული" },
  // de: { name: "German", transliteration: "Deutsch" },
  // el: { name: "Greek", transliteration: "Ελληνικά" },
  // gu: { name: "Gujarati", transliteration: "" },
  // ht: { name: "Haitian Creole", transliteration: "" },
  // ha: { name: "Hausa", transliteration: "" },
  // haw: { name: "Hawaiian", transliteration: "ʻŌlelo Hawaiʻi" },
  // iw: { name: "Hebrew", transliteration: "" },
  // he: { name: "Hebrew", transliteration: "עברית" },
  // hi: { name: "Hindi", transliteration: "हिन्दी" },
  // hmn: { name: "Hmong", transliteration: "" },
  // hu: { name: "Hungarian", transliteration: "Magyar" },
  // is: { name: "Icelandic", transliteration: "Íslenska" },
  // ig: { name: "Igbo", transliteration: "Igbo" },
  // id: { name: "Indonesian", transliteration: "Bahasa Indonesia" },
  // ga: { name: "Irish", transliteration: "Gaeilge" },
  // it: { name: "Italian", transliteration: "Italiano" },
  // ja: { name: "Japanese", transliteration: "日本語" },
  // jw: { name: "Javanese", transliteration: "Basa Jawa" },
  // kn: { name: "Kannada", transliteration: "ಕನ್ನಡ" },
  // kk: { name: "Kazakh", transliteration: "Қазақша" },
  // km: { name: "Khmer", transliteration: "" },
  // rw: { name: "Kinyarwanda", transliteration: "" },
  // ko: { name: "Korean", transliteration: "한국어" },
  // ku: { name: "Kurdish (Kurmanji)", transliteration: "" },
  // ckb: { name: "Kurdish (Sorani)", transliteration: "" },
  // ky: { name: "Kyrgyz", transliteration: "" },
  // lo: { name: "Lao", transliteration: "" },
  // la: { name: "Latin", transliteration: "Latina" },
  // lv: { name: "Latvian", transliteration: "Latviešu" },
  // lt: { name: "Lithuanian", transliteration: "Lietuvių" },
  // lb: { name: "Luxembourgish", transliteration: "Lëtzebuergesch" },
  // mk: { name: "Macedonian", transliteration: "Македонски" },
  // mai: { name: "Maithili", transliteration: "" },
  // mg: { name: "Malagasy", transliteration: "" },
  // ms: { name: "Malay", transliteration: "Bahasa Melayu" },
  // ml: { name: "Malayalam", transliteration: "" },
  // mt: { name: "Maltese", transliteration: "Malti" },
  // mi: { name: "Maori", transliteration: "Te Reo Māori" },
  // mr: { name: "Marathi", transliteration: "" },
  // mn: { name: "Mongolian", transliteration: "Монгол" },
  // my: { name: "Myanmar (Burmese)", transliteration: "" },
  // ne: { name: "Nepali", transliteration: "" },
  // no: { name: "Norwegian", transliteration: "Norsk nynorsk" },
  // or: { name: "Odia (Oriya)", transliteration: "" },
  // ps: { name: "Pashto", transliteration: "" },
  // fa: { name: "Persian", transliteration: "فارسی" },
  // pl: { name: "Polish", transliteration: "Polski" },
  // pt: { name: "Portuguese", transliteration: "Português" },
  // pa: { name: "Punjabi", transliteration: "" },
  // ro: { name: "Romanian", transliteration: "Română" },
  // ru: { name: "Russian", transliteration: "Русский" },
  // sm: { name: "Samoan", transliteration: "" },
  // gd: { name: "Scots Gaelic", transliteration: "" },
  // sr: { name: "Serbian", transliteration: "Српски" },
  // st: { name: "Sesotho", transliteration: "" },
  // sn: { name: "Shona", transliteration: "" },
  // sd: { name: "Sindhi", transliteration: "" },
  // si: { name: "Sinhala", transliteration: "" },
  // sk: { name: "Slovak", transliteration: "Slovenčina" },
  // sl: { name: "Slovenian", transliteration: "Slovenščina" },
  // so: { name: "Somali", transliteration: "Af-Soomaali" },
  // es: { name: "Spanish", transliteration: "Español" },
  // su: { name: "Sundanese", transliteration: "" },
  // sw: { name: "Swahili", transliteration: "" },
  // sv: { name: "Swedish", transliteration: "Svenska" },
  // tg: { name: "Tajik", transliteration: "" },
  // ta: { name: "Tamil", transliteration: "தமிழ்" },
  // tt: { name: "Tatar", transliteration: "Татарча" },
  // te: { name: "Telugu", transliteration: "" },
  // th: { name: "Thai", transliteration: "ไทย" },
  // tr: { name: "Turkish", transliteration: "Türkçe" },
  // tk: { name: "Turkmen", transliteration: "" },
  // uk: { name: "Ukrainian", transliteration: "Українська" },
  // ur: { name: "Urdu", transliteration: "" },
  // ug: { name: "Uyghur", transliteration: "" },
  // uz: { name: "Uzbek", transliteration: "" },
  // vi: { name: "Vietnamese", transliteration: "Tiếng Việt" },
  // cy: { name: "Welsh", transliteration: "Cymraeg" },
  // xh: { name: "Xhosa", transliteration: "" },
  // yi: { name: "Yiddish", transliteration: "ייִדיש" },
  // yo: { name: "Yoruba", transliteration: "Yorùbá" },
  // zu: { name: "Zulu", transliteration: "" }
};

export default TranslationLanguages;
