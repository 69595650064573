import {
  getUserApiKeys,
  deleteUserApiKey,
  createUserApiKey,
  updateUserApiKey
} from "@/api/apikeys";
import _ from "lodash";
// import Vue from "vue";

import {
  cancelPendingRequests,
  generateCancelToken,
  isErrorDueToCancelledToken
} from "@/utils/studio7ApiService";

const initialState = {
  api_keys: [],
  systemAccess: false,
  isLoading: false,
  cancelTokens: []
};

const state = _.cloneDeep(initialState);

const mutations = {
  SET_USER_API_KEY(state, api_keys) {
    state.api_keys = api_keys;
  },

  CREATE_USER_API_KEY(state, api_key) {
    state.api_keys.push(api_key);
  },

  UPDATE_USER_API_KEY(state, { index, api_key }) {
    state.api_keys.splice(index, 1, api_key);
  },

  DELETE_USER_API_KEY(state, api_key) {
    state.api_keys = state.api_keys.filter(
      oldApiKey => oldApiKey.user_api_key_id !== api_key.user_api_key_id
    );
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_SYSTEM_ACCESS(state, systemAccess) {
    state.systemAccess = systemAccess;
  },

  // eslint-disable-next-line no-unused-vars
  RESET_INITIAL_STATE(state) {
    state = _.cloneDeep(initialState);
  },

  ADD_TO_CANCEL_TOKENS(state, token) {
    state.cancelTokens.push(token);
  },

  SET_CANCEL_TOKENS(state, tokens) {
    state.cancelTokens = tokens;
  }
};

const actions = {
  resetInitialState({ commit }) {
    commit("RESET_INITIAL_STATE");
  },

  getUserApiKeys({ commit, state, rootState }, options = {}) {
    cancelPendingRequests(state.cancelTokens);
    // get a new cancel token
    let cancelToken = generateCancelToken();
    commit("ADD_TO_CANCEL_TOKENS", cancelToken);

    let mergedOptions = options;
    if (state.systemAccess) {
      mergedOptions = { ...options, all_users: 1 };
    }

    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      let app = rootState.app;
      let scopeToUse = app.userType;
      if (
        state.systemAccess &&
        app.selectedAccountId &&
        app.selectedAccountId !== "all"
      ) {
        scopeToUse = "ac";
      }
      getUserApiKeys(scopeToUse, mergedOptions, cancelToken.token)
        .then(({ data }) => {
          commit("SET_USER_API_KEY", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          cancelPendingRequests(state.cancelTokens);
          commit("SET_CANCEL_TOKENS", []);
          resolve(data);
        })
        .catch(err => {
          if (!isErrorDueToCancelledToken(err)) {
            commit("UPDATE_LOADING_STATUS", false);
          }
          reject(err);
        });
    });
  },

  setSystemAccess({ commit }, access) {
    commit("SET_SYSTEM_ACCESS", access);
  },

  createUserApiKey({ commit, rootState }, api_key) {
    return new Promise((resolve, reject) => {
      let app = rootState.app;
      commit("UPDATE_LOADING_STATUS", true);
      createUserApiKey(api_key, app.userType)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updateUserApiKey({ commit, rootState }, api_key) {
    return new Promise((resolve, reject) => {
      let app = rootState.app;
      commit("UPDATE_LOADING_STATUS", true);
      updateUserApiKey(api_key, app.userType)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deleteUserApiKey({ commit, rootState }, api_key) {
    return new Promise((resolve, reject) => {
      let app = rootState.app;
      commit("UPDATE_LOADING_STATUS", true);
      deleteUserApiKey(api_key, app.userType)
        .then(data => {
          commit("DELETE_USER_API_KEY", api_key);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  // TODO: Later
  undoDeleteUserApiKey({ commit, rootState }, api_key) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      api_key.is_enabled = 1;
      updateUserApiKey(api_key, rootState.app.userApplicationViewScope)
        .then(res => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
