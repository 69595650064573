import request, { ac_headers } from "@/utils/studio7ApiService";

export function getEmailProfiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/content/email-profiles?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createEmailProfile(data) {
  const emailProfile = { ...data, guard_name: "admin" };
  return request({
    url: "/content/email-profiles",
    method: "post",
    data: emailProfile,
    headers: ac_headers()
  });
}

export function updateEmailProfile(emailProfile) {
  return request({
    url: `/content/email-profiles/${emailProfile.profile_id}`,
    method: "put",
    data: emailProfile,
    headers: ac_headers()
  });
}

export function deleteEmailProfile(emailProfile) {
  return request({
    url: `/content/email-profiles/${emailProfile.profile_id}`,
    method: "delete",
    data: emailProfile,
    headers: ac_headers()
  });
}

export function sendTestEmail(profile_id, email) {
  return request({
    url: `/content/email-profiles/test/${profile_id}`,
    method: "post",
    data: {
      send_to: email
    },
    headers: ac_headers()
  });
}
