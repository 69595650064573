var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"search-wrapper"},[_c('el-input',{attrs:{"size":"small","placeholder":_vm.__('Search Folders')},on:{"input":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.foldersLoading),expression:"foldersLoading"}]},[_c('el-menu',{staticClass:"folder-names",attrs:{"default-active":_vm.selectedFolderId},on:{"select":_vm.selectFolder}},[_c('el-menu-item',{staticStyle:{"padding":"0 10px"},attrs:{"index":"0"}},[_c('drop',{staticClass:"folder-name-container",on:{"drop":function($event){return _vm.onDragToMove($event, 0)}}},[_c('i',{class:[
              _vm.selectedFolderId == 0
                ? 'el-icon-folder-opened'
                : 'el-icon-folder'
            ]}),_c('span',{staticClass:"folder-name"},[_vm._v("Main")])])],1),_vm._l((_vm.folders),function(folder){return _c('el-menu-item',{key:folder.folder_id,staticClass:"folder-name display-on-hover-parent",staticStyle:{"padding":"0 10px 0 25px","height":"40px","line-height":"40px"},attrs:{"index":folder.folder_id.toString()}},[_c('drop',{staticClass:"folder-name-container copy",on:{"drop":function($event){return _vm.onDragToMove($event, folder.folder_id)}}},[_c('i',{class:[
              _vm.selectedFolderId == folder.folder_id
                ? 'el-icon-folder-opened'
                : 'el-icon-folder'
            ]}),_c('span',{staticClass:"folder-name"},[_vm._v(_vm._s(folder.folder_name))]),_c('div',{staticClass:"display-on-hover"},[_c('span',{staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.editFolderName(folder.folder_id, folder.folder_name)}}},[_c('img',{attrs:{"src":require('@/assets/icons/icon-edit.svg'),"alt":""}})]),_c('span',{on:{"click":function($event){return _vm.deleteSelectedFolder(folder.folder_id)}}},[_c('img',{attrs:{"src":require('@/assets/icons/icon-delete.svg'),"alt":""}})])])])],1)}),_c('el-menu-item',{staticStyle:{"padding":"0 10px"},attrs:{"index":"-1"}},[_c('drop',{on:{"drop":function($event){return _vm.onDragToMove($event, -1)}}},[_c('i',{class:[
              _vm.selectedFolderId == '-1'
                ? 'el-icon-folder-opened'
                : 'el-icon-folder'
            ]}),_c('span',[_vm._v(_vm._s(_vm.__("Archived")))])])],1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }