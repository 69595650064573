import request from "@/utils/studio7ApiService";

export function getSystemSettings(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/system/system-settings?` + params.toString(),
    method: "get"
  });
}

export function updateSystemSettings(settings) {
  return request({
    url: `/system/system-settings/${settings.setting_id}`,
    method: "put",
    data: settings
  });
}
