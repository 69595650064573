import { createRole, deleteRole, getRoles, updateRole } from "@/api/role";

const state = {
  roles: [],
  isLoading: false
};

const mutations = {
  SET_ROLE(state, roles) {
    state.roles = roles;
  },

  CREATE_ROLE(state, role) {
    state.roles.push(role);
  },

  UPDATE_ROLE(state, { index, role }) {
    state.roles.splice(index, 1, role);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getRoles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getRoles(options)
        .then(({ data }) => {
          /* if (options.hide_system) {
            data.data = _.filter(data.data, role => role.name !== "system");
          }*/
          commit("SET_ROLE", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  setEmptyRole({ commit }) {
    commit("SET_ROLE", []);
  },

  createRole({ commit }, role) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createRole(role)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updateRole({ commit }, role) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      updateRole(role)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deleteRole({ commit }, role) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteRole(role)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  undoDeleteContent({ commit }, role) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      role.is_enabled = 1;
      updateRole(role)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
