import {
  getReports,
  createReport,
  updateReport,
  deleteReport,
  getTableColumns,
  generateChartData,
  createReportInstance,
  getReportInstancesForAccount,
  reRunReportInstance,
  duplicateReportTemplate
} from "@/api/reports";
import _ from "lodash";

const state = {
  reports: [],
  reportInstances: [],
  tableColumns: [],
  chartData: {},
  loading: false,
  receivedUpdateEvent: false,
  receivedUpdateEventFor: {}
};

export const getters = {
  reportNames(state) {
    if (state.reports) {
      return _.map(
        _.orderBy(state.reports, ["updated_at"], ["desc"]),
        "report_name"
      );
    } else {
      return [];
    }
  },
  getReports(state) {
    if (state.reports) {
      return state.reports;
    } else {
      return [];
    }
  }
};

const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },

  SET_REPORT_INSTANCES(state, reportInstances) {
    state.reportInstances = reportInstances;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  CREATE_REPORT(state, report) {
    state.reports.push(report);
  },

  UPDATE_REPORT(state, { index, report }) {
    if (index > -1) {
      state.reports.splice(index, 1, report);
    }
  },

  DELETE_REPORT(state, report) {
    state.reports = state.reports.filter(
      oldReport => oldReport.report_id !== report.report_id
    );
  },

  SET_TABLE_COLUMNS(state, tableColumns) {
    state.tableColumns = tableColumns;
  },

  SET_CHART_DATA(state, chartData) {
    state.chartData = chartData;
  },

  UPDATE_EVENT_RECEIVED(state, value) {
    state.receivedUpdateEvent = value;
  },

  UPDATE_REPORT_INSTANCE(state, reportInstance) {
    state.receivedUpdateEventFor = reportInstance;
    const reportInstanceIndex = _.findIndex(
      state.reportInstances,
      currentReportInstance => {
        return (
          reportInstance.report_instance_id ===
          currentReportInstance.report_instance_id
        );
      }
    );

    if (reportInstanceIndex > -1) {
      state.reportInstances.splice(reportInstanceIndex, 1, reportInstance);
    }
  }
};

const actions = {
  getReports({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getReports(options)
        .then(({ data }) => {
          commit("SET_REPORTS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getReportInstances({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getReportInstancesForAccount(options)
        .then(({ data }) => {
          commit("SET_REPORT_INSTANCES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  createReport({ commit }, report) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createReport(report)
        .then(({ data }) => {
          commit("CREATE_REPORT", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  duplicateReportTemplate({ commit }, report) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      duplicateReportTemplate(report.report_id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateReport({ commit }, report) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const reportObj = { ...report };
      updateReport(reportObj)
        .then(({ data }) => {
          const index = _.findIndex(state.reports, currentReport => {
            return report.report_id === currentReport.report_id;
          });
          commit("UPDATE_REPORT", { index, report: data.data });
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteReport({ commit }, report) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteReport(report)
        .then(data => {
          commit("DELETE_REPORT", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  undoDeleteReport({ commit }, report) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      report.is_enabled = 1;
      updateReport(report)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getTableColumns({ commit }, tableName) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getTableColumns({
        table_name: tableName
      })
        .then(({ data }) => {
          commit("SET_TABLE_COLUMNS", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  generateChartData({ commit }, chartOptions) {
    commit("CHANGE_LOADING", true);
    return new Promise((resolve, reject) => {
      generateChartData(chartOptions)
        .then(({ data }) => {
          commit("SET_CHART_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  createReportInstance({ commit }, instance) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createReportInstance(instance)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  reRunReportInstance({ commit }, instance) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      reRunReportInstance(instance)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateReportInstance({ commit, rootState, dispatch }, reportInstance) {
    if (rootState.app.selectedAccountId === reportInstance.report.ac_id) {
      dispatch("updateReportInstanceUpdateEventStatus", true);
      commit("UPDATE_REPORT_INSTANCE", reportInstance);
    }
  },

  updateReportInstanceUpdateEventStatus({ commit }, value) {
    commit("UPDATE_EVENT_RECEIVED", value);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
