import {
  getBillingInvoices,
  createBillingInvoice,
  updateBillingInvoice,
  deleteBillingInvoice,
  generateBillingInvoice
} from "@/api/billinginvoices";

const state = {
  billingInvoices: [],
  loading: false
};

export const getters = {};
const mutations = {
  SET_BILLING_INVOICES(state, billingInvoices) {
    state.billingInvoices = billingInvoices;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getBillingInvoices({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getBillingInvoices(options)
        .then(({ data }) => {
          commit("SET_BILLING_INVOICES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  createBillingInvoice({ commit }, billingInvoice) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createBillingInvoice(billingInvoice)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  updateBillingInvoice({ commit }, billingInvoice) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const billingInvoiceObj = { ...billingInvoice };
      updateBillingInvoice(billingInvoiceObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteBillingInvoice({ commit }, billingInvoice) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteBillingInvoice(billingInvoice)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  generateBillingInvoice({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      generateBillingInvoice(params)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
