import {
  createXsiProfile,
  deleteXsiProfile,
  getXsiProfiles,
  updateXsiProfile
} from "@/api/xsiprofile";
//import _ from "lodash";

const state = {
  xsi_profiles: [],
  isLoading: false
};

const mutations = {
  SET_XSI_PROFILE(state, xsi_profiles) {
    state.xsi_profiles = xsi_profiles;
  },

  CREATE_XSI_PROFILE(state, xsi_profile) {
    state.xsi_profiles.push(xsi_profile);
  },

  UPDATE_XSI_PROFILE(state, { index, xsi_profile }) {
    state.xsi_profiles.splice(index, 1, xsi_profile);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getXsiProfiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getXsiProfiles(options)
        .then(({ data }) => {
          commit("SET_XSI_PROFILE", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  createXsiProfile({ commit }, xsi_profile) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createXsiProfile(xsi_profile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updateXsiProfile({ commit }, xsi_profile) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      updateXsiProfile(xsi_profile)
        .then(({ data }) => {
          /*
          var index = _.findIndex(state.xsi_profiles, oldProfile => {
            return oldProfile.xsi_profile_id === xsi_profile.xsi_profile_id;
          });
          commit("UPDATE_XSI_PROFILE", { index, xsi_profile: data.data });*/
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deleteXsiProfile({ commit }, xsi_profile) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteXsiProfile(xsi_profile)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  undoDeleteContent({ commit }, xsi_profile) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      xsi_profile.is_enabled = 1;
      updateXsiProfile(xsi_profile)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
