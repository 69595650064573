import Layout from "@/layout";
import __ from "@/translation";

const discoverModuleRouter = {
  path: "/discover",
  component: Layout,
  redirect: "/",
  meta: {
    auth: true,
    singleChild: false,
    icon: "icon-discover.svg",
    get title() {
      return __("Discover");
    },
    index: "Discover"
  },
  children: [
    {
      path: "task-library",
      component: () => import("@/views/discover/task-library/index.vue"),
      name: "task-library",
      meta: {
        get title() {
          return __("Task Library");
        },
        icon: "icon-task-library.svg",
        index: "discover-1",
        permissions: ["discover.task-library"]
      }
    }
  ]
};

export default discoverModuleRouter;
