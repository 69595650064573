import {
  getServiceProviders,
  getServiceProviderData,
  createServiceProvider,
  updateServiceProvider,
  deleteServiceProvider,
  getFormContents,
  getTableColumns,
  getAllServiceProviders,
  getSpApiKeys,
  moveAccountToSp,
  storeSpSetting,
  storeEmailSetting,
  getEmailSetting,
  validateCustomEmailProfile,
  getServiceProvidersWithPagination,
  flushCache
} from "@/api/serviceproviders";
import { getAccountsForServiceProvider } from "@/api/accounts";
import { getTrunks } from "@/api/trunks";
import { getTasks } from "@/api/tasks";
import _ from "lodash";
import { fetchUser } from "@/api/auth";

const state = {
  loading: false,
  serviceProviders: [],
  serviceProviderAccounts: [],
  serviceProviderTrunks: [],
  selectedSpAccounts: [],
  selectedSpTrunks: [],
  selectedSpApiKeys: [],
  selectedAcTasks: [],
  themeSettings: [],
  emailSettings: [],
  serviceProviderData: {}
};

const getters = {
  accounts: state => sp_id => {
    let serviceProvider = _.find(state.serviceProviderAccounts, {
      sp_id
    });
    return serviceProvider
      ? serviceProvider.accounts.map(account => {
          return {
            label: account.ac_name,
            value: {
              ac_id: account.ac_id,
              mapping_scope: "ac",
              sp_id: account.sp_id
            }
          };
        })
      : [];
  },

  trunks: state => sp_id => {
    let serviceProvider = _.find(state.serviceProviderTrunks, {
      sp_id
    });
    return serviceProvider
      ? serviceProvider.trunks.map(trunk => {
          return {
            label: trunk.trunk_name,
            value: {
              trunk_id: trunk.trunk_id,
              sp_id: trunk.sp_id
            }
          };
        })
      : [];
  },

  spName: state => sp_id => {
    let serviceProvider = _.find(state.serviceProviders, {
      sp_id: parseInt(sp_id)
    });
    return serviceProvider ? serviceProvider.sp_name : "Error";
  }
};

const mutations = {
  CHANGE_LOADING(state, loading) {
    state.loading = loading;
  },

  CREATE_SERVICE_PROVIDER(state, serviceProvider) {
    state.serviceProviders.push(serviceProvider);
  },

  UPDATE_SERVICE_PROVIDER(state, { index, serviceProvider }) {
    state.serviceProviders.splice(index, 1, serviceProvider);
  },

  DELETE_SERVICE_PROVIDER(state, serviceProvider) {
    state.serviceProviders = state.serviceProviders.filter(
      oldServiceProvider => oldServiceProvider.sp_id !== serviceProvider.sp_id
    );
  },

  SET_SERVICE_PROVIDERS(state, serviceProviders) {
    state.serviceProviders = serviceProviders;
  },

  ADD_SERVICE_PROVIDER_ACCOUNTS(state, data) {
    state.serviceProviderAccounts.push(data);
  },
  REPLACE_SERVICE_PROVIDER_ACCOUNTS(state, { sp_id, accounts }) {
    let serviceProvider = _.find(state.serviceProviderAccounts, { sp_id });
    serviceProvider.accounts = accounts;
    // state.serviceProviderAccounts[sp_id] = accounts;
  },
  ADD_SERVICE_PROVIDER_TRUNKS(state, data) {
    state.serviceProviderTrunks.push(data);
  },
  REPLACE_SERVICE_PROVIDER_TRUNKS(state, { sp_id, trunks }) {
    let serviceProvider = _.find(state.serviceProviderTrunks, { sp_id });
    serviceProvider.trunks = trunks;
  },
  ADD_SELECTED_SERVICE_PROVIDER_ACCOUNTS(state, data) {
    state.selectedSpAccounts = data;
  },
  ADD_SELECTED_SERVICE_PROVIDER_TRUNKS(state, data) {
    state.selectedSpTrunks = data;
  },
  ADD_SELECTED_ACCOUNT_TASKS(state, data) {
    state.selectedAcTasks = data;
  },
  ADD_SELECTED_SP_API_KEYS(state, data) {
    state.selectedSpApiKeys = data;
  },
  STORE_SP_SETTING(state, data) {
    state.themeSettings = data;
  },

  STORE_EMAIL_SETTING(state, data) {
    state.emailSettings = data;
  },

  SET_SERVICE_PROVIDER_DATA(state, serviceProviderData) {
    state.serviceProviderData = serviceProviderData;
  }
};

const actions = {
  getServiceProviders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getServiceProviders(options)
        .then(({ data }) => {
          commit("SET_SERVICE_PROVIDERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getServiceProviderData({ commit }, sp_id) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getServiceProviderData(sp_id)
        .then(({ data }) => {
          commit("SET_SERVICE_PROVIDER_DATA", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getAllServiceProviders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getAllServiceProviders(options)
        .then(({ data }) => {
          commit("SET_SERVICE_PROVIDERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * get Service Providers list with Pagination
   * @param commit
   * @param options
   * @returns {Promise<unknown>}
   */
  getServiceProvidersWithPagination({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getServiceProvidersWithPagination(options)
        .then(({ data }) => {
          commit("SET_SERVICE_PROVIDERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  fetchAccountsForSelectedSP({ commit }, sp = null) {
    return new Promise((resolve, reject) => {
      if (sp) {
        commit("CHANGE_LOADING", true);
        getAccountsForServiceProvider({
          search: sp,
          fetch_all: 1,
          searchFields: "sp_id:="
        })
          .then(({ data }) => {
            commit("ADD_SELECTED_SERVICE_PROVIDER_ACCOUNTS", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  //todo: add method to get Accounts for selected SP move account
  fetchAccountsForSelectedSPForMovingAccount({ commit }, sp = null) {
    return new Promise((resolve, reject) => {
      if (sp) {
        commit("CHANGE_LOADING", true);
        getAccountsForServiceProvider({
          search: sp,
          fetch_all: 1,
          searchFields: "sp_id:=",
          isForMovingAccount: true
        })
          .then(({ data }) => {
            commit("ADD_SELECTED_SERVICE_PROVIDER_ACCOUNTS", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  fetchTrunksForSelectedSP({ commit }, sp = null) {
    return new Promise((resolve, reject) => {
      if (sp) {
        commit("CHANGE_LOADING", true);
        getTrunks({ search: sp, fetch_all: 1, searchFields: "sp_id:=" })
          .then(({ data }) => {
            commit("ADD_SELECTED_SERVICE_PROVIDER_TRUNKS", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  fetchTasksForSelectedAc({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (options.ac) {
        commit("CHANGE_LOADING", true);
        getTasks({
          search: options.ac,
          fetch_all: 1,
          task_type: options.task_type,
          searchFields: "ac_id:="
        })
          .then(({ data }) => {
            commit("ADD_SELECTED_ACCOUNT_TASKS", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  fetchAccountsForServiceProvider({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getAccountsForServiceProvider()
        .then(({ data }) => {
          const sp_id = rootState.app.selectedServiceProviderId;
          const accounts = _.filter(
            data.data,
            account => account.sp_id === sp_id
          );

          // const accounts = data.data;

          const mutation = _.includes(
            _.map(state.serviceProviderAccounts, "sp_id"),
            sp_id
          )
            ? "REPLACE_SERVICE_PROVIDER_ACCOUNTS"
            : "ADD_SERVICE_PROVIDER_ACCOUNTS";

          commit(mutation, { sp_id, accounts });
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  fetchTrunksForServiceProvider({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      getTrunks()
        .then(({ data }) => {
          const sp_id = rootState.app.selectedServiceProviderId;
          const trunks = data.data;

          const mutation = _.includes(
            _.map(state.serviceProviderTrunks, "sp_id"),
            sp_id
          )
            ? "REPLACE_SERVICE_PROVIDER_TRUNKS"
            : "ADD_SERVICE_PROVIDER_TRUNKS";

          commit(mutation, { sp_id, trunks });
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  createServiceProvider({ commit }, serviceProvider) {
    return new Promise((resolve, reject) => {
      createServiceProvider(serviceProvider)
        .then(({ data }) => {
          commit("CREATE_SERVICE_PROVIDER", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
          fetchUser()
            .then(() => resolve())
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateServiceProvider({ commit, state }, serviceProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const serviceProviderObj = { ...serviceProvider };
      updateServiceProvider(serviceProviderObj)
        .then(({ data }) => {
          var index = _.findIndex(
            state.serviceProviders,
            oldServiceProvider => {
              return oldServiceProvider.sp_id === serviceProvider.sp_id;
            }
          );
          commit("UPDATE_SERVICE_PROVIDER", {
            index,
            serviceProvider: data.data
          });
          commit("CHANGE_LOADING", false);
          resolve(data);
          fetchUser()
            .then(() => resolve())
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteServiceProvider({ commit }, serviceProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteServiceProvider(serviceProvider)
        .then(data => {
          var index = _.findIndex(
            state.serviceProviders,
            oldServiceProvider => {
              return oldServiceProvider.sp_id === serviceProvider.sp_id;
            }
          );
          commit("UPDATE_SERVICE_PROVIDER", {
            index,
            serviceProvider: data.data
          });
          commit("CHANGE_LOADING", false);
          resolve(data);
          fetchUser()
            .then(() => resolve(data))
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteServiceProvider({ commit }, serviceProvider) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      serviceProvider.is_enabled = 1;
      updateServiceProvider(serviceProvider)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getFormContents({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getFormContents()
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getTableColumns({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getTableColumns()
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getSpApiKeys({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getSpApiKeys(options)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          commit("ADD_SELECTED_SP_API_KEYS", data);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  moveAccountToSp({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      moveAccountToSp(options)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  storeSpSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeSpSetting(data)
        .then(({ data }) => {
          commit("STORE_SP_SETTING", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
          fetchUser()
            .then(() => resolve())
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getEmailSetting({ commit }) {
    return new Promise((resolve, reject) => {
      getEmailSetting()
        .then(({ data }) => {
          commit("STORE_EMAIL_SETTING", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  storeEmailSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeEmailSetting(data)
        .then(() => {
          commit("STORE_EMAIL_SETTING", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  validateCustomEmailProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      validateCustomEmailProfile(data)
        .then(response => {
          commit("CHANGE_LOADING", false);
          resolve(response.data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  flushCache({ commit }, serviceProvider) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      flushCache(serviceProvider.sp_id)
        .then(msg => {
          resolve(msg); // when succeeded, toast message
        })
        .catch(err => {
          console.log(err);
          reject(err); // when failed, toast messsage with error type
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
