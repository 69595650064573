import request, { sp_headers } from "@/utils/studio7ApiService";
export function showFeature(payload) {
  return request({
    url: "/launch-darkly/show-feature",
    method: "post",
    data: payload,
    headers: sp_headers()
  });
}

export function showFeatureAnonymous(payload) {
  return request({
    url: "/launch-darkly/show-feature-anonymous",
    method: "post",
    data: payload
  });
}
