import {
  createDnisPool,
  deleteDnisPool,
  getDnisPools,
  updateDnisPool,
  getPoolNumbersData,
  deleteDnisPoolNumber
} from "@/api/dnispool";

const state = {
  dnispool: [],
  pool_data: [],
  selected_pool_id: -1,
  loading: false
};

const mutations = {
  SET_DNIS_POOL(state, dnispool) {
    state.dnispool = dnispool;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_POOL_NUMBERS_DATA(state, pool_data) {
    state.pool_data = pool_data;
  },
  DELETE_DNIS_POOL(state, dnispool) {
    state.dnispool = state.dnispool.filter(
      oldPool => oldPool.pool_id !== dnispool.pool_id
    );
  },
  DELETE_DNIS_POOL_NUMBER(state, pool_number) {
    state.pool_data = state.pool_data.filter(
      oldPool => oldPool.pool_number_id !== pool_number.pool_number_id
    );
  },
  CHANGE_SELECTED_POOL_ID(state, pool_id) {
    state.selected_pool_id = pool_id;
  }
};

const actions = {
  getDnisPools({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      getDnisPools(options)
        .then(({ data }) => {
          commit("SET_DNIS_POOL", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  createDnisPool({ commit }, { dnispool }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createDnisPool(dnispool)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  updateDnisPool({ commit }, { dnispool }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateDnisPool(dnispool)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  setSelectedPoolId({ commit }, pool_id) {
    commit("CHANGE_SELECTED_POOL_ID", pool_id);
  },

  deleteDnisPool({ commit }, dnispool) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteDnisPool(dnispool)
        .then(data => {
          commit("CHANGE_LOADING", false);
          commit("DELETE_DNIS_POOL", dnispool);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getPoolNumbersData({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options, pool_id: state.selected_pool_id };
      getPoolNumbersData(options)
        .then(({ data }) => {
          commit("SET_POOL_NUMBERS_DATA", data.data);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  undoDeleteDnisPool({ commit }, dnispool) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      dnispool.is_enabled = 1;
      updateDnisPool(dnispool)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteDnisPoolNumber({ commit }, pool_number) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteDnisPoolNumber(pool_number)
        .then(data => {
          commit("CHANGE_LOADING", false);
          commit("DELETE_DNIS_POOL_NUMBER", pool_number);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
