import {
  getPrompts,
  createPrompt,
  updatePrompt,
  deletePrompt,
  duplicatePrompt
} from "@/api/prompts";
import _ from "lodash";

const state = {
  prompts: [],
  qfm_prompts: [],
  loading: false
};

export const getters = {
  prompts(state) {
    return _.cloneDeep(state.prompts);
  },

  promptNames(state) {
    if (state.prompts) {
      return _.map(
        _.orderBy(state.prompts, ["updated_at"], ["desc"]),
        "prompt_name"
      );
    } else {
      return [];
    }
  },
  qfmPromptNames(state) {
    if (state.qfm_prompts) {
      return _.map(
        _.orderBy(state.qfm_prompts, ["updated_at"], ["desc"]),
        "prompt_name"
      );
    } else {
      return [];
    }
  }
};
const mutations = {
  SET_PROMPTS(state, prompts) {
    state.prompts = prompts;
  },

  SET_QFM_PROMPTS(state, prompts) {
    state.qfm_prompts = prompts;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getPrompts({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, prompt_type: "IVA" };
      getPrompts(options)
        .then(({ data }) => {
          commit("SET_PROMPTS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  createPrompt({ commit }, prompt) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createPrompt(prompt)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  duplicatePrompt({ commit }, prompt) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      duplicatePrompt(prompt)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updatePrompt({ commit }, prompt) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const promptObj = { ...prompt };
      updatePrompt(promptObj)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  refreshPrompt({ commit, getters }, { content_id, refreshing }) {
    let prompts = _.map(getters.prompts, prompt => {
      if (prompt.prompt_id === content_id) {
        prompt.is_refreshing = refreshing;
      }
      return prompt;
    });
    commit("SET_PROMPTS", prompts);
  },

  deletePrompt({ commit }, prompt) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deletePrompt(prompt)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },
  undoDeletePrompt({ commit }, prompt) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      prompt.is_enabled = 1;
      updatePrompt(prompt)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getQfmPrompts({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, prompt_type: "QFM" };
      getPrompts(options)
        .then(({ data }) => {
          commit("SET_QFM_PROMPTS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
