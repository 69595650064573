import store from "@/store";
import __ from "@/translation";

const copyNodeUrl = id => {
  store.dispatch("canvas/copyNodeUrl", {
    id
  });
};

export default {
  text: __("Copy Node URL"),
  icon: "",
  onClick: copyNodeUrl
};
