import store from "@/store";
import __ from "@/translation";

const goBack = () => {
  store.dispatch("canvas/goBack");
};

export default {
  text: __("Go Back"),
  icon: "",
  onClick: goBack
};
