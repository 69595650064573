/* eslint-disable no-undef */

function isInstalled() {
  return typeof newrelic == "object";
}

function setCustomAttribute(key, value, persist = false) {
  if (isInstalled()) {
    newrelic.setCustomAttribute(key, value, persist);
    return true;
  }
  return false;
}

function setUserId(value) {
  if (isInstalled()) {
    newrelic.setUserId(value);
    return true;
  }
  return false;
}

export default {
  setCustomAttribute,
  setUserId
};
