import {
  getFolders,
  createFolder,
  updateFolder,
  deleteFolder,
  moveContentToFolder,
  checkInUse,
  checkInSysUse,
  checkInUseCount,
  checkInSysUseCount,
  refreshInUse
} from "@/api/folders";
import _ from "lodash";
import __ from "@/translation";

const contentModelToStringMap = {
  get Prompt() {
    return __("prompt");
  },
  get Variable() {
    return __("variable");
  },
  get FormType() {
    return __("form type");
  },
  get OpenFormType() {
    return __("open form type");
  },
  get DataStore() {
    return __("data store");
  },
  get AttachmentFile() {
    return __("file");
  },
  get HolidaySchedule() {
    return __("holiday schedule");
  },
  get AuthProfile() {
    return __("authentication");
  },
  get Campaign() {
    return __("campaign");
  },
  get Task() {
    return __("task");
  }
};

const getContentModelDisplayName = content_model =>
  _.get(contentModelToStringMap, content_model, content_model);

const generateCheckInUseErrMessage = function(content_model) {
  return __("we are unable to determine if this :contentModel is in use.", {
    contentModel: getContentModelDisplayName(content_model)
  });
};

const state = {
  folders: [],
  loading: false,
  contentLoading: false,
  selectedFolderId: 0,
  selectedTaskId: -1,
  checkInUseLoading: false,
  checkInUseErrorMessage: "",
  checkInUseCountLoading: false,
  checkInUseCountErrorMessage: "",
  currentTab: "folders"
};

export const getters = {
  folderName: state => folder_id => {
    if (state.folders && folder_id > 0) {
      const folder = _.find(state.folders, function(o) {
        return o.folder_id == folder_id;
      });
      return folder.folder_name;
    } else if (folder_id === "-1") {
      return "Archived";
    } else {
      return "Main";
    }
  }
};

const mutations = {
  SET_FOLDERS(state, folders) {
    state.folders = folders;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  CHANGE_CONTENT_LOADING(state, isLoading) {
    state.contentLoading = isLoading;
  },

  CHANGE_CHECK_IN_USE_LOADING(state, isLoading) {
    state.checkInUseLoading = isLoading;
  },

  CHANGE_CHECK_IN_USE_ERROR(state, errMessage = "") {
    state.checkInUseErrorMessage = errMessage;
  },

  CHANGE_CHECK_IN_USE_COUNT_LOADING(state, isLoading) {
    state.checkInUseCountLoading = isLoading;
  },

  CHANGE_CHECK_IN_USE_COUNT_ERROR(state, errMessage = "") {
    state.checkInUseCountErrorMessage = errMessage;
  },

  SELECT_FOLDER(state, folder_id) {
    state.selectedFolderId = folder_id;
  },

  SELECT_TASK(state, task_id) {
    state.selectedTaskId = task_id;
  },

  SELECT_TAB(state, tab) {
    state.currentTab = tab;
  }
};

const actions = {
  getFolders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options };
      getFolders(options)
        .then(({ data }) => {
          commit("SET_FOLDERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  createFolder({ commit }, folder) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createFolder(folder)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateFolder({ commit }, folder) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const folderObj = { ...folder };
      updateFolder(folderObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deleteFolder({ commit }, folder) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteFolder(folder)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  undoDeleteFolder({ commit }, folder) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      folder.is_enabled = 1;
      updateFolder(folder)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  moveContentToFolder({ commit }, { folder_id, content_ids, content_model }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_CONTENT_LOADING", true);
      moveContentToFolder(folder_id, content_ids, content_model)
        .then(data => {
          commit("CHANGE_CONTENT_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  updateSelectedFolderId({ commit }, folder_id) {
    commit("SELECT_FOLDER", folder_id);
  },

  updateSelectedTaskId({ commit }, task_id) {
    commit("SELECT_TASK", task_id);
  },

  checkInUse({ commit }, { content_model, content_id, mode }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_CHECK_IN_USE_ERROR", "");
      commit("CHANGE_CHECK_IN_USE_LOADING", true);
      checkInUse(content_model, content_id, mode)
        .then(data => {
          commit("CHANGE_CHECK_IN_USE_ERROR", "");
          resolve(data.data);
        })
        .catch(err => {
          let msg =
            generateCheckInUseErrMessage(content_model) +
            " " +
            _.last(err.message.split("."));
          commit("CHANGE_CHECK_IN_USE_ERROR", msg);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_CHECK_IN_USE_LOADING", false);
        });
    });
  },
  refreshInUse({ commit }, { content_model, content_id, content_name }) {
    return new Promise((resolve, reject) => {
      refreshInUse(content_model, content_id, content_name)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_CHECK_IN_USE_LOADING", false);
        });
    });
  },

  checkInSysUse({ commit }, { content_model, content_id }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_CHECK_IN_USE_ERROR", "");
      commit("CHANGE_CHECK_IN_USE_LOADING", true);
      checkInSysUse(content_model, content_id)
        .then(data => {
          commit("CHANGE_CHECK_IN_USE_ERROR", "");
          resolve(data.data);
        })
        .catch(err => {
          let msg =
            generateCheckInUseErrMessage(content_model) +
            " " +
            _.last(err.message.split("."));
          commit("CHANGE_CHECK_IN_USE_ERROR", msg);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_CHECK_IN_USE_LOADING", false);
        });
    });
  },

  /**
   * CHECK IN USE COUNT
   * @param commit
   * @param content_model
   * @param content_id
   * @returns {Promise<unknown>}
   */
  checkInUseCount({ commit }, { content_model, content_id }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_CHECK_IN_USE_COUNT_ERROR", "");
      commit("CHANGE_CHECK_IN_USE_COUNT_LOADING", true);
      checkInUseCount(content_model, content_id)
        .then(data => {
          commit("CHANGE_CHECK_IN_USE_COUNT_ERROR", "");
          resolve(data.data);
        })
        .catch(err => {
          let msg =
            generateCheckInUseErrMessage(content_model) +
            " " +
            _.last(err.message.split("."));
          commit("CHANGE_CHECK_IN_USE_COUNT_ERROR", msg);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_CHECK_IN_USE_COUNT_LOADING", false);
        });
    });
  },

  /**
   * Check in use count across all accounts
   * @param commit
   * @param content_model
   * @param content_id
   * @returns {Promise<unknown>}
   */
  checkInSysUseCount({ commit }, { content_model, content_id }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_CHECK_IN_USE_COUNT_ERROR", "");
      commit("CHANGE_CHECK_IN_USE_COUNT_LOADING", true);
      checkInSysUseCount(content_model, content_id)
        .then(data => {
          commit("CHANGE_CHECK_IN_USE_COUNT_ERROR", "");
          resolve(data.data);
        })
        .catch(err => {
          let msg =
            generateCheckInUseErrMessage(content_model) +
            " " +
            _.last(err.message.split("."));
          commit("CHANGE_CHECK_IN_USE_COUNT_ERROR", msg);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_CHECK_IN_USE_COUNT_LOADING", false);
        });
    });
  },

  updateCurrentTab({ commit }, tab) {
    commit("SELECT_TAB", tab);
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
