import store from "@/store";
import __ from "@/translation";

export const copyBranch = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/copyNode", {
      id,
      copyType: "copy_branch"
    });
  }
};

export default {
  text: `${__("Copy Branch")}<span>Ctrl+A, Ctrl+C</span>`,
  icon: "",
  onClick: copyBranch
};
