import {
  addAlertSettings,
  getAlertSettings,
  updateAlertSettings,
  updateOrCreateAlertSettings,
  updateOrCreateSpAlertSettings,
  getSpAlertSettings,
  addSpAlertSettings,
  updateSpAlertSettings
} from "@/api/alerts";

const state = {
  alertSettingsState: {},
  spAlertSettingsState: {},
  loading: false
};

const mutations = {
  CHANGE_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ALERT_SETTINGS(state, alertSettings) {
    state.alertSettingsState = alertSettings;
  },

  SET_SP_AlERT_SETTINGS(state, alertSettings) {
    state.spAlertSettingsState = alertSettings;
  }
};

const actions = {
  getAlertSettings({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getAlertSettings()
        .then(({ data }) => {
          commit("SET_ALERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  addAlertSettings({ commit }, alertSettings) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      addAlertSettings(alertSettings)
        .then(({ data }) => {
          commit("SET_ALERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },
  updateAlertSettings({ commit }, alertSettings) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateAlertSettings(alertSettings)
        .then(({ data }) => {
          commit("SET_ALERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateOrCreateAlertSettings({ commit }, alertSettings) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateOrCreateAlertSettings(alertSettings)
        .then(({ data }) => {
          commit("SET_ALERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateOrCreateSpAlertSettings({ commit }, alertSettings) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateOrCreateSpAlertSettings(alertSettings)
        .then(({ data }) => {
          commit("SET_SP_AlERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getSpAlertSettings({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getSpAlertSettings()
        .then(({ data }) => {
          commit("SET_SP_AlERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  addSpAlertSettings({ commit }, alertSettings) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      addSpAlertSettings(alertSettings)
        .then(({ data }) => {
          commit("SET_SP_AlERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },
  updateSpAlertSettings({ commit }, alertSettings) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateSpAlertSettings(alertSettings)
        .then(({ data }) => {
          commit("SET_SP_AlERT_SETTINGS", data.data);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
