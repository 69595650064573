<template>
  <div class="sidebar-settings">
    <el-menu
      menu-trigger="click"
      :default-active="activeIndex"
      mode="horizontal"
      class="sidebar-menu"
      dusk="sb-menu-ac-switch"
      unique-opened
      ref="accountSwitchMenu"
      v-on-clickaway="closeMenu"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-submenu index="1" popper-class="sidebar-settings-dropdown-menu">
        <template slot="title">
          <user-profile
            :user-info-primary="selectedAccountName"
            :user-info-secondary="selectedServiceProviderName"
            :user-img="logoToShow"
          ></user-profile>
        </template>
        <div class="menu-wrapper" @mouseleave="openSubMenuDebounceFn.cancel">
          <div
            @click.prevent.stop="closeMenu"
            class="px-10 py-15"
            style="display: flex; justify-content: space-between; align-items: center; font-size: 0.9125rem; cursor:pointer"
          >
            <span>
              {{ __("Switch to:") }}
            </span>
            <img
              style="margin-right: 2px"
              :src="require('@/assets/icons/icon-updown.svg')"
            />
          </div>
          <div v-if="isSpLevelSwitcher">
            <div class="px-10">
              <el-input
                class="sidebar-search "
                dusk="sb-sp-search"
                :placeholder="__('Search service providers')"
                v-model="searchServiceProviders"
              >
                <img
                  slot="suffix"
                  :src="require('@/assets/icons/icon-search.svg')"
                />
              </el-input>
            </div>

            <div class="title px-10 py-10">{{ __("Service Providers") }}</div>

            <!--Todo: filteredSwitcherOptions >= 1-->
            <el-menu-item
              id="sp-all"
              data-sp="sp-all"
              @mouseover.native.prevent.stop="handleHoverOnAllOption"
              @click="selectAccount({ ac_id: 'all', sp_id: 'all' })"
              v-if="
                !searchServiceProviders && filteredSwitcherOptions.length > 1
              "
              index="sp-all"
              dusk="sp-menu-sp-all"
            >
              <el-row type="flex">
                <span style="margin-left: 15px">{{ __("All") }}</span>
                <span class="selected" v-if="isServiceProviderSelectedIs('all')"
                  ><img :src="require('@/assets/icons/icon-tick.svg')" /> </span
              ></el-row>
            </el-menu-item>

            <el-scrollbar
              ref="sp-scroll"
              :native="false"
              v-loading="areSpsLoading"
            >
              <div style="max-height: 340px">
                <el-submenu
                  :show-timeout="0"
                  :hide-timeout="0"
                  v-for="(sp, index) in filteredSwitcherOptions"
                  :index="'sp-index-' + index"
                  :key="index"
                  :data-sp="sp.sp_id"
                  @click.native.capture="disableClickEventOnServiceProvider"
                  @mouseenter.native.self.prevent.stop="
                    handleMouseOver(sp.sp_id)
                  "
                  :dusk="`sb-submenu-sp-${sp.sp_id}`"
                  @mouseleave.native.self.stop.prevent="handleMouseLeave(index)"
                >
                  <template slot="title">
                    <el-tooltip
                      :content="sp.sp_name"
                      :open-delay="500"
                      :disabled="hideTooltip(sp.sp_name)"
                    >
                      <div style="display: flex; align-items: center">
                        <img
                          :src="sp.logopath || '/Five9Logo.jpg'"
                          @error="handleAltImg"
                          class="rounded-menu-icon"
                        />
                        <span style="margin-left: 10px">{{
                          sp.sp_name | truncate
                        }}</span>
                        <span
                          class="selected"
                          v-if="isServiceProviderSelectedIs(sp.sp_id)"
                        >
                          <img :src="require('@/assets/icons/icon-tick.svg')" />
                        </span>
                      </div>
                    </el-tooltip>
                  </template>

                  <div class="side-menu-wrapper">
                    <!--  fix for element ui bug, inputting space doesnt trigger input event-->
                    <!--  wrapping the el-input with a div with @keydown.stop modifier seems to fix it-->
                    <div @keydown.stop class="px-10">
                      <el-input
                        class="sidebar-search"
                        dusk="sb-ac-search"
                        :placeholder="__('Search accounts')"
                        v-model="searchAccounts"
                      >
                        <img
                          slot="suffix"
                          :src="require('@/assets/icons/icon-search.svg')"
                        />
                      </el-input>
                    </div>

                    <div class="title px-10 py-10">{{ __("Accounts") }}</div>

                    <el-menu-item
                      @click="selectAccount({ ac_id: 'all', sp_id: sp.sp_id })"
                      v-if="!searchAccounts"
                      :id="'ac-' + sp.sp_id + '-all'"
                      :index="'ac-' + sp.sp_id + '-all'"
                      dusk="sb-menu-ac-all"
                      data-ac="ac-all"
                    >
                      <el-row type="flex">
                        <span style="margin-left: 15px;">{{ __("All") }} </span>
                        <span
                          class="selected"
                          v-if="
                            isAccountSelectedIs('all') &&
                              selectedServiceProviderId === sp.sp_id
                          "
                          ><img
                            :src="
                              require('@/assets/icons/icon-tick.svg')
                            "/></span
                      ></el-row>
                    </el-menu-item>

                    <el-scrollbar
                      :ref="'ac-scroll-' + sp.sp_id"
                      :native="false"
                      v-loading="areAcsLoading"
                    >
                      <div style="max-height: 285px">
                        <el-menu-item
                          @click="selectAccount(account)"
                          :index="'ac-' + account.ac_id"
                          :id="'ac-' + sp.sp_id + '-' + account.ac_id"
                          :data-ac="account.ac_id"
                          v-for="(account, index) in sp.accounts"
                          :key="index"
                          :dusk="`sb-menu-ac-${account.ac_id}`"
                        >
                          <el-tooltip
                            :content="accountNameLabel(account)"
                            :open-delay="500"
                            :disabled="hideTooltip(accountNameLabel(account))"
                          >
                            <el-row
                              type="flex"
                              justify="flex-start"
                              style="align-items: center"
                            >
                              <img
                                :src="
                                  account.logopath ||
                                    sp.logopath ||
                                    '/Five9Logo.jpg'
                                "
                                @error="handleAltImg"
                                class="rounded-menu-icon"
                              />
                              <span style="margin-left: 10px;">{{
                                accountNameLabel(account) | truncate
                              }}</span>
                              <span
                                class="selected"
                                v-if="isAccountSelectedIs(account.ac_id)"
                                ><img
                                  :src="require('@/assets/icons/icon-tick.svg')"
                              /></span>
                            </el-row>
                          </el-tooltip>
                        </el-menu-item>
                      </div>
                    </el-scrollbar>
                  </div>
                </el-submenu>
              </div>
            </el-scrollbar>
          </div>
          <div v-else>
            <div>
              <div class="px-10">
                <el-input
                  class="sidebar-search"
                  dusk="sb-ac-search"
                  :placeholder="__('Search accounts')"
                  v-model="searchAccounts"
                >
                  <img
                    slot="suffix"
                    :src="require('@/assets/icons/icon-search.svg')"
                  />
                </el-input>
              </div>
              <div class="title px-10 py-10">{{ __("Accounts") }}</div>

              <el-menu-item
                @click="
                  selectAccount({
                    ac_id: 'all',
                    sp_id: selectedServiceProviderId
                  })
                "
                v-if="
                  !searchAccounts &&
                    userAccountsFor(selectedServiceProviderId).length > 1
                "
                :id="'ac-' + selectedServiceProviderId + '-all'"
                :index="'ac-' + selectedServiceProviderId + '-all'"
                data-ac="ac-all"
                dusk="sb-menu-ac-all"
              >
                <el-row type="flex">
                  <span style="margin-left: 15px;">{{ __("All") }} </span>
                  <span class="selected" v-if="isAccountSelectedIs('all')"
                    ><img :src="require('@/assets/icons/icon-tick.svg')"/></span
                ></el-row>
              </el-menu-item>

              <el-scrollbar ref="ac-scroll" :native="false">
                <div style="max-height: 225px">
                  <el-menu-item
                    @click="
                      selectAccount({
                        ac_id: account.ac_id,
                        sp_id: selectedServiceProviderId
                      })
                    "
                    :index="'ac-' + account.ac_id"
                    :id="'ac-' + account.sp_id + '-' + account.ac_id"
                    :data-ac="account.ac_id"
                    v-for="(account, index) in userAccountsToShowFor(
                      selectedServiceProviderId
                    )"
                    :key="index"
                    :dusk="`sb-menu-ac-${account.ac_id}`"
                  >
                    <el-row
                      type="flex"
                      justify="flex-start"
                      style="align-items: center"
                    >
                      <img
                        :src="
                          account.logopath ||
                            selectedServiceProvider.logopath ||
                            '/Five9Logo.jpg'
                        "
                        @error="handleAltImg"
                        class="rounded-menu-icon"
                      />
                      <span style="margin-left: 10px;">{{
                        accountNameLabel(account) | truncate
                      }}</span>
                      <span
                        class="selected"
                        v-if="isAccountSelectedIs(account.ac_id)"
                        ><img :src="require('@/assets/icons/icon-tick.svg')"
                      /></span>
                    </el-row>
                  </el-menu-item>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import UserProfile from "./UserProfile";
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import routerUtils from "@/utils/router";
import { accountNameLabel } from "@/utils/accounts";

export default {
  components: {
    UserProfile
  },

  mixins: [clickaway],

  data() {
    return {
      searchServiceProviders: "",
      searchAccounts: "",
      activeIndex: "1",
      openedSp: null,
      menuOpened: false,
      areAcsLoading: false,
      areSpsLoading: false,
      openSubMenuDebounceFn: _.debounce(this.openSubMenu, 150),
      filterSwitcherDebounceFn: _.debounce(this.filterSwitcherOptions, 300),
      filteredSwitcherOptions: []
    };
  },

  methods: {
    accountNameLabel(account) {
      return accountNameLabel(account);
    },
    ...mapActions("app", {
      changeUserSelectedServiceProviderId:
        "changeUserSelectedServiceProviderId",
      changeUserSelectedAccountId: "changeUserSelectedAccountId",
      getEnabledFeaturesForUser: "getEnabledFeaturesForUser",
      toggleOptionsLoading: "toggleOptionsLoading"
    }),

    ...mapActions("auth", { logout: "logout" }),

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    disableClickEventOnServiceProvider(ev) {
      let parent = ev.target;
      while (
        !parent.hasAttribute("data-sp") &&
        !parent.hasAttribute("data-ac")
      ) {
        parent = parent.parentNode;
      }

      if (parent.hasAttribute("data-sp")) {
        ev.stopPropagation();
        ev.preventDefault();
      }
    },

    hideTooltip(content) {
      return content.length <= 21;
    },

    openSubMenu(sp_id) {
      this.openedSp = sp_id;
      let index = _.findIndex(this.filteredSwitcherOptions, {
        sp_id
      });
      this.$refs.accountSwitchMenu &&
        this.$refs.accountSwitchMenu.open("sp-index-" + index);
    },

    handleMouseOver(sp_id) {
      if (this.menuOpened) {
        this.openSubMenuDebounceFn(sp_id);
      }
    },

    handleHoverOnAllOption() {
      this.openSubMenuDebounceFn.cancel();
      this.closeAllSubMenus();
    },

    handleMouseLeave(index) {
      this.openSubMenuDebounceFn.cancel();
      if (this.openedSp !== this.filteredSwitcherOptions[index].sp_id) {
        this.closeMenuAtIndex("sp-index-" + index);
      }
    },

    closeAllSubMenus() {
      _.map(this.filteredSwitcherOptions, (sp, index) => {
        this.closeMenuAtIndex("sp-index-" + index);
      });
    },

    closeMenu() {
      this.menuOpened = false;
      this.closeMenuAtIndex("1");
      this.handleClose();
    },

    closeMenuAtIndex(index) {
      this.$refs.accountSwitchMenu && this.$refs.accountSwitchMenu.close(index);
    },

    async selectAccount(account) {
      this.toggleOptionsLoading(true);
      this.handleClose();
      await this.changeUserSelectedServiceProviderId(account.sp_id);
      Promise.all([
        this.changeUserSelectedAccountId(account.ac_id),
        this.getEnabledFeaturesForUser(account.ac_id)
      ])
        .then(async () => {
          //await this.refreshFeatureFlags();
          this.toggleOptionsLoading(false);
          if (!routerUtils.hasPrivilege(this.$route)) {
            this.$router.push({ name: "Dashboard" }).catch(() => {});
          }
        })
        .catch(error => {
          console.log("error" + error);
        });
    },

    handleOpen() {
      this.menuOpened = true;
    },

    handleClose() {
      this.searchServiceProviders = "";
      this.searchAccounts = "";
      if (!this.isSpLevelSwitcher) {
        this.$refs["ac-scroll"].wrap.scrollTop = 0;
      } else {
        this.$refs["sp-scroll"].wrap.scrollTop = 0;
        let acScrollIndex = `ac-scroll-${this.openedSp}`;
        if (this.$refs[acScrollIndex] && this.$refs[acScrollIndex].length > 0) {
          this.$refs[acScrollIndex][0].wrap.scrollTop = 0;
        }
      }
    },

    filterSwitcherOptions() {
      let self = this;
      let options = _.cloneDeep(self.userAccountSwitcherOptions);
      if (!self.searchServiceProviders && !self.searchAccounts) {
        self.filteredSwitcherOptions = options;
      } else {
        // filter service providers
        let data = _.filter(
          options,
          serviceProvider =>
            serviceProvider.sp_name
              .toLowerCase()
              .includes(self.searchServiceProviders.toLowerCase()) ||
            _.some(serviceProvider.accounts, account =>
              accountNameLabel(account)
                .toLowerCase()
                .includes(self.searchServiceProviders.toLowerCase())
            )
        );
        // filter accounts
        if (self.openedSp && self.searchAccounts) {
          data = _.map(data, serviceProvider => {
            if (serviceProvider.sp_id === self.openedSp) {
              let accounts = serviceProvider.accounts;
              accounts = _.filter(accounts, account =>
                accountNameLabel(account)
                  .toLowerCase()
                  .includes(self.searchAccounts.toLowerCase())
              );
              return { ...serviceProvider, accounts };
            } else {
              return serviceProvider;
            }
          });
        }
        self.filteredSwitcherOptions = data;
      }
      self.areSpsLoading = false;
      self.areAcsLoading = false;
    }
  },

  filters: {
    truncate(value, limit = 21) {
      if (value && value.length <= limit) {
        return value;
      } else if (value) {
        return value.substring(0, limit) + "...";
      } else {
        return value;
      }
    }
  },

  computed: {
    ...mapState("app", {
      userAccountSwitcherOptions: state => state.userAccountSwitcherOptions,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapGetters("app", {
      selectedServiceProviderName: "selectedServiceProviderName",
      selectedServiceProvider: "selectedServiceProvider",
      selectedAccountName: "selectedAccountName",
      logoToShow: "logoToShow",
      userAccountsFor: "userAccountsFor"
    }),

    isServiceProviderSelectedIs() {
      let self = this;
      return value => {
        return self.selectedServiceProviderId === value;
      };
    },

    isAccountSelectedIs() {
      let self = this;
      return value => {
        return self.selectedAccountId === value;
      };
    },

    userAccountsToShowFor() {
      return serviceProvider => {
        let self = this;
        if (!self.searchAccounts) {
          return self.userAccountsFor(serviceProvider);
        }
        return _.filter(self.userAccountsFor(serviceProvider), account =>
          accountNameLabel(account)
            .toLowerCase()
            .includes(self.searchAccounts.toLowerCase())
        );
      };
    },

    isSpLevelSwitcher() {
      return this.userAccountSwitcherOptions.length >= 1;
    }
  },
  watch: {
    searchServiceProviders: {
      handler() {
        this.areSpsLoading = true;
        this.filterSwitcherDebounceFn();
        this.closeAllSubMenus();
        if (this.searchAccounts) {
          this.searchAccounts = "";
        }
      }
    },
    searchAccounts: {
      handler() {
        this.areAcsLoading = true;
        this.filterSwitcherDebounceFn();
      }
    },
    openedSp(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchAccounts = "";
      }
    },
    userAccountSwitcherOptions: {
      immediate: true,
      handler() {
        this.filterSwitcherOptions();
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.user-profile {
  ::v-deep &.el-row {
    align-items: center;

    .user-img {
      width: 44px;
      height: 44px;
      display: block;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 10px;
      background: white;
    }

    .user-info {
      display: flex;
      height: 44px;
      flex-direction: column;
      flex: 1 0 auto;
      align-items: flex-start;
      justify-content: flex-end;
      color: white;
      padding: 0 20px 0 0;

      &:hover {
        color: white;
      }
      span {
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-basis: content;
        &.primary-info {
          font-size: 1rem;
          padding-bottom: 2px;
        }
        &.secondary-info {
          font-size: 0.9rem;
          padding-bottom: 3px;
          padding-top: 5px;
          opacity: 0.8;
        }

        &.add-on {
          color: $--color-text-placeholder;
        }
      }
    }
  }
}

.sidebar-settings {
  padding: 10px 5px;
  width: 270px;

  .sidebar-menu {
    min-width: 270px !important;
    background-color: var(--theme-color);
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.15);
    z-index: 2;
  }

  ::v-deep .el-menu {
    border-bottom: none;
    width: auto;
    display: block;

    .el-submenu {
      width: 100%;
      display: block;
    }

    .el-submenu__title {
      width: 100%;
      padding: 0;
      display: block;
      border-bottom: none;
      height: auto;
      color: white;

      &:hover {
        background: inherit;
      }

      > .el-submenu__icon-arrow {
        visibility: hidden;
        transform: none;
        position: absolute;

        &:after {
          content: url("~@/assets/icons/icon-updown.svg");
          transition: none;
          visibility: visible;
          position: absolute;
          display: inline-flex;
          top: -5px;
          right: -8px;
        }
      }
    }

    .el-submenu.is-active .el-submenu__title {
      border-bottom: none;
    }
  }
}
</style>
<style lang="scss">
@import "~@/styles/element-variables.scss";

$account-switcher-color: #4db3f6 !default;
$account-switcher-color-hover: mix(#fff, $account-switcher-color, 20%) !default;

.px-10 {
  margin-left: 10px;
  margin-right: 10px;
  background-color: var(--theme-color);
}

.py-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  margin-bottom: 15px;
  background-color: var(--theme-color);
}

.sidebar-settings-dropdown-menu {
  margin-top: -60px;
  border-radius: 5px;
  color: white;
  background-color: var(--theme-color);

  .el-scrollbar {
    .is-vertical {
      .el-scrollbar__thumb {
        background-color: white;
      }
    }
  }

  .rounded-menu-icon {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    object-fit: cover;
    background: white;
    margin-left: 10px;
  }

  min-width: 270px;

  .menu-wrapper {
    background-color: var(--theme-color);
    .sidebar-search {
      width: 100%;
      display: flex;
      align-items: center;

      .el-input__suffix {
        display: flex;

        .el-input__suffix-inner {
          display: flex;
        }
      }

      .el-input__inner {
        height: 44px;
        border: none;

        &:focus,
        &:active {
          border: none;
        }
      }
    }

    .title {
      font-size: 0.8125rem;
      color: black;
    }
  }

  .el-divider--horizontal {
    margin: 5px 0;
  }

  .el-menu--horizontal {
    position: absolute !important;
    background-color: var(--theme-color);

    .el-menu--popup-right-start {
      position: fixed;
      top: 37px;
      width: 250px;
      height: auto;
    }

    ul {
      .side-menu-wrapper {
        margin: 20px 0 0 0;
      }
    }
  }

  .el-menu,
  .el-menu--horizontal {
    border-radius: 5px;
    background-color: var(--theme-color);
  }

  .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    transform: none !important;
  }

  .el-menu--horizontal {
    transition: none;
    opacity: 0;
    background-color: var(--theme-color);
  }

  .el-submenu.is-opened .el-menu--horizontal {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }

  .el-menu,
  .el-submenu,
  .el-menu--horizontal {
    color: white !important;
    background-color: var(--theme-color) !important;

    &:hover {
      color: white !important;
    }

    .el-submenu__title {
      height: 44px !important;
    }

    .el-menu-item,
    .el-submenu__title {
      transition: none;
      color: white !important;
      background-color: var(--theme-color) !important;
      padding: 0 !important;

      &:hover {
        transition: none;
        color: white !important;
        background-color: var(--theme-hover-color) !important;
      }

      .el-submenu__icon-arrow {
        visibility: hidden;
        display: inline-flex;
        align-self: center;
        justify-content: center;

        &:after {
          content: url("~@/assets/icons/icon-chevron.svg");
          visibility: visible;
          position: absolute;
          display: inline-flex;
        }
      }

      &:not(.is-disabled):hover,
      &:not(.is-disabled):focus {
        background-color: $--menu-item-hover-fill;
      }
    }

    .el-menu-item,
    .el-submenu {
      height: 56px !important;
      vertical-align: center;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .selected {
        margin-left: 5px;
      }
    }
  }

  .el-submenu:hover {
    transition: none;

    & .el-submenu__title {
      transition: none;
      background-color: var(--theme-hover-color) !important;
    }

    background-color: var(--theme-hover-color) !important;
  }

  .el-loading-spinner .path {
    stroke: #f2f6fc !important;
  }

  .el-loading-mask {
    background-color: rgba(98, 98, 98, 0.57);
  }
}
</style>
