import {
  getBusinessPlans,
  createBusinessPlan,
  updateBusinessPlan,
  deleteBusinessPlan,
  checkBusinessPlanChanges,
  getAvailableBasePlans,
  comparePlans,
  getTree
} from "@/api/businessplans";
import _ from "lodash";

export const state = {
  businessPlans: [],
  basePlans: [],
  plan_comparison: [],
  planChanged: false,
  isLoading: false,
  safe_update: true,
  tree: []
};

export const mutations = {
  SET_BUSINESS_PLANS(state, businessPlans) {
    state.businessPlans = businessPlans;
  },

  SET_BASE_PLANS(state, basePlans) {
    state.basePlans = basePlans;
  },

  CREATE_BUSINESS_PLAN(state, businessPlan) {
    state.businessPlans.push(businessPlan);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  UPDATE_BUSINESS_PLAN(state, { index, businessPlan }) {
    state.businessPlans.splice(index, 1, businessPlan);
  },

  DELETE_BUSINESS_PLAN(state, businessPlan) {
    state.businessPlans = state.businessPlans.filter(
      oldPlan => oldPlan.bp_id !== businessPlan.bp_id
    );
  },

  SET_BUSINESS_PLAN_UPDATE_FLAG(state, flag) {
    state.planChanged = flag;
  },

  SET_SAFE_UPDATE(state, message) {
    state.safe_update = message;
  },

  SET_PLAN_COMPARISON(state, comparison) {
    state.plan_comparison = comparison;
  },

  SET_TREE(state, tree) {
    state.tree = tree;
  },

  UPDATE_BASE_PLAN_RELATIONSHIPS(state, data) {
    if (data !== undefined) {
      let planItem = _.first(data);
      if (planItem !== undefined) {
        let basePlan = _.find(state.basePlans, { bp_id: planItem.bp_id });
        basePlan.features = planItem.features;
        basePlan.voices = planItem.voices;
        basePlan.nodes = planItem.nodes;
        basePlan.languages = planItem.languages;
        basePlan.features_not_in_plan = planItem.features_not_in_plan;
        basePlan.nodes_not_in_plan = planItem.nodes_not_in_plan;
        basePlan.tts_packages_not_in_plan = planItem.tts_packages_not_in_plan;
        basePlan.language_packs_not_in_plan =
          planItem.language_packs_not_in_plan;
      }
    }
  }
};

export const actions = {
  resetSafeUpdate({ commit }) {
    commit("SET_SAFE_UPDATE", true);
  },
  resetPlanComparison({ commit }) {
    commit("SET_PLAN_COMPARISON", []);
  },
  getBusinessPlans({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getBusinessPlans(options)
        .then(async ({ data }) => {
          commit("SET_BUSINESS_PLANS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getAvailableBasePlans({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAvailableBasePlans(options)
        .then(async ({ data }) => {
          commit("SET_BASE_PLANS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getRelationshipsForSelectedBasePlan({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAvailableBasePlans(options)
        .then(async ({ data }) => {
          commit("UPDATE_BASE_PLAN_RELATIONSHIPS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getBpRelationsForEdit({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAvailableBasePlans(options)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  comparePlans({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      comparePlans(options)
        .then(async ({ data }) => {
          commit("SET_PLAN_COMPARISON", [data.data]);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  createBusinessPlan({ commit }, businessPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createBusinessPlan(businessPlan)
        .then(async ({ data }) => {
          commit("CREATE_BUSINESS_PLAN", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          commit("SET_SAFE_UPDATE", "success");
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  updateBusinessPlan({ commit, state }, businessPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      updateBusinessPlan(businessPlan)
        .then(({ data }) => {
          var index = _.findIndex(state.businessPlans, oldPlan => {
            return oldPlan.bp_id === businessPlan.bp_id;
          });
          commit("UPDATE_BUSINESS_PLAN", { index, businessPlan: data.data });
          commit("SET_BUSINESS_PLAN_UPDATE_FLAG", true);
          commit("SET_SAFE_UPDATE", "success");
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteBusinessPlan({ commit }, businessPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      deleteBusinessPlan(businessPlan)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          commit("DELETE_BUSINESS_PLAN", businessPlan);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  undoDeleteBusinessPlan({ commit }, businessPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      businessPlan.is_enabled = 1;
      updateBusinessPlan(businessPlan)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  checkBusinessPlanChanges({ commit }, businessPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      checkBusinessPlanChanges(businessPlan)
        .then(({ data }) => {
          commit("SET_SAFE_UPDATE", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },
  getTree({ commit }, businessPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getTree(businessPlan)
        .then(({ data }) => {
          commit("SET_TREE", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  }
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
