import {
  createXsiSubscription,
  deleteXsiSubscription,
  getXsiPackages,
  getXsiSubscriptions
} from "@/api/xsisubscriptions";
//import _ from "lodash";

const state = {
  xsi_subscriptions: [],
  xsi_packages: [],
  isLoading: false
};

const mutations = {
  SET_XSI_SUBSCRIPTION(state, xsi_subscriptions) {
    state.xsi_subscriptions = xsi_subscriptions;
  },

  SET_XSI_PACKAGES(state, xsi_packages) {
    state.xsi_packages = xsi_packages;
  },

  CREATE_XSI_SUBSCRIPTION(state, xsi_subscription) {
    state.xsi_subscriptions.push(xsi_subscription);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getXsiSubscriptions({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getXsiSubscriptions(options)
        .then(({ data }) => {
          commit("SET_XSI_SUBSCRIPTION", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  getXsiPackages({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getXsiPackages(options)
        .then(({ data }) => {
          commit("SET_XSI_PACKAGES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  createXsiSubscription({ commit }, xsi_subscription) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createXsiSubscription(xsi_subscription)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deleteXsiSubscription({ commit }, xsi_subscription) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteXsiSubscription(xsi_subscription)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
