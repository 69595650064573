import request, { ac_headers } from "@/utils/studio7ApiService";

export function getAuthProfiles(
  options = { fetch_all: 1 },
  cancelToken = null
) {
  const params = new URLSearchParams(options);
  return request({
    url: "/authprofile/auth-profiles?" + params.toString(),
    method: "get",
    cancelToken,
    headers: ac_headers()
  });
}

export function createAuthProfile(data) {
  const authProfile = { ...data, guard_name: "admin" };
  return request({
    url: "/authprofile/auth-profiles",
    method: "post",
    data: authProfile,
    headers: ac_headers()
  });
}

export function updateAuthProfile(authProfile) {
  return request({
    url: `/authprofile/auth-profiles/${authProfile.auth_profile_id}`,
    method: "put",
    data: authProfile,
    headers: ac_headers()
  });
}

export function deleteAuthProfile(authProfile) {
  return request({
    url: `/authprofile/auth-profiles/${authProfile.auth_profile_id}`,
    method: "delete",
    data: authProfile,
    headers: ac_headers()
  });
}

export function testConnection({ flow_type, options, storeResult = false }) {
  return request({
    url: `/authprofile/auth-profiles/test-connection`,
    method: "post",
    data: { flow_type, options, storeResult },
    headers: ac_headers()
  });
}

export function validateAuthProfile(name) {
  return request({
    url: `/authprofile/validate`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}

export function getAuthProfileToken(id) {
  return request({
    url: `/authprofile/get-auth-profile-token?auth_profile_id=${id}`,
    method: "get",
    headers: ac_headers()
  });
}
