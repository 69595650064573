import {
  getAllServiceProviders,
  getS6ServiceProviders,
  getS6AccountsForSp,
  getMappedS7Sp,
  mapS6SpToS7Sp,
  mapS6AcToS7Ac,
  getMappedS7Ac,
  validateSelectedSp,
  validateSelectedAc,
  getUnmappedAccounts,
  getUnmappedServiceProviders,
  getAccountsAvailableForRemapping,
  unmapServiceProvider,
  unmapAccount,
  getBatchS6S7AccountDataForSp
} from "@/api/studio6serviceproviders";
import {
  getAccountsForServiceProvider,
  validateAccountContent,
  batchValidateAccountContent,
  migrateAccountContent,
  batchMigrateAccountContent,
  rollbackAccountContent,
  reportMigrationError,
  migratePortalTable,
  getPortalTablesToMigrate,
  rollbackPortalTable,
  rollbackAccountTable,
  getMigrationMap,
  consolidateNodeMigrationProgress,
  publishMigratedTemplates,
  checkMigrationErrors,
  getMigrationErrorList,
  getMigrationJobList,
  getMigrationJobInfo,
  getGroupIdsWithErrors,
  getGroupJobIdsWithErrors,
  groupIdsWithJobs,
  getMappedGroupIds,
  getJobIdsForGroup,
  getUnusedAccounts,
  deleteUnusedAccounts,
  getDataStoreListForAccount,
  getFlaggedNodesForAccount
} from "@/api/studio6accounts";
import _ from "lodash";

const state = {
  loading: false,
  serviceProviders: [],
  serviceProviderAccounts: [],
  themeSettings: [],
  accountContentValidation: [],
  processData: {},
  batchProcessData: [],
  portalTablesToMigrate: [],
  migrationMap: [],
  total: 0,
  migrationErrors: [],
  migrationErrorList: [],
  migrationJobList: [],
  migrationJobInfo: [],
  s6ServiceProviders: [],
  s6AccountsForSp: [],
  mappedS7Sp: {},
  mappedS7Ac: {},
  validatedSp: {},
  validatedAc: {},
  unmappedSps: [],
  unmappedAcs: [],
  accountsForRemapping: [],
  groupIdsWithErrors: [],
  jobIdsForGroup: [],
  groupIdsWithJobs: [],
  mappedGroupIds: [],
  groupJobIds: [],
  dataStoreListForAccount: [],
  unusedAccounts: [],
  unusedAccountsLoading: false,
  flaggedNodes: [],
  flaggedNodesLoading: false,
  batchAccountContentValidation: []
};

const getters = {
  accounts: state => sp_id => {
    let serviceProvider = _.find(state.serviceProviderAccounts, {
      sp_id
    });
    return serviceProvider
      ? serviceProvider.accounts.map(account => {
          return {
            label: account.ac_name,
            value: {
              ac_id: account.ac_id,
              mapping_scope: "ac",
              sp_id: account.sp_id
            }
          };
        })
      : [];
  }
};

const mutations = {
  CHANGE_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_SERVICE_PROVIDERS(state, serviceProviders) {
    state.serviceProviders = serviceProviders;
  },
  ADD_SELECTED_SERVICE_PROVIDER_ACCOUNTS(state, data) {
    state.serviceProviderAccounts = data;
  },
  SET_ACCOUNT_CONTENT_VALIDATION(state, accountContentValidation) {
    state.accountContentValidation = accountContentValidation;
  },
  SET_PROCESS_DATA(state, processData) {
    state.processData = processData;
  },

  SET_BATCH_PROCESS_DATA(state, batchProcessData) {
    state.batchProcessData = batchProcessData;
  },

  SET_PORTAL_TABLES_TO_MIGRATE(state, data) {
    state.portalTablesToMigrate = data;
  },

  SET_MIGRATION_MAP(state, data) {
    state.migrationMap = data;
  },
  SET_MIGRATION_ERRORS(state, data) {
    state.migrationErrors = data;
  },
  SET_MIGRATION_ERROR_LIST(state, data) {
    state.migrationErrorList = data;
  },
  SET_MIGRATION_JOB_LIST(state, data) {
    state.migrationJobList = data;
  },
  SET_MIGRATION_JOB_INFO(state, data) {
    state.migrationJobInfo = data;
  },

  SET_S6_SERVICE_PROVIDERS(state, data) {
    state.s6ServiceProviders = data;
  },

  SET_MAPPED_S7_SP(state, data) {
    state.mappedS7Sp = data;
  },

  SET_S6_ACCOUNTS_FOR_SP(state, data) {
    state.s6AccountsForSp = data;
  },

  SET_MAPPED_S7_AC(state, data) {
    state.mappedS7Ac = data;
  },

  SET_VALIDATED_SP(state, data) {
    state.validatedSp = data;
  },

  SET_VALIDATED_AC(state, data) {
    state.validatedAc = data;
  },
  SET_UNMAPPED_SERVICE_PROVIDERS(state, data) {
    state.unmappedSps = data;
  },
  SET_UNMAPPED_ACCOUNTS(state, data) {
    state.unmappedAcs = data;
  },
  SET_ACCOUNTS_FOR_REMAPPING(state, data) {
    state.accountsForRemapping = data;
  },
  SET_GROUP_ID_WITH_ERRORS_LIST(state, data) {
    state.groupIdsWithErrors = data;
  },
  SET_JOB_IDS_FOR_GROUP(state, data) {
    state.jobIdsForGroup = data;
  },
  SET_GROUP_IDS_WITH_JOBS_LIST(state, data) {
    state.groupIdsWithJobs = data;
  },
  SET_MAPPED_GROUP_IDS(state, data) {
    state.mappedGroupIds = data;
  },
  SET_JOB_ID_LIST_FOR_GROUP(state, data) {
    state.groupJobIds = data;
  },
  SET_DATA_STORE_LIST_FOR_ACCOUNT(state, data) {
    state.dataStoreListForAccount = data;
  },
  CHANGE_UNUSED_ACCOUNTS_LOADING(state, loading) {
    state.unusedAccountsLoading = loading;
  },
  SET_UNUSED_ACCOUNTS(state, data) {
    state.unusedAccounts = data;
  },
  CHANGE_FLAGGED_NODES_LOADING(state, data) {
    state.flaggedNodesLoading = data;
  },

  SET_FLAGGED_NODES(state, data) {
    state.flaggedNodes = data;
  },

  SET_BATCH_ACCOUNT_CONTENT_VALIDATION(state, batchAccountContentValidation) {
    state.batchAccountContentValidation = batchAccountContentValidation;
  }
};

const actions = {
  getAllServiceProviders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getAllServiceProviders(options)
        .then(({ data }) => {
          commit("SET_SERVICE_PROVIDERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getS6ServiceProviders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getS6ServiceProviders(options)
        .then(({ data }) => {
          commit("SET_S6_SERVICE_PROVIDERS", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getS6AccountsForSp({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getS6AccountsForSp(options)
        .then(({ data }) => {
          commit("SET_S6_ACCOUNTS_FOR_SP", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  getMappedS7Sp({ commit }, sp = null) {
    return new Promise((resolve, reject) => {
      if (sp) {
        commit("CHANGE_LOADING", true);
        getMappedS7Sp({
          s6_sp_id: sp
        })
          .then(({ data }) => {
            commit("SET_MAPPED_S7_SP", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  getMappedS7Ac({ commit }, ac = null) {
    return new Promise((resolve, reject) => {
      if (ac) {
        commit("CHANGE_LOADING", true);
        getMappedS7Ac({
          s6_ac_id: ac
        })
          .then(({ data }) => {
            commit("SET_MAPPED_S7_AC", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  fetchAccountsForSelectedSP({ commit }, sp = null) {
    return new Promise((resolve, reject) => {
      if (sp) {
        commit("CHANGE_LOADING", true);
        getAccountsForServiceProvider({
          service_provider_id: sp
        })
          .then(({ data }) => {
            commit("ADD_SELECTED_SERVICE_PROVIDER_ACCOUNTS", data.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("CHANGE_LOADING", false);
          });
      }
    });
  },

  validateAccountContent({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      validateAccountContent(options)
        .then(({ data }) => {
          commit("SET_ACCOUNT_CONTENT_VALIDATION", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getBatchS6S7AccountDataForSp({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getBatchS6S7AccountDataForSp(options)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  batchValidateAccountContent({ commit }, s6GroupsToMigrate) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      batchValidateAccountContent(s6GroupsToMigrate)
        .then(({ data }) => {
          commit("SET_BATCH_ACCOUNT_CONTENT_VALIDATION", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  migrateAccountContent({ commit }, accountContent) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      migrateAccountContent(accountContent)
        .then(({ data }) => {
          commit("SET_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  batchMigrateAccountContent({ commit }, s6GroupsToMigrate) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      batchMigrateAccountContent(s6GroupsToMigrate)
        .then(({ data }) => {
          commit("SET_BATCH_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  rollbackAccountContent({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      rollbackAccountContent(options)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  reportMigrationError({ commit }, migrationErrorData) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      reportMigrationError(migrationErrorData)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  migratePortalTable({ commit }, tableName) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      let options = {};
      if (tableName !== null) {
        options = { table_name: tableName };
      }
      migratePortalTable(options)
        .then(({ data }) => {
          console.log(data);
          // commit("SET_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  rollbackPortalTable({ commit }, tableName) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      let options = {};
      if (tableName !== null) {
        options = { table_name: tableName };
      }
      rollbackPortalTable(options)
        .then(({ data }) => {
          console.log(data);
          // commit("SET_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getMigrationMap({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getMigrationMap(options)
        .then(({ data }) => {
          commit("SET_MIGRATION_MAP", data.map);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getPortalTablesToMigrate({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getPortalTablesToMigrate()
        .then(({ data }) => {
          commit("SET_PORTAL_TABLES_TO_MIGRATE", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  rollbackAccountTable({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      rollbackAccountTable(options)
        .then(({ data }) => {
          console.log(data);
          // commit("SET_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  mapS6SpToS7Sp({ commit }, { s6_sp_id, s7_sp_id, remap = false }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      mapS6SpToS7Sp({ s6_sp_id: s6_sp_id, s7_sp_id: s7_sp_id, remap: remap })
        .then(({ data }) => {
          commit("SET_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  mapS6AcToS7Ac({ commit }, { s6_ac_id, s7_ac_id, remap = false }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      mapS6AcToS7Ac({ s6_ac_id: s6_ac_id, s7_ac_id: s7_ac_id, remap: remap })
        .then(({ data }) => {
          commit("SET_PROCESS_DATA", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  validateSelectedSp({ commit }, sp) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      validateSelectedSp({ sp_id: sp })
        .then(({ data }) => {
          commit("SET_VALIDATED_SP", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  validateSelectedAc({ commit }, ac) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      validateSelectedAc({ ac_id: ac })
        .then(({ data }) => {
          commit("SET_VALIDATED_AC", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  consolidateNodeMigrationProgress({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      consolidateNodeMigrationProgress(options)
        .then(({ data }) => {
          console.log(data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  publishTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      publishMigratedTemplates()
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  checkMigrationErrors({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      checkMigrationErrors(options)
        .then(({ data }) => {
          commit("SET_MIGRATION_ERRORS", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getMigrationErrorList({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getMigrationErrorList()
        .then(({ data }) => {
          commit("SET_MIGRATION_ERROR_LIST", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getMigrationJobList({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getMigrationJobList()
        .then(({ data }) => {
          commit("SET_MIGRATION_JOB_LIST", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getMigrationJobInfo({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getMigrationJobInfo(options)
        .then(({ data }) => {
          commit("SET_MIGRATION_JOB_INFO", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getUnmappedServiceProviders({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getUnmappedServiceProviders(options)
        .then(({ data }) => {
          commit("SET_UNMAPPED_SERVICE_PROVIDERS", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getUnmappedAccounts({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getUnmappedAccounts(options)
        .then(({ data }) => {
          commit("SET_UNMAPPED_ACCOUNTS", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  getAccountsAvailableForRemapping({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getAccountsAvailableForRemapping()
        .then(({ data }) => {
          commit("SET_ACCOUNTS_FOR_REMAPPING", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getGroupIdsWithErrors({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getGroupIdsWithErrors()
        .then(({ data }) => {
          commit("SET_GROUP_ID_WITH_ERRORS_LIST", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getGroupJobIdsWithErrors({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getGroupJobIdsWithErrors(options)
        .then(({ data }) => {
          commit("SET_JOB_IDS_FOR_GROUP", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getGroupIdsWithJobs({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      groupIdsWithJobs()
        .then(({ data }) => {
          commit("SET_GROUP_IDS_WITH_JOBS_LIST", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getMappedGroupIds({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getMappedGroupIds()
        .then(({ data }) => {
          commit("SET_MAPPED_GROUP_IDS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getJobIdsForGroup({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getJobIdsForGroup(options)
        .then(({ data }) => {
          commit("SET_JOB_ID_LIST_FOR_GROUP", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getDataStoreListForAccount({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getDataStoreListForAccount(options)
        .then(({ data }) => {
          commit("SET_DATA_STORE_LIST_FOR_ACCOUNT", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  unmapServiceProvider({ commit }, { s7_sp }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      unmapServiceProvider({ s7_sp: s7_sp })
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  unmapAccount({ commit }, { s7_ac }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      unmapAccount({ s7_ac: s7_ac })
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getUnusedAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_UNUSED_ACCOUNTS_LOADING", true);
      getUnusedAccounts()
        .then(({ data }) => {
          commit("SET_UNUSED_ACCOUNTS", data.data);
          commit("CHANGE_UNUSED_ACCOUNTS_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_UNUSED_ACCOUNTS_LOADING", false);
          reject(err);
        });
    });
  },

  deleteUnusedAccounts({ commit }, unusedAccountsToDelete) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_UNUSED_ACCOUNTS_LOADING", true);
      deleteUnusedAccounts(unusedAccountsToDelete)
        .then(({ data }) => {
          commit("CHANGE_UNUSED_ACCOUNTS_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_UNUSED_ACCOUNTS_LOADING", false);
          reject(err);
        });
    });
  },

  getFlaggedNodesForAccount({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_FLAGGED_NODES_LOADING", true);
      getFlaggedNodesForAccount(options)
        .then(({ data }) => {
          commit("SET_FLAGGED_NODES", data.data);
          commit("CHANGE_FLAGGED_NODES_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_FLAGGED_NODES_LOADING", false);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
