<template>
  <section>
    <!--    <transition name="fade-transform" mode="out-in">-->
    <router-view />
    <!--    </transition>-->
  </section>
</template>
<script>
export default {
  name: "AppMain"
};
</script>

<style lang="scss" scoped></style>
