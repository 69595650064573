import request, { ac_headers } from "@/utils/studio7ApiService";

export function getOpenFormTypes(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/form/open-form-types?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createOpenFormType(data) {
  const openFormtType = { ...data, guard_name: "admin" };
  return request({
    url: "/form/open-form-types",
    method: "post",
    data: openFormtType,
    headers: ac_headers()
  });
}

export function updateOpenFormType(openFormType) {
  return request({
    url: `/form/open-form-types/${openFormType.open_form_type_id}`,
    method: "put",
    data: openFormType,
    headers: ac_headers()
  });
}

export function deleteOpenFormType(openFormType) {
  return request({
    url: `/form/open-form-types/${openFormType.open_form_type_id}`,
    method: "delete",
    data: openFormType,
    headers: ac_headers()
  });
}

export function deleteSystemOpenFormType(openFormType) {
  return request({
    url: `/form/open-form-types/${openFormType.open_form_type_id}`,
    method: "delete",
    data: openFormType,
    headers: {
      scope: "ac",
      "scope-id": "all"
    }
  });
}

export function previewDialogflowResponse(openFormType) {
  return request({
    url: `/form/previewdialogflowresponse`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}

export function previewLexResponse(openFormType) {
  return request({
    url: `/form/previewlexresponse`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}

export function checkUniqueName(displayName) {
  return request({
    url: `/form/validate-oft-name/${displayName}`,
    method: "get",
    headers: ac_headers()
  });
}

export function publishOpenFormTypeAsSystem(data) {
  const openFormType = { ...data, guard_name: "admin" };
  return request({
    url: `/form/publish-system-open-form/${openFormType.open_form_type_id}`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}

export function previewWatsonResponse(openFormType) {
  return request({
    url: `/form/previewwatsonresponse`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}

export function previewDialogflowCXResponse(openFormType) {
  return request({
    url: `/form/previewdialogflowcxresponse`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}

export function previewAzureOpenaiResponse(openFormType) {
  return request({
    url: `/form/previewazureopenairesponse`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}

export function previewOpenaiResponse(openFormType) {
  return request({
    url: `/form/previewopenairesponse`,
    method: "post",
    data: openFormType,
    headers: ac_headers()
  });
}
