<template>
  <div class="menu-add-button">
    <el-button
      type="text"
      class="large"
      @click="$emit('handleAdd')"
      :dusk="duskSetting"
    >
      <i class="el-icon-plus"></i>
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    duskSetting: String
  }
};
</script>

<style>
.menu-add-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
</style>
