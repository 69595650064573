import request, { ac_headers } from "@/utils/studio7ApiService";

const baseURL = "/javascriptfunction";

/**
 * It returns a list of all the JavaScript functions that are available to be used in the current account
 * @param [options] - an object with the following keys:
 * @returns A list of javascript functions
 */
export function getJavascriptFunctions(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `${baseURL}/javascript-functions?${params.toString()}`,
    method: "get",
    headers: ac_headers()
  });
}

/**
 * It returns a list of all the JavaScript functions that are available to be used in the current account, but only with limited information about each function
 * @returns A list of javascript functions with limited information.
 */
export function getJavascriptFunctionsListLimitedInfo() {
  return request({
    url: `${baseURL}/get-javascript-functions-list`,
    method: "get",
    headers: ac_headers()
  });
}

export function createJavascriptFunction(data) {
  const jsFunc = { ...data, max_timeout: 5590 };
  return request({
    url: `${baseURL}/javascript-functions`,
    method: "post",
    data: jsFunc,
    headers: ac_headers()
  });
}

export function deleteJavascriptFunction(data) {
  return request({
    url: `${baseURL}/javascript-functions/${data.javascript_function_id}`,
    method: "delete",
    data: data,
    headers: ac_headers()
  });
}

export function updateJavascriptFunction(data) {
  return request({
    url: `${baseURL}/javascript-functions/${data.javascript_function_id}`,
    method: "put",
    data: data,
    headers: ac_headers()
  });
}

export function searchJavascriptFunction(name) {
  return request({
    url: `${baseURL}/validate`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}

export function generateCodeCompletions(data) {
  return request({
    url: `${baseURL}/context-autocomplete-function`,
    method: "post",
    data: data,
    headers: ac_headers()
  });
}
