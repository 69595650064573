import store from "@/store";
import __ from "@/translation";

export const cutNode = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/copyNode", {
      id,
      copyType: "cut_node"
    });
  }
};

export default {
  text: `${__("Cut Node")}<span>Ctrl+X</span>`,
  icon: "",
  onClick: cutNode
};
