import {
  createTTSVoice,
  deleteTTSVoice,
  getTTSVoices,
  syncTTSVoices,
  updateTTSVoice
} from "@/api/ttsVoices";
import _ from "lodash";

const getDistinctVoices = (ttsVoices, languages) => {
  const languagesFromDB = _.uniq(_.map(ttsVoices, "lang"));
  let newLanguages = [];
  _.map(languagesFromDB, languageFromDB => {
    const language = _.find(languages, { value: languageFromDB });
    if (!language) {
      newLanguages.push({ label: languageFromDB, value: languageFromDB });
    }
  });
  return newLanguages;
};

import __ from "@/translation";

export const state = {
  ttsVoices: [],
  isLoading: false,
  languages: [
    {
      label: __("US English"),
      value: "en-US"
    },
    {
      label: __("UK English"),
      value: "en-UK"
    }
  ],
  providers: [
    {
      label: __("Lumenvox"),
      value: "lumenvox"
    },
    {
      label: __("Google"),
      value: "google"
    },
    {
      label: __("IBM"),
      value: "ibm"
    }
  ]
};

export const getters = {
  getProviderLabel(state) {
    return provider => {
      return _.find(state.providers, { value: provider }).label;
    };
  },

  getLanguageLabel(state) {
    return language => {
      return _.find(state.languages, { value: language }).label;
    };
  },

  getTtsVoiceName(state) {
    return tts_voice_id => {
      return _.find(state.ttsVoices, { tts_voice_id: tts_voice_id }).voice_name;
    };
  },

  getTtsVoiceNameFromTree(state) {
    return tts_voice_id => {
      let voices = _.flatMapDeep(state.ttsVoices, "children");
      let voice = _.find(voices, {
        tts_voice_id: tts_voice_id
      });
      return voice.provider.toUpperCase() + " - " + voice.voice_name;
    };
  }
};

export const mutations = {
  SET_TTS_VOICES(state, ttsVoices) {
    state.ttsVoices = ttsVoices;
  },

  CREATE_TTS_VOICE(state, ttsVoice) {
    state.ttsVoices.push(ttsVoice);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  UPDATE_TTS_VOICE(state, { index, ttsVoice }) {
    state.ttsVoices.splice(index, 1, ttsVoice);
  },

  DELETE_TTS_VOICE(state, ttsVoice) {
    state.ttsVoices = state.ttsVoices.filter(
      oldTtsVoice => oldTtsVoice.tts_voice_id !== ttsVoice.tts_voice_id
    );
  },

  ADD_TO_LANGUAGES(state, languages) {
    state.languages = _.concat(state.languages, languages);
  }
};

export const actions = {
  getTTSVoices({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getTTSVoices(options)
        .then(async ({ data }) => {
          const newLanguages = await getDistinctVoices(
            data.data,
            state.languages
          );
          commit("ADD_TO_LANGUAGES", newLanguages);
          commit("SET_TTS_VOICES", data.data);

          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  getTTSVoicesTree({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getTTSVoices(options)
        .then(async ({ data }) => {
          commit("SET_TTS_VOICES", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  createTTSVoice({ commit }, ttsVoice) {
    return new Promise((resolve, reject) => {
      // mock data which needs to be replaced
      ttsVoice.display_order = 99;
      commit("UPDATE_LOADING_STATUS", true);
      createTTSVoice(ttsVoice)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  updateTTSVoice({ commit }, ttsVoice) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      updateTTSVoice(ttsVoice)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  deleteTTSVoice({ commit }, ttsVoice) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteTTSVoice(ttsVoice)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  syncTTSVoices({ commit }, provider) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      syncTTSVoices(provider)
        .then(async ({ data }) => {
          const newLanguages = await getDistinctVoices(data, state.languages);
          commit("ADD_TO_LANGUAGES", newLanguages);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  undoDeleteTTsVoice({ commit }, ttsVoice) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      ttsVoice.is_enabled = 1;
      updateTTSVoice(ttsVoice)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
