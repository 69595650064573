import request, { ac_headers } from "@/utils/studio7ApiService";

export function getXsiProfiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/broadworks/xsi-profiles?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createXsiProfile(xsiProfile) {
  return request({
    url: "/broadworks/xsi-profiles",
    method: "post",
    headers: ac_headers(),
    data: xsiProfile
  });
}

export function updateXsiProfile(xsiProfile) {
  return request({
    url: `/broadworks/xsi-profiles/${xsiProfile.xsi_profile_id}`,
    method: "put",
    headers: ac_headers(),
    data: xsiProfile
  });
}

export function deleteXsiProfile(xsiProfile) {
  return request({
    url: `/broadworks/xsi-profiles/${xsiProfile.xsi_profile_id}`,
    method: "delete",
    headers: ac_headers(),
    data: xsiProfile
  });
}
