<template>
  <!-- generatePageKey can be scoped to specific pages instead of immediately 
    rerending the page you are currently in,
    when developers are educated on when should the page refreshed
    ie, whether its on service provider switch or account switch
    as done in the manage users index page, the page should only be re rendered when the service provider
    selection changes -->
  <!-- <div :key="generatePageKey" v-if="userApplicationViewScope" class="user-view"> -->
  <div v-if="userApplicationViewScope" class="user-view">
    <el-row type="flex" style="width: 100%">
      <!--      <transition name="slide-fade" mode="out-in">-->
      <el-col
        :lg="3"
        :xl="3"
        v-if="!isInCanvas"
        class="hidden-md-and-down"
        style="max-width: 100%;min-width: 280px;width: 280px; border-right: #f5f5f8 1px solid;"
      >
        <side-bar />
      </el-col>
      <!--      </transition>-->
      <div class="hidden-lg-and-up" v-if="!isInCanvas" style="min-width: 48px">
        <el-button
          @click="drawer = true"
          plain
          style="border: none;padding: 5px;font-size: 1.3em"
        >
          <img
            :src="require('@/assets/icons/icon-hamburger.svg')"
            width="90%"
          />
        </el-button>
        <el-drawer
          custom-class="sidebar-drawer"
          :visible.sync="drawer"
          direction="ltr"
          :show-close="false"
          :modal="false"
          size="280px"
        >
          <side-bar style="width: 100%;" />
        </el-drawer>
      </div>
      <el-col>
        <el-scrollbar
          class="content-area-scroll"
          :class="{ modifyWidth }"
          :native="false"
        >
          <app-main class="app-contents" />
        </el-scrollbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { AppMain, SideBar } from "./components";
import { mapState } from "vuex";
export default {
  name: "LayoutIndex",
  components: {
    AppMain,
    SideBar
  },
  data() {
    return {
      drawer: false,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userApplicationViewScope: state => state.userApplicationViewScope,
      isInCanvas: state => state.isInCanvas
    }),

    modifyWidth() {
      if (this.isInCanvas) {
        return false;
      }
      return this.windowWidth >= 1200;
    }

    // generatePageKey() {
    //   return (
    //     String(this.selectedServiceProviderId) +
    //     "-" +
    //     String(this.selectedAccountId)
    //   );
    // }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    contentColumns(subtract) {
      return this.isInCanvas ? 24 : 24 - subtract;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
.modifyWidth {
  width: calc(100vw - 280px);
}
.user-view {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: flex;
}

.app-contents {
  height: 100vh;
  /*max-width: 100%;*/
}

.content-area-scroll {
  /*flex: 1;*/
  /*max-width: 100%;*/
  height: 100vh;

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0 !important;

    ::v-deep .el-scrollbar__view {
      height: 100%;
    }
  }
}

::v-deep .sidebar-drawer {
  & header {
    display: none !important;
  }
}
</style>

<style lang="scss">
.component-container {
  height: 98vh;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 2vh;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
