import request from "@/utils/studio7ApiService";

export function getBusinessPlans(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/business-plans?" + params.toString(),
    method: "get"
  });
}

export function createBusinessPlan(businessPlan) {
  return request({
    url: "/billing/business-plans",
    method: "post",
    data: businessPlan
  });
}

export function updateBusinessPlan(businessPlan) {
  return request({
    url: `/billing/business-plans/${businessPlan.bp_id}`,
    method: "put",
    data: businessPlan
  });
}

export function deleteBusinessPlan(businessPlan) {
  return request({
    url: `/billing/business-plans/${businessPlan.bp_id}`,
    method: "delete",
    data: businessPlan
  });
}

export function checkBusinessPlanChanges(businessPlan) {
  return request({
    url: `/billing/check-business-plan-changes/${businessPlan.bp_id}`,
    method: "post",
    data: businessPlan
  });
}

export function getAvailableBasePlans(data) {
  return request({
    url: `/billing/get-available-base-plans`,
    method: "post",
    data: data
  });
}

export function comparePlans(data) {
  return request({
    url: `/billing/compare-plans`,
    method: "post",
    data: data
  });
}

export function getTree(data) {
  return request({
    url: `/billing/get-plans-tree/${data}`,
    method: "get"
  });
}
