import request, { ac_headers } from "@/utils/studio7ApiService";

export function getCampaigns(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/campaign/campaigns?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getCampaign(campaign_id) {
  return request({
    url: `/campaign/campaigns/${campaign_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function createCampaign(data) {
  const campaign = { ...data };
  return request({
    url: "/campaign/campaigns",
    method: "post",
    data: campaign,
    headers: ac_headers()
  });
}

export function updateCampaign(campaign) {
  return request({
    url: `/campaign/campaigns/${campaign.campaign_id}`,
    method: "put",
    data: campaign,
    headers: ac_headers()
  });
}

export function deleteCampaign(campaign) {
  return request({
    url: `/campaign/campaigns/${campaign.campaign_id}`,
    method: "delete",
    data: campaign,
    headers: ac_headers()
  });
}

export function validateCampaign(type, name) {
  let obj = { campaign_type: type, campaign_name: name };
  return request({
    url: `/campaign/campaigns/validate`,
    method: "post",
    data: obj,
    headers: ac_headers()
  });
}

export function getCampaignData(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/campaign-data/campaign-data?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getListBasedCampaignTemplate(campaign) {
  return request({
    url: `/campaign/${campaign.campaign_id}/template`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function getListBasedCampaignUploadedData(campaign) {
  return request({
    url: `/campaign/${campaign.campaign_id}/download`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function getPostmanCollection(campaign) {
  return request({
    url: `/campaign/${campaign.campaign_id}/collection`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function uploadCampaignCallList(campaign, formData) {
  return request({
    url: `campaign/${campaign.campaign_id}/upload`,
    method: "post",
    headers: ac_headers(),
    data: formData
  });
}

export function makeACall(campaign) {
  return request({
    url: `/campaign/make_a_call/${campaign.campaign_id}`,
    method: "post",
    data: campaign,
    headers: ac_headers()
  });
}

export function triggerAction(campaign, action) {
  return request({
    url: `campaign/${campaign.campaign_id}/trigger`,
    method: "post",
    data: { action },
    headers: ac_headers()
  });
}

export function getAcMaxThreads() {
  return request({
    url: `campaign/max-threads`,
    method: "get",
    headers: ac_headers()
  });
}
