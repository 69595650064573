import request from "@/utils/studio7ApiService";

export function getRoles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/auth/roles?" + params.toString(),
    method: "get"
  });
}

export function createRole(data) {
  const role = { ...data, guard_name: "admin" };
  return request({
    url: "/auth/roles",
    method: "post",
    data: role
  });
}

export function updateRole(role) {
  return request({
    url: `/auth/roles/${role.id}`,
    method: "put",
    data: role
  });
}

export function deleteRole(role) {
  return request({
    url: `/auth/roles/${role.id}`,
    method: "delete"
  });
}

export function checkUniqueName(data) {
  return request({
    url: `/auth/roles/validate-role-name`,
    method: "post",
    data: data
  });
}
