import InsertNodeBelow from "../nodeActions/InsertNodeBelow";
import InsertNodeAbove from "../nodeActions/InsertNodeAbove";
import DeleteNode from "../nodeActions/DeleteNode";
import DeleteBranch from "../nodeActions/DeleteBranch";
import UnlinkNode from "@/utils/nodeActions/UnlinkNode";
import UnlinkBranch from "@/utils/nodeActions/UnlinkBranch";
import CopyNode from "@/utils/nodeActions/CopyNode";
import PasteNode from "@/utils/nodeActions/PasteNode";
import PasteBranch from "@/utils/nodeActions/PasteBranch";
import CopyBranch from "@/utils/nodeActions/CopyBranch";
import CloneBranch from "@/utils/nodeActions/CloneBranch";
import CutNode from "@/utils/nodeActions/CutNode";
import CutBranch from "@/utils/nodeActions/CutBranch";
import GotoNode from "@/utils/nodeActions/GotoNode";
import GoBack from "@/utils/nodeActions/GoBack";
import LinkTask from "@/utils/nodeActions/LinkTask";
import CopyNodeLink from "@/utils/nodeActions/CopyNodeLink";
// import GoBackTask from "@/utils/nodeActions/GoBackTask";

let menus = {
  node: {
    nodeMenu: {
      CopyNodeLink
    }
  },
  system: {
    nodeMenu: {
      InsertNodeBelow,
      CopyNodeLink
    }
  },
  system_paste_node: {
    nodeMenu: {
      InsertNodeBelow,
      PasteNode,
      CopyNodeLink
    }
  },
  system_paste_branch: {
    nodeMenu: {
      InsertNodeBelow,
      PasteBranch,
      CopyNodeLink
    }
  },
  system_root_paste_node: {
    nodeMenu: {
      InsertNodeBelow,
      PasteNode,
      CopyNodeLink
    }
  },
  system_root_paste_branch: {
    nodeMenu: {
      InsertNodeBelow,
      PasteBranch,
      CopyNodeLink
    }
  },
  system_root_leaf_paste_node: {
    nodeMenu: {
      InsertNodeBelow,
      PasteNode,
      CopyNodeLink
    }
  },
  system_root_leaf_paste_branch: {
    nodeMenu: {
      InsertNodeBelow,
      PasteBranch,
      CopyNodeLink
    }
  },
  one_child: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      CopyNodeLink
    }
  },
  one_child_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteNode,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      CopyNodeLink
    }
  },
  one_child_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteBranch,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      CopyNodeLink
    }
  },
  one_child_root: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      DeleteNode,
      DeleteBranch,
      CopyNodeLink
    }
  },
  one_child_root_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteNode,
      DeleteNode,
      DeleteBranch,
      CopyNodeLink
    }
  },
  one_child_root_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteBranch,
      DeleteNode,
      DeleteBranch,
      CopyNodeLink
    }
  },
  one_child_root_leaf: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  one_child_root_leaf_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  one_child_root_leaf_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteBranch,
      DeleteNode,
      CopyNodeLink
    }
  },
  one_child_leaf: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  one_child_leaf_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteNode,
      UnlinkNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  one_child_leaf_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteBranch,
      UnlinkNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  one_child_link_task: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_link_task: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      DeleteNode,
      DeleteBranch,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_leaf_link_task: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      DeleteNode,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_leaf_link_task: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_link_task_protected: {
    nodeMenu: {
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_leaf_link_task_protected: {
    nodeMenu: {
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_leaf_link_task_protected: {
    nodeMenu: {
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_link_task_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteNode,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_link_task_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteBranch,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_link_task_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteNode,
      DeleteNode,
      DeleteBranch,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_link_task_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteBranch,
      DeleteNode,
      DeleteBranch,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_leaf_link_task_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteNode,
      DeleteNode,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_root_leaf_link_task_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteBranch,
      DeleteNode,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_leaf_link_task_paste_node: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteNode,
      UnlinkNode,
      DeleteNode,
      LinkTask,
      CopyNodeLink
    }
  },
  one_child_leaf_link_task_paste_branch: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteBranch,
      UnlinkNode,
      DeleteNode,
      LinkTask,
      CopyNodeLink
    }
  },
  no_child: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  no_child_root_leaf: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      DeleteNode,
      CopyNodeLink
    }
  },
  no_child_goto: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      GotoNode,
      CopyNodeLink
    }
  },
  no_child_goto_protected: {
    nodeMenu: {
      GotoNode,
      CopyNodeLink
    }
  },
  no_child_root_leaf_goto: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      DeleteNode,
      GotoNode,
      CopyNodeLink
    }
  },
  no_child_root_leaf_goto_protected: {
    nodeMenu: {
      GotoNode,
      CopyNodeLink
    }
  },
  multiple_child: {
    nodeMenu: {
      InsertNodeAbove,
      CopyBranch,
      CutBranch,
      CloneBranch,
      UnlinkBranch,
      DeleteBranch,
      CopyNodeLink
    }
  },
  multiple_child_root: {
    nodeMenu: {
      InsertNodeAbove,
      CopyBranch,
      CutBranch,
      CloneBranch,
      DeleteBranch,
      CopyNodeLink
    }
  },
  same_node: {
    nodeMenu: {
      InsertNodeAbove,
      CopyBranch,
      CutBranch,
      CloneBranch,
      UnlinkBranch,
      DeleteBranch,
      CopyNodeLink
    }
  },

  one_child_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  // one_child_paste_node_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  // one_child_paste_branch_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  one_child_root_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  // one_child_root_paste_node_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  // one_child_root_paste_branch_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  one_child_root_leaf_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  // one_child_root_leaf_paste_node_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  // one_child_root_leaf_paste_branch_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  one_child_leaf_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  // one_child_leaf_paste_node_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  // one_child_leaf_paste_branch_goback_protected: {
  //   nodeMenu: {
  //     GoBack
  //   }
  // },
  no_child_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  no_child_root_leaf_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  no_child_goto_goback_protected: {
    nodeMenu: {
      GotoNode,
      GoBack,
      CopyNodeLink
    }
  },
  no_child_root_leaf_goto_goback_protected: {
    nodeMenu: {
      GotoNode,
      GoBack,
      CopyNodeLink
    }
  },
  multiple_child_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  multiple_child_root_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },
  same_node_goback_protected: {
    nodeMenu: {
      GoBack,
      CopyNodeLink
    }
  },

  one_child_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_paste_node_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteNode,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_paste_branch_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteBranch,
      UnlinkNode,
      UnlinkBranch,
      DeleteNode,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_root_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      DeleteNode,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_root_paste_node_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteNode,
      DeleteNode,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_root_paste_branch_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CopyBranch,
      CutNode,
      CutBranch,
      CloneBranch,
      PasteBranch,
      DeleteNode,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_root_leaf_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_root_leaf_paste_node_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_root_leaf_paste_branch_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteBranch,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_leaf_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_leaf_paste_node_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteNode,
      UnlinkNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  one_child_leaf_paste_branch_goback: {
    nodeMenu: {
      InsertNodeAbove,
      InsertNodeBelow,
      CopyNode,
      CutNode,
      PasteBranch,
      UnlinkNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  no_child_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  no_child_root_leaf_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      DeleteNode,
      GoBack,
      CopyNodeLink
    }
  },
  no_child_goto_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      UnlinkNode,
      DeleteNode,
      GotoNode,
      GoBack,
      CopyNodeLink
    }
  },
  no_child_root_leaf_goto_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyNode,
      CutNode,
      DeleteNode,
      GotoNode,
      GoBack,
      CopyNodeLink
    }
  },
  multiple_child_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyBranch,
      CutBranch,
      CloneBranch,
      UnlinkBranch,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  multiple_child_root_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyBranch,
      CutBranch,
      CloneBranch,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },
  same_node_goback: {
    nodeMenu: {
      InsertNodeAbove,
      CopyBranch,
      CutBranch,
      CloneBranch,
      UnlinkBranch,
      DeleteBranch,
      GoBack,
      CopyNodeLink
    }
  },

  condition_label: {
    template: "condition_label"
  },
  start_node: {
    template: "start_node",
    nodeMenu: {
      InsertNodeBelow,
      CopyNodeLink
    }
  },
  machine_detection: {
    template: "start_node",
    nodeMenu: {
      InsertNodeBelow,
      CopyNodeLink
    }
  },
  beep_detection: {
    template: "start_node",
    nodeMenu: {
      InsertNodeBelow,
      CopyNodeLink
    }
  },
  disconnect: {
    template: "start_node",
    nodeMenu: {
      InsertNodeBelow,
      CopyNodeLink
    }
  },
  protected: {
    nodeMenu: {
      CopyNodeLink
    }
  }
};

export default menus;
