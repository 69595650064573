import Vue from "vue";
import _ from "lodash";
import { showFeature } from "@/api/launchDarkly";
import store from "@/store";

export const checkFeature = (featureName, ac_id, sp_id, domain_id) => {
  let user = Vue.auth.user();
  const payload = {
    key: user.user_id,
    email: user.email,
    name: user.display_name,
    feature: featureName,
    custom: {
      ac_id: ac_id || store.getters.selectedAccountId,
      sp_id: sp_id || store.getters.selectedServiceProviderId,
      domain_id:
        domain_id || _.get(store.getters.selectedAccountDomain, "domain_id")
    }
  };
  return new Promise(resolve => {
    showFeature(payload)
      .then(response => {
        if (response.data.message === "success") {
          resolve(response.data.flag_state);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        console.error(error);
        resolve(false);
      });
  });
};
