import request, { ac_headers } from "@/utils/studio7ApiService";

export function getReports(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/reporting/reports?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createReport(data) {
  const report = { ...data, guard_name: "admin" };
  return request({
    url: "/reporting/reports",
    method: "post",
    data: report,
    headers: ac_headers()
  });
}

export function updateReport(report) {
  return request({
    url: `/reporting/reports/${report.report_id}`,
    method: "put",
    data: report,
    headers: ac_headers()
  });
}

export function duplicateReportTemplate(report_id) {
  return request({
    url: `/reporting/reports/duplicate/${report_id}`,
    method: "post",
    headers: ac_headers()
  });
}

export function deleteReport(report) {
  return request({
    url: `/reporting/reports/${report.report_id}`,
    method: "delete",
    data: report,
    headers: ac_headers()
  });
}

export function getTableColumns(data) {
  return request({
    url: "/reporting/table-columns",
    method: "post",
    data: data,
    headers: ac_headers()
  });
}

export function generateChartData(chartOptions) {
  return request({
    url: "/reporting/generate-chart-data",
    method: "post",
    data: chartOptions,
    headers: ac_headers()
  });
}

export function validateReportTemplateName(report_name) {
  return request({
    url: `/reporting/validate-report-template-name`,
    method: "post",
    data: { report_name },
    headers: ac_headers()
  });
}

export function createReportInstance(instance) {
  return request({
    url: `/reporting/instances/create`,
    method: "post",
    data: instance,
    headers: ac_headers()
  });
}

export function reRunReportInstance(instance) {
  return request({
    url: `/reporting/instances/re-run/${instance.report_instance_id}`,
    method: "put",
    data: instance,
    headers: ac_headers()
  });
}

export function getReportInstancesForAccount(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/reporting/instances?` + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getChartOptionsForReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}/chart-options`,
    method: "get",
    headers: ac_headers()
  });
}

export function getChartTableOptionsForReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}/chart-table-options`,
    method: "get",
    headers: ac_headers()
  });
}

export function getChartImageForReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}/chart-image`,
    method: "get",
    headers: ac_headers(),
    responseType: "blob" // important
  });
}

export function getChartDataForReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}/instance-data`,
    method: "get",
    headers: ac_headers(),
    responseType: "blob" // important
  });
}

export function getChartTableDataForReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}/instance-table-data`,
    method: "get",
    headers: ac_headers(),
    responseType: "blob" // important
  });
}
export function getRawChartDataForReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}/raw-data`,
    method: "get",
    headers: ac_headers(),
    responseType: "blob" // important
  });
}

export function removeReportInstance(reportInstanceId) {
  return request({
    url: `/reporting/instances/${reportInstanceId}`,
    method: "delete",
    headers: ac_headers()
  });
}

export function getAcIdForJob(jobId) {
  return request({
    url: `/reporting/instances/job/${jobId}`,
    method: "get"
  });
}

export function validateReportInstanceName(report_instance_name) {
  return request({
    url: `/reporting/instances/validate-report-instance-name`,
    method: "post",
    data: { report_instance_name },
    headers: ac_headers()
  });
}
