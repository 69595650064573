import {
  getFiles,
  createFile,
  updateFile,
  deleteFile,
  downloadFile
} from "@/api/files";
import _ from "lodash";

export const state = {
  files: [],
  isLoading: false
};

export const mutations = {
  SET_FILES(state, files) {
    state.files = files;
  },

  CREATE_FILE(state, file) {
    state.files.push(file);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  UPDATE_FILE(state, { index, file }) {
    state.files.splice(index, 1, file);
  },

  DELETE_FILE(state, file) {
    state.files = state.files.filter(
      oldFile => oldFile.file_id !== file.file_id
    );
  }
};

export const actions = {
  getFiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getFiles(options)
        .then(async ({ data }) => {
          commit("SET_FILES", data.data);

          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createFile({ commit }, file) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createFile(file)
        .then(async ({ data }) => {
          commit("CREATE_FILE", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  updateFile({ commit, state }, file) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      updateFile(file)
        .then(({ data }) => {
          var index = _.findIndex(state.files, oldFile => {
            return oldFile.file_id === file.file_id;
          });
          commit("UPDATE_FILE", { index, file: data.data });
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  safeDeleteFile({ commit, state }, file) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      file.is_archived = 1;
      updateFile(file)
        .then(({ data }) => {
          var index = _.findIndex(state.files, oldFile => {
            return oldFile.file_id === file.file_id;
          });
          commit("UPDATE_FILE", { index, file: data.data });
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteFile({ commit }, file) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      deleteFile(file)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          commit("DELETE_FILE", file);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteFile({ commit }, file) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      file.is_enabled = 1;
      updateFile(file)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  downloadFile({ commit }, file) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      downloadFile(file)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  refreshFile({ commit, getters }, { content_id, refreshing }) {
    let files = _.map(getters.files, file => {
      if (file.file_id === content_id) {
        file.is_refreshing = refreshing;
      }
      return file;
    });
    commit("SET_FILES", files);
  }
};

export const getters = {
  files(state) {
    return _.cloneDeep(state.files);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
