export const resetStateColors = data => {
  let bodyStyles = document.querySelector("html").style;
  bodyStyles.setProperty("--theme-hover-color", data.hover_color);
  bodyStyles.setProperty("--theme-outline-color", data.outline_color);
  bodyStyles.setProperty("--theme-row-hover-color", data.row_hover_color);
  let newCluster = getThemeCluster(data.theme_color.replace("#", ""));
  bodyStyles.setProperty("--theme-hover-background-color", newCluster[10]);
  bodyStyles.setProperty("--theme-tag-border-color", newCluster[9]);
  bodyStyles.setProperty("--theme-hover-font-color", newCluster[3]);
  //following 2 are used in content-palette.scss
  bodyStyles.setProperty("--customform-content-light-5", newCluster[5]);
  bodyStyles.setProperty("--color-primary-light-5", newCluster[5]);
  bodyStyles.setProperty(
    "--integration-content-dark",
    computeShade(-0.1, data.theme_color)
  );
  bodyStyles.setProperty(
    "--color-primary-dark",
    //getLighterShade(data.theme_color, -0.1)
    computeShade(-0.1, data.theme_color)
  );
  bodyStyles.setProperty(
    "--theme-color-light-10p",
    computeShade(0.1, data.theme_color)
  );
  bodyStyles.setProperty(
    "--theme-color-light-40p",
    computeShade(0.4, data.theme_color)
  );
};

export const getThemeCluster = theme => {
  const tintColor = (color, tint) => {
    let red = parseInt(color.slice(0, 2), 16);
    let green = parseInt(color.slice(2, 4), 16);
    let blue = parseInt(color.slice(4, 6), 16);

    if (tint === 0) {
      return [red, green, blue].join(",");
    } else {
      red += Math.round(tint * (255 - red));
      green += Math.round(tint * (255 - green));
      blue += Math.round(tint * (255 - blue));

      red = red.toString(16);
      green = green.toString(16);
      blue = blue.toString(16);

      return `#${red}${green}${blue}`;
    }
  };

  const shadeColor = (color, shade) => {
    // eslint-disable-next-line no-unused-vars
    let red = parseInt(color.slice(0, 2), 16);
    // eslint-disable-next-line no-unused-vars
    let green = parseInt(color.slice(2, 4), 16);
    // eslint-disable-next-line no-unused-vars
    let blue = parseInt(color.slice(4, 6), 16);

    red = Math.round((1 - shade) * red);
    green = Math.round((1 - shade) * green);
    blue = Math.round((1 - shade) * blue);

    red = red.toString(16);
    green = green.toString(16);
    blue = blue.toString(16);

    return `#${red}${green}${blue}`;
  };

  const clusters = [theme];
  for (let i = 0; i <= 9; i++) {
    clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
  }
  clusters.push(shadeColor(theme, 0.1));
  return clusters;
};

export const computeShade = (p, c0, c1, l) => {
  const pSBC = (p, c0, c1, l) => {
    let r,
      g,
      b,
      P,
      f,
      t,
      h,
      m = Math.round,
      a = typeof c1 == "string";
    if (
      typeof p != "number" ||
      p < -1 ||
      p > 1 ||
      typeof c0 != "string" ||
      (c0[0] !== "r" && c0[0] !== "#") ||
      (c1 && !a)
    )
      return null;
    (h = c0.length > 9),
      (h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h),
      (f = pSBC.pSBCr(c0)),
      (P = p < 0),
      (t =
        c1 && c1 !== "c"
          ? pSBC.pSBCr(c1)
          : P
          ? { r: 0, g: 0, b: 0, a: -1 }
          : { r: 255, g: 255, b: 255, a: -1 }),
      (p = P ? p * -1 : p),
      (P = 1 - p);
    if (!f || !t) return null;
    if (l)
      (r = m(P * f.r + p * t.r)),
        (g = m(P * f.g + p * t.g)),
        (b = m(P * f.b + p * t.b));
    else
      (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
        (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
        (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
    (a = f.a),
      (t = t.a),
      (f = a >= 0 || t >= 0),
      (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
    if (h)
      return (
        "rgb" +
        (f ? "a(" : "(") +
        r +
        "," +
        g +
        "," +
        b +
        (f ? "," + m(a * 1000) / 1000 : "") +
        ")"
      );
    else
      return (
        "#" +
        (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
          .toString(16)
          .slice(1, f ? undefined : -2)
      );
  };

  pSBC.pSBCr = d => {
    const i = parseInt,
      m = Math.round;
    let n = d.length,
      x = {};
    if (n > 9) {
      const [r, g, b, a] = (d = d.split(","));
      n = d.length;
      if (n < 3 || n > 4) return null;
      (x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4))),
        (x.g = i(g)),
        (x.b = i(b)),
        (x.a = a ? parseFloat(a) : -1);
    } else {
      if (n === 8 || n === 6 || n < 4) return null;
      if (n < 6)
        d =
          "#" +
          d[1] +
          d[1] +
          d[2] +
          d[2] +
          d[3] +
          d[3] +
          (n > 4 ? d[4] + d[4] : "");
      d = i(d.slice(1), 16);
      if (n === 9 || n === 5)
        (x.r = (d >> 24) & 255),
          (x.g = (d >> 16) & 255),
          (x.b = (d >> 8) & 255),
          (x.a = m((d & 255) / 0.255) / 1000);
      else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
    }
    return x;
  };

  return pSBC(p, c0, c1, l);
};

/*function getLighterShade(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}*/
