import request, { ac_headers } from "@/utils/studio7ApiService";

export function getProfiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/node-logging/profiles?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getTasksForProfile({ profile_id, profile_type }) {
  const params = new URLSearchParams({ profile_id, profile_type });
  return request({
    url: "/node-logging/profiles/get-tasks?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function unmapTaskFromProfile({ profile_id, profile_type, task_id }) {
  return request({
    url: "/node-logging/profiles/remove-task",
    method: "post",
    data: { profile_id, profile_type, task_id },
    headers: ac_headers()
  });
}

export function createNodeLoggingProfile(profile) {
  return request({
    url: `/node-logging/profiles`,
    method: "post",
    data: profile,
    headers: ac_headers()
  });
}

export function updateNodeLoggingProfile(profile) {
  return request({
    url: `/node-logging/profiles`,
    method: "put",
    data: profile,
    headers: ac_headers()
  });
}

export function deleteNodeLoggingProfile(profile) {
  return request({
    url: `/node-logging/profiles`,
    method: "delete",
    data: {
      profile_type: profile.profile_type,
      profile_id: profile.profile_id
    },
    headers: ac_headers()
  });
}

export function updateProfile(profile) {
  return request({
    url: `/node-logging/profiles`,
    method: "put",
    data: profile,
    headers: ac_headers()
  });
}

export function testFirebaseConnection({ db_url, credentials_file_path }) {
  return request({
    url: `/node-logging/profiles/firebase/test-connection`,
    method: "post",
    data: { db_url, credentials_file_path },
    headers: ac_headers()
  });
}

export function checkProfileNameExists(name, profile_type) {
  return request({
    url: `/node-logging/validate-name`,
    method: "post",
    data: { name, profile_type },
    headers: ac_headers()
  });
}
