import _ from "lodash";

//Parse URL Hash params into Key:Value Array
export const parseParams = function(hash) {
  // remove hash char occurrence at the start of the hash string if its present
  hash = hash.indexOf("#") == 0 ? hash.substring(1) : hash;
  let pieces = hash.split("&"),
    data = {},
    i,
    parts;
  // process each query pair
  for (i = 0; i < pieces.length; i++) {
    parts = pieces[i].split("=");
    if (parts.length < 2) {
      parts.push("");
    }
    data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
  }
  data["state"] = data["state"] || "";
  try {
    let state = JSON.parse(data["state"]);
    let redirect = _.get(state, "redirect", "");
    if (redirect) {
      data["redirect"] = redirect;
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return data;
};

export const prettifyJsonPath = (
  jsonPath,
  removeDataProp = true,
  rootTag = "response"
) => {
  let regex;
  let dollarSignRegex;
  if (removeDataProp && /^root\['ws_response_data']/.test(jsonPath)) {
    regex = new RegExp("^root\\['ws_response_data']", "g");
    dollarSignRegex = new RegExp("\\$\\['ws_response_data']", "g");
  } else if (/^root\['ws_response_meta']/.test(jsonPath)) {
    regex = new RegExp("^root\\['ws_response_meta']", "g");
    dollarSignRegex = new RegExp("\\$\\['ws_response_meta']", "g");
  } else {
    regex = new RegExp("^root", "g");
    dollarSignRegex = new RegExp("\\$", "g");
  }
  let path = jsonPath.replace(regex, rootTag);
  path = path.replace(dollarSignRegex, "$");
  return path;
};

// export const escapeRegExp = string =>
//   string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
//
export const getFakeUrlObj = relativeURL =>
  new URL(relativeURL, "http://localhost:8000");

export const getQueryParams = relativeURL => {
  const url = getFakeUrlObj(relativeURL);
  return url.searchParams;
};
