import { getAccountTtsVoices } from "@/api/accounts";
import _ from "lodash";

export const state = {
  accountTtsVoices: [],
  isLoading: false
};

export const getters = {
  /**
   * Get tts voice based on the tts_voice_id
   * @param state
   * @returns {object}
   */
  getAccountTtsVoice(state) {
    return tts_voice_id => {
      let tts_voice = _.find(state.accountTtsVoices, tts_voice => {
        return tts_voice.tts_voice_id === tts_voice_id;
      });
      return tts_voice ? tts_voice : [];
    };
  },
  /**
   * Get tts voice name based on the tts_voice_id
   * @param state
   * @param getters
   * @returns {function(*=): *|string}
   */
  getAccountTtsVoiceName(state, getters) {
    return tts_voice_id => {
      let tts_voice = getters.getAccountTtsVoice(tts_voice_id);
      return !_.isEmpty(tts_voice) ? tts_voice.voice_name : "";
    };
  },
  /**
   * Get tts voice provider name based on the tts_voice_id
   * @param state
   * @param getters
   * @returns {function(*=): *|string}
   */
  getAccountTtsVoiceProvider(state, getters) {
    return tts_voice_id => {
      let tts_voice = getters.getAccountTtsVoice(tts_voice_id);
      return !_.isEmpty(tts_voice) ? tts_voice.provider : "";
    };
  }
};

export const mutations = {
  SET_ACCOUNT_TTS_VOICES(state, accountTtsVoices) {
    state.accountTtsVoices = accountTtsVoices;
  },
  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

export const actions = {
  /**
   * get selected account accessible tts voices
   * @param commit
   * @param account_id
   * @returns {Promise<unknown>}
   */
  getAccountTtsVoices({ commit }, account_id) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      if (account_id !== "all") {
        getAccountTtsVoices()
          .then(({ data }) => {
            commit("SET_ACCOUNT_TTS_VOICES", data.data);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      } else {
        commit("SET_ACCOUNT_TTS_VOICES", []);
        commit("UPDATE_LOADING_STATUS", false);
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
