import Vue from "vue";
import _ from "lodash";

/**
 * Check if the currently authenticated user is managed by Five9 admin console
 * @returns { bool }
 */
export function currentUserIsManaged() {
  const authenticatedUser = Vue.auth.user();
  return !_.isEmpty(_.get(authenticatedUser, "userUID"));
}
