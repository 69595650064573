<template>
  <el-container
    class="h-full"
    v-if="selectedAccountId && selectedAccountId !== 'all'"
  >
    <el-scrollbar :native="true" class="folder-sidebar-scrollbar">
      <folders-sidebar
        :navItems="navItems"
        ref="foldersSidebar"
      ></folders-sidebar>
    </el-scrollbar>

    <div style="width: 100%">
      <page-header
        class="absolute"
        style="padding: 20px 30px; width: calc(100% - 665px)"
        :title="contentTitle"
        :title-icon="contentTitleIcon"
        :navItems="navItems"
        @navItemChanged="handleNavItemChanged"
        :key="componentKey"
        :loading-state="loadingTabs"
      ></page-header>
      <component
        :element-loading-text="__('checking usages') + '...'"
        v-loading="!checkInUseDialog && checkInUseLoading"
        v-bind:is="component"
      ></component>
    </div>

    <el-dialog
      :title="__('Move to folder')"
      :visible.sync="moveToFolderDialog"
      width="30%"
      append-to-body
    >
      <el-select
        v-model="selected_folder_id"
        placeholder="Select"
        default-first-option
      >
        <el-option :value="0" :label="__('Main')"></el-option>
        <el-option
          v-for="folder in folders"
          :key="folder.folder_id"
          :label="folder.folder_name"
          :value="folder.folder_id"
          style="padding-left: 35px;"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moveToFolderDialog = false">{{
          __("Cancel")
        }}</el-button>
        <el-button type="primary" @click="moveToFolder">{{
          __("Confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="__('Check in use')"
      :visible.sync="checkInUseDialog"
      width="50%"
      class="check-in-use-dialog"
      append-to-body
    >
      <div v-loading="checkInUseLoading">
        <el-tree
          v-if="contentUsages.length"
          :data="contentUsages"
          :props="defaultProps"
          accordion
          style="margin-top: 15px; margin-bottom: 22px;"
        >
        </el-tree>
        <div v-else-if="!checkInUseLoading && !checkInUseErrorMessage">
          {{ __("This item is not currently in use.") }}
        </div>
        <div v-else>
          {{ checkInUseErrorMessage }}
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDeleteConfirmationDialog"
      width="50%"
      class="check-in-use-dialog"
      append-to-body
    >
      <div v-if="avoidDeletingContent">
        <span>
          <!-- eslint-disable-next-line -->
          {{ __("Sorry this :currentContentModel couldn't be deleted since it is used inside the tasks or other content items", {
                currentContentModel: currentContentModel
              }
            )
          }}
        </span>
        <el-tree
          :data="contentUsages"
          :props="defaultProps"
          accordion
          style="margin-top: 15px; margin-bottom: 22px;"
        >
        </el-tree>
      </div>
      <div v-if="!avoidDeletingContent">
        <span>
          <!-- eslint-disable-next-line -->
          {{ __("This content is getting used in some places. Deleting this content might break the associated tasks.") }}
        </span>
        <el-tree
          :data="contentUsages"
          :props="defaultProps"
          accordion
          style="margin-top: 15px; margin-bottom: 22px;"
        >
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDeleteConfirmationDialog = false">{{
            __("Cancel")
          }}</el-button>
          <el-tooltip
            class="item"
            effect="dark"
            :content="disabledSafeDeleteTooltip"
            placement="bottom"
          >
            <div style="display: inline-block;margin-left: 10px;">
              <el-button
                type="primary"
                @click="handleSafeDelete"
                v-if="selectedFolderId !== '-1' && !disableForceDelete"
                >{{ __("Safe Delete") }}</el-button
              >
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            :content="disabledForceDeleteTooltip"
            placement="bottom"
          >
            <div style="display: inline-block;margin-left: 10px;">
              <el-button
                type="primary"
                @click="handleForceDelete"
                v-if="!disableForceDelete"
                id="disabledbutton"
                >{{ __("Force Delete") }}</el-button
              >
            </div>
          </el-tooltip>
        </span>
      </div>
    </el-dialog>
  </el-container>
  <div v-else>
    <not-scoped scope="Account" resource="Content" />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import NotScoped from "@/components/NotScoped";
import FoldersSidebar from "./folders/FoldersSidebar";
import { EventBus } from "@/EventBus";
import Vue from "vue";

export default {
  name: "ContentsIndex",
  components: {
    PageHeader,
    NotScoped,
    FoldersSidebar
  },

  data() {
    return {
      contentTitle: "Main",
      // contentTitleIcon: "icon-folder",
      contentTitleIcon: "el-icon-folder-opened", // todo: update icon when design is available
      componentKey: 0,
      selected_folder_id: 0,
      content_ids: [],
      content_model: "",
      moveToFolderDialog: false,
      checkInUseDialog: false,
      showDeleteConfirmationDialog: false,
      checkInUseContentId: 0,
      contentUsages: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      loadingTabs: true,
      currentContentData: {},

      // ori
      selectedComponent: "prompts",
      selectedIndex: null,
      navItems: [
        {
          label: "Prompts",
          displayText: __("Prompts"),
          active: false,
          path: "prompts",
          icon: "icon-microphone.svg",
          readPermission: "build.content.prompts.read"
        },
        {
          label: "Variables",
          displayText: __("Variables"),
          active: false,
          path: "variables",
          icon: "icon-variable.svg",
          readPermission: "build.content.variables.read"
        },
        {
          label: "Custom Forms",
          displayText: __("Custom Forms"),
          active: false,
          path: "customformtypes",
          icon: "icon-custom-form.svg",
          readPermission: "build.content.custom-forms.read"
        },
        {
          label: "Open Forms",
          displayText: __("Open Forms"),
          active: false,
          path: "openformtypes",
          icon: "icon-open-form.svg",
          readPermission: "build.content.open-forms.read"
        },
        {
          label: "Dynamic Forms",
          displayText: __("Dynamic Forms"),
          active: false,
          path: "dynamicformtypes",
          icon: "icon-custom-form.svg",
          ld_key: this.$getConst("DYNAMIC_FORMS"),
          readPermission: "build.content.dynamic-forms.read"
        },
        {
          label: "Datastores",
          displayText: __("Datastores"),
          active: false,
          path: "datastores",
          icon: "icon-datastore.svg",
          readPermission: "build.content.data-stores.read"
        },
        {
          label: "Files",
          displayText: __("Files"),
          active: false,
          path: "files",
          icon: "icon-file.svg",
          readPermission: "build.content.files.read"
        },
        {
          label: "Holidays",
          displayText: __("Holidays"),
          active: false,
          path: "holidays",
          icon: "icon-holiday.svg",
          readPermission: "build.content.holidays.read"
        },
        {
          label: "Functions",
          displayText: __("Functions"),
          active: false,
          path: "javascripts",
          icon: "icon-javascript.svg",
          readPermission: "build.content.javascript-functions.read"
        },
        {
          label: "Intent",
          displayText: __("Intent"),
          active: false,
          path: "intent",
          ld_key: this.$getConst("LLM_TEXT_FORM_NODE"),
          icon: "icon-intent.svg",
          readPermission: "build.content.intents.read"
        }
      ],
      currentContentModel: "",
      avoidDeletingContentModels: [
        "Prompt",
        "Variable",
        "DataStore",
        "JavascriptFunction"
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    },
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("folders", {
      folders: state => state.folders,
      foldersLoading: state => state.loading,
      checkInUseLoading: state => state.checkInUseLoading,
      checkInUseErrorMessage: state => state.checkInUseErrorMessage,
      selectedFolderId: state => state.selectedFolderId
    }),
    contentUsageCount() {
      return _.size(this.contentUsages);
    },
    avoidDeletingContent() {
      return (
        this.avoidDeletingContentModels.includes(this.currentContentModel) &&
        this.contentUsageCount > 0
      );
    },

    dialogTitle() {
      let self = this;
      if (self.avoidDeletingContent) {
        return __("Content cannot be deleted");
      }
      return __("Are you sure about deleting this content?");
    },

    // disable force delete button based on content specific condition
    disableForceDelete() {
      return (
        ["DataStore"].includes(this.currentContentModel) &&
        this.contentUsageCount > 0
      );
    },

    disabledSafeDeleteTooltip() {
      // eslint-disable-next-line
      return __("Safe delete will archive this content to restore or delete later");
    },

    disabledForceDeleteTooltip() {
      // eslint-disable-next-line
      let disableContent = __("Unlink this content usage from the associated tasks and contents before deleting");
      let activeContent = __("Force Delete might impact the associated tasks");
      return this.disableForceDelete ? disableContent : activeContent;
    }
  },

  methods: {
    ...mapActions("folders", {
      moveContentToFolder: "moveContentToFolder",
      checkInUse: "checkInUse",
      refreshInUse: "refreshInUse",
      updateSelectedFolderId: "updateSelectedFolderId",
      updateCurrentTab: "updateCurrentTab"
    }),

    ...mapActions("app", {
      listenRefresh: "listenRefresh"
    }),

    importComponent(path) {
      return () => import("@/views/build/content/" + path + "/index.vue");
    },

    async showTabs() {
      for (const [index, item] of this.navItems.entries()) {
        if (item.ld_key) {
          this.navItems[index]["show"] = await this.showFeature(item.ld_key);
        }
      }
      this.loadingTabs = false;
    },

    hideAllLDFlags() {
      this.navItems.forEach((item, index) => {
        if (item.ld_key) {
          this.navItems[index]["show"] = false;
        }
      });
    },

    formatter(str) {
      return __(_.startCase(str));
    },

    handleNavItemChanged(navItem) {
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    },

    moveToFolder() {
      this.moveContentToFolder({
        folder_id: this.selected_folder_id,
        content_ids: this.content_ids,
        content_model: this.content_model
      })
        .then(() => {
          EventBus.$emit("select-folder", this.selectedFolderId);
          this.moveToFolderDialog = false;
        })
        .finally(() => {
          EventBus.$emit("uncheck-all-items");
          EventBus.$emit("de-select-all");
          EventBus.$emit("empty-multi-selected-items");
        });
    },

    checkContentInUse(content_model, content_id) {
      return new Promise((resolve, reject) => {
        this.contentUsages = [];
        this.checkInUse({
          content_model,
          content_id
        })
          .then(data => {
            this.contentUsages = [...data];
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    refreshContentInUse(content_model, content_id, content_name) {
      return new Promise((resolve, reject) => {
        this.refreshInUse({
          content_model,
          content_id,
          content_name
        })
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    handleForceDelete() {
      this.showDeleteConfirmationDialog = false;
      EventBus.$emit("initiate-force-delete-" + this.currentContentModel, {
        content: this.currentContentData
      });
    },

    handleSafeDelete() {
      this.showDeleteConfirmationDialog = false;
      EventBus.$emit("initiate-safe-delete-" + this.currentContentModel, {
        content: this.currentContentData
      });
    },

    setActiveNavItem() {
      for (let i = 0; i < this.navItems.length; i++) {
        if (this.can(this.navItems[i].readPermission)) {
          this.navItems[i].active = true;
          this.selectedComponent = this.navItems[i].path;
          break;
        }
      }
    },

    updateFolder() {
      EventBus.$emit("uncheck-all-items");
      EventBus.$emit("de-select-all");
      EventBus.$emit("empty-multi-selected-items");
      this.updateSelectedFolderId(0);
      EventBus.$emit("fetch-content-items-in-folder", 0);
      EventBus.$emit("update-content-title", "Main", "folder");
      if (this.$refs.foldersSidebar) {
        this.$refs.foldersSidebar.activeName = "folders";
      }
      this.updateCurrentTab("folders");
    }
  },

  watch: {
    checkInUseErrorMessage: {
      handler() {
        if (this.checkInUseErrorMessage) {
          this.$message({
            type: "error",
            message: this.checkInUseErrorMessage
          });
        }
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.hideAllLDFlags();
        } else {
          await this.showTabs();
        }

        this.updateFolder();
      }
    }
  },
  created() {
    this.setActiveNavItem();

    EventBus.$on("update-content-title", (title, type) => {
      this.contentTitle = title;
      if (type === "folder") {
        this.contentTitleIcon = "el-icon-folder-opened";
      } else if (type === "voice") {
        this.contentTitleIcon = "icon-voice";
      } else if (type === "messaging") {
        this.contentTitleIcon = "icon-messaging";
      } else if (type === "workflow") {
        this.contentTitleIcon = "icon-workflow";
      } else if (type === "chatbot") {
        this.contentTitleIcon = "icon-chatbot";
      } else {
        this.contentTitleIcon = "";
      }
      this.componentKey++;
    });

    EventBus.$on(
      "open-move-to-folder-modal",
      ({ content_ids, content_model }) => {
        this.content_ids = content_ids;
        this.content_model = content_model;
        this.moveToFolderDialog = true;
      }
    );

    EventBus.$on(
      "open-check-in-use-modal",
      async ({ content_id, content_model }) => {
        this.checkInUseDialog = true;
        await this.checkContentInUse(content_model, content_id);
      }
    );

    EventBus.$on(
      "call-refresh-check-in-use-job",
      async ({ content_id, content_model, content_name, refresh }) => {
        // change content in store state
        refresh({
          content_id: content_id,
          refreshing: 1,
          ac_id: this.selectedAccountId
        });

        // set socket
        this.listenRefresh({
          content_model: content_model,
          content_id: content_id,
          refresh: refresh
        });

        await this.refreshContentInUse(content_model, content_id, content_name);

        let message =
          "<div style='text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 0; min-width: 100%'>" +
          this.formatter(content_model) +
          ":&nbsp;" +
          `${content_name}` +
          "</div>" +
          "<div style='word-wrap: break-word'>" +
          __(
            "Content item usage is being force refreshed. This can take a few moments. You will be notified once it is complete."
          ) +
          "</div>";
        Vue.prototype.$notify.info({
          title: __("Info"),
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
    );

    EventBus.$on(
      "open-delete-confirmation-dialog-box",
      async ({ content_id, content_model, content }) => {
        this.checkInUseDialog = false;
        this.currentContentData = content;
        this.currentContentModel = content_model;

        await this.checkContentInUse(content_model, content_id)
          .then(() => {
            if (this.contentUsageCount < 1) {
              this.handleForceDelete();
            } else {
              this.showDeleteConfirmationDialog = true;
            }
          })
          .catch(err => console.log(err));
      }
    );
  },
  beforeDestroy() {
    EventBus.$off("open-move-to-folder-modal");
    EventBus.$off("open-check-in-use-modal");
    EventBus.$off("open-delete-confirmation-dialog-box");
    EventBus.$off("call-refresh-check-in-use-job");
    // this.$echo.leave("refreshing_" + this.content_model + content_id);
  }
};
</script>

<style lang="scss">
.action-icon {
  height: 15px;
  width: 15px;
  padding-right: 20px;
}

.content-component {
  width: 100% !important;

  .createBtn {
    right: 20px;
  }
}

.check-in-use-dialog {
  .el-dialog__body {
    min-height: 100px;
  }
}

#disabledbutton {
  padding: 10px;
}

#container {
  display: inline-block;
  position: relative;
  left: 23%;
  transform: translateX(-50%);
}

.content-area-scroll {
  .el-scrollbar__view {
    height: 100%;
  }
}

.folder-sidebar-scrollbar {
  min-width: 300px;
  background-color: #f5f5f8;
}

.el-button.btn-plain {
  color: #555555 !important;
  background: #f4f4f4 !important;
  border: 1px solid #dddddd !important;
  border-radius: 4px !important;
}
</style>

<style lang="scss" scoped>
::v-deep .page-header .navItems:not(:first-child) .navItem {
  margin-right: 1rem;
}
</style>
