import {
  getSmsProfiles,
  createSmsProfile,
  updateSmsProfile,
  deleteSmsProfile,
  sendTestSms
} from "@/api/smsprofiles";
import _ from "lodash";

const state = {
  smsProfiles: [],
  smsProfile_filter_key: "all",
  loading: false
};

const getters = {
  getSmsProfile: state => sms_profile_id => {
    return _.find(state.smsProfiles, { sms_profile_id: +sms_profile_id });
  },

  getUserInputParameters: state => sms_profile_id => {
    const smsProfile = _.find(state.smsProfiles, {
      sms_profile_id: +sms_profile_id
    });
    console.log(smsProfile);
    const smsProvider = smsProfile["sms_provider"]["data"][0];
    const smsProviderRequest = JSON.parse(smsProvider.json_data).request;
    return Object.keys(smsProviderRequest).reduce(function(acc, current) {
      if (smsProviderRequest[current].type !== "sms_profile") {
        acc[current] = smsProviderRequest[current];
      }
      return acc;
    }, {});
  }
};

const mutations = {
  SET_EMAIL_PROFILES(state, smsProfiles) {
    state.smsProfiles = smsProfiles;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getSmsProfiles({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getSmsProfiles(options)
        .then(({ data }) => {
          let smsProfiles = data.data;
          smsProfiles.forEach(function(smsProfile) {
            smsProfile.edit_name = false;
          });
          commit("SET_EMAIL_PROFILES", smsProfiles);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createSmsProfile({ commit }, smsProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createSmsProfile(smsProfile)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateSmsProfile({ commit }, smsProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const smsProfileObj = { ...smsProfile };
      updateSmsProfile(smsProfileObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteSmsProfile({ commit }, smsProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteSmsProfile(smsProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteSmsProfile({ commit }, smsProfile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      smsProfile.is_enabled = 1;
      updateSmsProfile(smsProfile)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  sendTestSms({ commit }, { sms_profile_id, recipient, smsContent }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      sendTestSms(sms_profile_id, recipient, smsContent)
        .then(() => {
          commit("CHANGE_LOADING", false);
          resolve();
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
