<template>
  <div>
    <div class="search-wrapper">
      <el-input
        size="small"
        :placeholder="__('Search Tasks')"
        v-model="search"
        @input="handleSearch"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i
      ></el-input>
    </div>

    <div v-loading="tasksLoading">
      <el-menu
        @select="selectTask"
        :default-active="selectedTaskId"
        class="task-names"
      >
        <el-menu-item
          v-for="task in tasks"
          :index="task.task_id.toString()"
          :key="task.task_id"
          style="padding: 0 10px;"
          class="task-name"
        >
          <span>{{ task.task_name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { EventBus } from "@/EventBus";

export default {
  data() {
    return {
      search: ""
    };
  },

  computed: {
    ...mapState("tasks", {
      tasksLoading: state => state.loading,
      tasks: state => state.tasks,
      taskFilter: state => state.taskFilter
    }),

    ...mapState("folders", {
      selectedTaskId: state => state.selectedTaskId.toString()
    }),

    ...mapGetters("tasks", {
      getTask: "getTask"
    })
  },

  methods: {
    ...mapActions("folders", {
      updateSelectedTaskId: "updateSelectedTaskId"
    }),

    async selectTask(key) {
      await this.updateSelectedTaskId(key);
      EventBus.$emit("fetch-content-items-in-folder", key);

      const task = this.getTask(key);
      EventBus.$emit("update-content-title", task.task_name, task.task_type);

      EventBus.$emit("uncheck-all-items");
      EventBus.$emit("de-select-all");
      EventBus.$emit("empty-multi-selected-items");
    },

    handleSearch() {
      this.updateSelectedTaskId(-1);
      EventBus.$emit("search-in-root", this.search);
    }
  },

  created() {
    this.search = "";
    EventBus.$on("select-task", value => {
      this.selectTask(value);
    });
  },

  beforeDestroy() {
    this.search = "";
    EventBus.$off("select-task");
  }
};
</script>

<style scoped lang="scss">
.task-names {
  .task-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 283px;
    overflow: hidden;

    &:first-child {
      margin-top: 5px;
    }
  }
}
</style>
