<template>
  <el-dialog
    :visible.sync="dialogVisible"
    v-if="dialogVisible"
    @close="handleDialogClose"
    :close-on-click-modal="isInListMode"
    :close-on-press-escape="isInListMode"
    show-close
    custom-class="globalContentItemModal"
    top="4vh"
    width="90%"
    append-to-body
  >
    <!--    <span slot="title">-->
    <!--      <page-header-->
    <!--        v-if="mode === 'list'"-->
    <!--        title="Content items"-->
    <!--        :navItems="options"-->
    <!--        @navItemChanged="handleOptionChange"-->
    <!--      />-->
    <!--      <page-header-->
    <!--        v-else-->
    <!--        :title="-->
    <!--          (Object.keys(itemToEdit).includes('variable_id')-->
    <!--            ? 'Edit '-->
    <!--            : 'Create ') + currentSelection.item-->
    <!--        "-->
    <!--      />-->
    <!--    </span>-->
    <!--    <template v-if="mode === 'list'">-->
    <!--      <component-->
    <!--        @handleEdit="handleEdit"-->
    <!--        class="component"-->
    <!--        :is="currentSelection.label"-->
    <!--      />-->
    <!--      <el-button @click="changeMode" class="btnAdd" type="primary" plain-->
    <!--        >Add {{ currentSelection.item }}</el-button-->
    <!--      >-->
    <!--    </template>-->
    <!--    <template v-else>-->
    <!--      <content-creator-->
    <!--        class="component"-->
    <!--        :contentType="currentSelection.item"-->
    <!--        :item="itemToEdit"-->
    <!--        @cancel="handleDialogClose"-->
    <!--        @success="handleDialogClose"-->
    <!--      />-->
    <!--    </template>-->
    <contents></contents>
  </el-dialog>
</template>

<script>
import { EventBus } from "@/EventBus";
import _ from "lodash";
import Contents from "@/views/build/content/index";
import { mapActions } from "vuex";

export default {
  name: "GlobalContentsIndex",
  components: {
    Contents
  },
  data() {
    return {
      dialogVisible: false,
      options: [
        { label: __("Variables"), item: "Variable", active: true },
        { label: __("Prompts"), item: "Prompt", active: false }
      ],
      mode: "list",
      itemToEdit: {}
    };
  },
  created() {
    EventBus.$on("open-contents-modal", () => {
      this.setGlobalContentModalIsOpen(true);
      this.dialogVisible = true;
    });
    EventBus.$on("close-contents-modal", () => {
      this.setGlobalContentModalIsOpen(false);
      this.dialogVisible = false;
    });
  },
  computed: {
    currentSelection() {
      return _.find(this.options, { active: true });
    },
    isInListMode() {
      return this.mode === "list";
    }
  },
  methods: {
    ...mapActions("app", {
      setGlobalContentModalIsOpen: "setGlobalContentModalIsOpen"
    }),

    handleOptionChange(navItem) {
      this.options = _.map(this.options, option => {
        option.active = false;
        return option;
      });
      _.find(this.options, { label: navItem.label }).active = true;
    },

    changeMode() {
      this.itemToEdit = {};
      this.mode = "edit";
    },

    handleEdit(item) {
      this.itemToEdit = item;
      this.mode = "edit";
    },

    handleDialogClose() {
      this.setGlobalContentModalIsOpen(false);
      this.$emit("global-contents-closed");
      EventBus.$emit("fetch-form-type");
    }
  }
};
</script>

<style lang="scss" scoped>
.btnAdd {
  margin-top: 30px;
}

.dialog-container {
  padding: 30px;
}
</style>

<style lang="scss">
.globalContentItemModal {
  padding: 0;
  border-radius: 0;
  .contentCardsContainer {
    max-height: calc(100vh - 400px) !important;
  }
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      z-index: 100;
    }
  }

  .el-dialog__body {
    padding: 0 !important;
    //height: 90vh;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
