import {
  getWebServiceProfiles,
  getAcWebServiceProfiles
} from "@/api/webserviceprofiles";

const state = {
  webServiceProfiles: [],
  isLoading: false
};

const mutations = {
  SET_WEB_SERVICE_PROFILES(state, webServiceProfiles) {
    state.webServiceProfiles = webServiceProfiles;
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getWebServiceProfiles({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.webServiceProfiles.length) {
        resolve();
      } else {
        commit("UPDATE_LOADING_STATUS", true);
        getWebServiceProfiles()
          .then(({ data }) => {
            commit("SET_WEB_SERVICE_PROFILES", data.data);
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      }
    });
  },

  getAcWebServiceProfiles({ commit }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAcWebServiceProfiles()
        .then(({ data }) => {
          commit("SET_WEB_SERVICE_PROFILES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
