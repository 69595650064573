import request from "@/utils/studio7ApiService";

export function getRegions(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/util/regions?" + params.toString(),
    method: "get"
  });
}

export function createRegion(region) {
  return request({
    url: "/util/regions",
    method: "post",
    data: region
  });
}

export function updateRegion(region) {
  return request({
    url: `/util/regions/${region.region_id}`,
    method: "put",
    data: region
  });
}

export function deleteRegion(region) {
  return request({
    url: `/util/regions/${region.region_id}`,
    method: "delete",
    data: region
  });
}

export function searchRegions(queryString) {
  if (queryString !== undefined) {
    return request({
      url: `/util/regions?search=${queryString.query}&searchFields=${queryString.operator}`,
      method: "get"
    });
  } else {
    return request({
      url: "/util/regions",
      method: "get"
    });
  }
}
