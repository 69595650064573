import request from "@/utils/studio7ApiService";

export function getSpPlans(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/sp-plans?" + params.toString(),
    method: "get"
  });
}

export function createSpPlan(spPlan) {
  return request({
    url: "/billing/sp-plans",
    method: "post",
    data: spPlan
  });
}

export function updateSpPlan(spPlan) {
  return request({
    url: `/billing/sp-plans/${spPlan.sp_plan_id}`,
    method: "put",
    data: spPlan
  });
}

export function deleteSpPlan(spPlan) {
  return request({
    url: `/billing/sp-plans/${spPlan.sp_plan_id}`,
    method: "delete",
    data: spPlan
  });
}

export function getBusinessPlansOfSameType(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/billing/business-plans-of-same-type?` + params.toString(),
    method: "get"
  });
}

export function getAvailablePlansForSP(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/billing/available-business-plans-for-sp?` + params.toString(),
    method: "get"
  });
}

export function getServiceProvidersForBusinessPlans(
  options = { fetch_all: 1 }
) {
  const params = new URLSearchParams(options);
  return request({
    url: `/billing/available-sps-for-business-plans?` + params.toString(),
    method: "get"
  });
}

export function getAvailableFeaturesForSpPlan(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/business-plan-features-for-sp-plan?" + params.toString(),
    method: "get"
  });
}

export function checkUniqueName(sp_plan_name, sp_id) {
  return request({
    url: `/billing/validate-sp-plan-name`,
    method: "post",
    data: { sp_plan_name, sp_id }
  });
}
