/*
 App prefix not to be changed - represents Studio 7 project related flags
* */
const APP_PREFIX = "studio.";

/*
 Predefined Feature Flags Types: - Do not change existing but can add new
      - Temporary (.temp): A temporary flag has a limited lifespan. (most commonly used)
      - Kill Switch (.kill): A Kill Switch is a type of permanent flag.
        Having the label in the name quickly helps identify that this flags main purpose it to disable a feature quickly.
* */
const KILL_SUFFIX = ".kill";
const TEMP_SUFFIX = ".temp";
const DEV_SUFFIX = ".dev";

/*
Add your new Custom Feature Flags function name here
* */
const MULTI_LANGUAGE_SUPPORT = "mulit-language-support";
const DYNAMIC_FORMS = "dynamic-form-type";
const VISUAL_FORM_FILE_UPLOAD = "visualform-file-upload";

// flags for reference (not used by any features yet)
const CUSTOM_FEATURE = "custom-feature";
const PERMANENT_FEATURE = "permanent-feature";
const ADVANCED_DATA_EXPORT = "advanced-data-exports";
const CALLFLOW_VISUALISER = "callflow-visualiser";
const OPEN_AI_WS_INTEGRATION = "open-ai-webservice-integration";
const CODE_GENERATION = "js-function.open-ai-integration";
const LLM_TEXT_FORM_NODE = "llm-text-form-node";
const BIOMETRICS_QUALITY_ASSURANCE = "biometrics-quality-assurance";
const STUDIO_EXPRESSION_BUILDER_IMPROVEMENTS =
  "expression-builder-improvements";
const STUDIO_CHAT_BOT_CARD_AND_CAROUSEL = "chatbot.card-and-carousel";
const AUTH_PROFILE_API_KEY = "auth-profile-api-key";
const ALLOW_DEBUG_SYSTEM_TASK_LOGS = "onprem.allow-debug-system-task-logs";
const GCS_UPLOAD = "gcs-upload";
const PROACTIVE_MESSAGING = "proactive-messaging-node";
const AI_SUMMARY_NODE = "ai-summary-node";
const GAIN_SIGHT_PX_ANALYTICS = "gainsightpx";
const FRONTEND_KNOWLEDGE_NODE = "frontend.knowledge-node";
const STUDIO_INTERACTION_VARIABLES = "interaction-variables";

/*
Update newly added feature function in this map
* */
export const FEATURE_FLAG_KEYS = {
  DYNAMIC_FORMS: APP_PREFIX + DYNAMIC_FORMS + TEMP_SUFFIX,
  VISUAL_FORM_FILE_UPLOAD: APP_PREFIX + VISUAL_FORM_FILE_UPLOAD + TEMP_SUFFIX,
  MULTI_LANGUAGE_SUPPORT: APP_PREFIX + MULTI_LANGUAGE_SUPPORT + TEMP_SUFFIX,
  CUSTOM_FEATURE: APP_PREFIX + CUSTOM_FEATURE + TEMP_SUFFIX,
  PERMANENT_FEATURE: APP_PREFIX + PERMANENT_FEATURE + KILL_SUFFIX,
  ADVANCED_DATA_EXPORT: APP_PREFIX + ADVANCED_DATA_EXPORT + TEMP_SUFFIX,
  CALLFLOW_VISUALISER: APP_PREFIX + CALLFLOW_VISUALISER + TEMP_SUFFIX,
  OPEN_AI_WS_INTEGRATION: APP_PREFIX + OPEN_AI_WS_INTEGRATION + TEMP_SUFFIX,
  CODE_GENERATION: APP_PREFIX + CODE_GENERATION + TEMP_SUFFIX,
  LLM_TEXT_FORM_NODE: APP_PREFIX + LLM_TEXT_FORM_NODE + TEMP_SUFFIX,
  BIOMETRICS_QUALITY_ASSURANCE:
    APP_PREFIX + BIOMETRICS_QUALITY_ASSURANCE + TEMP_SUFFIX,
  STUDIO_EXPRESSION_BUILDER_IMPROVEMENTS:
    APP_PREFIX + STUDIO_EXPRESSION_BUILDER_IMPROVEMENTS + TEMP_SUFFIX,
  STUDIO_CHAT_BOT_CARD_AND_CAROUSEL:
    APP_PREFIX + STUDIO_CHAT_BOT_CARD_AND_CAROUSEL + TEMP_SUFFIX,
  AUTH_PROFILE_API_KEY: APP_PREFIX + AUTH_PROFILE_API_KEY + TEMP_SUFFIX,
  ALLOW_DEBUG_SYSTEM_TASK_LOGS:
    APP_PREFIX + ALLOW_DEBUG_SYSTEM_TASK_LOGS + KILL_SUFFIX,
  ALLOW_GCS_UPLOAD: APP_PREFIX + GCS_UPLOAD + TEMP_SUFFIX,
  PROACTIVE_MESSAGING: APP_PREFIX + PROACTIVE_MESSAGING + TEMP_SUFFIX,
  AI_SUMMARY_NODE: APP_PREFIX + AI_SUMMARY_NODE + TEMP_SUFFIX,
  GAIN_SIGHT_PX_ANALYTICS: APP_PREFIX + GAIN_SIGHT_PX_ANALYTICS + KILL_SUFFIX,
  FRONTEND_KNOWLEDGE_NODE: APP_PREFIX + FRONTEND_KNOWLEDGE_NODE + DEV_SUFFIX,
  STUDIO_INTERACTION_VARIABLES:
    APP_PREFIX + STUDIO_INTERACTION_VARIABLES + TEMP_SUFFIX
};
