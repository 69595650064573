import request, { ac_headers } from "@/utils/studio7ApiService";

export function getFolders(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/content/folders?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createFolder(data) {
  const folder = { ...data, guard_name: "admin" };
  return request({
    url: "/content/folders",
    method: "post",
    data: folder,
    headers: ac_headers()
  });
}

export function updateFolder(folder) {
  return request({
    url: `/content/folders/${folder.folder_id}`,
    method: "put",
    data: folder,
    headers: ac_headers()
  });
}

export function deleteFolder(folder) {
  return request({
    url: `/content/folders/${folder.folder_id}`,
    method: "delete",
    data: folder,
    headers: ac_headers()
  });
}

export function moveContentToFolder(folder_id, content_ids, content_model) {
  return request({
    url: "/content/folder-contents",
    method: "post",
    data: {
      folder_id: folder_id,
      content_model: content_model,
      content_ids: content_ids
    },
    headers: ac_headers()
  });
}

export function checkInUse(content_model, content_id, mode = null) {
  return request({
    url: "/content/check-in-use",
    method: "post",
    data: {
      content_model: content_model,
      content_id: content_id,
      delete_type: mode
    },
    headers: ac_headers()
  });
}

export function refreshInUse(content_model, content_id, content_name) {
  return request({
    url: "/content/refresh-check-in-use",
    method: "post",
    data: {
      content_model: content_model,
      content_id: content_id,
      content_name: content_name
    },
    headers: ac_headers()
  });
}

export function checkInSysUse(content_model, content_id, mode = null) {
  return request({
    url: "/content/check-in-use",
    method: "post",
    data: {
      content_model: content_model,
      content_id: content_id,
      delete_type: mode
    },
    headers: {
      scope: "ac",
      "scope-id": "all"
    }
  });
}

/**
 * check in use count
 * @param content_model
 * @param content_id
 * @returns {*}
 */
export function checkInUseCount(content_model, content_id) {
  return request({
    url: "/content/check-in-use-count",
    method: "post",
    data: {
      content_model: content_model,
      content_id: content_id
    },
    headers: ac_headers()
  });
}

/**
 * check in use count for all accounts
 * @param content_model
 * @param content_id
 * @returns {*}
 */
export function checkInSysUseCount(content_model, content_id) {
  return request({
    url: "/content/check-in-use-count",
    method: "post",
    data: {
      content_model: content_model,
      content_id: content_id
    },
    headers: {
      scope: "ac",
      "scope-id": "all"
    }
  });
}
