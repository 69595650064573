import {
  createQueue,
  deleteQueue,
  deleteWaiter,
  getPlanTypesDetail,
  getQformeCallLog,
  getQueues,
  getWaiters,
  updateQueue
} from "@/api/qforme";
import _ from "lodash";

const state = {
  queues: [],
  waiters: [],
  queueCallLog: [],
  planTypes: [],
  loading: false
};

const getters = {
  getQueue: state => queue_id => {
    return _.find(state.queues, { queue_id: +queue_id });
  }
};

const mutations = {
  SET_QUEUES(state, queues) {
    state.queues = queues;
  },

  SET_WAITERS(state, waiters) {
    state.waiters = waiters;
  },

  SET_QUEUE_CALL_LOG(state, callLog) {
    state.queueCallLog = callLog;
  },

  SET_PLAN_TYPES(state, planTypes) {
    state.planTypes = planTypes;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getQueues({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, include: "ac_plan" };
      getQueues(options)
        .then(({ data }) => {
          commit("SET_QUEUES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createQueue({ commit }, queue) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createQueue(queue)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateQueue({ commit }, queue) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateQueue(queue)
        .then(res => {
          commit("CHANGE_LOADING", false);
          resolve(res);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteQueue({ commit }, queue) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteQueue(queue)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteQueue({ commit }, queue) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      queue.is_enabled = 1;
      updateQueue(queue)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getWaiters({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, include: "qfm_queue,qfm_queue.ac_plan" };
      getWaiters(options)
        .then(({ data }) => {
          commit("SET_WAITERS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteWaiter({ commit }, waiter) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteWaiter(waiter)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getQformeCallLog({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, include: "qfm_queue,qfm_waiter,ac_plan" };
      getQformeCallLog(options)
        .then(({ data }) => {
          commit("SET_QUEUE_CALL_LOG", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getPlanTypesDetail({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getPlanTypesDetail()
        .then(({ data }) => {
          commit("SET_PLAN_TYPES", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
