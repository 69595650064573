import request, { ac_headers, sp_headers } from "@/utils/studio7ApiService";

export function getAlertSettings() {
  return request({
    url: "/services/ac-alert-settings",
    method: "get",
    headers: ac_headers()
  });
}
export function addAlertSettings(alertSettings) {
  return request({
    url: "/services/ac-alert-settings",
    method: "post",
    data: alertSettings,
    headers: ac_headers()
  });
}
export function updateAlertSettings(alertSettings) {
  return request({
    url: `/services/ac-alert-settings/${alertSettings.alert_setting_id}`,
    method: "put",
    data: alertSettings,
    headers: ac_headers()
  });
}
export function updateOrCreateAlertSettings(alertSettings) {
  return request({
    url: "/services/update-or-create-ac-alert-setting",
    method: "post",
    data: alertSettings,
    headers: ac_headers()
  });
}

export function updateOrCreateSpAlertSettings(alertSettings) {
  return request({
    url: "/services/update-or-create-sp-alert-setting",
    method: "post",
    data: alertSettings,
    headers: sp_headers()
  });
}

export function getSpAlertSettings() {
  return request({
    url: "/services/sp-alert-settings",
    method: "get",
    headers: sp_headers()
  });
}
export function addSpAlertSettings(alertSettings) {
  return request({
    url: "/services/sp-alert-settings",
    method: "post",
    data: alertSettings,
    headers: sp_headers()
  });
}
export function updateSpAlertSettings(alertSettings) {
  return request({
    url: `/services/sp-alert-settings/${alertSettings.alert_setting_id}`,
    method: "put",
    data: alertSettings,
    headers: sp_headers()
  });
}
