import _ from "lodash";

/*
 * Web Service Node action values
 * */
const DIALOGFLOW = "dialogflow";
const OPEN_AI_ACTION = "open_ai";
const FIVE9_WFA_ACTION = "five9_wfa";

/*
 * Web Service Node action display names
 * */
export const OPEN_AI_DISPLAY_NAME = "Generative AI";
export const FIVE9_WFA_DISPLAY_NAME = "Five9 WFA";

/*
 * Open AI Web Service action use-cases
 * */
export const OPEN_AI_SUMMARY_USE_CASE = "summary";
export const OPEN_AI_CUSTOM_PROMPTING_USE_CASE = "custom_prompt";
export const OPEN_AI_INTENT_DETECTION_USE_CASE = "detect_intent";
export const OPEN_AI_DATASTORE_FAQ_USE_CASE = "datastore_faq";

export const OPEN_AI_USE_CASES_LIST = [
  {
    get label() {
      return __("Intent Detection");
    },
    value: OPEN_AI_INTENT_DETECTION_USE_CASE
  },
  {
    get label() {
      return __("Summarization");
    },
    value: OPEN_AI_SUMMARY_USE_CASE
  },
  {
    get label() {
      return __("Custom Prompting");
    },
    value: OPEN_AI_CUSTOM_PROMPTING_USE_CASE
  },
  {
    get label() {
      return __("Datastore FAQ");
    },
    value: OPEN_AI_DATASTORE_FAQ_USE_CASE
  }
];

/**
 * The function returns a display name for a given web service node type/action
 */
export const webServiceNodeDisplayName = node_type => {
  switch (node_type) {
    case FIVE9_WFA_ACTION:
      return FIVE9_WFA_DISPLAY_NAME;
    case OPEN_AI_ACTION:
      return OPEN_AI_DISPLAY_NAME;
  }
  return _.startCase(_.camelCase(node_type));
};

export default {
  DIALOGFLOW,
  OPEN_AI_ACTION,
  FIVE9_WFA_ACTION,

  DIALOGFLOW_TEXT_INPUT: "TextInput",
  DIALOGFLOW_EVENT_INPUT: "EventInput"
};
