import store from "@/store";
import __ from "@/translation";

const gotoNode = id => {
  store.dispatch("canvas/gotoNode", {
    id
  });
};

export default {
  text: __("Goto Node"),
  icon: "",
  onClick: gotoNode
};
