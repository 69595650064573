import __ from "@/translation";

export const TYPES = {
  TUTORIAL: "tutorial",
  WHATS_NEW: "whats_new"
};

let labels = {};
// Must define property as getter since this file is evaluated before the translation is available.
Object.defineProperties(labels, {
  [TYPES.TUTORIAL]: {
    get() {
      return __("Tutorials");
    }
  },
  [TYPES.WHATS_NEW]: {
    get() {
      return __("See what's new in Studio");
    }
  }
});

export const typeToLabel = labels;
