import { deleteUpload, removeUnusedFiles, upload } from "@/api/upload";

const state = {
  isLoading: false
};

const mutations = {
  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  upload({ commit }, path) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      upload(path)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  removeUnusedFiles({ commit }, path) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      removeUnusedFiles(path)
        .then(() => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve();
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteUpload({ commit }, path) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteUpload(path)
        .then(() => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve();
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  deleteLogo({ commit }, path) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteUpload(path)
        .then(() => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve();
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
