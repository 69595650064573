import store from "@/store";
import __ from "@/translation";

const linkTask = id => {
  store.dispatch("canvas/GotoTask", {
    id
  });
};

export default {
  text: __("Go To Task"),
  icon: "",
  onClick: linkTask
};
