import { getAppFeatures } from "@/api/appfeatures";
import { getFeatureUsageAndLimit } from "@/api/appfeatures";
import { getAcAppFeatures } from "@/api/appfeatures";

export const state = {
  appFeatures: ["Voice"],
  acAppFeatures: [],
  originalPlanFeatures: [],
  isLoading: false,
  featureUsageAndLimit: {
    featureUsagesLeft: null,
    featureBurstUsagesLeft: null,
    featureHardLimit: null,
    featureLimit: null,
    featureBurstLimit: null
  }
};

export const mutations = {
  SET_APPLICATION_FEATURES(state, appFeatures) {
    state.appFeatures = appFeatures;
  },
  SET_AC_APPLICATION_FEATURES(state, acAppFeatures) {
    state.acAppFeatures = acAppFeatures;
  },
  SET_ORIGINAL_PLAN_APPLICATION_FEATURES(state, appFeatures) {
    state.originalPlanFeatures = appFeatures;
  },

  SET_FEATURE_USAGE_AND_LIMIT(state, featureUsageAndLimit) {
    state.featureUsageAndLimit = featureUsageAndLimit;
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

export const actions = {
  getAppFeatures({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAppFeatures(options)
        .then(async ({ data }) => {
          commit("SET_APPLICATION_FEATURES", data.data);
          commit("SET_ORIGINAL_PLAN_APPLICATION_FEATURES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  getAcAppFeatures({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAcAppFeatures(options.ac_id, options.plan_type, options.ac_plan_id)
        .then(async ({ data }) => {
          commit("SET_AC_APPLICATION_FEATURES", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  getFeatureUsageAndLimit({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getFeatureUsageAndLimit(options)
        .then(async ({ data }) => {
          commit("SET_FEATURE_USAGE_AND_LIMIT", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },
  setFeaturesExcludingBasePlanFeatures({ commit }, features) {
    commit("SET_APPLICATION_FEATURES", features);
  },

  resetFeaturesAsPerPlanType({ commit, state }) {
    commit("SET_APPLICATION_FEATURES", state.originalPlanFeatures);
  }
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
