import request, { sp_headers } from "@/utils/studio7ApiService";

export function getBillingInvoices(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/billing-invoices?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function createBillingInvoice(data) {
  const billingInvoice = { ...data, guard_name: "admin" };
  return request({
    url: "/billing/billing-invoices",
    method: "post",
    data: billingInvoice,
    headers: sp_headers()
  });
}

export function updateBillingInvoice(billingInvoice) {
  return request({
    url: `/billing/billing-invoices/${billingInvoice.invoice_id}`,
    method: "put",
    data: billingInvoice,
    headers: sp_headers()
  });
}

export function deleteBillingInvoice(billingInvoice) {
  return request({
    url: `/billing/billing-invoices/${billingInvoice.invoice_id}`,
    method: "delete",
    data: billingInvoice,
    headers: sp_headers()
  });
}

export function generateBillingInvoice(data) {
  const yearMonth = { ...data, guard_name: "admin" };
  return request({
    url: "/billing/generate/" + data.sp_id,
    method: "post",
    data: yearMonth,
    headers: sp_headers()
  });
}
