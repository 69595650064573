import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

/**
 * Enables Conversation Monitoring by sending a POST request to the backend.
 *
 * @param {Object} params - The parameters for enabling monitoring.
 * @param {number|string} params.taskId - The ID of the task.
 * @param {string} params.ani - The ANI (Automatic Number Identification).
 * @param {string} params.scope - The scope of the request ('ac' or 'sp').
 * @returns {Promise} - Axios Promise for the HTTP request.
 */
export function enableMonitoring({ taskId, ani, scope = "ac" }) {
  return request({
    url: "/task/live-conversation-monitoring/enable",
    method: "post",
    data: {
      task_id: String(taskId),
      ani: String(ani)
    },
    headers: scope === "ac" ? ac_headers() : scope === "sp" ? sp_headers() : {}
  });
}
