import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import {
  createNodeLoggingProfile,
  deleteNodeLoggingProfile,
  getProfiles,
  updateNodeLoggingProfile,
  updateProfile
} from "@/api/nodeLoggingProfiles";

import _ from "lodash";

const initialState = {
  profileFilter: null,
  webhookProfiles: [],
  firebaseProfiles: [
    // {
    //   profile_id: 1,
    //   profile_name: "firebase_profile_1",
    //   profile_type: NODE_LOG_PROFILE.FIREBASE_PROFILE
    // }
  ],
  // amazonAuroraProfiles: [
  //   // {
  //   //   profile_id: 1,
  //   //   profile_name: "aurora_profile_1",
  //   //   profile_type: NODE_LOG_PROFILE.AURORA_PROFILE
  //   // }
  // ],
  loading: false
};

export const state = _.cloneDeep(initialState);

export const mutations = {
  SET_PROFILE_FILTER(state, filter) {
    state.profileFilter = filter;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_PROFILES(state, { profiles, type }) {
    switch (type) {
      case NODE_LOG_PROFILE.WEBHOOK_PROFILE:
        state.webhookProfiles = profiles;
        break;
      case NODE_LOG_PROFILE.FIREBASE_PROFILE:
        state.firebaseProfiles = profiles;
        break;
    }
  },

  // eslint-disable-next-line no-unused-vars
  RESET_INITIAL_STATE(state) {
    state = _.cloneDeep(initialState);
  }
};

export const getters = {
  allNodeLoggingProfiles: state =>
    _(state.webhookProfiles)
      .concat(state.firebaseProfiles)
      // .concat(state.amazonAuroraProfiles)
      .filter()
      .value(),

  hasNodeLoggingProfiles: (state, getter) =>
    getter.allNodeLoggingProfiles.length,

  nodeLoggingProfileOptions: (state, getter) => {
    return _(getter.allNodeLoggingProfiles)
      .map(profile => ({
        value: profile.profile_type + "-" + profile.profile_id,
        label: profile.profile_name,
        type: _.startCase(profile.profile_type) + "s"
      }))
      .groupBy(profile => profile.type)
      .map((profiles, profile_type) => {
        return {
          label: profile_type,
          options: profiles
        };
      })
      .value();
  }
};

export const actions = {
  resetInitialState({ commit }) {
    commit("RESET_INITIAL_STATE");
  },

  setProfileFilter({ commit }, filter) {
    commit("SET_PROFILE_FILTER", filter);
  },

  getNodeLoggingProfiles({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      if (!options.profile_type && !state.profileFilter) {
        commit("SET_PROFILE_FILTER", NODE_LOG_PROFILE.WEBHOOK_PROFILE);
      }

      options = {
        ...options,
        profile_type: options.profile_type
          ? options.profile_type
          : state.profileFilter
      };

      if (!options["notShowLoader"]) {
        commit("SET_PROFILES", { profiles: [], type: options.profile_type });
        commit("CHANGE_LOADING", true);
      }

      getProfiles(options)
        .then(({ data }) => {
          commit("SET_PROFILES", {
            profiles: data.data,
            type: options.profile_type
          });
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  createNodeLoggingProfile({ commit }, profile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createNodeLoggingProfile(profile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  updateNodeLoggingProfile({ commit }, profile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      updateNodeLoggingProfile(profile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  deleteNodeLoggingProfile({ commit }, profile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteNodeLoggingProfile(profile)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  undoDeleteProfile({ commit }, profile) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      profile.is_enabled = 1;
      updateProfile(profile)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
