import request from "@/utils/studio7ApiService";

export function getAllServiceProviders() {
  return request({
    url: "/studio6/service-providers",
    method: "get"
  });
}

export function getS6ServiceProviders() {
  return request({
    url: "/studio6/portal/get-s6-service-providers",
    method: "get"
  });
}

export function getS6AccountsForSp(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-s6-groups?" + params.toString(),
    method: "get"
  });
}

export function getMappedS7Sp(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-mapped-s7-sp?" + params.toString(),
    method: "get"
  });
}

export function getMappedS7Ac(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-mapped-s7-ac?" + params.toString(),
    method: "get"
  });
}

export function validateSelectedSp(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/validate-sp?" + params.toString(),
    method: "get"
  });
}

export function validateSelectedAc(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/validate-ac?" + params.toString(),
    method: "get"
  });
}

export function mapS6SpToS7Sp({ s6_sp_id, s7_sp_id, remap }) {
  return request({
    url: "/studio6/portal/map-sp",
    method: "post",
    data: {
      s6_sp_id: s6_sp_id,
      s7_sp_id: s7_sp_id,
      remap: remap
    }
  });
}

export function mapS6AcToS7Ac({ s6_ac_id, s7_ac_id, remap }) {
  return request({
    url: "/studio6/portal/map-ac",
    method: "post",
    data: {
      s6_ac_id: s6_ac_id,
      s7_ac_id: s7_ac_id,
      remap: remap
    }
  });
}

export function getUnmappedServiceProviders(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/unmapped-sps?" + params.toString(),
    method: "get"
  });
}

export function getUnmappedAccounts(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/unmapped-acs?" + params.toString(),
    method: "get"
  });
}

export function getAccountsAvailableForRemapping() {
  return request({
    url: "/studio6/portal/available-accounts",
    method: "get"
  });
}

export function getS6ServiceProviderData(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-s6-sp-data?" + params.toString(),
    method: "get"
  });
}

export function getS7ServiceProviderData(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-s7-sp-data?" + params.toString(),
    method: "get"
  });
}

export function getS6GroupDataForSp(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-s6-group-data-for-sp?" + params.toString(),
    method: "get"
  });
}

export function getS7AccountData(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio6/portal/get-s7-ac-data?" + params.toString(),
    method: "get"
  });
}

export function unmapServiceProvider({ s7_sp }) {
  return request({
    url: "/studio6/portal/unmap-sp",
    method: "post",
    data: {
      s7_sp_id: s7_sp
    }
  });
}
export function unmapAccount({ s7_ac }) {
  return request({
    url: "/studio6/portal/unmap-ac",
    method: "post",
    data: {
      s7_ac_id: s7_ac
    }
  });
}

export function getBatchS6S7AccountDataForSp(options = {}) {
  const params = new URLSearchParams(options);
  return request({
    url:
      "/studio6/portal/get-batch-s6-s7-account-data-for-sp?" +
      params.toString(),
    method: "get"
  });
}
