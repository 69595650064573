<template>
  <el-row type="flex" :gutter="15" class="user-profile">
    <el-col class="user-icon">
      <img
        v-if="userInfoPrimary"
        :src="userImg"
        class="user-img"
        @error="handleAltImg"
      />
      <!--      assumes if user-info-add-on is given then its actual user information-->
      <avatar
        class="user-avatar"
        :size="44"
        v-if="userInfoAddOn"
        :username="userInfoSecondary"
      ></avatar>
    </el-col>
    <el-col :span="18">
      <div class="user-info">
        <el-tooltip
          :content="userInfoPrimary"
          :open-delay="500"
          :disabled="hideTooltip(userInfoPrimary)"
        >
          <span v-if="userInfoPrimary" class="primary-info">{{
            userInfoPrimary | truncate
          }}</span>
        </el-tooltip>
        <el-tooltip
          :content="userInfoSecondary"
          :open-delay="500"
          :disabled="hideTooltip(userInfoSecondary)"
        >
          <span v-if="userInfoSecondary" class="secondary-info">{{
            userInfoSecondary | truncate
          }}</span>
        </el-tooltip>
        <span v-if="userInfoAddOn" class="add-on">{{ userInfoAddOn }}</span>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  props: {
    userImg: {
      type: String,
      default: "/Five9Logo.jpg"
    },

    userInfoPrimary: {
      type: String,
      required: false,
      default: ""
    },
    userInfoSecondary: {
      type: String,
      required: false,
      default: ""
    },
    userInfoAddOn: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    Avatar
  },
  filters: {
    truncate(value, limit = 21) {
      if (value && value.length <= limit) {
        return value;
      } else if (value) {
        return value.substring(0, limit) + "...";
      } else {
        return value;
      }
    }
  },
  methods: {
    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    hideTooltip(content) {
      return content.length <= 21;
    }
  }
};
</script>

<style scoped lang="scss">
.user-icon {
  display: flex;
  flex: 1;
}
</style>
