import request, { ac_headers } from "@/utils/studio7ApiService";

export function getExportDataForUser(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/data/data-store-export?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function deleteDataExport(dataStoreExport) {
  return request({
    url: `/data/data-store-export/${dataStoreExport.data_store_export_id}`,
    method: "delete",
    headers: ac_headers()
  });
}

export function requestDataExport(exportData) {
  return request({
    url: `/data/data-store-export`,
    method: "post",
    data: exportData,
    headers: ac_headers()
  });
}
