import request, { ac_headers } from "@/utils/studio7ApiService";

export function getPaymentProfiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/payment/payment-profile?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createPaymentProfile(data) {
  const paymentProfile = { ...data, guard_name: "admin" };
  return request({
    url: "/payment/payment-profile",
    method: "post",
    data: paymentProfile,
    headers: ac_headers()
  });
}

export function updatePaymentProfile(paymentProfile) {
  return request({
    url: `/payment/payment-profile/${paymentProfile.payment_profile_id}`,
    method: "put",
    data: paymentProfile,
    headers: ac_headers()
  });
}

export function deletePaymentProfile(paymentProfile) {
  return request({
    url: `/payment/payment-profile/${paymentProfile.payment_profile_id}`,
    method: "delete",
    data: paymentProfile,
    headers: ac_headers()
  });
}

export function getPaymentGatewayRequestFields(paymentGateway) {
  return request({
    url: `/payment/payment-profile/payment-gateway-params/${paymentGateway}`,
    method: "get",
    headers: ac_headers()
  });
}

export function testPaymentProfile(paymentGatewayData) {
  return request({
    url: "/payment/payment-profile/test-payment-gateway",
    method: "post",
    data: paymentGatewayData,
    headers: ac_headers()
  });
}

export function generateRequestBody(
  paymentProfileId,
  paymentProcessType,
  requestParamVariables,
  requestParamInputs,
  customPayload
) {
  return request({
    url: "/payment/payment-profile/generate-request-body",
    method: "post",
    data: {
      payment_profile_id: paymentProfileId,
      request_param_variables: requestParamVariables,
      request_param_inputs: requestParamInputs,
      custom_payload: customPayload,
      payment_process_type: paymentProcessType
    },
    headers: ac_headers()
  });
}
