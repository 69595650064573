import {
  getAcPlans,
  createAcPlan,
  updateAcPlan,
  deleteAcPlan,
  getAcPlansForSelectedAccount,
  getAccountsForSP,
  getAllocatedPlanAndUpdatedUnits,
  getAccountFeatureLimits
} from "@/api/acplans";
import { getAccountsForServiceProvider } from "@/api/accounts";
import _ from "lodash";
import store from "@/store";

export const state = {
  acPlans: [],
  availablePlans: [],
  accounts: [],
  isLoading: false,
  planFilter: "IVA",
  acFeatureLimits: [],
  planChanged: false
};

export const mutations = {
  SET_AC_PLANS(state, acPlans) {
    state.acPlans = acPlans;
  },

  SET_AVAILABLE_AC_PLANS(state, availablePlans) {
    state.availablePlans = availablePlans;
  },

  SET_AVAILABLE_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },

  CREATE_AC_PLAN(state, acPlan) {
    state.acPlans.push(acPlan);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  UPDATE_AC_PLAN(state, { index, acPlan }) {
    state.acPlans.splice(index, 1, acPlan);
  },

  DELETE_AC_PLAN(state, acPlan) {
    state.acPlans = state.acPlans.filter(
      oldPlan => oldPlan.ac_plan_id !== acPlan.ac_plan_id
    );
  },
  SET_PLAN_FILTER_KEY(state, filter) {
    state.planFilter = filter;
  },
  SET_AC_FEATURE_LIMITS(state, acFeatureLimits) {
    state.acFeatureLimits = acFeatureLimits;
  },

  UPDATE_AC_PLAN_CHANGED(state, flag) {
    state.planChanged = flag;
  }
};

export const actions = {
  getAcPlans({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      options = { ...options, plan_type: state.planFilter };
      getAcPlans(options)
        .then(async ({ data }) => {
          commit("SET_AC_PLANS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  setBusinessPlanFilter({ commit }, filter) {
    commit("SET_PLAN_FILTER_KEY", filter);
  },

  createAcPlan({ commit }, acPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createAcPlan(acPlan)
        .then(async ({ data }) => {
          commit("CREATE_AC_PLAN", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          commit("UPDATE_AC_PLAN_CHANGED", true);
          await store.dispatch(
            "app/getEnabledFeaturesForUser",
            acPlan.ac_id,
            1
          );
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  updateAcPlan({ commit, state }, acPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      updateAcPlan(acPlan)
        .then(async ({ data }) => {
          var index = _.findIndex(state.acPlans, oldPlan => {
            return oldPlan.ac_plan_id === acPlan.ac_plan_id;
          });
          commit("UPDATE_AC_PLAN", { index, acPlan: data.data });
          commit("UPDATE_AC_PLAN_CHANGED", true);
          commit("UPDATE_LOADING_STATUS", false);
          await store.dispatch(
            "app/getEnabledFeaturesForUser",
            acPlan.ac_id,
            1
          );
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteAcPlan({ commit }, acPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      deleteAcPlan(acPlan)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          commit("UPDATE_AC_PLAN_CHANGED", true);
          commit("DELETE_AC_PLAN", acPlan);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  undoDeleteAcPlan({ commit }, acPlan) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      acPlan.is_enabled = 1;
      updateAcPlan(acPlan)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getAcPlansForSelectedAccount({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (options) {
        commit("UPDATE_LOADING_STATUS", true);
        getAcPlansForSelectedAccount(options.ac_id, options.plan_type)
          .then(async ({ data }) => {
            commit("SET_AVAILABLE_AC_PLANS", data);
            commit("UPDATE_LOADING_STATUS", false);
            resolve();
          })
          .catch(err => {
            commit("UPDATE_LOADING_STATUS", false);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },

  getAccountsForSP({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (options) {
        commit("UPDATE_LOADING_STATUS", true);
        getAccountsForSP(options)
          .then(async ({ data }) => {
            commit("SET_AVAILABLE_ACCOUNTS", data);
            commit("SET_AVAILABLE_AC_PLANS", []);
            commit("UPDATE_LOADING_STATUS", false);
            resolve();
          })
          .catch(err => {
            commit("UPDATE_LOADING_STATUS", false);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },

  getAllocatedPlanAndUpdatedUnits({ commit }, ac_plan_id) {
    return new Promise((resolve, reject) => {
      if (ac_plan_id) {
        commit("UPDATE_LOADING_STATUS", true);
        getAllocatedPlanAndUpdatedUnits(ac_plan_id)
          .then(async ({ data }) => {
            commit("SET_AVAILABLE_AC_PLANS", data);
            commit("UPDATE_LOADING_STATUS", false);
            resolve();
          })
          .catch(err => {
            commit("UPDATE_LOADING_STATUS", false);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },

  fetchSelectedAccount({ commit }, ac_id = null) {
    return new Promise((resolve, reject) => {
      if (ac_id) {
        commit("UPDATE_LOADING_STATUS", true);
        getAccountsForServiceProvider({
          search: ac_id,
          searchFields: "ac_id:="
        })
          .then(({ data }) => {
            commit("SET_AVAILABLE_ACCOUNTS", data.data);
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      }
    });
  },

  getAccountFeatureLimits({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAccountFeatureLimits(options)
        .then(({ data }) => {
          commit("SET_AC_FEATURE_LIMITS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve();
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  }
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
