import request, { ac_headers } from "@/utils/studio7ApiService";

export function getTTSVoices(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/prompt/tts-voices?" + params.toString(),
    method: "get"
  });
}

export function createTTSVoice(ttsVoice) {
  return request({
    url: "/prompt/tts-voices",
    method: "post",
    data: ttsVoice
  });
}

export function updateTTSVoice(ttsVoice) {
  return request({
    url: `/prompt/tts-voices/${ttsVoice.tts_voice_id}`,
    method: "put",
    data: ttsVoice
  });
}

export function deleteTTSVoice(ttsVoice) {
  return request({
    url: `/prompt/tts-voices/${ttsVoice.tts_voice_id}`,
    method: "delete",
    data: ttsVoice
  });
}

export function syncTTSVoices(provider) {
  return request({
    url: `/prompt/new-third-party-voices/${provider}`,
    method: "get"
  });
}

export function checkUniqueName(data) {
  return request({
    url: `/prompt/validate-tts-voices`,
    method: "post",
    data: data,
    headers: ac_headers()
  });
}
