import request, { ac_headers } from "@/utils/studio7ApiService";

export function getWslProfiles(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/wslprofile/wsl-profiles?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createWslProfile(data) {
  const wslProfile = { ...data, guard_name: "admin" };
  return request({
    url: "/wslprofile/wsl-profiles",
    method: "post",
    data: wslProfile,
    headers: ac_headers()
  });
}

export function updateWslProfile(wslProfile) {
  return request({
    url: `/wslprofile/wsl-profiles/${wslProfile.wsl_profile_id}`,
    method: "put",
    data: wslProfile,
    headers: ac_headers()
  });
}

export function deleteWslProfile(wslProfile) {
  return request({
    url: `/wslprofile/wsl-profiles/${wslProfile.wsl_profile_id}`,
    method: "delete",
    data: wslProfile,
    headers: ac_headers()
  });
}

export function validateWslProfile(name) {
  return request({
    url: `/wslprofile/validate`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}
export function getWslProfilesVoices() {
  return request({
    url: "/wslprofile/profiles-voices",
    method: "get",
    headers: ac_headers()
  });
}

export function getWslAudio(requestObject) {
  return request({
    url: "prompt/get-well-said-audio",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}
