import {
  getHolidaySchedules,
  createHolidaySchedule,
  updateHolidaySchedule,
  deleteHolidaySchedule
} from "@/api/holidayschedules";
import _ from "lodash";

const formatHolidays = (accountHolidaysMap, holidays, ac_id) => {
  let holidayCollection = _.find(accountHolidaysMap, {
    ac_id
  });

  if (holidayCollection === undefined) {
    accountHolidaysMap.push({
      ac_id,
      holidays
    });
  } else {
    holidayCollection.holidays = holidays;
  }
  return accountHolidaysMap;
};

const state = {
  accountHolidaysMap: [],
  loading: false
};

export const getters = {
  holidays(state, getters, rootState) {
    let holidayCollection = _.find(state.accountHolidaysMap, {
      ac_id: rootState.app.selectedAccountId
    });
    if (holidayCollection) {
      return holidayCollection.holidays;
    } else {
      return [];
    }
  },
  holidayScheduleNames(state, getters, rootState) {
    let holidayCollection = _.cloneDeep(
      _.find(state.accountHolidaysMap, {
        ac_id: rootState.app.selectedAccountId
      })
    );
    if (holidayCollection) {
      return _.map(
        _.orderBy(holidayCollection.holidays, ["updated_at"], ["desc"]),
        "schedule_name"
      );
    } else {
      return [];
    }
  },

  getHolidays(state) {
    return _.cloneDeep(state.accountHolidaysMap);
  }
};

const mutations = {
  SET_HOLIDAYS_FOR_ACCOUNT(state, accountHolidaysMap) {
    state.accountHolidaysMap = accountHolidaysMap;
  },

  ADD_HOLIDAY(state, holiday) {
    const holidayCollection = _.find(state.accountHolidaysMap, {
      ac_id: holiday.ac_id
    });

    if (holidayCollection !== undefined) {
      holidayCollection.holidays.push(holiday);
    } else {
      state.accountHolidaysMap.push({
        ac_id: holiday.ac_id,
        holidays: [holiday]
      });
    }
  },

  EDIT_HOLIDAY(state, holiday) {
    const holidayCollection = _.find(state.accountHolidaysMap, {
      ac_id: holiday.ac_id
    });

    if (holidayCollection !== undefined) {
      const index = _.findLastIndex(holidayCollection.holidays, {
        schedule_id: holiday.schedule_id
      });

      if (index === -1) {
        holidayCollection.holidays.push(holiday);
      } else {
        holidayCollection.holidays.splice(index, 1, holiday);
      }
    } else {
      state.accountHolidaysMap.push({
        ac_id: holiday.ac_id,
        holidays: [holiday]
      });
    }
  },

  DELETE_HOLIDAY(state, holiday) {
    const holidayCollection = _.find(state.accountHolidaysMap, {
      ac_id: holiday.ac_id
    });

    if (holidayCollection !== undefined) {
      const index = _.findLastIndex(holidayCollection.holidays, {
        schedule_id: holiday.schedule_id
      });

      holidayCollection.holidays.splice(index, 1);
    }
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  setLoading({ commit }, value) {
    commit("CHANGE_LOADING", value);
  },

  resetVariables({ commit }) {
    commit("SET_HOLIDAYS_FOR_ACCOUNT", []);
  },

  fetchHolidaySchedules({ commit, state, rootState, dispatch }) {
    return new Promise(resolve => {
      if (
        _.find(state.accountHolidaysMap, {
          ac_id: rootState.app.selectedAccountId
        })
      ) {
        resolve(state.accountHolidaysMap);
      } else {
        commit("CHANGE_LOADING", true);
        Promise.resolve(dispatch("forceGetHolidaySchedules")).then(() => {
          commit("CHANGE_LOADING", false);
          resolve(state.accountHolidaysMap);
        });
      }
    });
  },

  getHolidaySchedules({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getHolidaySchedules(options)
        .then(({ data }) => {
          commit("SET_HOLIDAYS_FOR_ACCOUNT", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  forceGetHolidaySchedules({ commit, state, rootState }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      options = { ...options, is_archived: "all" };
      getHolidaySchedules(options)
        .then(({ data }) => {
          const holidays = formatHolidays(
            _.cloneDeep(state.accountHolidaysMap),
            data.data,
            rootState.app.selectedAccountId
          );
          commit("SET_HOLIDAYS_FOR_ACCOUNT", holidays);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  createHolidaySchedule({ commit }, holidaySchedule) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createHolidaySchedule(holidaySchedule)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  refreshHolidaySchedule({ commit, getters }, { content_id, refreshing }) {
    let holidaySchedules = _.map(getters.getHolidays, holidaySchedule => {
      if (holidaySchedule.schedule_id === content_id) {
        holidaySchedule.is_refreshing = refreshing;
      }
      return holidaySchedule;
    });
    commit("SET_HOLIDAYS_FOR_ACCOUNT", holidaySchedules);
  },

  updateHolidaySchedule({ commit }, holidaySchedule) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const holidayScheduleObj = { ...holidaySchedule };
      updateHolidaySchedule(holidayScheduleObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  safeDeleteHolidaySchedule({ commit }, holidaySchedule) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      holidaySchedule.is_archived = 1;
      const holidayScheduleObj = { ...holidaySchedule };
      updateHolidaySchedule(holidayScheduleObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteHolidaySchedule({ commit }, holidaySchedule) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteHolidaySchedule(holidaySchedule)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  undoDeleteHolidaySchedule({ commit }, holidaySchedule) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      holidaySchedule.is_enabled = 1;
      updateHolidaySchedule(holidaySchedule)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
