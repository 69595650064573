export default {
  TYPE_STRING: "STRING",
  TYPE_BOOLEAN: "BOOLEAN",
  TYPE_NUMERIC: "NUMERIC",
  TYPE_CURRENCY_USD: "CURRENCY_USD",
  TYPE_CURRENCY_GPB: "CURRENCY_GPB",
  TYPE_CURRENCY_EUR: "CURRENCY_EUR",
  TYPE_DATE: "DATE",
  TYPE_TIME: "TIME",
  TYPE_KVLIST: "KVLIST"
};
