import { getJavascriptFunctionsListLimitedInfo } from "@/api/javascript";
import _ from "lodash";
import JSTypes from "@/utils/JsContentFnTypeHelper";
import __ from "@/translation";

const copiedExpressionsDefault = {
  expressions: [],
  userSelectedExpression: false,
  finalExpression: ""
};

export const state = {
  hideableOperations: ["lpad", "rpad", "ternary"],
  copiedExpressions: {
    expressions: [],
    userSelectedExpression: false,
    finalExpression: ""
  },
  allowedOpsFor: {
    keyword_finder: [
      "extract_email",
      "extract_number_fixed",
      "extract_number_min_max",
      "extract_letter_fixed",
      "extract_letter_min_max",
      "regex",
      "matches_regex"
    ]
  },
  ruleBuilderFor: ["keyword_finder"],
  regexFlags: [
    {
      label: `<span class="highlight">i</span>nsensitive`,
      desc: __("Case insensitive match"),
      identifier: "i"
    },
    {
      label: `<span class="highlight">m</span>ulti line`,
      desc: __("^ and $ match start/end of line"),
      identifier: "m"
    },
    {
      label: `e<span class="highlight">x</span>tended`,
      desc: __("Ignore whitespace"),
      identifier: "x"
    },
    {
      label: `<span class="highlight">s</span>ingle line`,
      desc: __("Dot matches newline"),
      identifier: "s"
    },
    {
      label: `<span class="highlight">u</span>nicode`,
      desc: __("Match with full unicode"),
      identifier: "u"
    },
    {
      label: `<span class="highlight">U</span>ngreedy`,
      desc: __("Make quantifiers lazy"),
      identifier: "U"
    },
    {
      label: `<span class="highlight">A</span>nchored`,
      // eslint-disable-next-line
      desc: __("Anchor to start of pattern, or at the end of the most recent match"),
      identifier: "A"
    },
    {
      label: `<span class="highlight">J</span>changed`,
      desc: __("Allow duplicate subpattern names"),
      identifier: "J"
    },
    {
      label: `<span class="highlight">D</span>ollar end only`,
      desc: __("$ matches only end of pattern"),
      identifier: "D"
    }
  ],
  operations: [
    {
      label: __("String Operations"),
      operations: [
        {
          value: "text_input",
          label: __("Text Input"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            }
          ]
        },
        {
          value: "strlen",
          label: __("Get String Length"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            }
          ]
        },
        {
          value: "strtoupper",
          label: __("Convert To Uppercase"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            }
          ]
        },
        {
          value: "strtolower",
          label: __("Convert To Lowercase"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            }
          ]
        },
        {
          value: "trim",
          label: __("Trim String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("character list"),
              type: "input",
              default: "",
              info: 'defaults to " \\t\\n\\r\\0\x0B" if empty'
            }
          ]
        },
        {
          value: "rtrim",
          label: __("Trim String From End"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("character list"),
              type: "input",
              default: "",
              info: 'defaults to " \\t\\n\\r\\0\x0B" if empty'
            }
          ]
        },
        {
          value: "ltrim",
          label: __("Trim String From Start"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("character list"),
              type: "input",
              default: "",
              info: 'defaults to " \\t\\n\\r\\0\x0B" if empty'
            }
          ]
        },
        {
          value: "lpad",
          label: __("Left Pad String With A Character"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("length"),
              type: "input"
            },
            {
              tooltip: __("character"),
              type: "input"
            }
          ]
        },
        {
          value: "rpad",
          label: __("Right Pad String With A Character"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("length"),
              type: "input"
            },
            {
              tooltip: __("character"),
              type: "input"
            }
          ]
        },
        {
          value: "substr",
          label: __("Get Substring"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("start index"),
              type: "input"
            },
            {
              tooltip: __("length"),
              type: "input"
            }
          ]
        },
        {
          value: "str_replace",
          label: __("Replace String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("search for"),
              type: "input"
            },
            {
              tooltip: __("replace with"),
              type: "input"
            },
            {
              tooltip: __("Case Sensitive?"),
              type: "checkbox",
              values: [
                { label: __("Case Sensitive"), value: true },
                { label: __("Case Insensitive"), value: false }
              ]
            }
          ]
        },
        {
          value: "str_search",
          label: __("Search String"),
          parameters: [
            {
              tooltip: __("subject string"),
              type: "input"
            },
            {
              tooltip: __("search string"),
              type: "input"
            },
            {
              tooltip: __("Case Sensitive?"),
              type: "checkbox",
              values: [
                { label: __("Case Sensitive"), value: true },
                { label: __("Case Insensitive"), value: false }
              ]
            }
          ]
        },
        {
          value: "extract_email",
          label: __("Extract Email From String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            }
          ]
        },
        {
          value: "extract_number_fixed",
          label: __("Extract Number (fixed) From String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("digit length"),
              type: "input",
              default: "5"
            }
          ]
        },
        {
          value: "extract_number_min_max",
          label: __("Extract Number (min-max) From String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("min digits (optional)"),
              type: "input"
            },
            {
              tooltip: __("max digits (optional)"),
              type: "input"
            }
          ]
        },
        {
          value: "extract_letter_fixed",
          label: __("Extract Letter (fixed) From String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("letter length"),
              type: "input",
              default: "5"
            }
          ]
        },
        {
          value: "extract_letter_min_max",
          label: __("Extract Letter (min-max) From String"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("min letters (optional)"),
              type: "input"
            },
            {
              tooltip: __("max letters (optional)"),
              type: "input"
            }
          ]
        },
        {
          value: "regex",
          label: __("Custom Regex"),
          parameters: [
            {
              tooltip: "text",
              type: "input"
            },
            {
              tooltip: __("regex"),
              info: __("return first match or first matched group"),
              type: "regex_input",
              default: "//im"
            }
          ]
        }
      ]
    },
    {
      label: __("Math Operations"),
      operations: [
        {
          value: "round",
          label: __("Round"),
          parameters: [
            {
              tooltip: __("number"),
              type: "input"
            },
            {
              tooltip: __("precision"),
              type: "input"
            }
          ]
        },
        {
          value: "floor",
          label: __("Floor"),
          parameters: [
            {
              tooltip: __("number"),
              type: "input"
            }
          ]
        },
        {
          value: "ceil",
          label: __("Ceil"),
          parameters: [
            {
              tooltip: __("number"),
              type: "input"
            }
          ]
        },
        {
          value: "math_operations",
          label: __("Math Operations"),
          parameters: [
            {
              tooltip: __("value1"),
              type: "input"
            },
            {
              tooltip: __("Operator"),
              type: "dropdown",
              values: [
                { label: __("Add"), value: "+" },
                { label: __("Subtract"), value: "-" },
                { label: __("Multiply"), value: "*" },
                { label: __("Divide"), value: "/" },
                { label: __("Modulo"), value: "%" }
              ],
              required: true
            },
            {
              tooltip: __("value2"),
              type: "input"
            }
          ]
        },
        {
          value: "random_number",
          label: __("Random Number"),
          parameters: [
            {
              tooltip: __("Min"),
              type: "input"
            },
            {
              tooltip: __("Max"),
              type: "input"
            }
          ]
        }
      ]
    },
    {
      label: __("Logical Operations"),
      operations: [
        {
          value: "boolean_input",
          label: __("Boolean Input"),
          parameters: [
            {
              tooltip: __("value"),
              type: "dropdown",
              values: [
                { label: __("true"), value: true },
                { label: __("false"), value: false }
              ]
            }
          ]
        },
        {
          value: "compare",
          label: __("Compare"),
          parameters: [
            {
              tooltip: __("treat input as"),
              type: "dropdown",
              values: [
                { label: __("Number"), value: "number" },
                { label: __("Text"), value: "text" },
                { label: __("Boolean"), value: "bool" }
              ]
            },
            {
              tooltip: __("value1"),
              type: "input"
            },
            {
              tooltip: __("Operator"),
              type: "dropdown",
              valuesFn: function(param) {
                switch (param) {
                  case "bool":
                    return [
                      { label: __("Equal To"), value: "==" },
                      { label: __("Not Equal To"), value: "!=" }
                    ];
                  default:
                    return [
                      { label: __("Greater Than"), value: ">" },
                      { label: __("Greater Than or Equal To"), value: ">=" },
                      { label: __("Equal To"), value: "==" },
                      { label: __("Less Than"), value: "<" },
                      { label: __("Less Than or Equal To"), value: "<=" },
                      { label: __("Not Equal To"), value: "!=" }
                    ];
                }
              },
              required: true
            },
            {
              tooltip: __("value2"),
              type: "input"
            }
          ]
        },
        {
          value: "conditions",
          label: __("Conditions(AND/OR)"),
          parameters: [
            {
              tooltip: __("value1"),
              type: "input"
            },
            {
              tooltip: __("Operator"),
              type: "dropdown",
              values: [
                { label: __("AND"), value: "AND" },
                { label: __("OR"), value: "OR" }
              ],
              required: true
            },
            {
              tooltip: __("value2"),
              type: "input"
            }
          ]
        },
        {
          value: "matches_regex",
          label: __("Matches Regex"),
          parameters: [
            {
              tooltip: __("text"),
              type: "input"
            },
            {
              tooltip: __("regex"),
              type: "regex_input",
              default: "//im"
            }
          ]
        },
        {
          value: "ternary",
          label: __("Ternary"),
          parameters: [
            {
              tooltip: __("if"),
              type: "input"
            },
            {
              tooltip: __("then"),
              type: "input"
            },
            {
              tooltip: __("else"),
              type: "input"
            }
          ]
        }
      ]
    },
    {
      label: __("Premium Operations"),
      operations: [
        {
          value: "get_number_type",
          label: __("Get Number Type"),
          parameters: [
            {
              tooltip: __("ANI"),
              type: "input"
            }
          ]
        }
      ]
    },
    {
      label: __("JavaScript Function"),
      operations: [
        {
          value: __("Loading..."),
          label: "",
          parameters: []
        }
      ]
    },
    {
      label: __("JSON"),
      operations: [
        {
          value: "json_decode",
          label: __("Decode JSON string"),
          parameters: [
            {
              tooltip: __("stringified json"),
              type: "input"
            }
          ]
        },
        {
          value: "get_array_element",
          label: __("Get Element From JSON"),
          parameters: [
            {
              tooltip: __("json"),
              type: "json-dropdown"
            },
            {
              tooltip: __("path"),
              type: "array-rule"
            }
          ]
        },
        {
          value: "array_length",
          label: __("Get Array Length"),
          parameters: [
            {
              tooltip: __("json"),
              type: "json-dropdown"
            },
            {
              tooltip: __("path"),
              type: "array-rule"
            }
          ]
        },
        {
          value: "get_xsi_event_prop",
          label: __("Get XSI event property"),
          parameters: [
            {
              tooltip: __("event"),
              type: "xsi-dropdown"
            },
            {
              tooltip: __("path"),
              type: "array-rule"
            }
          ]
        }
      ]
    },
    {
      label: __("Others"),
      operations: [
        {
          value: "datetime",
          label: __("Date Time"),
          parameters: [
            {
              tooltip: __("date time format"),
              type: "input",
              default: "Y-m-d H:i:s O"
            },
            {
              tooltip: __("For"),
              type: "input",
              default: "Now"
            },
            {
              tooltip: __("parse to UTC"),
              type: "checkbox",
              values: [
                { label: __("In UTC"), value: true },
                { label: __("In Account/Server Timezone"), value: false }
              ]
            }
          ]
        },
        {
          value: "in_timezone",
          label: __("Date Time in Timezone"),
          parameters: [
            {
              tooltip: __("date time format"),
              type: "input",
              default: "Y-m-d H:i:s O"
            },
            {
              tooltip: __("For"),
              type: "input",
              default: "Now"
            },
            {
              tooltip: __("Select Timezone"),
              type: "tz-dropdown"
            }
          ]
        },
        {
          value: "strtotime",
          label: __("String To Timestamp"),
          parameters: [
            {
              tooltip: __("For"),
              type: "input",
              default: "Now"
            }
          ]
        }
      ]
    }
  ],

  // List of user defined JavaScript operations
  jsContentOperations: {}
};

export const getters = {
  getHideableOperations: state => state.hideableOperations,
  functions: state =>
    _.uniq(_.flatMapDeep(_.map(state.operations, "operations"))),

  javascriptFunctions: state =>
    state.operations.find(
      operation => operation.label === __("JavaScript Function")
    ),

  /**
   * Get the list of javascript function descriptor
   * @param {*} state
   * @returns Key-value pair of user-defined javascript function name as the key and the descriptor as the value
   */
  javascriptFunctionsDescriptor: state => state.jsContentOperations,

  maxParameters: (state, getters) =>
    _.max(_.map(getters.functions, func => func.parameters.length)),

  selectedFunction: (state, getters) => selectedFunction =>
    _.cloneDeep(
      _.find(getters.functions, {
        value: selectedFunction
      })
    ),

  getRangeOfParameters: (state, getters) => _.range(getters.maxParameters),

  parametersFor: (state, getters) => selectedFunction => {
    const selectedFunctionObj = getters.selectedFunction(selectedFunction);
    return _.isEmpty(selectedFunctionObj) ? [] : selectedFunctionObj.parameters;
  },

  parameterCountFor: (state, getters) => selectedFunction =>
    getters.parametersFor(selectedFunction).length,

  //json_decode is not included here, because the input to the function is a string even though its grouped inside json functions
  jsonFunctions: () => [
    "get_array_element",
    "array_length",
    "get_xsi_event_prop"
  ],

  isJsonFunction: (state, getters) => func =>
    getters.jsonFunctions.includes(func),

  variablesAndExpressionsCanBeFoundInTypes: () => [
    "input",
    "array-rule",
    "json-dropdown",
    "tz-dropdown"
  ],

  isVariablesAndExpressionsCanBeFoundInType: (state, getters) => type =>
    getters.variablesAndExpressionsCanBeFoundInTypes.includes(type),

  functionsThatCannotBeFinal: () => ["json_decode"],

  getFunctionLabel: (state, getters) => func => {
    const selectedFunction = getters.selectedFunction(func);
    return _.isEmpty(selectedFunction) ? "" : selectedFunction.label || "";
  },

  isSourceARuleBuilder: state => source => {
    return state.ruleBuilderFor.includes(source);
  }
};

export const mutations = {
  COPY_EXPRESSIONS(state, expressions) {
    state.copiedExpressions = _.cloneDeep(expressions);
  },
  CLEAR_COPY_EXPRESSIONS(state) {
    state.copiedExpressions = _.cloneDeep(copiedExpressionsDefault);
  },
  CHANGE_EXP_PARAMETERS(state, { index, param }) {
    state.operations.splice(index, 1, param);
  },
  /**
   * Set user javascript functions
   * @param state
   * @param functions
   * @constructor
   */
  SET_USER_JAVASCRIPT_FUNCTIONS(state, functions) {
    // Update list of operations for user-defined JS
    state.operations = state.operations.map(allOps => {
      if (allOps.label === "JavaScript Function") {
        allOps.operations = functions;
      }
      return allOps;
    });

    // Update list of JS functions
    state.jsContentOperations = functions.reduce((prev, cur) => {
      prev[cur.value] = cur.js_content;
      return prev;
    }, []);
  }
};

export const actions = {
  /**
   * update user javascript functions
   * @param commit
   * @returns {Promise<unknown>}
   */
  updateUserJavascript({ commit }) {
    return new Promise((resolve, reject) => {
      getJavascriptFunctionsListLimitedInfo()
        .then(({ data }) => {
          commit(
            "SET_USER_JAVASCRIPT_FUNCTIONS",
            data.data.map(userScriptData => {
              return {
                value: `jsf_${userScriptData.javascript_function_id}`,
                label: userScriptData.name,
                parameters: userScriptData.javascript_function_parameters.map(
                  funcParams => {
                    return {
                      tooltip: funcParams.name,
                      type: "input"
                    };
                  }
                ),
                js_content: {
                  is_kvlist:
                    userScriptData.function_return_type === JSTypes.TYPE_KVLIST
                }
              };
            })
          );
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  copyExpressions({ commit }, expressions) {
    return new Promise(resolve => {
      if (!_.isEmpty(expressions)) {
        commit("COPY_EXPRESSIONS", expressions);
      }
      resolve();
    });
  },

  clearCopyExpressions({ commit }) {
    return new Promise(resolve => {
      commit("CLEAR_COPY_EXPRESSIONS");
      resolve();
    });
  },
  setExpressionValue({ commit }, function_name) {
    return new Promise(resolve => {
      if (!_.isEmpty(function_name)) {
        let param = {
          tooltip: __("Subscription"),
          type: "dropdown",
          values: [{ label: __("Subscription"), value: "text" }]
        };
        var index = _.findIndex(state.operations, operation => {
          return operation.label === __("JSON");
        });
        commit("CHANGE_EXP_PARAMETERS", { index: index, param: param });
      }
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
