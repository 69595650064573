import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

export function getAppFeatures(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/billing/application-features?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}
export function getAcAppFeatures(ac_id, plan_type, ac_plan_id) {
  return request({
    url: `/billing/ac-app-features/${ac_id}/${plan_type}/${ac_plan_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function getFeatureUsageAndLimit(featureName) {
  return request({
    url: `/billing/feature-usage-and-limit/${featureName}`,
    method: "get",
    headers: ac_headers()
  });
}

export function getEnabledFeaturesForUser(accountId) {
  return request({
    url: "/billing/ac-enabled-features/" + accountId,
    method: "get",
    headers: sp_headers()
  });
}
