import store from "@/store";
import __ from "@/translation";

export const deleteNode = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/askPermissionForDelete", {
      id,
      deleteType: "delete_node"
    });
  }
};

export default {
  text: `${__("Delete Node")}<span>Ctrl+D</span>`,
  icon: "",
  onClick: deleteNode
};
