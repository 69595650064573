import request, { ac_headers } from "@/utils/studio7ApiService";

export function getEventLogs(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/node/event-logs?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}
