import {
  getDataStoresCollection,
  insertDataStoreDocument,
  bulkDeleteDataStoreDocuments,
  bulkUpdateDataStoreDocuments
} from "@/api/datastorecollection";

import {
  cancelPendingRequests,
  generateCancelToken,
  isErrorDueToCancelledToken
} from "@/utils/studio7ApiService";

const state = {
  datastorescollection: [],
  requestData: {},
  loading: false,
  cancelTokens: []
};

const mutations = {
  SET_DATASTORES_COLLECTION(state, datastorescollection) {
    state.datastorescollection = datastorescollection;
  },
  SET_REQUEST_DATA(state, data) {
    state.requestData = data;
  },
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  ADD_TO_CANCEL_TOKENS(state, token) {
    state.cancelTokens.push(token);
  },

  SET_CANCEL_TOKENS(state, tokens) {
    state.cancelTokens = tokens;
  }
};

const actions = {
  getDataStoresCollection({ commit, state }, condition) {
    cancelPendingRequests(state.cancelTokens);
    let mergedConditions = { ...condition, ...state.requestData };

    // get a new cancel token
    let cancelToken = generateCancelToken();
    commit("ADD_TO_CANCEL_TOKENS", cancelToken);
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getDataStoresCollection(mergedConditions, cancelToken.token)
        .then(({ data }) => {
          commit("SET_DATASTORES_COLLECTION", data.data);
          cancelPendingRequests(state.cancelTokens);
          commit("SET_CANCEL_TOKENS", []);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          if (!isErrorDueToCancelledToken(err)) {
            commit("CHANGE_LOADING", false);
          }
          reject(err);
        });
    });
  },

  setRequestData({ commit }, data) {
    commit("SET_REQUEST_DATA", data);
  },

  insertDataStoreDocument({ commit }, documentData) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      insertDataStoreDocument(documentData)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  bulkUpdateDataStoreDocuments({ commit }, documentData) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      bulkUpdateDataStoreDocuments(documentData)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  bulkDeleteDataStoreDocuments({ commit }, documentData) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      bulkDeleteDataStoreDocuments(documentData)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
