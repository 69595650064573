import Layout from "@/layout";
import __ from "@/translation";

const buildModuleRouter = {
  path: "/build",
  component: Layout,
  redirect: "/",
  meta: {
    auth: true,
    icon: "icon-build.svg",
    singleChild: false,
    get title() {
      return __("Build");
    },
    index: "ac-1"
  },
  children: [
    {
      path: "tasks",
      component: () => import("@/views/build/tasks/index"),
      redirect: "/build/tasks",
      meta: {
        get title() {
          return __("Tasks");
        },
        icon: "icon-tasks.svg",
        index: "ac-1-1",
        permissions: ["build.tasks"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/build/tasks/pages/list"),
          name: "tasks",
          meta: {
            get title() {
              return __("Tasks");
            },
            permissions: ["build.tasks"]
          },
          props: true,
          hidden: true
        },
        {
          path: "library",
          component: () =>
            import("@/views/build/tasks/pages/CreateTaskOptions"),
          name: "addTaskFromLibrary",
          meta: {
            get title() {
              return __("Add Task");
            }
          },
          props: true,
          hidden: true
        },
        {
          path: "create-from-library",
          component: () =>
            import("@/views/build/tasks/create-task/TemplateDetails"),
          name: "enterTaskDetails",
          meta: {
            get title() {
              return __("A kind of blank page");
            }
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "callflow/:task_id(\\d+)/:nav_to_node_id(\\d+)?",
      component: () => import("@/views/build/callflow/pages/CallFlowEditor"),
      name: "callflow",
      meta: {
        get title() {
          return __("Edit CallFlow");
        }
      },
      props: true,
      hidden: true
    },
    {
      path: "callflow/:task_id(\\d+)/edit/:open_node_id",
      component: () => import("@/views/build/callflow/pages/CallFlowEditor"),
      name: "edit_node",
      meta: {
        get title() {
          return __("Edit Node");
        }
      },
      props: true,
      hidden: true
    },
    {
      path: "campaigns",
      component: () => import("@/views/build/campaigns/index"),
      redirect: "/build/campaigns",
      meta: {
        get title() {
          return __("Campaigns");
        },
        icon: "icon-campaigns.svg",
        index: "ac-1-2",
        permissions: ["build.campaigns"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/build/campaigns/index"),
          name: "campaign",
          meta: {
            get title() {
              return __("Campaign");
            },
            permissions: ["build.campaigns"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    // {
    //   path: "call-filters",
    //   component: () => import("@/views/build/call-filters/index"),
    //   meta: {
    //     title: "Call Filters",
    //     icon: "el-icon-document-copy",
    //     index: "ac-1-4"
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/build/call-filters/pages/index"),
    //       name: "call-filters",
    //       meta: {
    //         title: "Call Filters"
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
    {
      path: "content",
      component: () => import("@/views/build/content/index"),
      meta: {
        get title() {
          return __("Content");
        },
        icon: "icon-content.svg",
        index: "ac-1-3",
        permissions: ["build.content"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/build/content/index"),
          name: "content",
          meta: {
            get title() {
              return __("Content");
            },
            permissions: ["build.content"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "qforme",
      component: () => import("@/views/build/qforme/index"),
      meta: {
        get title() {
          return __("QforMe");
        },
        icon: "icon-queue.svg",
        index: "ac-1-4",
        permissions: ["build.qforme"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/build/qforme/index"),
          name: "qforme",
          meta: {
            get title() {
              return __("QforMe");
            },
            permissions: ["build.qforme"]
          },
          props: true,
          hidden: true
        }
      ]
    }
    // {
    //   path: "email-profiles",
    //   component: () => import("@/views/build/emailprofiles/index"),
    //   meta: {
    //     title: "Email Profiles",
    //     icon: "el-icon-headset",
    //     index: "ac-1-4"
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/build/emailprofiles/index"),
    //       name: "emailprofiles",
    //       meta: {
    //         title: "Email Profiles"
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
  ]
};

export default buildModuleRouter;
