import {
  getAccountsForServiceProvider,
  createAccount,
  updateAccount,
  deleteAccount,
  bulkDeleteAccounts,
  getDomainInfo,
  getDomainSkills,
  getAccountsBySpId,
  getAccountData,
  forceDeleteAccount,
  flushCache
} from "@/api/accounts";

import { fetchUser } from "@/api/auth";

import _ from "lodash";

const state = {
  accounts: [],
  accountData: {},
  timezones: [],
  isLoading: false,
  accountEnabled: 1,
  accountMigrationStates: {
    S7_GA: "S7_GA: Fully migrated to Studio 7",
    S7_BETA: "S7_BETA: Customer testing Studio 7",
    S7_F9: "S7_F9: Five9 Testing"
  }
};

const mutations = {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },

  CREATE_ACCOUNT(state, account) {
    state.accounts.push(account);
  },

  UPDATE_ACCOUNT(state, { index, account }) {
    state.accounts.splice(index, 1, account);
  },

  DELETE_ACCOUNT(state, account) {
    state.accounts = state.accounts.filter(
      oldAccount => oldAccount.ac_id !== account.ac_id
    );
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_ACCOUNT_FILTER_KEY(state, filter) {
    state.accountEnabled = filter;
  },

  SET_ACCOUNT_DATA(state, accountData) {
    state.accountData = accountData;
  }
};

const actions = {
  // addNewApiKeyToAccount({ commit }, account) {
  //   return new Promise((resolve, reject) => {
  //     commit("UPDATE_LOADING_STATUS", true);
  //     addApiKey(account)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       })
  //       .finally(() => {
  //         commit("UPDATE_LOADING_STATUS", false);
  //       });
  //   });
  // },
  getAccountsForServiceProvider({ commit }, options) {
    return new Promise((resolve, reject) => {
      let mergedOptions = { ...options, account_enabled: 1 };
      commit("UPDATE_LOADING_STATUS", true);
      getAccountsForServiceProvider(mergedOptions)
        .then(({ data }) => {
          commit("SET_ACCOUNTS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  getAllAccountsForSystem({ commit }, options) {
    return new Promise((resolve, reject) => {
      Object.assign(options, { fetch_all: 1, system_access: 1 });
      commit("UPDATE_LOADING_STATUS", true);
      getAccountsForServiceProvider(options)
        .then(({ data }) => {
          commit("SET_ACCOUNTS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  getPaginatedAccountsForSystem({ commit }, options) {
    return new Promise((resolve, reject) => {
      let baseOptions = {
        fetch_all: 0,
        system_access: 1,
        account_enabled: state.accountEnabled
      };

      options =
        options !== undefined
          ? Object.assign(options, baseOptions)
          : baseOptions;

      commit("UPDATE_LOADING_STATUS", true);
      getAccountsForServiceProvider(options)
        .then(({ data }) => {
          commit("SET_ACCOUNTS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  getPaginatedAccountsForSP({ commit }, options) {
    return new Promise((resolve, reject) => {
      let baseOptions = {
        fetch_all: 0,
        system_access: 0,
        account_enabled: state.accountEnabled
      };
      options =
        options !== undefined
          ? Object.assign(options, baseOptions)
          : baseOptions;

      commit("UPDATE_LOADING_STATUS", true);
      getAccountsForServiceProvider(options)
        .then(({ data }) => {
          commit("SET_ACCOUNTS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },
  setAccountsFilter({ commit }, filter) {
    commit("SET_ACCOUNT_FILTER_KEY", filter);
  },
  createAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createAccount(account)
        .then(({ data }) => {
          commit("CREATE_ACCOUNT", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
          fetchUser()
            .then(() => resolve())
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * update account information
   * @param commit
   * @param state
   * @param account
   * @returns {Promise<unknown>}
   */
  updateAccount({ commit, state }, account) {
    return new Promise((resolve, reject) => {
      const accountObj = { ...account };
      commit("UPDATE_LOADING_STATUS", true);
      updateAccount(accountObj)
        .then(({ data }) => {
          var index = _.findIndex(state.accounts, oldAccount => {
            return (
              oldAccount.ac_id !== undefined &&
              oldAccount.ac_id === account.ac_id
            );
          });
          commit("UPDATE_ACCOUNT", { index, account: data.data });
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
          fetchUser()
            .then(() => resolve())
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteAccount(account)
        .then(data => {
          commit("DELETE_ACCOUNT", account);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);

          fetchUser()
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  flushCache({ commit }, account) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      flushCache(account.ac_id)
        .then(msg => {
          resolve(msg); // when succeeded, toast message
        })
        .catch(err => {
          console.log(err);
          reject(err); // when failed, toast messsage with error type
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  forceDeleteAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      forceDeleteAccount(account)
        .then(data => {
          commit("DELETE_ACCOUNT", account);
          resolve(data);
        })
        .catch(err => {
          // 404 means the user has been deleted by the background job already so consider it a success
          if (err.isAxiosError && err.response.status === 404) {
            commit("DELETE_ACCOUNT", account);
            resolve();
            return;
          }

          reject(err);
        });
    });
  },

  bulkDeleteAccounts({ commit }, accounts) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      bulkDeleteAccounts(accounts)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data.data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      account.is_enabled = 1;
      updateAccount(account)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);

          fetchUser()
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getDomainInfo({ commit }, domainId) {
    return new Promise((resolve, reject) => {
      getDomainInfo(domainId)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  getDomainSkills({ commit }, domainId) {
    return new Promise((resolve, reject) => {
      getDomainSkills(domainId)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  getAccountsBySpId({ commit }, sp_id) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAccountsBySpId(sp_id)
        .then(({ data }) => {
          commit("SET_ACCOUNTS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * get account information
   * @param commit
   * @param ac_id
   * @param state
   * @returns {Promise<unknown>}
   */
  getAccountData({ commit }, ac_id) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAccountData(ac_id)
        .then(({ data }) => {
          commit("SET_ACCOUNT_DATA", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("SET_ACCOUNT_DATA", {});
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

const getters = {
  acName: state => ac_id => {
    let account = _.find(state.accounts, {
      ac_id: parseInt(ac_id)
    });
    return account ? account.ac_name : "Error";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
