import request, { sp_headers } from "@/utils/studio7ApiService";

export function getServiceProviders(options) {
  options = { ...options, fetch_all: 1 };
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/service-providers?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function getServiceProviderData(sp_id) {
  return request({
    url: `/portal/service-providers/${sp_id}`,
    method: "get",
    headers: sp_headers()
  });
}

export function getAllServiceProviders(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/service-providers?" + params.toString(),
    method: "get",
    headers: { scope: "sp", "scope-id": "all", "convert-timezone": 1 }
  });
}

/**
 * get Service Providers with pagination
 * @param options
 * @returns A list of Service Providers with Pagination info
 */
export function getServiceProvidersWithPagination(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/service-providers?" + params.toString(),
    method: "get",
    headers: { scope: "sp", "scope-id": "all", "convert-timezone": 1 }
  });
}

export function createServiceProvider(data) {
  const serviceProvider = { ...data, guard_name: "admin" };
  return request({
    url: "/portal/service-providers",
    method: "post",
    data: serviceProvider,
    headers: sp_headers()
  });
}

export function updateServiceProvider(serviceProvider) {
  return request({
    url: `/portal/service-providers/${serviceProvider.sp_id}`,
    method: "put",
    data: serviceProvider,
    headers: sp_headers()
  });
}

export function deleteServiceProvider(serviceProvider) {
  return request({
    url: `/portal/service-providers/${serviceProvider.sp_id}`,
    method: "delete",
    data: serviceProvider,
    headers: sp_headers()
  });
}

export function getFormContents() {
  return request({
    url: `/portal/service-providers/form-contents`,
    method: "get"
  });
}

export function getTableColumns() {
  return request({
    url: `/portal/service-providers/table-columns`,
    method: "get"
  });
}

export function getSpApiKeys(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/sp/api-keys?" + params.toString(),
    method: "get"
  });
}

export function moveAccountToSp(options) {
  // const params = new URLSearchParams(options);
  return request({
    url: "/portal/moveAccountToSp",
    data: options,
    method: "post"
  });
}

export function storeSpSetting(data) {
  return request({
    url: "/portal/sp-settings",
    method: "post",
    data: data,
    headers: sp_headers()
  });
}

export function getThemeColors() {
  return request({
    url: "/portal/sp-theme-colors",
    method: "get",
    headers: sp_headers()
  });
}

export function getEmailSetting() {
  return request({
    url: "/portal/sp-email-settings",
    method: "get",
    headers: sp_headers()
  });
}

export function storeEmailSetting(data) {
  return request({
    url: "/portal/sp-email-settings",
    method: "post",
    data: data,
    headers: sp_headers()
  });
}

export function validateCustomEmailProfile(data) {
  return request({
    url: "/portal/validate-custom-email-profile",
    method: "post",
    data: data,
    headers: sp_headers()
  });
}

export function checkUniqueSubdomain(data) {
  return request({
    url: `/portal/validate-subdomain`,
    method: "post",
    data: data
  });
}

export function flushCache(sp_id) {
  return request({
    url: `/portal/clear-service-provider-cache`,
    method: "post",
    data: { sp_ids: [sp_id] }
  });
}
