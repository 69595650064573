import { getEventLogs } from "@/api/eventlogs";

const state = {
  eventlogs: []
};

export const getters = {
  eventLogs(state) {
    if (state.eventlogs) {
      return state.eventlogs;
    } else {
      return [];
    }
  }
};
const mutations = {
  SET_EVENT_LOGS(state, eventlogs) {
    state.eventlogs = eventlogs;
  },
  REMOVE_EVENT_LOG(state, id) {
    state.eventlogs.splice(id, 1);
  },
  RESET_EVENT_LOGS(state) {
    state.eventlogs = [];
  }
};

const actions = {
  getEventLogs({ commit }, options) {
    return new Promise((resolve, reject) => {
      getEventLogs(options)
        .then(({ data }) => {
          commit("SET_EVENT_LOGS", data.result);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeEventLog({ commit }, id) {
    commit("REMOVE_EVENT_LOG", id);
  },
  resetEventLogs({ commit }) {
    commit("RESET_EVENT_LOGS");
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
