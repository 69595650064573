import request, { ac_headers, sp_headers } from "@/utils/studio7ApiService";

export function getLatestAnnouncements() {
  return request({
    url: "/dashboard/announcements/latest",
    method: "get",
    headers: ac_headers()
  });
}

export function loadDashboardStats(scope) {
  return request({
    url: "/dashboard/stats",
    method: "get",
    headers: scope === "ac" ? ac_headers() : sp_headers()
  });
}
