import { getThemeColors } from "@/api/serviceproviders";
import { resetStateColors } from "@/utils/shades";

const state = {
  colors: {
    theme_color: "#244CDE",
    hover_color: "#0e97d4",
    row_hover_color: "#EEF1FC",
    outline_color: "#9cd6fc"
  },
  resetColor: {
    theme_color: "#244CDE",
    hover_color: "#0e97d4",
    row_hover_color: "#EEF1FC",
    outline_color: "#9cd6fc"
  },
  loading: false
};

const mutations = {
  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_THEME_COLORS(state, colors) {
    state.colors = colors;
  },
  RESET_THEME_COLORS(state) {
    state.colors = state.resetColor;
  }
};

const actions = {
  getThemeColors({ commit, state }) {
    return new Promise((resolve, reject) => {
      getThemeColors()
        .then(({ data }) => {
          if (data.data.theme_color) {
            commit("SET_THEME_COLORS", data.data);
            resetStateColors(state.colors);
          } else {
            commit("RESET_THEME_COLORS");
            resetStateColors(state.resetColor);
          }
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("CHANGE_LOADING", false);
        });
    });
  },

  setThemeColors({ commit }, colors) {
    if (colors !== null) {
      commit("SET_THEME_COLORS", colors);
      resetStateColors(colors);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
