import {
  createPaymentGateway,
  deletePaymentGateway,
  getPaymentGateways,
  updatePaymentGateway
} from "@/api/paymentgateways";
import _ from "lodash";

const state = {
  paymentGateways: [],
  loading: false
};

const getters = {
  getPaymentGateway: state => payment_gateway_id => {
    return state.paymentGateways.find(
      paymentGateway => paymentGateway.payment_gateway_id === payment_gateway_id
    );
  },
  /**
   * get payment gateways sorted alphabetically by name
   * @param state
   * @returns {[]|*|unknown[]}
   */
  getPaymentGatewaysSortedByName: state => {
    if (!_.isEmpty(state.paymentGateways)) {
      return _.orderBy(
        state.paymentGateways,
        [
          paymentGateway => paymentGateway["payment_gateway_name"].toLowerCase()
        ],
        ["asc"]
      );
    }
    return state.paymentGateways;
  }
};

const mutations = {
  SET_PAYMENT_GATEWAYS(state, paymentGateways) {
    state.paymentGateways = paymentGateways;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getPaymentGateways({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getPaymentGateways(options)
        .then(({ data }) => {
          let paymentGateways = data.data;
          commit("SET_PAYMENT_GATEWAYS", paymentGateways);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createPaymentGateway({ commit }, paymentGateway) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      createPaymentGateway(paymentGateway)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updatePaymentGateway({ commit }, paymentGateway) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      const paymentGatewayObj = { ...paymentGateway };
      updatePaymentGateway(paymentGatewayObj)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },

  deletePaymentGateway({ commit }, paymentGateway) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deletePaymentGateway(paymentGateway)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeletePaymentGateway({ commit }, paymentGateway) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      paymentGateway.is_enabled = 1;
      updatePaymentGateway(paymentGateway)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
