import localStorageSession from "@/utils/localStorageManager";
import sessionStorageSession from "@/utils/sessionManager";
import { logout } from "@/api/auth";
import store from "@/store";

export const processRequestTimeStamp = async config => {
  // store timestamp for each subsequent request other than refresh and logout request
  if (!["/auth/refresh", "/auth/logout"].includes(config.url)) {
    // Check if session expired before storing request timestamp
    const sessionExpired = await isSessionTimeout();
    if (!sessionExpired) {
      // Add time expiry for about 2 hours from now
      let ttl = new Date().getTime() + 2 * 60 * 60 * 1000;
      let accessToken = localStorageSession.get("studio7-user-token");
      // build session timeout payload
      const payload = {
        url: config.url,
        token: accessToken,
        expiry: ttl
      };
      localStorageSession.set("timeout-payload", payload);
    }
  }
};

export const isSessionTimeout = async () => {
  const timeOutPayload = localStorageSession.get("timeout-payload");
  const accessToken = localStorageSession.get("studio7-user-token");

  // Check if user logged in before checking session expiry
  if (accessToken) {
    // compare the expiry time of the item with the current time
    if (timeOutPayload && new Date().getTime() > timeOutPayload.expiry) {
      console.log("timeout");
      // If the item is expired, clear the session and local storage and logout from studio
      localStorageSession.remove("timeout-payload");
      sessionStorageSession.clearAll();
      store.dispatch("app/resetAppState");

      await logout();
      return true;
    }
  }

  return false;
};
