import _ from "lodash";

let account = {
  id: null, //StudioAccountId
  name: null, //studioAccountName
  domainId: null, //domainId
  domainName: null, //domainName
  jurisdictionId: null //studioJurisdiction,
};

let user = {
  user_id: null,
  name: null,
  email: null,
  role: null
};

function setCustomAttribute(key, value) {
  _.set(account, key, value);
}

function getCustomAttribute(key) {
  return _.get(account, key);
}

function getUserAttribute(key) {
  return _.get(user, key, null);
}

function setUserAttribute(key, value) {
  _.set(user, key, value);
}

function setGainSightIdentityAccountObject(account) {
  if (account) {
    setCustomAttribute(
      "ac_id",
      // eslint-disable-next-line no-prototype-builtins
      account.hasOwnProperty("ac_id") ? account.ac_id : ""
    );
    setCustomAttribute(
      "domain_id",
      // eslint-disable-next-line no-prototype-builtins
      account.hasOwnProperty("domain_id") ? account.domain_id : ""
    );
    setCustomAttribute(
      "jurisdiction_id",
      // eslint-disable-next-line no-prototype-builtins
      account.hasOwnProperty("jurisdictionId") ? account.jurisdictionId : ""
    );
  }
}

function setGainSightIdentityUserObject(user) {
  if (user) {
    let firstName = null;
    let lastName = null;

    if (user.display_name !== undefined && user.display_name !== null) {
      let name = user.display_name.split(/\s+/);
      firstName = name[0];
      lastName = name[1];
    }
    setUserAttribute("user_id", user.user_id);
    setUserAttribute("email", user.email);
    setUserAttribute("firstName", firstName);
    setUserAttribute("lastName", lastName);
    setUserAttribute("role", _.head(user.roles));
  }
}

function set() {
  window.aptrinsic(
    "set",
    "user",
    {
      id:
        getCustomAttribute("jurisdictionId") !== null
          ? getCustomAttribute("jurisdictionId") +
            "_" +
            getUserAttribute("user_id")
          : getUserAttribute("user_id")
    },
    {
      id: getCustomAttribute("ac_id"),
      jurisdictionId: getCustomAttribute("jurisdictionId"),
      domainId: getCustomAttribute("domainId")
      //   name: DOMAIN_NAME,
      //   domainId: DOMAIN_ID,
      //   domainName: DOMAIN_NAME,
    }
  );
}

function identify() {
  window.aptrinsic(
    "identify",
    {
      id:
        getCustomAttribute("jurisdictionId") !== null
          ? getCustomAttribute("jurisdictionId") +
            "_" +
            getUserAttribute("user_id")
          : getUserAttribute("user_id"),
      email: getUserAttribute("email"),
      firstName: getUserAttribute("firstName"),
      lastName: getUserAttribute("lastName"),
      role: getUserAttribute("role")
    },
    {
      id: getCustomAttribute("ac_id"),
      jurisdictionId: getCustomAttribute("jurisdictionId"),
      domainId: getCustomAttribute("domain_id")
      //   domainId: DOMAIN_ID,
      //   domainName: DOMAIN_NAME,
    }
  );
}

export default {
  identify,
  set,
  setGainSightIdentityAccountObject,
  setGainSightIdentityUserObject,
  setCustomAttribute,
  getUserAttribute,
  getCustomAttribute
};
