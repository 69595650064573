import store from "@/store";
import __ from "@/translation";

const unlinkBranch = id => {
  if (!store.getters["canvas/isTaskReadOnly"]) {
    store.dispatch("canvas/unlinkNode", {
      id,
      unlinkType: "unlink_branch"
    });
  }
};

export default {
  text: __("Unlink Branch"),
  icon: "",
  onClick: unlinkBranch
};
