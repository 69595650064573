import {
  getCallUsage,
  getDetailedUsageLog,
  getWorkflowUsage,
  getMessagingUsage,
  getDetailedMsgUsageLog,
  getChatbotUsages,
  getChatbotDetailedLog,
  getVoiceDetailedLog,
  getBiometricUsage,
  getBiometricDetailedLog,
  getTranscriptionUsage,
  getTranscriptionDetailedLog,
  getToneAnalyzerUsage,
  getToneAnalyzerDetailedLog,
  getNumberInsightUsage,
  getNumberInsightDetailedLog
} from "@/api/usages";
// import { fetchUser } from "@/api/auth";

const state = {
  loading: false,
  voiceUsages: [],
  messagingUsages: [],
  chatbotUsages: [],
  biometricUsages: [],
  workflowUsages: [],
  transcriptionUsages: [],
  toneAnalyzerUsages: [],
  numberInsightUsages: [],
  taskForDetailedLogs: null,
  dateSearchRange: [],
  detailedLogs: []
};

const getters = {};

const mutations = {
  CHANGE_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_VOICE_USAGE(state, usage) {
    state.voiceUsages = usage;
  },

  SET_MESSAGING_USAGE(state, usage) {
    state.messagingUsages = usage;
  },

  SET_WORKFLOW_USAGE(state, usage) {
    state.workflowUsages = usage;
  },

  SET_CHATBOT_USAGE(state, usage) {
    state.chatbotUsages = usage;
  },

  SET_BIOMETRIC_USAGE(state, usage) {
    state.biometricUsages = usage;
  },

  SET_TRANSCRIPTION_USAGE(state, usage) {
    state.transcriptionUsages = usage;
  },

  SET_TONE_ANALYZER_USAGE(state, usage) {
    state.toneAnalyzerUsages = usage;
  },

  SET_NUMBER_INSIGHT_USAGE(state, usage) {
    state.numberInsightUsages = usage;
  },

  SET_TASK_FOR_DETAILED_LOG(state, task) {
    state.taskForDetailedLogs = task;
  },

  SET_LOG_SEARCH_RANGE(state, dates) {
    state.dateSearchRange = dates;
  },
  SET_DETAILED_LOGS(state, detailedLogs) {
    state.detailedLogs = detailedLogs;
  }
};

const actions = {
  // getVoiceUsages({ commit }, options) {
  //
  // },

  setTaskForDetailedLogs({ commit }, task) {
    commit("SET_TASK_FOR_DETAILED_LOG", task);
  },

  setDateSearchRange({ commit }, val) {
    commit("SET_LOG_SEARCH_RANGE", val);
  },

  getCallUsage({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getCallUsage(options)
        .then(({ data }) => {
          commit("SET_VOICE_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  resetDetailedUsages({ commit }) {
    commit("SET_DETAILED_LOGS", []);
  },

  getDetailedUsageLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getDetailedUsageLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getWorkflowUsage({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getWorkflowUsage(options)
        .then(({ data }) => {
          commit("SET_WORKFLOW_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getMessagingUsages({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getMessagingUsage(options)
        .then(({ data }) => {
          commit("SET_MESSAGING_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getDetailedMsgUsageLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getDetailedMsgUsageLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getChatbotUsages({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getChatbotUsages(options)
        .then(({ data }) => {
          commit("SET_CHATBOT_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getChatbotDetailedLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getChatbotDetailedLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getVoiceDetailedLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getVoiceDetailedLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getBiometricUsage({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getBiometricUsage(options)
        .then(({ data }) => {
          commit("SET_BIOMETRIC_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getBiometricDetailedLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getBiometricDetailedLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getTranscriptionUsage({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getTranscriptionUsage(options)
        .then(({ data }) => {
          commit("SET_TRANSCRIPTION_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getTranscriptionDetailedLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getTranscriptionDetailedLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getToneAnalyzerUsage({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getToneAnalyzerUsage(options)
        .then(({ data }) => {
          commit("SET_TONE_ANALYZER_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getToneAnalyzerDetailedLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getToneAnalyzerDetailedLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getNumberInsightUsage({ commit }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getNumberInsightUsage(options)
        .then(({ data }) => {
          commit("SET_NUMBER_INSIGHT_USAGE", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  },

  getNumberInsightDetailedLog({ commit, state }, options) {
    return new Promise((resolve, reject) => {
      if (!options["notShowLoader"]) {
        commit("CHANGE_LOADING", true);
      }
      options = { ...options };
      getNumberInsightDetailedLog(
        state.taskForDetailedLogs,
        state.dateSearchRange,
        options
      )
        .then(({ data }) => {
          commit("SET_DETAILED_LOGS", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          if (!options["notShowLoader"]) {
            commit("CHANGE_LOADING", false);
          }
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
