import {
  getTrunks,
  getAllTrunks,
  createTrunk,
  updateTrunk,
  deleteTrunk,
  registerTrunk,
  updateRegistration,
  getSBCIps
} from "@/api/trunks";
import _ from "lodash";

const state = {
  trunks: [],
  isLoading: false,
  SBC_PCI_PRIMARY_INTERNAL_IP_PORT: "",
  SBC_PCI_SECONDARY_INTERNAL_IP_PORT: "",
  SBC_PCI_PRIMARY_EXTERNAL_IP_PORT: "",
  SBC_PCI_SECONDARY_EXTERNAL_IP_PORT: ""
};

const mutations = {
  SET_TRUNKS(state, trunks) {
    state.trunks = trunks;
  },

  CREATE_TRUNK(state, trunk) {
    state.trunks.push(trunk);
  },

  UPDATE_TRUNK(state, { index, trunk }) {
    state.trunks.splice(index, 1, trunk);
  },

  ADD_TRUNK_REGISTRANT(state, { index, registration_data }) {
    state.trunks[index]["trunk_registrants"].push(registration_data);
  },

  UPDATE_TRUNK_REGISTRANT(state, { trunkIndex, index, registration_data }) {
    state.trunks[trunkIndex]["trunk_registrants"].splice(
      index,
      1,
      registration_data
    );
  },

  DELETE_TRUNK(state, trunk) {
    state.trunks = state.trunks.filter(
      oldTrunk => oldTrunk.trunk_id !== trunk.trunk_id
    );
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_SBC_IPS(state, data) {
    state.SBC_PCI_PRIMARY_INTERNAL_IP_PORT =
      data.SBC_PCI_PRIMARY_INTERNAL_IP_PORT;
    state.SBC_PCI_SECONDARY_INTERNAL_IP_PORT =
      data.SBC_PCI_SECONDARY_INTERNAL_IP_PORT;
    state.SBC_PCI_PRIMARY_EXTERNAL_IP_PORT =
      data.SBC_PCI_PRIMARY_EXTERNAL_IP_PORT;
    state.SBC_PCI_SECONDARY_EXTERNAL_IP_PORT =
      data.SBC_PCI_SECONDARY_EXTERNAL_IP_PORT;
  }
};

const actions = {
  getTrunks({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getTrunks(options)
        .then(({ data }) => {
          commit("SET_TRUNKS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getAllTrunks({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getAllTrunks(options)
        .then(({ data }) => {
          commit("SET_TRUNKS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  createTrunk({ commit }, trunk) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createTrunk(trunk)
        .then(({ data }) => {
          commit("CREATE_TRUNK", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  updateTrunk({ commit, state }, trunk) {
    return new Promise((resolve, reject) => {
      const trunkObj = { ...trunk };
      commit("UPDATE_LOADING_STATUS", true);
      updateTrunk(trunkObj)
        .then(({ data }) => {
          var index = _.findIndex(state.trunks, oldTrunk => {
            return oldTrunk.trunk_id === trunk.trunk_id;
          });
          commit("UPDATE_TRUNK", { index, trunk: data.data });
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteTrunk({ commit }, trunk) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteTrunk(trunk)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          commit("DELETE_TRUNK", trunk);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  undoDeleteTrunk({ commit }, trunk) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      trunk.is_enabled = 1;
      updateTrunk(trunk)
        .then(data => {
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  registerTrunk({ commit }, trunkRegistration) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      registerTrunk(trunkRegistration)
        .then(data => {
          const index = _.findIndex(state.trunks, oldTrunk => {
            return oldTrunk.trunk_id === trunkRegistration.trunk_id;
          });
          commit("UPDATE_LOADING_STATUS", false);
          commit("ADD_TRUNK_REGISTRANT", {
            index,
            registration_data: data.data.data
          });
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  updateRegistration({ commit, state }, trunkRegistration) {
    return new Promise((resolve, reject) => {
      const trunkRegObj = { ...trunkRegistration };
      commit("UPDATE_LOADING_STATUS", true);
      updateRegistration(trunkRegObj)
        .then(data => {
          const trunkIndex = _.findIndex(state.trunks, oldTrunk => {
            return oldTrunk.trunk_id === trunkRegistration.trunk_id;
          });
          const index = _.findIndex(
            state.trunks[trunkIndex]["trunk_registrants"],
            oldTrunk => {
              return (
                oldTrunk.trunk_registrant_id ===
                trunkRegistration.trunk_registrant_id
              );
            }
          );
          commit("UPDATE_LOADING_STATUS", false);
          commit("UPDATE_TRUNK_REGISTRANT", {
            trunkIndex,
            index,
            registration_data: data.data.data
          });
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  },

  getSBCIps({ commit }) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getSBCIps()
        .then(({ data }) => {
          commit("SET_SBC_IPS", data.data);
          commit("UPDATE_LOADING_STATUS", false);
          resolve(data);
        })
        .catch(err => {
          commit("UPDATE_LOADING_STATUS", false);
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
