import axios from "axios";
import store from "@/store";
import { processRequestTimeStamp } from "@/utils/sessionTimeout";
import _ from "lodash";
import { appendSector } from "@/utils/url";

// functions to create the correct headers to scope the request before making the request
export const sp_headers = () => {
  return {
    scope: "sp",
    "scope-id": store.getters.selectedServiceProviderId,
    "convert-timezone": 1
  };
};

export const ac_headers = () => {
  return {
    scope: "ac",
    "scope-id": store.getters.selectedAccountId,
    "convert-timezone": 1
  };
};

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_API
});

// request interceptor
service.interceptors.request.use(
  async config => {
    // handle request access timestamp
    processRequestTimeStamp(config);
    // do something before request is sent
    let appendSectorStr = appendSector(
      window.location.hostname,
      config.baseURL,
      config.url,
      true
    );
    if (appendSectorStr !== "") {
      config.params = { ...config.params, ...JSON.parse(appendSectorStr) };
    }

    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  response => {
    // do something before request is resolved and response sent back to called method
    if (["/auth/refresh", "/auth/login"].includes(response.config.url)) {
      if (response.data.token) {
        store.dispatch("app/tokenRefreshed", response.data.token);
      }
    }
    return response;
  },
  async error => {
    if (!isErrorDueToCancelledToken(error)) {
      return Promise.reject({
        ...error,
        message:
          (error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0] &&
            error.response.data.errors[0][0]) ||
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          (error.response &&
            error.response.data &&
            error.response.data instanceof Blob &&
            error.response.data.type === "application/json" &&
            JSON.parse(await error.response.data.text()).message) ||
          __("Operation Failed")
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export const cancelPendingRequests = cancelTokens => {
  _.map(cancelTokens, cancelToken =>
    cancelToken.cancel(__("Operation canceled due to new request."))
  );
};

export const generateCancelToken = () => {
  return axios.CancelToken.source();
};

export const isErrorDueToCancelledToken = err => {
  return axios.isCancel(err);
};

export const backendUrl = process.env.VUE_APP_BACKEND_BASE_API;

export default service;
