import request, { ac_headers } from "@/utils/studio7ApiService";

const baseURL = "/intent";

export function getIntent(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `${baseURL}/intent?` + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function createIntent(data) {
  const intent = { ...data, guard_name: "admin" };
  return request({
    url: `${baseURL}/intent`,
    method: "post",
    data: intent,
    headers: ac_headers()
  });
}

export function updateIntent(intent) {
  return request({
    url: `${baseURL}/intent/${intent.intent_id}`,
    method: "put",
    data: intent,
    headers: ac_headers()
  });
}

export function deleteIntent(intent) {
  return request({
    url: `${baseURL}/intent/${intent.intent_id}`,
    method: "delete",
    data: intent,
    headers: ac_headers()
  });
}

export function checkUniqueName(displayName) {
  return request({
    url: `${baseURL}/validate`,
    method: "post",
    data: { name: displayName },
    headers: ac_headers()
  });
}

export function getImportIntentTemplate() {
  return request({
    url: `${baseURL}/get-intent-template`,
    method: "get",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function importIntent(formData) {
  return request({
    url: `${baseURL}/import`,
    method: "post",
    headers: ac_headers(),
    data: formData
  });
}

export function exportIntent(intent) {
  return request({
    url: `${baseURL}/${intent.intent_id}/export`,
    method: "get",
    headers: ac_headers(),
    responseType: "blob"
  });
}
