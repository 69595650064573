import { getMsgCreditUsage } from "@/api/msgcreditusages";

const state = {
  remainingBalance: 0,
  usage: []
};

const mutations = {
  SET_MSG_CREDIT_USAGE(state, msgCreditUsage) {
    if (msgCreditUsage && msgCreditUsage.usage) {
      state.remainingBalance = msgCreditUsage.remaining_balance;
      state.usage = msgCreditUsage.usage;
    }
  }
};

const actions = {
  getMsgCreditUsage({ commit }, scope) {
    return new Promise((resolve, reject) => {
      getMsgCreditUsage(scope)
        .then(({ data }) => {
          commit("SET_MSG_CREDIT_USAGE", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
