import {
  generateBurstUsages,
  getBurstUsages,
  getBurstUsageItems,
  deleteBurstUsages,
  generateBurstUsageCsv
} from "@/api/burstUsages";

const state = {
  burstUsages: [],
  burstUsageItems: [],
  loading: false
};

export const getters = {};
const mutations = {
  SET_BURST_USAGES(state, burstUsages) {
    state.burstUsages = burstUsages;
  },

  SET_BURST_USAGE_ITEMS(state, burstUsageItems) {
    state.burstUsageItems = burstUsageItems;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  getBurstUsages({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getBurstUsages(options)
        .then(({ data }) => {
          commit("SET_BURST_USAGES", data.data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  getBurstUsageItems({ commit }, billing_burst_usage_id) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getBurstUsageItems(billing_burst_usage_id)
        .then(({ data }) => {
          commit("SET_BURST_USAGE_ITEMS", data);
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("CHANGE_LOADING", false);
          reject(err);
        });
    });
  },
  deleteBurstUsages({ commit }, featureUsage) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      deleteBurstUsages(featureUsage)
        .then(data => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  generateBurstUsages({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      generateBurstUsages(params)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          commit("SET_BURST_USAGES", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  generateBurstUsageCsv({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      generateBurstUsageCsv(params)
        .then(({ data }) => {
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
