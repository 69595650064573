import { getTimeZone } from "@/api/timezones";
import _ from "lodash";

const state = {
  timezones: undefined,
  isLoading: false
};

const mutations = {
  SET_TIMEZONES(state, timezones) {
    state.timezones = timezones;
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  getTimeZone({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(state.timezones)) {
        resolve();
      } else {
        commit("UPDATE_LOADING_STATUS", true);
        getTimeZone()
          .then(({ data }) => {
            commit("SET_TIMEZONES", data.data);
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            commit("UPDATE_LOADING_STATUS", false);
          });
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
