<template>
  <el-container class="h-full">
    <el-container>
      <slot name="list"></slot>
    </el-container>
    <el-aside class="form-aside relative" :width="`${asideWidth}%`">
      <div class="aside-content" v-if="formAction.length === 0">
        <el-image
          :src="require('@/assets/images/asidedefault_new.svg')"
          fit="cover"
        ></el-image>
        <div style="padding-top: 30px; padding-bottom: 30px; font-size: 14px;">
          {{ asideText }}
        </div>
        <slot name="buttonGroup">
          <el-button type="primary" @click="$emit('handleAsideButtonClick')">{{
            asideButtonText
          }}</el-button>
        </slot>
      </div>
      <div v-else>
        <slot name="form"></slot>
      </div>
    </el-aside>
  </el-container>
</template>

<script>
import __ from "@/translation";

export default {
  name: "MainContentIndex",
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: __("Select an item to edit and view more details.")
    },
    asideButtonText: {
      default: __("Create")
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 30
    }
  },
  mounted() {
    window.__ = __;
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: $--color-grey;
}
</style>
